const RealPrimeSymptomData = [
  {
    "outcome": "SEV",
    "first_prime_symptom": 19,
    "outlier": "CNF",
    "pathological_event_duration": 120,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "FIR",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": 98,
    "fatal_symptom_2": 120,
    "mild_symptom_1_duration": 107,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 15,
    "prime_symptom_1": 19,
    "prime_symptom_2": 21,
    "prime_symptom_3": 20
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 20,
    "outlier": "CNF",
    "pathological_event_duration": 46,
    "first_prime_symptom_type": "DCT",
    "consultant_doctor": "CRW",
    "prime_symptom_level": 2,
    "event_count": 2,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 32,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": 20,
    "prime_symptom_3": 21
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 10,
    "outlier": "CNF",
    "pathological_event_duration": 28,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "CRW",
    "prime_symptom_level": 4,
    "event_count": 2,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 13,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 18,
    "prime_symptom_2": null,
    "prime_symptom_3": 10
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 24,
    "outlier": "DUB",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "FIR",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 18,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 24
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 59,
    "outlier": "DUB",
    "pathological_event_duration": 125,
    "first_prime_symptom_type": "DCT",
    "consultant_doctor": "FIR",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": 112,
    "fatal_symptom_2": 125,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 79,
    "prime_symptom_2": 59,
    "prime_symptom_3": null
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 19,
    "outlier": "CNF",
    "pathological_event_duration": 62,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "FIR",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": 62,
    "fatal_symptom_2": 62,
    "mild_symptom_1_duration": 54,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 11,
    "prime_symptom_1": 31,
    "prime_symptom_2": 33,
    "prime_symptom_3": 19
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 15,
    "outlier": "CNF",
    "pathological_event_duration": 33,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "CRW",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 19,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 10,
    "prime_symptom_1": 15,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 18,
    "outlier": "CNF",
    "pathological_event_duration": 40,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "CRW",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 26,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 18,
    "prime_symptom_2": null,
    "prime_symptom_3": 20
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 16,
    "outlier": "CNF",
    "pathological_event_duration": 165,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "FIR",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": 165,
    "fatal_symptom_2": 157,
    "mild_symptom_1_duration": 157,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 12,
    "prime_symptom_1": 20,
    "prime_symptom_2": 24,
    "prime_symptom_3": 16
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 17,
    "outlier": "CNF",
    "pathological_event_duration": 22,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "CRW",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 6,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 20,
    "prime_symptom_2": 22,
    "prime_symptom_3": 17
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 15,
    "outlier": "CNF",
    "pathological_event_duration": 17,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "CRW",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 8,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 15,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 17,
    "outlier": "CNF",
    "pathological_event_duration": 113,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "FIR",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": 113,
    "fatal_symptom_2": 103,
    "mild_symptom_1_duration": 103,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 15,
    "prime_symptom_1": 22,
    "prime_symptom_2": 39,
    "prime_symptom_3": 17
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 14,
    "outlier": "CNF",
    "pathological_event_duration": 102,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "FIR",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": 102,
    "fatal_symptom_2": 102,
    "mild_symptom_1_duration": 94,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 11,
    "prime_symptom_1": 14,
    "prime_symptom_2": 35,
    "prime_symptom_3": null
  },
  {
    "outcome": "UNK",
    "first_prime_symptom": 13,
    "outlier": "CNF",
    "pathological_event_duration": 124,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 5,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 114,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 10,
    "prime_symptom_1": 21,
    "prime_symptom_2": 40,
    "prime_symptom_3": 13
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 20,
    "outlier": "CNF",
    "pathological_event_duration": 60,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 6,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 45,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 24,
    "prime_symptom_2": null,
    "prime_symptom_3": 20
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 13,
    "outlier": "CNF",
    "pathological_event_duration": 74,
    "first_prime_symptom_type": "DCT",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 4,
    "event_count": 2,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 7,
    "prime_symptom_1": null,
    "prime_symptom_2": 13,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 12,
    "outlier": "CNF",
    "pathological_event_duration": 42,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 6,
    "event_count": 3,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 32,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 10,
    "prime_symptom_1": 12,
    "prime_symptom_2": 23,
    "prime_symptom_3": 17
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 17,
    "outlier": "CNF",
    "pathological_event_duration": 60,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "CRW",
    "prime_symptom_level": 5,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 50,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 10,
    "prime_symptom_1": 18,
    "prime_symptom_2": 23,
    "prime_symptom_3": 17
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 10,
    "outlier": "CNF",
    "pathological_event_duration": 34,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "CRW",
    "prime_symptom_level": 5,
    "event_count": 2,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 22,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 10,
    "prime_symptom_2": 24,
    "prime_symptom_3": 16
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 16,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "FIR",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 16,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 21,
    "outlier": "CNF",
    "pathological_event_duration": 124,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "FIR",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": 124,
    "fatal_symptom_2": 124,
    "mild_symptom_1_duration": 106,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 19,
    "prime_symptom_1": 21,
    "prime_symptom_2": 60,
    "prime_symptom_3": 22
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 11,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "FIR",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 10,
    "prime_symptom_1": 11,
    "prime_symptom_2": 26,
    "prime_symptom_3": 13
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 32,
    "outlier": "CNF",
    "pathological_event_duration": 85,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "FIR",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": 85,
    "fatal_symptom_2": 75,
    "mild_symptom_1_duration": 77,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 11,
    "prime_symptom_1": null,
    "prime_symptom_2": 43,
    "prime_symptom_3": 32
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 14,
    "outlier": "CNF",
    "pathological_event_duration": 31,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "CRW",
    "prime_symptom_level": 1,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 19,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 31,
    "prime_symptom_2": null,
    "prime_symptom_3": 14
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 11,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "RPV",
    "prime_symptom_level": 2,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 11,
    "prime_symptom_2": null,
    "prime_symptom_3": 24
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 14,
    "outlier": "CNF",
    "pathological_event_duration": 55,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "RPV",
    "prime_symptom_level": 2,
    "event_count": 1,
    "fatal_symptom_1": 55,
    "fatal_symptom_2": 55,
    "mild_symptom_1_duration": 48,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 20,
    "prime_symptom_2": null,
    "prime_symptom_3": 14
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 10,
    "outlier": "CNF",
    "pathological_event_duration": 35,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "CRW",
    "prime_symptom_level": 2,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 10,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 14,
    "outlier": "CNF",
    "pathological_event_duration": 111,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "RPV",
    "prime_symptom_level": 7,
    "event_count": 1,
    "fatal_symptom_1": 111,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 101,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 14,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 18,
    "outlier": "CNF",
    "pathological_event_duration": 34,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "CRW",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 17,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 12,
    "prime_symptom_1": 34,
    "prime_symptom_2": null,
    "prime_symptom_3": 18
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 19,
    "outlier": "CNF",
    "pathological_event_duration": 135,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "FIR",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": 135,
    "fatal_symptom_2": 123,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 14,
    "prime_symptom_1": 19,
    "prime_symptom_2": 68,
    "prime_symptom_3": 21
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 16,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "FIR",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 14,
    "prime_symptom_1": 20,
    "prime_symptom_2": 31,
    "prime_symptom_3": 16
  },
  {
    "outcome": "UNK",
    "first_prime_symptom": 29,
    "outlier": "DUB",
    "pathological_event_duration": 52,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "RPV",
    "prime_symptom_level": 3,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 29,
    "prime_symptom_2": 32,
    "prime_symptom_3": 52
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 44,
    "outlier": "CNF",
    "pathological_event_duration": 104,
    "first_prime_symptom_type": "DCT",
    "consultant_doctor": "RPV",
    "prime_symptom_level": 1,
    "event_count": 1,
    "fatal_symptom_1": 104,
    "fatal_symptom_2": 104,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 26,
    "prime_symptom_1": null,
    "prime_symptom_2": 44,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 23,
    "outlier": "CNF",
    "pathological_event_duration": 104,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "AGG",
    "prime_symptom_level": 6,
    "event_count": 6,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 9,
    "prime_symptom_1": 23,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 11,
    "outlier": "CNF",
    "pathological_event_duration": 52,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "AGG",
    "prime_symptom_level": 6,
    "event_count": 7,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 11,
    "prime_symptom_2": null,
    "prime_symptom_3": 21
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 12,
    "outlier": "CNF",
    "pathological_event_duration": 74,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "AGG",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 62,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 12,
    "prime_symptom_2": null,
    "prime_symptom_3": 15
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 17,
    "outlier": "CNF",
    "pathological_event_duration": 42,
    "first_prime_symptom_type": "DCT",
    "consultant_doctor": "AGG",
    "prime_symptom_level": 3,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 42,
    "prime_symptom_1": 23,
    "prime_symptom_2": 17,
    "prime_symptom_3": 19
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 13,
    "outlier": "CNF",
    "pathological_event_duration": 63,
    "first_prime_symptom_type": "DCT",
    "consultant_doctor": "AGG",
    "prime_symptom_level": 1,
    "event_count": 3,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 17,
    "prime_symptom_1": null,
    "prime_symptom_2": 13,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 10,
    "outlier": "CNF",
    "pathological_event_duration": 28,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "CRW",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 16,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 10
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 12,
    "outlier": "CNF",
    "pathological_event_duration": 37,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "CRW",
    "prime_symptom_level": 4,
    "event_count": 3,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 28,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 12,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 10,
    "outlier": "CNF",
    "pathological_event_duration": 35,
    "first_prime_symptom_type": "DCT",
    "consultant_doctor": "CRW",
    "prime_symptom_level": 3,
    "event_count": 7,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 26,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": 10,
    "prime_symptom_3": 30
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 10,
    "outlier": "CNF",
    "pathological_event_duration": 57,
    "first_prime_symptom_type": "DCT",
    "consultant_doctor": "CRW",
    "prime_symptom_level": 2,
    "event_count": 8,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 49,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": 10,
    "prime_symptom_3": null
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 10,
    "outlier": "CNF",
    "pathological_event_duration": 167,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "RPV",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": 167,
    "fatal_symptom_2": 167,
    "mild_symptom_1_duration": 158,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 10
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 17,
    "outlier": "CNF",
    "pathological_event_duration": 45,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "RPV",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": 45,
    "fatal_symptom_2": 33,
    "mild_symptom_1_duration": 32,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 17
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 53,
    "outlier": "DUB",
    "pathological_event_duration": 242,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "RPV",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": 242,
    "fatal_symptom_2": 242,
    "mild_symptom_1_duration": 220,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 53
  },
  {
    "outcome": "UNK",
    "first_prime_symptom": 24,
    "outlier": "CNF",
    "pathological_event_duration": 36,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "RPV",
    "prime_symptom_level": 5,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 18,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 20,
    "prime_symptom_1": 36,
    "prime_symptom_2": null,
    "prime_symptom_3": 24
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 14,
    "outlier": "CNF",
    "pathological_event_duration": 176,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "RPV",
    "prime_symptom_level": 6,
    "event_count": 1,
    "fatal_symptom_1": 156,
    "fatal_symptom_2": 176,
    "mild_symptom_1_duration": 163,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 20,
    "prime_symptom_2": 49,
    "prime_symptom_3": 14
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 12,
    "outlier": "CNF",
    "pathological_event_duration": 129,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "RPV",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": 129,
    "fatal_symptom_2": 129,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 12
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 15,
    "outlier": "CNF",
    "pathological_event_duration": 138,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "RPV",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": 138,
    "fatal_symptom_2": 129,
    "mild_symptom_1_duration": 128,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 10,
    "prime_symptom_1": 30,
    "prime_symptom_2": 34,
    "prime_symptom_3": 15
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 17,
    "outlier": "CNF",
    "pathological_event_duration": 78,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "RPV",
    "prime_symptom_level": 1,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": 78,
    "mild_symptom_1_duration": 56,
    "mild_symptom_1_2": 50,
    "mild_symptom_2": 13,
    "prime_symptom_1": 20,
    "prime_symptom_2": null,
    "prime_symptom_3": 17
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 21,
    "outlier": "CNF",
    "pathological_event_duration": 78,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "RPV",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": 78,
    "fatal_symptom_2": 51,
    "mild_symptom_1_duration": 68,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 21,
    "prime_symptom_2": 51,
    "prime_symptom_3": 23
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 16,
    "outlier": "CNF",
    "pathological_event_duration": 27,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "CRW",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 19,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 12,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 16
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 16,
    "outlier": "CNF",
    "pathological_event_duration": 30,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "CRW",
    "prime_symptom_level": 5,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 14,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 12,
    "prime_symptom_1": 16,
    "prime_symptom_2": null,
    "prime_symptom_3": 18
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 18,
    "outlier": "CNF",
    "pathological_event_duration": 52,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 5,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 45,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 18,
    "prime_symptom_2": 32,
    "prime_symptom_3": 23
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 9,
    "outlier": "CNF",
    "pathological_event_duration": 18,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 5,
    "event_count": 2,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 9,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 6,
    "outlier": "CNF",
    "pathological_event_duration": 36,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "CRW",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 6,
    "prime_symptom_2": 11,
    "prime_symptom_3": 20
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 14,
    "outlier": "CNF",
    "pathological_event_duration": 15,
    "first_prime_symptom_type": "DCT",
    "consultant_doctor": "HPR",
    "prime_symptom_level": 2,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 5,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": 14,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 12,
    "outlier": "CNF",
    "pathological_event_duration": 29,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "HPR",
    "prime_symptom_level": 3,
    "event_count": 2,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 19,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 12,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 20,
    "outlier": "CNF",
    "pathological_event_duration": 25,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "CRW",
    "prime_symptom_level": 3,
    "event_count": 3,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 6,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 22,
    "prime_symptom_2": null,
    "prime_symptom_3": 20
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 14,
    "outlier": "CNF",
    "pathological_event_duration": 14,
    "first_prime_symptom_type": "DCT",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 2,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": 14,
    "prime_symptom_3": null
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 32,
    "outlier": "CNF",
    "pathological_event_duration": 50,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "RPV",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": 50,
    "fatal_symptom_2": 50,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 16,
    "prime_symptom_1": 32,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 22,
    "outlier": "CNF",
    "pathological_event_duration": 32,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "AMT",
    "prime_symptom_level": null,
    "event_count": 2,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 12,
    "prime_symptom_1": 22,
    "prime_symptom_2": 22,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 21,
    "outlier": "CNF",
    "pathological_event_duration": 34,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 3,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 15,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 23,
    "prime_symptom_1": 21,
    "prime_symptom_2": 23,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 28,
    "outlier": "CNF",
    "pathological_event_duration": 84,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "AMT",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 28
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 10,
    "outlier": "CNF",
    "pathological_event_duration": 34,
    "first_prime_symptom_type": "DCT",
    "consultant_doctor": "AMT",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 20,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": 10,
    "prime_symptom_3": 10
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 10,
    "outlier": "DUB",
    "pathological_event_duration": 39,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "RPV",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": 39,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 10
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 10,
    "outlier": "CNF",
    "pathological_event_duration": 14,
    "first_prime_symptom_type": "DCT",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 1,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 4,
    "prime_symptom_1": null,
    "prime_symptom_2": 10,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 23,
    "outlier": "CNF",
    "pathological_event_duration": 24,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "CRW",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 14,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 23
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 12,
    "outlier": "CNF",
    "pathological_event_duration": 29,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "BOD",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 17,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 12,
    "prime_symptom_2": null,
    "prime_symptom_3": 28
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 8,
    "outlier": "CNF",
    "pathological_event_duration": 37,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 4,
    "event_count": 3,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 2,
    "prime_symptom_1": 8,
    "prime_symptom_2": 8,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 16,
    "outlier": "CNF",
    "pathological_event_duration": 24,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 10,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 14,
    "prime_symptom_1": 18,
    "prime_symptom_2": null,
    "prime_symptom_3": 16
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 17,
    "outlier": "CNF",
    "pathological_event_duration": 53,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 6,
    "event_count": 2,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 45,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 11,
    "prime_symptom_1": 18,
    "prime_symptom_2": null,
    "prime_symptom_3": 17
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 15,
    "outlier": "CNF",
    "pathological_event_duration": 97,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 5,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 88,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 11,
    "prime_symptom_1": 15,
    "prime_symptom_2": null,
    "prime_symptom_3": 40
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 30,
    "outlier": "CNF",
    "pathological_event_duration": 41,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 1,
    "event_count": 5,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 37,
    "prime_symptom_1": null,
    "prime_symptom_2": 35,
    "prime_symptom_3": 30
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 12,
    "outlier": "CNF",
    "pathological_event_duration": 24,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 3,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 14,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 7,
    "prime_symptom_1": 12,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 12,
    "outlier": "CNF",
    "pathological_event_duration": 30,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 20,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 9,
    "prime_symptom_1": 22,
    "prime_symptom_2": null,
    "prime_symptom_3": 12
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 16,
    "outlier": "CNF",
    "pathological_event_duration": 20,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "CRW",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 16
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 19,
    "outlier": "CNF",
    "pathological_event_duration": 47,
    "first_prime_symptom_type": "DCT",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 2,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 9,
    "prime_symptom_1": null,
    "prime_symptom_2": 19,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 12,
    "outlier": "CNF",
    "pathological_event_duration": 18,
    "first_prime_symptom_type": "DCT",
    "consultant_doctor": "BOD",
    "prime_symptom_level": 2,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 9,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": 12,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 25,
    "outlier": "CNF",
    "pathological_event_duration": 25,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 5,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 17,
    "prime_symptom_1": 25,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 38,
    "outlier": "CNF",
    "pathological_event_duration": 45,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "CRW",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 38
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 55,
    "outlier": "DUB",
    "pathological_event_duration": 55,
    "first_prime_symptom_type": "DCT",
    "consultant_doctor": "AMT",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": 71,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": 55,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 38,
    "outlier": "CNF",
    "pathological_event_duration": 45,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "AMT",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 38
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 24,
    "outlier": "CNF",
    "pathological_event_duration": 30,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "COC",
    "prime_symptom_level": 3,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 13,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 24,
    "prime_symptom_1": 30,
    "prime_symptom_2": null,
    "prime_symptom_3": 24
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 11,
    "outlier": "CNF",
    "pathological_event_duration": 27,
    "first_prime_symptom_type": "DCT",
    "consultant_doctor": "HPR",
    "prime_symptom_level": 3,
    "event_count": 3,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 17,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": 11,
    "prime_symptom_3": 12
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 23,
    "outlier": "CNF",
    "pathological_event_duration": 153,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "RPV",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": 153,
    "fatal_symptom_2": 153,
    "mild_symptom_1_duration": 135,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 23,
    "prime_symptom_2": 63,
    "prime_symptom_3": 32
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 18,
    "outlier": "CNF",
    "pathological_event_duration": 74,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "RPV",
    "prime_symptom_level": 2,
    "event_count": 1,
    "fatal_symptom_1": 74,
    "fatal_symptom_2": 63,
    "mild_symptom_1_duration": 64,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 24,
    "prime_symptom_2": null,
    "prime_symptom_3": 18
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 23,
    "outlier": "CNF",
    "pathological_event_duration": 38,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "NEM",
    "prime_symptom_level": 2,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 23,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 12,
    "outlier": "CNF",
    "pathological_event_duration": 14,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "CRW",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 12
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 23,
    "outlier": "CNF",
    "pathological_event_duration": 23,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "CRW",
    "prime_symptom_level": 2,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 23,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 23,
    "outlier": "CNF",
    "pathological_event_duration": 23,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "CRW",
    "prime_symptom_level": 2,
    "event_count": 3,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 23,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 19,
    "outlier": "CNF",
    "pathological_event_duration": 25,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "CRW",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 11,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 14,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 19
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 37,
    "outlier": "CNF",
    "pathological_event_duration": 71,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "CRW",
    "prime_symptom_level": 5,
    "event_count": 2,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 59,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 9,
    "prime_symptom_1": 37,
    "prime_symptom_2": 47,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 30,
    "outlier": "CNF",
    "pathological_event_duration": 47,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 4,
    "event_count": 2,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 29,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 18,
    "prime_symptom_1": 30,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 70,
    "outlier": "CNF",
    "pathological_event_duration": 93,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 3,
    "event_count": 2,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 42,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 57,
    "prime_symptom_1": 70,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 20,
    "outlier": "CNF",
    "pathological_event_duration": 35,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "CRW",
    "prime_symptom_level": 1,
    "event_count": 4,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 16,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 19,
    "prime_symptom_1": 20,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 10,
    "outlier": "CNF",
    "pathological_event_duration": 27,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "CRW",
    "prime_symptom_level": 2,
    "event_count": 9,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 17,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 10,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 13,
    "outlier": "CNF",
    "pathological_event_duration": 31,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "CRW",
    "prime_symptom_level": 3,
    "event_count": 10,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 6,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 13,
    "prime_symptom_2": null,
    "prime_symptom_3": 25
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 10,
    "outlier": "CNF",
    "pathological_event_duration": 26,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "CRW",
    "prime_symptom_level": 3,
    "event_count": 11,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 19,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 10,
    "prime_symptom_2": null,
    "prime_symptom_3": 20
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 12,
    "outlier": "CNF",
    "pathological_event_duration": 23,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "CRW",
    "prime_symptom_level": null,
    "event_count": 13,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 16,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 12,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 9,
    "outlier": "CNF",
    "pathological_event_duration": 26,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "CRW",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 12,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 11,
    "prime_symptom_2": 10,
    "prime_symptom_3": 9
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 18,
    "outlier": "CNF",
    "pathological_event_duration": 83,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "RPV",
    "prime_symptom_level": 6,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 68,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 18,
    "prime_symptom_2": 25,
    "prime_symptom_3": 37
  },
  {
    "outcome": "UNK",
    "first_prime_symptom": 7,
    "outlier": "CNF",
    "pathological_event_duration": 77,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "RPV",
    "prime_symptom_level": 3,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 27,
    "prime_symptom_1": 77,
    "prime_symptom_2": null,
    "prime_symptom_3": 7
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 16,
    "outlier": "CNF",
    "pathological_event_duration": 51,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 43,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 16,
    "prime_symptom_2": 29,
    "prime_symptom_3": 16
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 15,
    "outlier": "CNF",
    "pathological_event_duration": 20,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 3,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 13,
    "prime_symptom_1": 15,
    "prime_symptom_2": null,
    "prime_symptom_3": 20
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 20,
    "outlier": "DUB",
    "pathological_event_duration": 67,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "RPV",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": 67,
    "fatal_symptom_2": 67,
    "mild_symptom_1_duration": 51,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 12,
    "prime_symptom_1": 20,
    "prime_symptom_2": null,
    "prime_symptom_3": 22
  },
  {
    "outcome": "UNK",
    "first_prime_symptom": 14,
    "outlier": "CNF",
    "pathological_event_duration": 24,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "RPV",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 15,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 14,
    "prime_symptom_2": null,
    "prime_symptom_3": 21
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 22,
    "outlier": "CNF",
    "pathological_event_duration": 95,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "RPV",
    "prime_symptom_level": 2,
    "event_count": 1,
    "fatal_symptom_1": 95,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 77,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 18,
    "prime_symptom_1": 22,
    "prime_symptom_2": null,
    "prime_symptom_3": 53
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 35,
    "outlier": "CNF",
    "pathological_event_duration": 90,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 6,
    "event_count": 2,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 55,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 31,
    "prime_symptom_1": 35,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 19,
    "outlier": "CNF",
    "pathological_event_duration": 37,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 5,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 20,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 15,
    "prime_symptom_1": 19,
    "prime_symptom_2": null,
    "prime_symptom_3": 24
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 18,
    "outlier": "DUB",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "AMT",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 18,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 14,
    "outlier": "CNF",
    "pathological_event_duration": 38,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "PRV",
    "prime_symptom_level": 3,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": 38,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 22,
    "prime_symptom_2": null,
    "prime_symptom_3": 14
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 21,
    "outlier": "CNF",
    "pathological_event_duration": 92,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "PRV",
    "prime_symptom_level": 2,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": 52,
    "mild_symptom_1_duration": 75,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 21,
    "prime_symptom_2": null,
    "prime_symptom_3": 31
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 6,
    "outlier": "CNF",
    "pathological_event_duration": 22,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 1,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 16,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 6,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 10,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "DEE",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 10,
    "prime_symptom_2": 38,
    "prime_symptom_3": 15
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 14,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "DEE",
    "prime_symptom_level": 3,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 14,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 18,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "DCT",
    "consultant_doctor": "DEE",
    "prime_symptom_level": 1,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": 18,
    "prime_symptom_3": null
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 11,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "DEE",
    "prime_symptom_level": 3,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 11,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 15,
    "outlier": "CNF",
    "pathological_event_duration": 55,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "RPV",
    "prime_symptom_level": 3,
    "event_count": 1,
    "fatal_symptom_1": 55,
    "fatal_symptom_2": 55,
    "mild_symptom_1_duration": 48,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 15,
    "prime_symptom_2": null,
    "prime_symptom_3": 15
  },
  {
    "outcome": "UNK",
    "first_prime_symptom": 21,
    "outlier": "CNF",
    "pathological_event_duration": 81,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "RPV",
    "prime_symptom_level": 3,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 66,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 15,
    "prime_symptom_1": 21,
    "prime_symptom_2": 81,
    "prime_symptom_3": 23
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 26,
    "outlier": "DUB",
    "pathological_event_duration": 476,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "RPV",
    "prime_symptom_level": 2,
    "event_count": 1,
    "fatal_symptom_1": 476,
    "fatal_symptom_2": 476,
    "mild_symptom_1_duration": 450,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 26,
    "prime_symptom_2": null,
    "prime_symptom_3": 26
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 26,
    "outlier": "DUB",
    "pathological_event_duration": 88,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "RPV",
    "prime_symptom_level": 1,
    "event_count": 1,
    "fatal_symptom_1": 81,
    "fatal_symptom_2": 88,
    "mild_symptom_1_duration": 74,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": 46,
    "prime_symptom_3": 26
  },
  {
    "outcome": "UNK",
    "first_prime_symptom": 20,
    "outlier": "DUB",
    "pathological_event_duration": 24,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "RPV",
    "prime_symptom_level": 1,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 13,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 17,
    "prime_symptom_1": 20,
    "prime_symptom_2": null,
    "prime_symptom_3": 24
  },
  {
    "outcome": "UNK",
    "first_prime_symptom": 4,
    "outlier": "DUB",
    "pathological_event_duration": 4,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "RPV",
    "prime_symptom_level": 1,
    "event_count": 1,
    "fatal_symptom_1": 102,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 4
  },
  {
    "outcome": "UNK",
    "first_prime_symptom": 11,
    "outlier": "CNF",
    "pathological_event_duration": 49,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "PRV",
    "prime_symptom_level": 1,
    "event_count": 1,
    "fatal_symptom_1": 71,
    "fatal_symptom_2": 60,
    "mild_symptom_1_duration": 42,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 49,
    "prime_symptom_1": 11,
    "prime_symptom_2": 45,
    "prime_symptom_3": 27
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 10,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "DCT",
    "consultant_doctor": "DEE",
    "prime_symptom_level": 3,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 16,
    "prime_symptom_2": 10,
    "prime_symptom_3": 15
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 70,
    "outlier": "CNF",
    "pathological_event_duration": 99,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "DEE",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": 99,
    "fatal_symptom_2": 99,
    "mild_symptom_1_duration": 29,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 19,
    "prime_symptom_1": 70,
    "prime_symptom_2": null,
    "prime_symptom_3": 85
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 52,
    "outlier": "CNF",
    "pathological_event_duration": 52,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "PRV",
    "prime_symptom_level": 1,
    "event_count": 1,
    "fatal_symptom_1": 52,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 42,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 52,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 13,
    "outlier": "CNF",
    "pathological_event_duration": 38,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "DEE",
    "prime_symptom_level": 2,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": 38,
    "mild_symptom_1_duration": 25,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 13,
    "prime_symptom_2": null,
    "prime_symptom_3": 15
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 20,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "DEE",
    "prime_symptom_level": 1,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 5,
    "prime_symptom_1": 20,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 12,
    "outlier": "CNF",
    "pathological_event_duration": 30,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "DEE",
    "prime_symptom_level": 2,
    "event_count": 1,
    "fatal_symptom_1": 30,
    "fatal_symptom_2": 30,
    "mild_symptom_1_duration": 25,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 12,
    "prime_symptom_2": null,
    "prime_symptom_3": 27
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 13,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "DEE",
    "prime_symptom_level": 3,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 13,
    "prime_symptom_2": 38,
    "prime_symptom_3": 18
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 30,
    "outlier": "DUB",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "DEE",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 30
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 23,
    "outlier": "CNF",
    "pathological_event_duration": 48,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "DEE",
    "prime_symptom_level": 3,
    "event_count": 1,
    "fatal_symptom_1": 48,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 35,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 6,
    "prime_symptom_1": 23,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "UNK",
    "first_prime_symptom": 27,
    "outlier": "CNF",
    "pathological_event_duration": 60,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "PRV",
    "prime_symptom_level": 2,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 49,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 20,
    "prime_symptom_1": 27,
    "prime_symptom_2": 60,
    "prime_symptom_3": null
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 15,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "DEE",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 15,
    "prime_symptom_2": null,
    "prime_symptom_3": 23
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 28,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "DEE",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 28
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 28,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "DEE",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 28
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 30,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "DCT",
    "consultant_doctor": "DEE",
    "prime_symptom_level": 1,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 25,
    "prime_symptom_1": null,
    "prime_symptom_2": 30,
    "prime_symptom_3": 31
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 22,
    "outlier": "CNF",
    "pathological_event_duration": 39,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "DEE",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": 39,
    "fatal_symptom_2": 39,
    "mild_symptom_1_duration": 17,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 22,
    "prime_symptom_2": null,
    "prime_symptom_3": 24
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 12,
    "outlier": "CNF",
    "pathological_event_duration": 58,
    "first_prime_symptom_type": "DCT",
    "consultant_doctor": "DEE",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": 58,
    "fatal_symptom_2": 58,
    "mild_symptom_1_duration": 54,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 35,
    "prime_symptom_2": 12,
    "prime_symptom_3": 25
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 18,
    "outlier": "CNF",
    "pathological_event_duration": 118,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "DEE",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": 118,
    "fatal_symptom_2": 42,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 35,
    "prime_symptom_2": 25,
    "prime_symptom_3": 18
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 28,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "DEE",
    "prime_symptom_level": 3,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 28,
    "prime_symptom_2": null,
    "prime_symptom_3": 29
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 27,
    "outlier": "CNF",
    "pathological_event_duration": 57,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "DEE",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": 57,
    "fatal_symptom_2": 57,
    "mild_symptom_1_duration": 37,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 27,
    "prime_symptom_2": null,
    "prime_symptom_3": 27
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 63,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "DEE",
    "prime_symptom_level": 2,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 10,
    "prime_symptom_1": 63,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 24,
    "outlier": "CNF",
    "pathological_event_duration": 35,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "DEE",
    "prime_symptom_level": 5,
    "event_count": 1,
    "fatal_symptom_1": 35,
    "fatal_symptom_2": 35,
    "mild_symptom_1_duration": 15,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 24,
    "prime_symptom_2": null,
    "prime_symptom_3": 30
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 19,
    "outlier": "CNF",
    "pathological_event_duration": 76,
    "first_prime_symptom_type": "DCT",
    "consultant_doctor": "DEE",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": 76,
    "fatal_symptom_2": 76,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 14,
    "prime_symptom_1": 40,
    "prime_symptom_2": 19,
    "prime_symptom_3": 40
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 13,
    "outlier": "CNF",
    "pathological_event_duration": 50,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "DEE",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": 50,
    "fatal_symptom_2": 50,
    "mild_symptom_1_duration": 39,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 13,
    "prime_symptom_2": null,
    "prime_symptom_3": 38
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 28,
    "outlier": "CNF",
    "pathological_event_duration": 130,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "DEE",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": 130,
    "fatal_symptom_2": 115,
    "mild_symptom_1_duration": 119,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 28,
    "prime_symptom_2": 43,
    "prime_symptom_3": 28
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 31,
    "outlier": "DUB",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "DEE",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 31
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 24,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "DEE",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 52,
    "prime_symptom_1": 30,
    "prime_symptom_2": null,
    "prime_symptom_3": 24
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 30,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "DEE",
    "prime_symptom_level": 2,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 12,
    "prime_symptom_1": 30,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 20,
    "outlier": "CNF",
    "pathological_event_duration": 73,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "DEE",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": 73,
    "fatal_symptom_2": 43,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 20,
    "prime_symptom_2": null,
    "prime_symptom_3": 32
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 15,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "DEE",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 15,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 23,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "DEE",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 23,
    "prime_symptom_2": null,
    "prime_symptom_3": 31
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 15,
    "outlier": "CNF",
    "pathological_event_duration": 73,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "DEE",
    "prime_symptom_level": 3,
    "event_count": 1,
    "fatal_symptom_1": 73,
    "fatal_symptom_2": 69,
    "mild_symptom_1_duration": 58,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 28,
    "prime_symptom_2": null,
    "prime_symptom_3": 15
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 15,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "DEE",
    "prime_symptom_level": 3,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 26,
    "prime_symptom_2": null,
    "prime_symptom_3": 15
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 15,
    "outlier": "CNF",
    "pathological_event_duration": 75,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "DEE",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": 75,
    "fatal_symptom_2": 41,
    "mild_symptom_1_duration": 60,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 15,
    "prime_symptom_2": null,
    "prime_symptom_3": 28
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 13,
    "outlier": "CNF",
    "pathological_event_duration": 70,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "PRV",
    "prime_symptom_level": 6,
    "event_count": 1,
    "fatal_symptom_1": 70,
    "fatal_symptom_2": 53,
    "mild_symptom_1_duration": 60,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 13,
    "prime_symptom_2": 33,
    "prime_symptom_3": 22
  },
  {
    "outcome": "UNK",
    "first_prime_symptom": 17,
    "outlier": "CNF",
    "pathological_event_duration": 21,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "PRV",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 12,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 17,
    "prime_symptom_2": 21,
    "prime_symptom_3": 17
  },
  {
    "outcome": "UNK",
    "first_prime_symptom": 16,
    "outlier": "CNF",
    "pathological_event_duration": 25,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "PRV",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": 58,
    "fatal_symptom_2": 58,
    "mild_symptom_1_duration": 21,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 25,
    "prime_symptom_2": null,
    "prime_symptom_3": 16
  },
  {
    "outcome": "UNK",
    "first_prime_symptom": 22,
    "outlier": "CNF",
    "pathological_event_duration": 50,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "PRV",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": 62,
    "fatal_symptom_2": 62,
    "mild_symptom_1_duration": 32,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 31,
    "prime_symptom_2": 50,
    "prime_symptom_3": 22
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 27,
    "outlier": "CNF",
    "pathological_event_duration": 82,
    "first_prime_symptom_type": "DCT",
    "consultant_doctor": "PRV",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": 82,
    "fatal_symptom_2": 75,
    "mild_symptom_1_duration": 67,
    "mild_symptom_1_2": 48,
    "mild_symptom_2": 12,
    "prime_symptom_1": null,
    "prime_symptom_2": 27,
    "prime_symptom_3": 27
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 25,
    "outlier": "CNF",
    "pathological_event_duration": 60,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "PRV",
    "prime_symptom_level": 6,
    "event_count": 1,
    "fatal_symptom_1": 60,
    "fatal_symptom_2": 40,
    "mild_symptom_1_duration": 54,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 29,
    "prime_symptom_2": null,
    "prime_symptom_3": 25
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 50,
    "outlier": "CNF",
    "pathological_event_duration": 125,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "PRV",
    "prime_symptom_level": 5,
    "event_count": 1,
    "fatal_symptom_1": 125,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 105,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 50,
    "prime_symptom_1": 50,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 23,
    "outlier": "CNF",
    "pathological_event_duration": 90,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "PRV",
    "prime_symptom_level": 5,
    "event_count": 1,
    "fatal_symptom_1": 90,
    "fatal_symptom_2": 59,
    "mild_symptom_1_duration": 73,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 15,
    "prime_symptom_1": 23,
    "prime_symptom_2": 23,
    "prime_symptom_3": 33
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 22,
    "outlier": "CNF",
    "pathological_event_duration": 88,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "PRV",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": 88,
    "fatal_symptom_2": 72,
    "mild_symptom_1_duration": 74,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 14,
    "prime_symptom_1": 39,
    "prime_symptom_2": null,
    "prime_symptom_3": 22
  },
  {
    "outcome": "UNK",
    "first_prime_symptom": 25,
    "outlier": "CNF",
    "pathological_event_duration": 36,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "PRV",
    "prime_symptom_level": 5,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 11,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 36,
    "prime_symptom_2": 30,
    "prime_symptom_3": 25
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 19,
    "outlier": "CNF",
    "pathological_event_duration": 32,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "AMT",
    "prime_symptom_level": null,
    "event_count": 3,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 6,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 12,
    "prime_symptom_1": 19,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 19,
    "outlier": "CNF",
    "pathological_event_duration": 19,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 5,
    "event_count": 4,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 12,
    "prime_symptom_1": 19,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 60,
    "outlier": "CNF",
    "pathological_event_duration": 63,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 3,
    "event_count": 1,
    "fatal_symptom_1": 107,
    "fatal_symptom_2": 107,
    "mild_symptom_1_duration": 12,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 51,
    "prime_symptom_1": 63,
    "prime_symptom_2": null,
    "prime_symptom_3": 60
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 21,
    "outlier": "CNF",
    "pathological_event_duration": 31,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "AMT",
    "prime_symptom_level": null,
    "event_count": 2,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 31,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 21
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 40,
    "outlier": "CNF",
    "pathological_event_duration": 56,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "CRW",
    "prime_symptom_level": 3,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 46,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 12,
    "prime_symptom_1": 40,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 15,
    "outlier": "CNF",
    "pathological_event_duration": 28,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 2,
    "event_count": 3,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 21,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 9,
    "prime_symptom_1": 15,
    "prime_symptom_2": 15,
    "prime_symptom_3": 28
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 20,
    "outlier": "UNU",
    "pathological_event_duration": 77,
    "first_prime_symptom_type": "DCT",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 2,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 12,
    "prime_symptom_1": null,
    "prime_symptom_2": 20,
    "prime_symptom_3": null
  },
  {
    "outcome": "UNK",
    "first_prime_symptom": 31,
    "outlier": "UNU",
    "pathological_event_duration": 35,
    "first_prime_symptom_type": "DCT",
    "consultant_doctor": "PRV",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": 62,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 19,
    "prime_symptom_1": 35,
    "prime_symptom_2": 31,
    "prime_symptom_3": 35
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 10,
    "outlier": "CNF",
    "pathological_event_duration": 17,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 6,
    "event_count": 2,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 7,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 10,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 15,
    "outlier": "CNF",
    "pathological_event_duration": 31,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "AMT",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 18,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 15,
    "prime_symptom_2": null,
    "prime_symptom_3": 31
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 101,
    "outlier": "EAR",
    "pathological_event_duration": 101,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "AMT",
    "prime_symptom_level": null,
    "event_count": 11,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 33,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 85,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 101
  },
  {
    "outcome": "NSV",
    "first_prime_symptom": 41,
    "outlier": "EAR",
    "pathological_event_duration": 51,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "AMT",
    "prime_symptom_level": 1,
    "event_count": 13,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 38,
    "prime_symptom_1": 41,
    "prime_symptom_2": null,
    "prime_symptom_3": null
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 11,
    "outlier": "CNF",
    "pathological_event_duration": 60,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "PRI",
    "prime_symptom_level": 2,
    "event_count": 1,
    "fatal_symptom_1": 60,
    "fatal_symptom_2": 38,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 34,
    "prime_symptom_2": null,
    "prime_symptom_3": 11
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 96,
    "outlier": "UNU",
    "pathological_event_duration": 108,
    "first_prime_symptom_type": "DCT",
    "consultant_doctor": "PRI",
    "prime_symptom_level": 1,
    "event_count": 1,
    "fatal_symptom_1": 108,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": 22,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 80,
    "prime_symptom_1": null,
    "prime_symptom_2": 96,
    "prime_symptom_3": null
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 21,
    "outlier": "CNF",
    "pathological_event_duration": 163,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "PRV",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": 163,
    "fatal_symptom_2": 136,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 17,
    "prime_symptom_1": 21,
    "prime_symptom_2": 37,
    "prime_symptom_3": 27
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 34,
    "outlier": "CNF",
    "pathological_event_duration": 141,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "PRV",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": 141,
    "mild_symptom_1_duration": 109,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 19,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 34
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 17,
    "outlier": "UNU",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "PRV",
    "prime_symptom_level": 3,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 11,
    "prime_symptom_1": 17,
    "prime_symptom_2": null,
    "prime_symptom_3": 19
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 17,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "PRV",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 17
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 28,
    "outlier": "UNU",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "PRV",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 28
  },
  {
    "outcome": "UNK",
    "first_prime_symptom": 14,
    "outlier": "CNF",
    "pathological_event_duration": 24,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "PRV",
    "prime_symptom_level": 3,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 14,
    "prime_symptom_2": null,
    "prime_symptom_3": 14
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 19,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "PRV",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 10,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 19
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 23,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "DCT",
    "consultant_doctor": "PRV",
    "prime_symptom_level": 2,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": 23,
    "prime_symptom_3": 33
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 14,
    "outlier": "UNU",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "DCT",
    "consultant_doctor": "PRV",
    "prime_symptom_level": 2,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": 14,
    "prime_symptom_3": null
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 16,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "PRV",
    "prime_symptom_level": 3,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 14,
    "prime_symptom_1": 16,
    "prime_symptom_2": 32,
    "prime_symptom_3": 33
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 23,
    "outlier": "CNF",
    "pathological_event_duration": 48,
    "first_prime_symptom_type": "DCB",
    "consultant_doctor": "PRV",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": 48,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": 23,
    "prime_symptom_2": null,
    "prime_symptom_3": 27
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 25,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "PRV",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 25
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 34,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "PRV",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 25,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 34
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 23,
    "outlier": "DUB",
    "pathological_event_duration": 272,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "PRV",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": 272,
    "fatal_symptom_2": 272,
    "mild_symptom_1_duration": 264,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 23
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 22,
    "outlier": "CNF",
    "pathological_event_duration": 75,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "PRV",
    "prime_symptom_level": null,
    "event_count": 1,
    "fatal_symptom_1": 75,
    "fatal_symptom_2": 51,
    "mild_symptom_1_duration": 69,
    "mild_symptom_1_2": null,
    "mild_symptom_2": null,
    "prime_symptom_1": null,
    "prime_symptom_2": null,
    "prime_symptom_3": 22
  },
  {
    "outcome": "SEV",
    "first_prime_symptom": 18,
    "outlier": "CNF",
    "pathological_event_duration": null,
    "first_prime_symptom_type": "ABD",
    "consultant_doctor": "PRI",
    "prime_symptom_level": 4,
    "event_count": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_1_2": null,
    "mild_symptom_2": 14,
    "prime_symptom_1": 20,
    "prime_symptom_2": 22,
    "prime_symptom_3": 18
  }
]

export default RealPrimeSymptomData
