const RealFullData = [
  {
    "id": 2613,
    "pathological_event_id": 145,
    "care_site": "LRM",
    "pathological_source_ref_id": "1A55C5A",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 62,
    "outlier": "CNF",
    "pathological_event_date": "2022-03-17",
    "etiology": "NWI",
    "care_equipment_1": "IHO",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 363,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 7,
    "care_error_level": 7,
    "overall_patient_rating": 5.69,
    "patient_id": 15,
    "event_count": 4,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2709,
    "pathological_event_id": 241,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "DUB",
    "pathological_event_date": "2011-08-11",
    "etiology": null,
    "care_equipment_1": null,
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": null,
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": null,
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": false,
    "event_record_length": 45,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 3,
    "care_error_level": 0,
    "overall_patient_rating": 3,
    "patient_id": 181,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2478,
    "pathological_event_id": 9,
    "care_site": "FIR",
    "pathological_source_ref_id": "9",
    "consultant_doctor": "FIR",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "STO",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 7,
    "care_error_level": 0,
    "overall_patient_rating": 7,
    "patient_id": 65,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2470,
    "pathological_event_id": 1,
    "care_site": "FIR",
    "pathological_source_ref_id": "1",
    "consultant_doctor": "FIR",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 13,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 107,
    "mild_symptom_2": 15,
    "mild_symptom_2_duration": 105,
    "prime_symptom_1": 19,
    "prime_symptom_1_duration": 101,
    "prime_symptom_2": 21,
    "prime_symptom_2_duration": 99,
    "first_prime_symptom": 19,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 101,
    "full_prime_symptom_duration": 101,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 20,
    "prime_symptom_3_duration": 100,
    "fatal_symptom_1": 98,
    "fatal_symptom_2": 120,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 250,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.1583,
    "pathological_event_duration": 120,
    "event_record_is_complete": false,
    "pathological_severity": 167.3,
    "care_error_level": 0,
    "overall_patient_rating": 167.3,
    "patient_id": 27,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2473,
    "pathological_event_id": 4,
    "care_site": "FIR",
    "pathological_source_ref_id": "4",
    "consultant_doctor": "FIR",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "DUB",
    "pathological_event_date": null,
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "KNE",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 18,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 24,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 24,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 8,
    "care_error_level": 0,
    "overall_patient_rating": 8,
    "patient_id": 61,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2474,
    "pathological_event_id": 5,
    "care_site": "FIR",
    "pathological_source_ref_id": "5",
    "consultant_doctor": "FIR",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DNT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "DUB",
    "pathological_event_date": null,
    "etiology": "LWD",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "LIE",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 79,
    "prime_symptom_1_duration": 46,
    "prime_symptom_2": 59,
    "prime_symptom_2_duration": 66,
    "first_prime_symptom": 59,
    "first_prime_symptom_type": "DCT",
    "prime_symptom_duration": 66,
    "full_prime_symptom_duration": 66,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": 112,
    "fatal_symptom_2": 125,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 451,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.472,
    "pathological_event_duration": 125,
    "event_record_is_complete": false,
    "pathological_severity": 75.6,
    "care_error_level": 0,
    "overall_patient_rating": 75.6,
    "patient_id": 62,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2475,
    "pathological_event_id": 6,
    "care_site": "FIR",
    "pathological_source_ref_id": "6",
    "consultant_doctor": "FIR",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DNT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 8,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 54,
    "mild_symptom_2": 11,
    "mild_symptom_2_duration": 51,
    "prime_symptom_1": 31,
    "prime_symptom_1_duration": 31,
    "prime_symptom_2": 33,
    "prime_symptom_2_duration": 29,
    "first_prime_symptom": 19,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 31,
    "full_prime_symptom_duration": 43,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 19,
    "prime_symptom_3_duration": 43,
    "fatal_symptom_1": 62,
    "fatal_symptom_2": 62,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 62,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.3065,
    "pathological_event_duration": 62,
    "event_record_is_complete": false,
    "pathological_severity": 68.6,
    "care_error_level": 0,
    "overall_patient_rating": 68.6,
    "patient_id": 63,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2479,
    "pathological_event_id": 10,
    "care_site": "FIR",
    "pathological_source_ref_id": "10",
    "consultant_doctor": "FIR",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DNT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "UNK",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 8,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 157,
    "mild_symptom_2": 12,
    "mild_symptom_2_duration": 153,
    "prime_symptom_1": 20,
    "prime_symptom_1_duration": 145,
    "prime_symptom_2": 24,
    "prime_symptom_2_duration": 141,
    "first_prime_symptom": 16,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 145,
    "full_prime_symptom_duration": 149,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 16,
    "prime_symptom_3_duration": 149,
    "fatal_symptom_1": 165,
    "fatal_symptom_2": 157,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 455,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.097,
    "pathological_event_duration": 165,
    "event_record_is_complete": false,
    "pathological_severity": 237.8,
    "care_error_level": 0,
    "overall_patient_rating": 237.8,
    "patient_id": 66,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2482,
    "pathological_event_id": 13,
    "care_site": "FIR",
    "pathological_source_ref_id": "13",
    "consultant_doctor": "FIR",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DNT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 10,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 103,
    "mild_symptom_2": 15,
    "mild_symptom_2_duration": 98,
    "prime_symptom_1": 22,
    "prime_symptom_1_duration": 91,
    "prime_symptom_2": 39,
    "prime_symptom_2_duration": 74,
    "first_prime_symptom": 17,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 91,
    "full_prime_symptom_duration": 96,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 17,
    "prime_symptom_3_duration": 96,
    "fatal_symptom_1": 113,
    "fatal_symptom_2": 103,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.1504,
    "pathological_event_duration": 113,
    "event_record_is_complete": false,
    "pathological_severity": 156.7,
    "care_error_level": 0,
    "overall_patient_rating": 156.7,
    "patient_id": 118,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2483,
    "pathological_event_id": 14,
    "care_site": "FIR",
    "pathological_source_ref_id": "14",
    "consultant_doctor": "FIR",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DNT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 8,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 94,
    "mild_symptom_2": 11,
    "mild_symptom_2_duration": 91,
    "prime_symptom_1": 14,
    "prime_symptom_1_duration": 88,
    "prime_symptom_2": 35,
    "prime_symptom_2_duration": 67,
    "first_prime_symptom": 14,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 88,
    "full_prime_symptom_duration": 88,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": 102,
    "fatal_symptom_2": 102,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.1373,
    "pathological_event_duration": 102,
    "event_record_is_complete": false,
    "pathological_severity": 111.8,
    "care_error_level": 0,
    "overall_patient_rating": 111.8,
    "patient_id": 28,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2484,
    "pathological_event_id": 15,
    "care_site": "LRM",
    "pathological_source_ref_id": "97BEC3C",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": null,
    "outcome": "UNK",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 54,
    "outlier": "CNF",
    "pathological_event_date": "2022-12-26",
    "etiology": "STO",
    "care_equipment_1": "SCW",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "LWD",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 352,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 10,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 114,
    "mild_symptom_2": 10,
    "mild_symptom_2_duration": 114,
    "prime_symptom_1": 21,
    "prime_symptom_1_duration": 103,
    "prime_symptom_2": 40,
    "prime_symptom_2_duration": 84,
    "first_prime_symptom": 13,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 103,
    "full_prime_symptom_duration": 111,
    "prime_symptom_any": true,
    "prime_symptom_level": 5,
    "prime_symptom_3": 13,
    "prime_symptom_3_duration": 111,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 66,
    "recovery_duration": 58,
    "recovery_proportion": 0.4677,
    "prime_symptom_proportion": 0.1048,
    "pathological_event_duration": 124,
    "event_record_is_complete": true,
    "pathological_severity": 170.7,
    "care_error_level": 0,
    "overall_patient_rating": 111.8,
    "patient_id": 68,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2491,
    "pathological_event_id": 22,
    "care_site": "FIR",
    "pathological_source_ref_id": "12",
    "consultant_doctor": "FIR",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "ICE",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 9,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 16,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 16,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 6,
    "care_error_level": 0,
    "overall_patient_rating": 6,
    "patient_id": 69,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2492,
    "pathological_event_id": 23,
    "care_site": "FIR",
    "pathological_source_ref_id": "3",
    "consultant_doctor": "FIR",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DNT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 18,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 106,
    "mild_symptom_2": 19,
    "mild_symptom_2_duration": 105,
    "prime_symptom_1": 21,
    "prime_symptom_1_duration": 103,
    "prime_symptom_2": 60,
    "prime_symptom_2_duration": 64,
    "first_prime_symptom": 21,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 103,
    "full_prime_symptom_duration": 103,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 22,
    "prime_symptom_3_duration": 102,
    "fatal_symptom_1": 124,
    "fatal_symptom_2": 124,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 181,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.1694,
    "pathological_event_duration": 124,
    "event_record_is_complete": false,
    "pathological_severity": 212.7,
    "care_error_level": 0,
    "overall_patient_rating": 212.7,
    "patient_id": 70,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2493,
    "pathological_event_id": 24,
    "care_site": "FIR",
    "pathological_source_ref_id": "7",
    "consultant_doctor": "FIR",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DNT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 10,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 10,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 11,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 26,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 11,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 13,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 6,
    "care_error_level": 0,
    "overall_patient_rating": 6,
    "patient_id": 71,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2494,
    "pathological_event_id": 25,
    "care_site": "FIR",
    "pathological_source_ref_id": "11",
    "consultant_doctor": "FIR",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 8,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 77,
    "mild_symptom_2": 11,
    "mild_symptom_2_duration": 74,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 43,
    "prime_symptom_2_duration": 42,
    "first_prime_symptom": 32,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 42,
    "full_prime_symptom_duration": 53,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 32,
    "prime_symptom_3_duration": 53,
    "fatal_symptom_1": 85,
    "fatal_symptom_2": 75,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 185,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.3765,
    "pathological_event_duration": 85,
    "event_record_is_complete": false,
    "pathological_severity": 104.7,
    "care_error_level": 0,
    "overall_patient_rating": 104.7,
    "patient_id": 72,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2496,
    "pathological_event_id": 27,
    "care_site": "LRM",
    "pathological_source_ref_id": "D6A6A36",
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 56,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "FAN",
    "care_equipment_1": "SAR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "001",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": true,
    "event_record_length": 92,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 13,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 11,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 11,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 2,
    "prime_symptom_3": 24,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 84,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 8,
    "care_error_level": 0,
    "overall_patient_rating": 8,
    "patient_id": 30,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2497,
    "pathological_event_id": 28,
    "care_site": "LRM",
    "pathological_source_ref_id": "EE1FAB5",
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DNT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 54,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "UNK",
    "care_equipment_1": "SCW",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 339,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 7,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 48,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 20,
    "prime_symptom_1_duration": 35,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 14,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 35,
    "full_prime_symptom_duration": 41,
    "prime_symptom_any": true,
    "prime_symptom_level": 2,
    "prime_symptom_3": 14,
    "prime_symptom_3_duration": 41,
    "fatal_symptom_1": 55,
    "fatal_symptom_2": 55,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 132,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.2545,
    "pathological_event_duration": 55,
    "event_record_is_complete": false,
    "pathological_severity": 82.1,
    "care_error_level": 0,
    "overall_patient_rating": 82.1,
    "patient_id": 31,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2499,
    "pathological_event_id": 30,
    "care_site": "LRM",
    "pathological_source_ref_id": "F79C142",
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DNT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 56,
    "outlier": "CNF",
    "pathological_event_date": "2012-01-01",
    "etiology": "PRD",
    "care_equipment_1": "SCT",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 6,
    "care_technique_2": "CTP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 345,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 10,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 101,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 14,
    "prime_symptom_1_duration": 97,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 14,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 97,
    "full_prime_symptom_duration": 97,
    "prime_symptom_any": true,
    "prime_symptom_level": 7,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": 111,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": 26,
    "slight_death_response_2": null,
    "death_response_2": 32,
    "time_of_death": 131,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.1261,
    "pathological_event_duration": 111,
    "event_record_is_complete": false,
    "pathological_severity": 187.5,
    "care_error_level": 0,
    "overall_patient_rating": 187.5,
    "patient_id": 74,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2502,
    "pathological_event_id": 33,
    "care_site": "FIR",
    "pathological_source_ref_id": "2",
    "consultant_doctor": "FIR",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DNT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 14,
    "mild_symptom_2_duration": 121,
    "prime_symptom_1": 19,
    "prime_symptom_1_duration": 116,
    "prime_symptom_2": 68,
    "prime_symptom_2_duration": 67,
    "first_prime_symptom": 19,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 116,
    "full_prime_symptom_duration": 116,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 21,
    "prime_symptom_3_duration": 114,
    "fatal_symptom_1": 135,
    "fatal_symptom_2": 123,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 167,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.1407,
    "pathological_event_duration": 135,
    "event_record_is_complete": false,
    "pathological_severity": 232.7,
    "care_error_level": 0,
    "overall_patient_rating": 232.7,
    "patient_id": 77,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2503,
    "pathological_event_id": 34,
    "care_site": "FIR",
    "pathological_source_ref_id": "8",
    "consultant_doctor": "FIR",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DNT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 12,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 14,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 20,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 31,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 16,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 16,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 6,
    "care_error_level": 0,
    "overall_patient_rating": 6,
    "patient_id": 78,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2504,
    "pathological_event_id": 35,
    "care_site": "LRM",
    "pathological_source_ref_id": "95308EA",
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "UNK",
    "outcome_type": "DNT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 57,
    "outlier": "DUB",
    "pathological_event_date": "2021-07-01",
    "etiology": "LWD",
    "care_equipment_1": "SCW",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CTP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 102,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 29,
    "prime_symptom_1_duration": 23,
    "prime_symptom_2": 32,
    "prime_symptom_2_duration": 20,
    "first_prime_symptom": 29,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 23,
    "full_prime_symptom_duration": 23,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": 52,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": 32,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.5577,
    "pathological_event_duration": 52,
    "event_record_is_complete": false,
    "pathological_severity": 36.6,
    "care_error_level": 0,
    "overall_patient_rating": 6,
    "patient_id": 79,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2505,
    "pathological_event_id": 36,
    "care_site": "LRM",
    "pathological_source_ref_id": "E06ED24",
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DNT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 73,
    "outlier": "CNF",
    "pathological_event_date": "2023-05-12",
    "etiology": "STO",
    "care_equipment_1": "SCT",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "MLT",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "STD",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 212,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 26,
    "mild_symptom_2_duration": 78,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 44,
    "prime_symptom_2_duration": 60,
    "first_prime_symptom": 44,
    "first_prime_symptom_type": "DCT",
    "prime_symptom_duration": 60,
    "full_prime_symptom_duration": 60,
    "prime_symptom_any": true,
    "prime_symptom_level": 1,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": 104,
    "fatal_symptom_2": 104,
    "slight_death_response_1": null,
    "death_response_1": 57,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 104,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.4231,
    "pathological_event_duration": 104,
    "event_record_is_complete": false,
    "pathological_severity": 82.7,
    "care_error_level": 0,
    "overall_patient_rating": 82.7,
    "patient_id": 80,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2521,
    "pathological_event_id": 52,
    "care_site": "RPV",
    "pathological_source_ref_id": null,
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 70,
    "outlier": "CNF",
    "pathological_event_date": "2016-09-06",
    "etiology": "PRD",
    "care_equipment_1": "ELC",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 6,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 205,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 9,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 158,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 10,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": 157,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 10,
    "prime_symptom_3_duration": 157,
    "fatal_symptom_1": 167,
    "fatal_symptom_2": 167,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 188,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.0599,
    "pathological_event_duration": 167,
    "event_record_is_complete": false,
    "pathological_severity": 130.1,
    "care_error_level": 0,
    "overall_patient_rating": 130.1,
    "patient_id": 83,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2522,
    "pathological_event_id": 53,
    "care_site": "RPV",
    "pathological_source_ref_id": null,
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "FAN",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "002",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 215,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 13,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 32,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 17,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": 28,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 17,
    "prime_symptom_3_duration": 28,
    "fatal_symptom_1": 45,
    "fatal_symptom_2": 33,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 45,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.3778,
    "pathological_event_duration": 45,
    "event_record_is_complete": false,
    "pathological_severity": 34.1,
    "care_error_level": 0,
    "overall_patient_rating": 34.1,
    "patient_id": 84,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2523,
    "pathological_event_id": 54,
    "care_site": "RPV",
    "pathological_source_ref_id": null,
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 68,
    "outlier": "DUB",
    "pathological_event_date": null,
    "etiology": "FAN",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "001",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 474,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 22,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 220,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 53,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": 189,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 53,
    "prime_symptom_3_duration": 189,
    "fatal_symptom_1": 242,
    "fatal_symptom_2": 242,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 242,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.219,
    "pathological_event_duration": 242,
    "event_record_is_complete": false,
    "pathological_severity": 150.8,
    "care_error_level": 0,
    "overall_patient_rating": 150.8,
    "patient_id": 85,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2770,
    "pathological_event_id": 302,
    "care_site": "LRM",
    "pathological_source_ref_id": "7700F40",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "X",
    "patient_weight": 68,
    "outlier": "DUB",
    "pathological_event_date": "2023-10-18",
    "etiology": "UNK",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "TIP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 6,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 13,
    "care_error_level": 3,
    "overall_patient_rating": 5.16,
    "patient_id": 223,
    "event_count": 6,
    "event_title": "",
    "notes": "video is too short"
  },
  {
    "id": 2524,
    "pathological_event_id": 55,
    "care_site": "RPV",
    "pathological_source_ref_id": null,
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "UNK",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 62,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "STO",
    "care_equipment_1": "ELC",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CHD",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 152,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 18,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 18,
    "mild_symptom_2": 20,
    "mild_symptom_2_duration": 16,
    "prime_symptom_1": 36,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 24,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": 12,
    "prime_symptom_any": true,
    "prime_symptom_level": 5,
    "prime_symptom_3": 24,
    "prime_symptom_3_duration": 12,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.6667,
    "pathological_event_duration": 36,
    "event_record_is_complete": false,
    "pathological_severity": 24,
    "care_error_level": 0,
    "overall_patient_rating": 150.8,
    "patient_id": 86,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2525,
    "pathological_event_id": 56,
    "care_site": "RPV",
    "pathological_source_ref_id": null,
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 69,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "STO",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "007",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": true,
    "event_record_length": 289,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 13,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 163,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 20,
    "prime_symptom_1_duration": 156,
    "prime_symptom_2": 49,
    "prime_symptom_2_duration": 127,
    "first_prime_symptom": 14,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 156,
    "full_prime_symptom_duration": 162,
    "prime_symptom_any": true,
    "prime_symptom_level": 6,
    "prime_symptom_3": 14,
    "prime_symptom_3_duration": 162,
    "fatal_symptom_1": 156,
    "fatal_symptom_2": 176,
    "slight_death_response_1": 205,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 205,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.0795,
    "pathological_event_duration": 176,
    "event_record_is_complete": false,
    "pathological_severity": 339.2,
    "care_error_level": 0,
    "overall_patient_rating": 339.2,
    "patient_id": 87,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2526,
    "pathological_event_id": 57,
    "care_site": "RPV",
    "pathological_source_ref_id": null,
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 84,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "PRD",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 12,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 255,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 12,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": 117,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 12,
    "prime_symptom_3_duration": 117,
    "fatal_symptom_1": 129,
    "fatal_symptom_2": 129,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 129,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.093,
    "pathological_event_duration": 129,
    "event_record_is_complete": false,
    "pathological_severity": 79.7,
    "care_error_level": 0,
    "overall_patient_rating": 79.7,
    "patient_id": 88,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2527,
    "pathological_event_id": 58,
    "care_site": "RPV",
    "pathological_source_ref_id": null,
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "PRD",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "003",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 6,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 197,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 10,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 128,
    "mild_symptom_2": 10,
    "mild_symptom_2_duration": 128,
    "prime_symptom_1": 30,
    "prime_symptom_1_duration": 108,
    "prime_symptom_2": 34,
    "prime_symptom_2_duration": 104,
    "first_prime_symptom": 15,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 108,
    "full_prime_symptom_duration": 123,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 15,
    "prime_symptom_3_duration": 123,
    "fatal_symptom_1": 138,
    "fatal_symptom_2": 129,
    "slight_death_response_1": 169,
    "death_response_1": null,
    "slight_death_response_2": 102,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.1087,
    "pathological_event_duration": 138,
    "event_record_is_complete": false,
    "pathological_severity": 231.1,
    "care_error_level": 0,
    "overall_patient_rating": 231.1,
    "patient_id": 89,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2528,
    "pathological_event_id": 59,
    "care_site": "LRM",
    "pathological_source_ref_id": "D8BC69B",
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 62,
    "outlier": "CNF",
    "pathological_event_date": "2023-04-14",
    "etiology": "PRD",
    "care_equipment_1": "SCT",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "007",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 6,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 94,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 10,
    "mild_symptom_1_1_end": 38,
    "mild_symptom_1_2": 50,
    "mild_symptom_1_duration": 56,
    "mild_symptom_2": 13,
    "mild_symptom_2_duration": 65,
    "prime_symptom_1": 20,
    "prime_symptom_1_duration": 58,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 17,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 58,
    "full_prime_symptom_duration": 61,
    "prime_symptom_any": true,
    "prime_symptom_level": 1,
    "prime_symptom_3": 17,
    "prime_symptom_3_duration": 61,
    "fatal_symptom_1": null,
    "fatal_symptom_2": 78,
    "slight_death_response_1": null,
    "death_response_1": 70,
    "slight_death_response_2": 54,
    "death_response_2": 38,
    "time_of_death": 78,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.2179,
    "pathological_event_duration": 78,
    "event_record_is_complete": false,
    "pathological_severity": 128.4,
    "care_error_level": 0,
    "overall_patient_rating": 128.4,
    "patient_id": 90,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2529,
    "pathological_event_id": 60,
    "care_site": "LRM",
    "pathological_source_ref_id": "794FF72",
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2023-04-14",
    "etiology": "FAN",
    "care_equipment_1": "SCW",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "004",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 228,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 10,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 68,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 21,
    "prime_symptom_1_duration": 57,
    "prime_symptom_2": 51,
    "prime_symptom_2_duration": 27,
    "first_prime_symptom": 21,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 57,
    "full_prime_symptom_duration": 57,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 23,
    "prime_symptom_3_duration": 55,
    "fatal_symptom_1": 78,
    "fatal_symptom_2": 51,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": 58,
    "time_of_death": 78,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.2692,
    "pathological_event_duration": 78,
    "event_record_is_complete": false,
    "pathological_severity": 117.2,
    "care_error_level": 0,
    "overall_patient_rating": 117.2,
    "patient_id": 91,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2544,
    "pathological_event_id": 75,
    "care_site": "BPF",
    "pathological_source_ref_id": "6/4",
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "ICE",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 58,
    "outlier": "CNF",
    "pathological_event_date": "2018-06-11",
    "etiology": "NWI",
    "care_equipment_1": "THH",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": false,
    "event_record_length": 63,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 16,
    "mild_symptom_2_duration": 34,
    "prime_symptom_1": 32,
    "prime_symptom_1_duration": 18,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 32,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 18,
    "full_prime_symptom_duration": 18,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": 50,
    "fatal_symptom_2": 50,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.64,
    "pathological_event_duration": 50,
    "event_record_is_complete": false,
    "pathological_severity": 40.4,
    "care_error_level": 0,
    "overall_patient_rating": 40.4,
    "patient_id": 94,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2548,
    "pathological_event_id": 80,
    "care_site": "BPF",
    "pathological_source_ref_id": "2/136",
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 60,
    "outlier": "DUB",
    "pathological_event_date": "2018-06-11",
    "etiology": "STO",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 65,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 10,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": 29,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 10,
    "prime_symptom_3_duration": 29,
    "fatal_symptom_1": 39,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.2564,
    "pathological_event_duration": 39,
    "event_record_is_complete": false,
    "pathological_severity": 21.5,
    "care_error_level": 0,
    "overall_patient_rating": 21.5,
    "patient_id": 99,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2616,
    "pathological_event_id": 148,
    "care_site": "LRM",
    "pathological_source_ref_id": "4723488",
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DNT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2020-05-15",
    "etiology": "SIT",
    "care_equipment_1": "SCW",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "SIT",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 199,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 18,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 135,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 23,
    "prime_symptom_1_duration": 130,
    "prime_symptom_2": 63,
    "prime_symptom_2_duration": 90,
    "first_prime_symptom": 23,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 130,
    "full_prime_symptom_duration": 130,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 32,
    "prime_symptom_3_duration": 121,
    "fatal_symptom_1": 153,
    "fatal_symptom_2": 153,
    "slight_death_response_1": 60,
    "death_response_1": null,
    "slight_death_response_2": 60,
    "death_response_2": null,
    "time_of_death": 153,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.1503,
    "pathological_event_duration": 153,
    "event_record_is_complete": false,
    "pathological_severity": 248.4,
    "care_error_level": 0,
    "overall_patient_rating": 248.4,
    "patient_id": 53,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2617,
    "pathological_event_id": 149,
    "care_site": "LRM",
    "pathological_source_ref_id": "80DB90D",
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "ICE",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 73,
    "outlier": "CNF",
    "pathological_event_date": "2023-08-04",
    "etiology": "PRD",
    "care_equipment_1": "ELC",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "004",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 15,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 93,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 10,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 64,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 24,
    "prime_symptom_1_duration": 50,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 18,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 50,
    "full_prime_symptom_duration": 56,
    "prime_symptom_any": true,
    "prime_symptom_level": 2,
    "prime_symptom_3": 18,
    "prime_symptom_3_duration": 56,
    "fatal_symptom_1": 74,
    "fatal_symptom_2": 63,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 74,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.2432,
    "pathological_event_duration": 74,
    "event_record_is_complete": false,
    "pathological_severity": 130.1,
    "care_error_level": 0,
    "overall_patient_rating": 130.1,
    "patient_id": 54,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2644,
    "pathological_event_id": 176,
    "care_site": "UNK",
    "pathological_source_ref_id": null,
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "UNK",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2016-06-22",
    "etiology": "STO",
    "care_equipment_1": "SCW",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "UNK",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 182,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 27,
    "mild_symptom_2_duration": 50,
    "prime_symptom_1": 77,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 7,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": 70,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": 7,
    "prime_symptom_3_duration": 70,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.0909,
    "pathological_event_duration": 77,
    "event_record_is_complete": false,
    "pathological_severity": 39.7,
    "care_error_level": 0,
    "overall_patient_rating": 130.1,
    "patient_id": 133,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2653,
    "pathological_event_id": 185,
    "care_site": "LRM",
    "pathological_source_ref_id": "E975FAC",
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "UNK",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2023-08-17",
    "etiology": "LWD",
    "care_equipment_1": "INO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "008",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "UNK",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 40,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 24,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 16,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 40,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 40,
    "event_record_is_complete": false,
    "pathological_severity": 12.2,
    "care_error_level": 0,
    "overall_patient_rating": 130.1,
    "patient_id": 127,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2654,
    "pathological_event_id": 186,
    "care_site": "LRM",
    "pathological_source_ref_id": "D064C16",
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "DUB",
    "pathological_event_date": "2023-05-30",
    "etiology": "PRD",
    "care_equipment_1": "SCW",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 6,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 89,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 16,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 51,
    "mild_symptom_2": 12,
    "mild_symptom_2_duration": 55,
    "prime_symptom_1": 20,
    "prime_symptom_1_duration": 47,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 20,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 47,
    "full_prime_symptom_duration": 47,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 22,
    "prime_symptom_3_duration": 45,
    "fatal_symptom_1": 67,
    "fatal_symptom_2": 67,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.2985,
    "pathological_event_duration": 67,
    "event_record_is_complete": false,
    "pathological_severity": 103.8,
    "care_error_level": 0,
    "overall_patient_rating": 103.8,
    "patient_id": 126,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2655,
    "pathological_event_id": 187,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "UNK",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2023-05-02",
    "etiology": "STO",
    "care_equipment_1": "SCT",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "008",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": false,
    "event_record_length": 46,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 9,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 15,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 14,
    "prime_symptom_1_duration": 10,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 14,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 10,
    "full_prime_symptom_duration": 10,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 21,
    "prime_symptom_3_duration": 3,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": 24,
    "recovery_proportion": 1,
    "prime_symptom_proportion": 0.5833,
    "pathological_event_duration": 24,
    "event_record_is_complete": true,
    "pathological_severity": 29.1,
    "care_error_level": 0,
    "overall_patient_rating": 103.8,
    "patient_id": 125,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2656,
    "pathological_event_id": 188,
    "care_site": "LRM",
    "pathological_source_ref_id": "3F88654",
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2023-07-06",
    "etiology": "PRD",
    "care_equipment_1": "SCT",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 12,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 260,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 18,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 77,
    "mild_symptom_2": 18,
    "mild_symptom_2_duration": 77,
    "prime_symptom_1": 22,
    "prime_symptom_1_duration": 73,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 22,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 73,
    "full_prime_symptom_duration": 73,
    "prime_symptom_any": true,
    "prime_symptom_level": 2,
    "prime_symptom_3": 53,
    "prime_symptom_3_duration": 42,
    "fatal_symptom_1": 95,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 95,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.2316,
    "pathological_event_duration": 95,
    "event_record_is_complete": false,
    "pathological_severity": 150.6,
    "care_error_level": 0,
    "overall_patient_rating": 150.6,
    "patient_id": 124,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2659,
    "pathological_event_id": 191,
    "care_site": "LRM",
    "pathological_source_ref_id": "DB9D8FC",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 64,
    "outlier": "DUB",
    "pathological_event_date": "2021-02-18",
    "etiology": "UNK",
    "care_equipment_1": "SCT",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "007",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "SIT",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": true,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 37,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 18,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 18,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 31,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 9,
    "care_error_level": 0,
    "overall_patient_rating": 9,
    "patient_id": 123,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2667,
    "pathological_event_id": 199,
    "care_site": "LRM",
    "pathological_source_ref_id": "09BBD6D",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DNT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 60,
    "outlier": "CNF",
    "pathological_event_date": "2022-12-12",
    "etiology": "PRD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "004",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 12,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 50,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 22,
    "prime_symptom_1_duration": 16,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 14,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 16,
    "full_prime_symptom_duration": 24,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": 14,
    "prime_symptom_3_duration": 24,
    "fatal_symptom_1": null,
    "fatal_symptom_2": 38,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 38,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.3684,
    "pathological_event_duration": 38,
    "event_record_is_complete": false,
    "pathological_severity": 56.1,
    "care_error_level": 0,
    "overall_patient_rating": 56.1,
    "patient_id": 140,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2673,
    "pathological_event_id": 205,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2018-05-03",
    "etiology": "NWI",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "007",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 113,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 10,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 10,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 38,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 10,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 15,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 7,
    "care_error_level": 0,
    "overall_patient_rating": 7,
    "patient_id": 145,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2674,
    "pathological_event_id": 206,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 70,
    "outlier": "CNF",
    "pathological_event_date": "2018-11-19",
    "etiology": "PRD",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "004",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 3,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 19,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 8,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 14,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 14,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 12,
    "care_error_level": 0,
    "overall_patient_rating": 12,
    "patient_id": 146,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2675,
    "pathological_event_id": 207,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2018-11-19",
    "etiology": "PRD",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "003",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 12,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": true,
    "event_record_length": 19,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 12,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 18,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 18,
    "first_prime_symptom_type": "DCT",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 1,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 20,
    "care_error_level": 0,
    "overall_patient_rating": 20,
    "patient_id": 147,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2676,
    "pathological_event_id": 208,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2018-11-19",
    "etiology": "PRD",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "008",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 3,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 19,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 11,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 11,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 11,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 12,
    "care_error_level": 0,
    "overall_patient_rating": 12,
    "patient_id": 148,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2677,
    "pathological_event_id": 209,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2018-12-01",
    "etiology": "STO",
    "care_equipment_1": "SCW",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 90,
    "intro_symptom_start": 0,
    "intro_symptom_end": 7,
    "intro_symptom_duration": 7,
    "mild_symptom_1": 7,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 48,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 15,
    "prime_symptom_1_duration": 40,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 15,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 40,
    "full_prime_symptom_duration": 40,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": 15,
    "prime_symptom_3_duration": 40,
    "fatal_symptom_1": 55,
    "fatal_symptom_2": 55,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.2727,
    "pathological_event_duration": 55,
    "event_record_is_complete": false,
    "pathological_severity": 81.3,
    "care_error_level": 0,
    "overall_patient_rating": 81.3,
    "patient_id": 149,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2678,
    "pathological_event_id": 210,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "UNK",
    "outcome_type": "DCI",
    "source_country": "BR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 70,
    "outlier": "CNF",
    "pathological_event_date": "2017-10-04",
    "etiology": "LWD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 119,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 15,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 66,
    "mild_symptom_2": 15,
    "mild_symptom_2_duration": 66,
    "prime_symptom_1": 21,
    "prime_symptom_1_duration": 60,
    "prime_symptom_2": 81,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 21,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 60,
    "full_prime_symptom_duration": 60,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": 23,
    "prime_symptom_3_duration": 58,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.2593,
    "pathological_event_duration": 81,
    "event_record_is_complete": false,
    "pathological_severity": 121.8,
    "care_error_level": 0,
    "overall_patient_rating": 81.3,
    "patient_id": 150,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2679,
    "pathological_event_id": 211,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 65,
    "outlier": "DUB",
    "pathological_event_date": "2018-07-17",
    "etiology": "LWD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "SIT",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 354,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 26,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 450,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 26,
    "prime_symptom_1_duration": 450,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 26,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 450,
    "full_prime_symptom_duration": 450,
    "prime_symptom_any": true,
    "prime_symptom_level": 2,
    "prime_symptom_3": 26,
    "prime_symptom_3_duration": 450,
    "fatal_symptom_1": 476,
    "fatal_symptom_2": 476,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.0546,
    "pathological_event_duration": 476,
    "event_record_is_complete": false,
    "pathological_severity": 823.9,
    "care_error_level": 0,
    "overall_patient_rating": 823.9,
    "patient_id": 151,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2680,
    "pathological_event_id": 212,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 65,
    "outlier": "DUB",
    "pathological_event_date": "2019-07-05",
    "etiology": "STO",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 142,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 14,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 74,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 46,
    "prime_symptom_2_duration": 42,
    "first_prime_symptom": 26,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 42,
    "full_prime_symptom_duration": 62,
    "prime_symptom_any": true,
    "prime_symptom_level": 1,
    "prime_symptom_3": 26,
    "prime_symptom_3_duration": 62,
    "fatal_symptom_1": 81,
    "fatal_symptom_2": 88,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.2955,
    "pathological_event_duration": 88,
    "event_record_is_complete": false,
    "pathological_severity": 106.8,
    "care_error_level": 0,
    "overall_patient_rating": 106.8,
    "patient_id": 152,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2681,
    "pathological_event_id": 213,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "UNK",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 63,
    "outlier": "DUB",
    "pathological_event_date": "2021-05-12",
    "etiology": "STO",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "KNL",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 115,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 11,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 13,
    "mild_symptom_2": 17,
    "mild_symptom_2_duration": 7,
    "prime_symptom_1": 20,
    "prime_symptom_1_duration": 4,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 20,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 4,
    "full_prime_symptom_duration": 4,
    "prime_symptom_any": true,
    "prime_symptom_level": 1,
    "prime_symptom_3": 24,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.8333,
    "pathological_event_duration": 24,
    "event_record_is_complete": false,
    "pathological_severity": 19.4,
    "care_error_level": 0,
    "overall_patient_rating": 106.8,
    "patient_id": 153,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2682,
    "pathological_event_id": 214,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": null,
    "outcome": "UNK",
    "outcome_type": "DCI",
    "source_country": "LT",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 70,
    "outlier": "DUB",
    "pathological_event_date": "2019-03-18",
    "etiology": "STO",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "KNL",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 137,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 4,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 4,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 1,
    "prime_symptom_3": 4,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": 102,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 157,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 1,
    "pathological_event_duration": 4,
    "event_record_is_complete": false,
    "pathological_severity": 10.4,
    "care_error_level": 0,
    "overall_patient_rating": 106.8,
    "patient_id": 154,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2683,
    "pathological_event_id": 215,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "UNK",
    "outcome_type": "DCI",
    "source_country": "IN",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2021-12-15",
    "etiology": "STO",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 352,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 7,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 42,
    "mild_symptom_2": 49,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 11,
    "prime_symptom_1_duration": 38,
    "prime_symptom_2": 45,
    "prime_symptom_2_duration": 4,
    "first_prime_symptom": 11,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 38,
    "full_prime_symptom_duration": 38,
    "prime_symptom_any": true,
    "prime_symptom_level": 1,
    "prime_symptom_3": 27,
    "prime_symptom_3_duration": 22,
    "fatal_symptom_1": 71,
    "fatal_symptom_2": 60,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 263,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.2245,
    "pathological_event_duration": 49,
    "event_record_is_complete": false,
    "pathological_severity": 76.1,
    "care_error_level": 0,
    "overall_patient_rating": 106.8,
    "patient_id": 155,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2684,
    "pathological_event_id": 216,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2014-02-12",
    "etiology": "NWI",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "003",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 41,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 10,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 16,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 10,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 10,
    "first_prime_symptom_type": "DCT",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": 15,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": 41,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 14,
    "care_error_level": 0,
    "overall_patient_rating": 14,
    "patient_id": 156,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2685,
    "pathological_event_id": 217,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 70,
    "outlier": "CNF",
    "pathological_event_date": "2018-01-05",
    "etiology": "LWD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "003",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 117,
    "intro_symptom_start": 0,
    "intro_symptom_end": 4,
    "intro_symptom_duration": 4,
    "mild_symptom_1": 70,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 29,
    "mild_symptom_2": 19,
    "mild_symptom_2_duration": 80,
    "prime_symptom_1": 70,
    "prime_symptom_1_duration": 29,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 70,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 29,
    "full_prime_symptom_duration": 29,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 85,
    "prime_symptom_3_duration": 14,
    "fatal_symptom_1": 99,
    "fatal_symptom_2": 99,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 99,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.7071,
    "pathological_event_duration": 99,
    "event_record_is_complete": false,
    "pathological_severity": 65.3,
    "care_error_level": 0,
    "overall_patient_rating": 65.3,
    "patient_id": 157,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2686,
    "pathological_event_id": 218,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 70,
    "outlier": "CNF",
    "pathological_event_date": "2020-07-15",
    "etiology": "PRD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "003",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 30,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 186,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 10,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 42,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 52,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 52,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 1,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": 52,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 52,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 1,
    "pathological_event_duration": 52,
    "event_record_is_complete": false,
    "pathological_severity": 41.6,
    "care_error_level": 0,
    "overall_patient_rating": 41.6,
    "patient_id": 158,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2687,
    "pathological_event_id": 219,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 61,
    "outlier": "CNF",
    "pathological_event_date": "2019-01-15",
    "etiology": "NWI",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 53,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 13,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 25,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 13,
    "prime_symptom_1_duration": 25,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 13,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 25,
    "full_prime_symptom_duration": 25,
    "prime_symptom_any": true,
    "prime_symptom_level": 2,
    "prime_symptom_3": 15,
    "prime_symptom_3_duration": 23,
    "fatal_symptom_1": null,
    "fatal_symptom_2": 38,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": 39,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.3421,
    "pathological_event_duration": 38,
    "event_record_is_complete": false,
    "pathological_severity": 57.6,
    "care_error_level": 0,
    "overall_patient_rating": 57.6,
    "patient_id": 159,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2688,
    "pathological_event_id": 220,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 63,
    "outlier": "CNF",
    "pathological_event_date": "2019-01-15",
    "etiology": "NWI",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 53,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 12,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 5,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 20,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 20,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 1,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 12,
    "care_error_level": 0,
    "overall_patient_rating": 12,
    "patient_id": 160,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2689,
    "pathological_event_id": 221,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2019-01-15",
    "etiology": "NWI",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 53,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 5,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 25,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 12,
    "prime_symptom_1_duration": 18,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 12,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 18,
    "full_prime_symptom_duration": 18,
    "prime_symptom_any": true,
    "prime_symptom_level": 2,
    "prime_symptom_3": 27,
    "prime_symptom_3_duration": 3,
    "fatal_symptom_1": 30,
    "fatal_symptom_2": 30,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.4,
    "pathological_event_duration": 30,
    "event_record_is_complete": false,
    "pathological_severity": 41.1,
    "care_error_level": 0,
    "overall_patient_rating": 41.1,
    "patient_id": 161,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2690,
    "pathological_event_id": 222,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 60,
    "outlier": "CNF",
    "pathological_event_date": "2016-11-25",
    "etiology": "PRD",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 24,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": true,
    "event_record_length": 52,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 7,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 13,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 38,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 13,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": 18,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 23,
    "care_error_level": 0,
    "overall_patient_rating": 23,
    "patient_id": 162,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2691,
    "pathological_event_id": 223,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 65,
    "outlier": "DUB",
    "pathological_event_date": "2016-11-25",
    "etiology": "PRD",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 24,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 52,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 30,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 30,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 23,
    "care_error_level": 0,
    "overall_patient_rating": 23,
    "patient_id": 163,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2692,
    "pathological_event_id": 224,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 65,
    "outlier": "DUB",
    "pathological_event_date": "2016-11-25",
    "etiology": "PRD",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 24,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": false,
    "event_record_length": 52,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 21,
    "care_error_level": 0,
    "overall_patient_rating": 21,
    "patient_id": 164,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2693,
    "pathological_event_id": 225,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 68,
    "outlier": "CNF",
    "pathological_event_date": "2016-11-25",
    "etiology": "PRD",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "004",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 12,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": true,
    "event_record_length": 52,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 13,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 35,
    "mild_symptom_2": 6,
    "mild_symptom_2_duration": 42,
    "prime_symptom_1": 23,
    "prime_symptom_1_duration": 25,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 23,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 25,
    "full_prime_symptom_duration": 25,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": 48,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.4792,
    "pathological_event_duration": 48,
    "event_record_is_complete": false,
    "pathological_severity": 59.4,
    "care_error_level": 0,
    "overall_patient_rating": 59.4,
    "patient_id": 165,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2694,
    "pathological_event_id": 226,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "UNK",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 66,
    "outlier": "CNF",
    "pathological_event_date": "2017-10-07",
    "etiology": "STO",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 73,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 11,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 49,
    "mild_symptom_2": 20,
    "mild_symptom_2_duration": 40,
    "prime_symptom_1": 27,
    "prime_symptom_1_duration": 33,
    "prime_symptom_2": 60,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 27,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 33,
    "full_prime_symptom_duration": 33,
    "prime_symptom_any": true,
    "prime_symptom_level": 2,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.45,
    "pathological_event_duration": 60,
    "event_record_is_complete": false,
    "pathological_severity": 59.3,
    "care_error_level": 0,
    "overall_patient_rating": 59.4,
    "patient_id": 166,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2695,
    "pathological_event_id": 227,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2008-01-11",
    "etiology": "PRD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "003",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 12,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 34,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 15,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 15,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 23,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 25,
    "care_error_level": 0,
    "overall_patient_rating": 25,
    "patient_id": 167,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2696,
    "pathological_event_id": 228,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "DUB",
    "pathological_event_date": "2008-01-11",
    "etiology": "PRD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "003",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 12,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": false,
    "event_record_length": 34,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 23,
    "care_error_level": 0,
    "overall_patient_rating": 23,
    "patient_id": 168,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2697,
    "pathological_event_id": 229,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2008-01-11",
    "etiology": "PRD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "004",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 12,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 34,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 28,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 28,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 25,
    "care_error_level": 0,
    "overall_patient_rating": 25,
    "patient_id": 169,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2698,
    "pathological_event_id": 230,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2008-01-11",
    "etiology": "PRD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "007",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 12,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": true,
    "event_record_length": 34,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 28,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 28,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 25,
    "care_error_level": 0,
    "overall_patient_rating": 25,
    "patient_id": 170,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2699,
    "pathological_event_id": 231,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "DUB",
    "pathological_event_date": "2008-01-11",
    "etiology": "PRD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 12,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": true,
    "event_record_length": 34,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 23,
    "care_error_level": 0,
    "overall_patient_rating": 23,
    "patient_id": 171,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2700,
    "pathological_event_id": 232,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 62,
    "outlier": "CNF",
    "pathological_event_date": "2010-10-15",
    "etiology": "PRD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "003",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 18,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 48,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 1,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 25,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 30,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 30,
    "first_prime_symptom_type": "DCT",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 1,
    "prime_symptom_3": 31,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 28,
    "care_error_level": 0,
    "overall_patient_rating": 28,
    "patient_id": 172,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2701,
    "pathological_event_id": 233,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 66,
    "outlier": "CNF",
    "pathological_event_date": "2010-10-15",
    "etiology": "PRD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 18,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": false,
    "event_record_length": 48,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 10,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 26,
    "care_error_level": 0,
    "overall_patient_rating": 26,
    "patient_id": 173,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2702,
    "pathological_event_id": 234,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2010-10-15",
    "etiology": "PRD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "004",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 18,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 48,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 22,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 17,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 22,
    "prime_symptom_1_duration": 17,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 22,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 17,
    "full_prime_symptom_duration": 17,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 24,
    "prime_symptom_3_duration": 15,
    "fatal_symptom_1": 39,
    "fatal_symptom_2": 39,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.5641,
    "pathological_event_duration": 39,
    "event_record_is_complete": false,
    "pathological_severity": 61.5,
    "care_error_level": 0,
    "overall_patient_rating": 61.5,
    "patient_id": 174,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2703,
    "pathological_event_id": 235,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 70,
    "outlier": "CNF",
    "pathological_event_date": "2014-02-16",
    "etiology": "PRD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 24,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": true,
    "event_record_length": 179,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 4,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 54,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 35,
    "prime_symptom_1_duration": 23,
    "prime_symptom_2": 12,
    "prime_symptom_2_duration": 46,
    "first_prime_symptom": 12,
    "first_prime_symptom_type": "DCT",
    "prime_symptom_duration": 46,
    "full_prime_symptom_duration": 46,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 25,
    "prime_symptom_3_duration": 33,
    "fatal_symptom_1": 58,
    "fatal_symptom_2": 58,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 58,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.2069,
    "pathological_event_duration": 58,
    "event_record_is_complete": false,
    "pathological_severity": 112.5,
    "care_error_level": 0,
    "overall_patient_rating": 112.5,
    "patient_id": 175,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2704,
    "pathological_event_id": 236,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 67,
    "outlier": "CNF",
    "pathological_event_date": "2014-02-16",
    "etiology": "PRD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 21,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": true,
    "event_record_length": 179,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 35,
    "prime_symptom_1_duration": 83,
    "prime_symptom_2": 25,
    "prime_symptom_2_duration": 93,
    "first_prime_symptom": 18,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 93,
    "full_prime_symptom_duration": 100,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 18,
    "prime_symptom_3_duration": 100,
    "fatal_symptom_1": 118,
    "fatal_symptom_2": 42,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 118,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.1525,
    "pathological_event_duration": 118,
    "event_record_is_complete": false,
    "pathological_severity": 190.5,
    "care_error_level": 0,
    "overall_patient_rating": 190.5,
    "patient_id": 176,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2705,
    "pathological_event_id": 237,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2014-02-27",
    "etiology": "STO",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": null,
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": false,
    "event_record_length": 127,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 10,
    "care_error_level": 0,
    "overall_patient_rating": 10,
    "patient_id": 177,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2706,
    "pathological_event_id": 238,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2014-02-28",
    "etiology": "PRD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "004",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 18,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": true,
    "event_record_length": 43,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 28,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 28,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": 29,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 28,
    "care_error_level": 0,
    "overall_patient_rating": 28,
    "patient_id": 178,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2707,
    "pathological_event_id": 239,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "DUB",
    "pathological_event_date": "2011-08-11",
    "etiology": "STO",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": null,
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": false,
    "event_record_length": 45,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 10,
    "care_error_level": 0,
    "overall_patient_rating": 10,
    "patient_id": 179,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2708,
    "pathological_event_id": 240,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "DUB",
    "pathological_event_date": "2011-08-11",
    "etiology": "STO",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": null,
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": false,
    "event_record_length": 45,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 10,
    "care_error_level": 0,
    "overall_patient_rating": 10,
    "patient_id": 180,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2710,
    "pathological_event_id": 242,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 63,
    "outlier": "CNF",
    "pathological_event_date": "2007-07-15",
    "etiology": "NWI",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 96,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 20,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 37,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 27,
    "prime_symptom_1_duration": 30,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 27,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 30,
    "full_prime_symptom_duration": 30,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 27,
    "prime_symptom_3_duration": 30,
    "fatal_symptom_1": 57,
    "fatal_symptom_2": 57,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.4737,
    "pathological_event_duration": 57,
    "event_record_is_complete": false,
    "pathological_severity": 72.8,
    "care_error_level": 0,
    "overall_patient_rating": 72.8,
    "patient_id": 182,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2711,
    "pathological_event_id": 243,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2007-07-15",
    "etiology": "NWI",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "007",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 96,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 63,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 10,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 63,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 63,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 2,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 12,
    "care_error_level": 0,
    "overall_patient_rating": 12,
    "patient_id": 183,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2712,
    "pathological_event_id": 244,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 63,
    "outlier": "CNF",
    "pathological_event_date": "2007-07-15",
    "etiology": "NWI",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "004",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 96,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 20,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 15,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 24,
    "prime_symptom_1_duration": 11,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 24,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 11,
    "full_prime_symptom_duration": 11,
    "prime_symptom_any": true,
    "prime_symptom_level": 5,
    "prime_symptom_3": 30,
    "prime_symptom_3_duration": 5,
    "fatal_symptom_1": 35,
    "fatal_symptom_2": 35,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.6857,
    "pathological_event_duration": 35,
    "event_record_is_complete": false,
    "pathological_severity": 34.5,
    "care_error_level": 0,
    "overall_patient_rating": 34.5,
    "patient_id": 184,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2713,
    "pathological_event_id": 245,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 62,
    "outlier": "CNF",
    "pathological_event_date": "2014-03-03",
    "etiology": "NWI",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "004",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 193,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 14,
    "mild_symptom_2_duration": 62,
    "prime_symptom_1": 40,
    "prime_symptom_1_duration": 36,
    "prime_symptom_2": 19,
    "prime_symptom_2_duration": 57,
    "first_prime_symptom": 19,
    "first_prime_symptom_type": "DCT",
    "prime_symptom_duration": 57,
    "full_prime_symptom_duration": 57,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 40,
    "prime_symptom_3_duration": 36,
    "fatal_symptom_1": 76,
    "fatal_symptom_2": 76,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.25,
    "pathological_event_duration": 76,
    "event_record_is_complete": false,
    "pathological_severity": 106.7,
    "care_error_level": 0,
    "overall_patient_rating": 106.7,
    "patient_id": 185,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2714,
    "pathological_event_id": 246,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 70,
    "outlier": "CNF",
    "pathological_event_date": "2014-03-03",
    "etiology": "NWI",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 193,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 11,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 39,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 13,
    "prime_symptom_1_duration": 37,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 13,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 37,
    "full_prime_symptom_duration": 37,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 38,
    "prime_symptom_3_duration": 12,
    "fatal_symptom_1": 50,
    "fatal_symptom_2": 50,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.26,
    "pathological_event_duration": 50,
    "event_record_is_complete": false,
    "pathological_severity": 73.6,
    "care_error_level": 0,
    "overall_patient_rating": 73.6,
    "patient_id": 186,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2715,
    "pathological_event_id": 247,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2014-03-03",
    "etiology": "NWI",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 193,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 11,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 119,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 28,
    "prime_symptom_1_duration": 102,
    "prime_symptom_2": 43,
    "prime_symptom_2_duration": 87,
    "first_prime_symptom": 28,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 102,
    "full_prime_symptom_duration": 102,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 28,
    "prime_symptom_3_duration": 102,
    "fatal_symptom_1": 130,
    "fatal_symptom_2": 115,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.2154,
    "pathological_event_duration": 130,
    "event_record_is_complete": false,
    "pathological_severity": 211,
    "care_error_level": 0,
    "overall_patient_rating": 211,
    "patient_id": 187,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2716,
    "pathological_event_id": 248,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 66,
    "outlier": "DUB",
    "pathological_event_date": "2019-10-07",
    "etiology": "PRD",
    "care_equipment_1": "INO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 6,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": true,
    "event_record_length": 57,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 31,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 31,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 18,
    "care_error_level": 0,
    "overall_patient_rating": 18,
    "patient_id": 188,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2717,
    "pathological_event_id": 249,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2016-10-13",
    "etiology": "NWI",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 85,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 52,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 30,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 24,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 24,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": 45,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 12,
    "care_error_level": 0,
    "overall_patient_rating": 12,
    "patient_id": 189,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2718,
    "pathological_event_id": 250,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2017-05-09",
    "etiology": "NWI",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": null,
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 50,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 12,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 30,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 30,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 2,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 9,
    "care_error_level": 0,
    "overall_patient_rating": 9,
    "patient_id": 190,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2719,
    "pathological_event_id": 251,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2016-08-11",
    "etiology": "NWI",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": true,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 69,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 20,
    "prime_symptom_1_duration": 53,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 20,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 53,
    "full_prime_symptom_duration": 53,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 32,
    "prime_symptom_3_duration": 41,
    "fatal_symptom_1": 73,
    "fatal_symptom_2": 43,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.274,
    "pathological_event_duration": 73,
    "event_record_is_complete": false,
    "pathological_severity": 101.8,
    "care_error_level": 0,
    "overall_patient_rating": 101.8,
    "patient_id": 191,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2720,
    "pathological_event_id": 252,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "DUB",
    "pathological_event_date": "2016-04-24",
    "etiology": "STO",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": null,
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 34,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 10,
    "care_error_level": 0,
    "overall_patient_rating": 10,
    "patient_id": 192,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2721,
    "pathological_event_id": 253,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 68,
    "outlier": "CNF",
    "pathological_event_date": "2017-01-12",
    "etiology": "STO",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "007",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 31,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 15,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 15,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 15,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 14,
    "care_error_level": 0,
    "overall_patient_rating": 14,
    "patient_id": 193,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2722,
    "pathological_event_id": 254,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 69,
    "outlier": "CNF",
    "pathological_event_date": "2017-01-12",
    "etiology": "STO",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": null,
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": false,
    "event_record_length": 31,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 19,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 23,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 23,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 31,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 14,
    "care_error_level": 0,
    "overall_patient_rating": 14,
    "patient_id": 194,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2723,
    "pathological_event_id": 255,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2017-11-17",
    "etiology": "NWI",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": null,
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 93,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 15,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 58,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 28,
    "prime_symptom_1_duration": 45,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 15,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 45,
    "full_prime_symptom_duration": 58,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": 15,
    "prime_symptom_3_duration": 58,
    "fatal_symptom_1": 73,
    "fatal_symptom_2": 69,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.2055,
    "pathological_event_duration": 73,
    "event_record_is_complete": false,
    "pathological_severity": 100.8,
    "care_error_level": 0,
    "overall_patient_rating": 100.8,
    "patient_id": 195,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2724,
    "pathological_event_id": 256,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2017-11-17",
    "etiology": "NWI",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": null,
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 93,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 2,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 7,
    "care_error_level": 0,
    "overall_patient_rating": 7,
    "patient_id": 196,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2725,
    "pathological_event_id": 257,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2017-11-17",
    "etiology": "NWI",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": null,
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 93,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 15,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 26,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 15,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": 15,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 9,
    "care_error_level": 0,
    "overall_patient_rating": 9,
    "patient_id": 197,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2726,
    "pathological_event_id": 258,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "DEE",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "IR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 63,
    "outlier": "CNF",
    "pathological_event_date": "2014-08-08",
    "etiology": "PRD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "007",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 18,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 76,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 15,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 60,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 15,
    "prime_symptom_1_duration": 60,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 15,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 60,
    "full_prime_symptom_duration": 60,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 28,
    "prime_symptom_3_duration": 47,
    "fatal_symptom_1": 75,
    "fatal_symptom_2": 41,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": 54,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.2,
    "pathological_event_duration": 75,
    "event_record_is_complete": false,
    "pathological_severity": 134.6,
    "care_error_level": 0,
    "overall_patient_rating": 134.6,
    "patient_id": 198,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2727,
    "pathological_event_id": 259,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": "IN",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2022-06-16",
    "etiology": "STO",
    "care_equipment_1": "INO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "004",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": false,
    "event_record_length": 78,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 10,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 60,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 13,
    "prime_symptom_1_duration": 57,
    "prime_symptom_2": 33,
    "prime_symptom_2_duration": 37,
    "first_prime_symptom": 13,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 57,
    "full_prime_symptom_duration": 57,
    "prime_symptom_any": true,
    "prime_symptom_level": 6,
    "prime_symptom_3": 22,
    "prime_symptom_3_duration": 48,
    "fatal_symptom_1": 70,
    "fatal_symptom_2": 53,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.1857,
    "pathological_event_duration": 70,
    "event_record_is_complete": false,
    "pathological_severity": 124.7,
    "care_error_level": 0,
    "overall_patient_rating": 124.7,
    "patient_id": 199,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2728,
    "pathological_event_id": 260,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "UNK",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "DUB",
    "pathological_event_date": "2000-01-04",
    "etiology": "PRD",
    "care_equipment_1": "INO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": null,
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 6,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 60,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 12,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 12,
    "event_record_is_complete": false,
    "pathological_severity": 15.2,
    "care_error_level": 0,
    "overall_patient_rating": 124.7,
    "patient_id": 200,
    "event_count": 1,
    "event_title": "",
    "notes": "Possibly covered by FJP"
  },
  {
    "id": 2729,
    "pathological_event_id": 261,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "UNK",
    "outcome_type": "DCI",
    "source_country": "ID",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 67,
    "outlier": "CNF",
    "pathological_event_date": "2017-03-17",
    "etiology": "PRD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "001",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 12,
    "care_technique_2": "UNK",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": true,
    "event_record_length": 161,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 44,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 27,
    "mild_symptom_2_duration": 17,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": 150,
    "fatal_symptom_2": 150,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 150,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 44,
    "event_record_is_complete": false,
    "pathological_severity": 25.4,
    "care_error_level": 0,
    "overall_patient_rating": 124.7,
    "patient_id": 201,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2730,
    "pathological_event_id": 262,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "UNK",
    "outcome_type": "DCI",
    "source_country": "MX",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 70,
    "outlier": "CNF",
    "pathological_event_date": "2020-03-06",
    "etiology": "STO",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": null,
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": false,
    "event_record_length": 70,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 9,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 12,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 17,
    "prime_symptom_1_duration": 4,
    "prime_symptom_2": 21,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 17,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 4,
    "full_prime_symptom_duration": 4,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 17,
    "prime_symptom_3_duration": 4,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.8095,
    "pathological_event_duration": 21,
    "event_record_is_complete": false,
    "pathological_severity": 22.9,
    "care_error_level": 0,
    "overall_patient_rating": 124.7,
    "patient_id": 202,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2731,
    "pathological_event_id": 263,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "UNK",
    "outcome_type": "DCI",
    "source_country": "IN",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 70,
    "outlier": "CNF",
    "pathological_event_date": "2018-09-05",
    "etiology": "STO",
    "care_equipment_1": "SCT",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": null,
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": false,
    "event_record_length": 170,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 4,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 21,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 25,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 16,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": 9,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 16,
    "prime_symptom_3_duration": 9,
    "fatal_symptom_1": 58,
    "fatal_symptom_2": 58,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.64,
    "pathological_event_duration": 25,
    "event_record_is_complete": false,
    "pathological_severity": 19.3,
    "care_error_level": 0,
    "overall_patient_rating": 124.7,
    "patient_id": 203,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2732,
    "pathological_event_id": 264,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "UNK",
    "outcome_type": "DCI",
    "source_country": "BD",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 62,
    "outlier": "CNF",
    "pathological_event_date": "2023-04-05",
    "etiology": "PRD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 12,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 303,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 18,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 32,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 31,
    "prime_symptom_1_duration": 19,
    "prime_symptom_2": 50,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 22,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 19,
    "full_prime_symptom_duration": 28,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 22,
    "prime_symptom_3_duration": 28,
    "fatal_symptom_1": 62,
    "fatal_symptom_2": 62,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 278,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.44,
    "pathological_event_duration": 50,
    "event_record_is_complete": false,
    "pathological_severity": 70.2,
    "care_error_level": 0,
    "overall_patient_rating": 124.7,
    "patient_id": 204,
    "event_count": 1,
    "event_title": "",
    "notes": "On the day I started this, my birthday"
  },
  {
    "id": 2733,
    "pathological_event_id": 265,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": "BR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2023-06-02",
    "etiology": "PRD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "001",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 24,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 83,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 7,
    "mild_symptom_1_1_end": 40,
    "mild_symptom_1_2": 48,
    "mild_symptom_1_duration": 67,
    "mild_symptom_2": 12,
    "mild_symptom_2_duration": 70,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 27,
    "prime_symptom_2_duration": 55,
    "first_prime_symptom": 27,
    "first_prime_symptom_type": "DCT",
    "prime_symptom_duration": 55,
    "full_prime_symptom_duration": 55,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 27,
    "prime_symptom_3_duration": 55,
    "fatal_symptom_1": 82,
    "fatal_symptom_2": 75,
    "slight_death_response_1": null,
    "death_response_1": 64,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.3293,
    "pathological_event_duration": 82,
    "event_record_is_complete": false,
    "pathological_severity": 137.1,
    "care_error_level": 0,
    "overall_patient_rating": 137.1,
    "patient_id": 205,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2734,
    "pathological_event_id": 266,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": "IN",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 55,
    "outlier": "CNF",
    "pathological_event_date": "2017-04-16",
    "etiology": "STO",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "004",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 60,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 6,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 54,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 29,
    "prime_symptom_1_duration": 31,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 25,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 31,
    "full_prime_symptom_duration": 35,
    "prime_symptom_any": true,
    "prime_symptom_level": 6,
    "prime_symptom_3": 25,
    "prime_symptom_3_duration": 35,
    "fatal_symptom_1": 60,
    "fatal_symptom_2": 40,
    "slight_death_response_1": null,
    "death_response_1": 42,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.4167,
    "pathological_event_duration": 60,
    "event_record_is_complete": false,
    "pathological_severity": 85,
    "care_error_level": 0,
    "overall_patient_rating": 85,
    "patient_id": 206,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2735,
    "pathological_event_id": 267,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": "FR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 70,
    "outlier": "CNF",
    "pathological_event_date": "2023-12-01",
    "etiology": "LWD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "SIT",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 150,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 20,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 105,
    "mild_symptom_2": 50,
    "mild_symptom_2_duration": 75,
    "prime_symptom_1": 50,
    "prime_symptom_1_duration": 75,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 50,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 75,
    "full_prime_symptom_duration": 75,
    "prime_symptom_any": true,
    "prime_symptom_level": 5,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": 125,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 140,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.4,
    "pathological_event_duration": 125,
    "event_record_is_complete": false,
    "pathological_severity": 138.1,
    "care_error_level": 0,
    "overall_patient_rating": 138.1,
    "patient_id": 207,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2543,
    "pathological_event_id": 74,
    "care_site": "LRM",
    "pathological_source_ref_id": "064788A",
    "consultant_doctor": "NEM",
    "consultant_doctor_variation": 5,
    "outcome": "NSV",
    "outcome_type": "FSN",
    "source_country": "US",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 60,
    "outlier": "CNF",
    "pathological_event_date": "2015-01-05",
    "etiology": "NWI",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "RPH",
    "care_technique_4": true,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": true,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 44,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 30,
    "recovery_duration": 3,
    "recovery_proportion": 0.0909,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 33,
    "event_record_is_complete": true,
    "pathological_severity": 12.3,
    "care_error_level": 10.5,
    "overall_patient_rating": 9.03,
    "patient_id": 22,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2736,
    "pathological_event_id": 268,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": "IN",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2023-10-03",
    "etiology": "STO",
    "care_equipment_1": "SCW",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "007",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 107,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 17,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 73,
    "mild_symptom_2": 15,
    "mild_symptom_2_duration": 75,
    "prime_symptom_1": 23,
    "prime_symptom_1_duration": 67,
    "prime_symptom_2": 23,
    "prime_symptom_2_duration": 67,
    "first_prime_symptom": 23,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 67,
    "full_prime_symptom_duration": 67,
    "prime_symptom_any": true,
    "prime_symptom_level": 5,
    "prime_symptom_3": 33,
    "prime_symptom_3_duration": 57,
    "fatal_symptom_1": 90,
    "fatal_symptom_2": 59,
    "slight_death_response_1": null,
    "death_response_1": 71,
    "slight_death_response_2": 63,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.2556,
    "pathological_event_duration": 90,
    "event_record_is_complete": false,
    "pathological_severity": 137.8,
    "care_error_level": 0,
    "overall_patient_rating": 137.8,
    "patient_id": 208,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2745,
    "pathological_event_id": 277,
    "care_site": "LRM",
    "pathological_source_ref_id": "A19EDFE",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2023-10-30",
    "etiology": "STO",
    "care_equipment_1": "SCW",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "003",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": true,
    "event_record_length": 99,
    "intro_symptom_start": 0,
    "intro_symptom_end": 7,
    "intro_symptom_duration": 7,
    "mild_symptom_1": 14,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 74,
    "mild_symptom_2": 14,
    "mild_symptom_2_duration": 74,
    "prime_symptom_1": 39,
    "prime_symptom_1_duration": 49,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 22,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 49,
    "full_prime_symptom_duration": 66,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 22,
    "prime_symptom_3_duration": 66,
    "fatal_symptom_1": 88,
    "fatal_symptom_2": 72,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.25,
    "pathological_event_duration": 88,
    "event_record_is_complete": false,
    "pathological_severity": 114.3,
    "care_error_level": 0,
    "overall_patient_rating": 114.3,
    "patient_id": 211,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2746,
    "pathological_event_id": 278,
    "care_site": "LRM",
    "pathological_source_ref_id": "8F708DB",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "UNK",
    "outcome_type": "DNT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2023-11-07",
    "etiology": "LWD",
    "care_equipment_1": "SCW",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 140,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 25,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 11,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 36,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 30,
    "prime_symptom_2_duration": 6,
    "first_prime_symptom": 25,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 6,
    "full_prime_symptom_duration": 11,
    "prime_symptom_any": true,
    "prime_symptom_level": 5,
    "prime_symptom_3": 25,
    "prime_symptom_3_duration": 11,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.6944,
    "pathological_event_duration": 36,
    "event_record_is_complete": false,
    "pathological_severity": 22.9,
    "care_error_level": 0,
    "overall_patient_rating": 114.3,
    "patient_id": 212,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2761,
    "pathological_event_id": 293,
    "care_site": "LRM",
    "pathological_source_ref_id": "AB6266E",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "UNK",
    "outcome_type": "ACC",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 60,
    "outlier": "UNU",
    "pathological_event_date": "2023-04-30",
    "etiology": "STO",
    "care_equipment_1": "THO",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "TIP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 64,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 19,
    "mild_symptom_2_duration": 16,
    "prime_symptom_1": 35,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 31,
    "prime_symptom_2_duration": 4,
    "first_prime_symptom": 31,
    "first_prime_symptom_type": "DCT",
    "prime_symptom_duration": 4,
    "full_prime_symptom_duration": 4,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 35,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": 62,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 55,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.8857,
    "pathological_event_duration": 35,
    "event_record_is_complete": false,
    "pathological_severity": 17.3,
    "care_error_level": 0,
    "overall_patient_rating": 114.3,
    "patient_id": 220,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2784,
    "pathological_event_id": 316,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRI",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "EQ",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2024-01-10",
    "etiology": "NWI",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 70,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 34,
    "prime_symptom_1_duration": 26,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 11,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 26,
    "full_prime_symptom_duration": 49,
    "prime_symptom_any": true,
    "prime_symptom_level": 2,
    "prime_symptom_3": 11,
    "prime_symptom_3_duration": 49,
    "fatal_symptom_1": 60,
    "fatal_symptom_2": 38,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.1833,
    "pathological_event_duration": 60,
    "event_record_is_complete": false,
    "pathological_severity": 65.5,
    "care_error_level": 0,
    "overall_patient_rating": 65.5,
    "patient_id": 224,
    "event_count": 1,
    "event_title": "",
    "notes": "Boots and tied hands effect rigidity output"
  },
  {
    "id": 2785,
    "pathological_event_id": 317,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRI",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DEE",
    "source_country": "EQ",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 65,
    "outlier": "UNU",
    "pathological_event_date": "2024-01-09",
    "etiology": "NWI",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "003",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 114,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 86,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 22,
    "mild_symptom_2": 80,
    "mild_symptom_2_duration": 28,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 96,
    "prime_symptom_2_duration": 12,
    "first_prime_symptom": 96,
    "first_prime_symptom_type": "DCT",
    "prime_symptom_duration": 12,
    "full_prime_symptom_duration": 12,
    "prime_symptom_any": true,
    "prime_symptom_level": 1,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": 108,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.8889,
    "pathological_event_duration": 108,
    "event_record_is_complete": false,
    "pathological_severity": 41.5,
    "care_error_level": 0,
    "overall_patient_rating": 41.5,
    "patient_id": 225,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2786,
    "pathological_event_id": 318,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": "IN",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2024-01-08",
    "etiology": "STO",
    "care_equipment_1": "INO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 202,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 17,
    "mild_symptom_2_duration": 146,
    "prime_symptom_1": 21,
    "prime_symptom_1_duration": 142,
    "prime_symptom_2": 37,
    "prime_symptom_2_duration": 126,
    "first_prime_symptom": 21,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 142,
    "full_prime_symptom_duration": 142,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 27,
    "prime_symptom_3_duration": 136,
    "fatal_symptom_1": 163,
    "fatal_symptom_2": 136,
    "slight_death_response_1": null,
    "death_response_1": 129,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.1288,
    "pathological_event_duration": 163,
    "event_record_is_complete": false,
    "pathological_severity": 248.7,
    "care_error_level": 0,
    "overall_patient_rating": 248.7,
    "patient_id": 226,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2787,
    "pathological_event_id": 319,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": "IN",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 60,
    "outlier": "CNF",
    "pathological_event_date": "2024-01-09",
    "etiology": "PRD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "007",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 12,
    "care_technique_2": "FFT",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 152,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 32,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 109,
    "mild_symptom_2": 19,
    "mild_symptom_2_duration": 122,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 34,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": 107,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 34,
    "prime_symptom_3_duration": 107,
    "fatal_symptom_1": null,
    "fatal_symptom_2": 141,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.2411,
    "pathological_event_duration": 141,
    "event_record_is_complete": false,
    "pathological_severity": 102.7,
    "care_error_level": 0,
    "overall_patient_rating": 102.7,
    "patient_id": 227,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2788,
    "pathological_event_id": 320,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": "IN",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 62,
    "outlier": "UNU",
    "pathological_event_date": "2022-08-07",
    "etiology": "UNK",
    "care_equipment_1": "SCT",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "SIT",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 85,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 11,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 17,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 17,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": 19,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 6,
    "care_error_level": 0,
    "overall_patient_rating": 6,
    "patient_id": 228,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2789,
    "pathological_event_id": 321,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": "BR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2022-05-24",
    "etiology": "PRD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "003",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 3,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 113,
    "intro_symptom_start": 0,
    "intro_symptom_end": 5,
    "intro_symptom_duration": 5,
    "mild_symptom_1": 13,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 17,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 17,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 17,
    "care_error_level": 0,
    "overall_patient_rating": 17,
    "patient_id": 229,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2790,
    "pathological_event_id": 322,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "SEV",
    "outcome_type": "DCI",
    "source_country": "CL",
    "commissioner_initials": null,
    "presented_gender": "_",
    "patient_weight": null,
    "outlier": "UNU",
    "pathological_event_date": "2021-01-31",
    "etiology": "PRD",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 12,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 31,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 20,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 28,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 28,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 20,
    "care_error_level": 0,
    "overall_patient_rating": 20,
    "patient_id": 230,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2791,
    "pathological_event_id": 323,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "UNK",
    "outcome_type": "DCI",
    "source_country": "IN",
    "commissioner_initials": null,
    "presented_gender": "_",
    "patient_weight": null,
    "outlier": "DUB",
    "pathological_event_date": "2021-04-12",
    "etiology": "PRD",
    "care_equipment_1": "SCT",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "002",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 18,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 19,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 12,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 12,
    "event_record_is_complete": false,
    "pathological_severity": 24.2,
    "care_error_level": 0,
    "overall_patient_rating": 20,
    "patient_id": 231,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2792,
    "pathological_event_id": 324,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "UNK",
    "outcome_type": "ATM",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 50,
    "outlier": "CNF",
    "pathological_event_date": "2021-04-26",
    "etiology": "PRD",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 6,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 39,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 14,
    "prime_symptom_1_duration": 10,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 14,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 10,
    "full_prime_symptom_duration": 10,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": 14,
    "prime_symptom_3_duration": 10,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 24,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.5833,
    "pathological_event_duration": 24,
    "event_record_is_complete": false,
    "pathological_severity": 31.5,
    "care_error_level": 0,
    "overall_patient_rating": 20,
    "patient_id": 232,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2793,
    "pathological_event_id": 325,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "FAT",
    "outcome_type": "DCI",
    "source_country": "IN",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 58,
    "outlier": "CNF",
    "pathological_event_date": "2021-05-17",
    "etiology": "PRD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "007",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 21,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 42,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 5,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": 23,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 35,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 26,
    "care_error_level": 0,
    "overall_patient_rating": 20,
    "patient_id": 233,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2794,
    "pathological_event_id": 326,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "FAT",
    "outcome_type": "DCI",
    "source_country": "CL",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2021-08-26",
    "etiology": "STO",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "001",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 53,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 13,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 10,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 19,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 19,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": 40,
    "slight_death_response_2": null,
    "death_response_2": 49,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 12,
    "care_error_level": 0,
    "overall_patient_rating": 20,
    "patient_id": 234,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2795,
    "pathological_event_id": 327,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "FAT",
    "outcome_type": "DCI",
    "source_country": "BR",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 62,
    "outlier": "CNF",
    "pathological_event_date": "2021-11-21",
    "etiology": "STO",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "004",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 58,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 10,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 23,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 23,
    "first_prime_symptom_type": "DCT",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 2,
    "prime_symptom_3": 33,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 12,
    "care_error_level": 0,
    "overall_patient_rating": 20,
    "patient_id": 235,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2796,
    "pathological_event_id": 328,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "FAT",
    "outcome_type": "DCI",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 58,
    "outlier": "UNU",
    "pathological_event_date": "2020-06-03",
    "etiology": "PRD",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 3,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": false,
    "event_record_length": 32,
    "intro_symptom_start": 0,
    "intro_symptom_end": 6,
    "intro_symptom_duration": 6,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 14,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 14,
    "first_prime_symptom_type": "DCT",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 2,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 12,
    "care_error_level": 0,
    "overall_patient_rating": 20,
    "patient_id": 236,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2797,
    "pathological_event_id": 329,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "FAT",
    "outcome_type": "DCI",
    "source_country": "IT",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2020-06-23",
    "etiology": "STO",
    "care_equipment_1": "SCT",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "001",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 140,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 35,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 7,
    "care_error_level": 0,
    "overall_patient_rating": 20,
    "patient_id": 237,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2798,
    "pathological_event_id": 330,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "FAT",
    "outcome_type": "DCI",
    "source_country": "PH",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2020-04-02",
    "etiology": "LWD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 122,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 12,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 14,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 16,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 32,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 16,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": 33,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 12,
    "care_error_level": 0,
    "overall_patient_rating": 20,
    "patient_id": 238,
    "event_count": 1,
    "event_title": "",
    "notes": "Phillipines"
  },
  {
    "id": 2799,
    "pathological_event_id": 332,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "FAT",
    "outcome_type": "DCI",
    "source_country": "IN",
    "commissioner_initials": null,
    "presented_gender": "_",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2019-06-18",
    "etiology": "STO",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "007",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 140,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 23,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 23,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 27,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": 48,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 104,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 12,
    "care_error_level": 0,
    "overall_patient_rating": 20,
    "patient_id": 240,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2800,
    "pathological_event_id": 333,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "FAT",
    "outcome_type": "DCI",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "_",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2019-04-23",
    "etiology": "STO",
    "care_equipment_1": "SCT",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "UNK",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 63,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 14,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 25,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 25,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 7,
    "care_error_level": 0,
    "overall_patient_rating": 20,
    "patient_id": 241,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2801,
    "pathological_event_id": 334,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "FAT",
    "outcome_type": "DCI",
    "source_country": "IN",
    "commissioner_initials": null,
    "presented_gender": "_",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2018-10-09",
    "etiology": "PRD",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "004",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 24,
    "care_technique_2": "FFT",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": true,
    "event_record_length": 46,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 25,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 34,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 34,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 22,
    "care_error_level": 0,
    "overall_patient_rating": 20,
    "patient_id": 242,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2803,
    "pathological_event_id": 336,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "FAT",
    "outcome_type": "DCI",
    "source_country": "IN",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "DUB",
    "pathological_event_date": "2018-07-31",
    "etiology": "PRD",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "007",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 3,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": false,
    "event_record_length": 300,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 8,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 23,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 23,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": 272,
    "fatal_symptom_2": 272,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 272,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 12,
    "care_error_level": 0,
    "overall_patient_rating": 20,
    "patient_id": 244,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2804,
    "pathological_event_id": 337,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "FAT",
    "outcome_type": "DCI",
    "source_country": "IN",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2018-06-12",
    "etiology": "LWD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 98,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 6,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 22,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 22,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": 75,
    "fatal_symptom_2": 51,
    "slight_death_response_1": null,
    "death_response_1": 51,
    "slight_death_response_2": null,
    "death_response_2": 51,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 11,
    "care_error_level": 0,
    "overall_patient_rating": 20,
    "patient_id": 245,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2805,
    "pathological_event_id": 338,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRI",
    "consultant_doctor_variation": null,
    "outcome": "FAT",
    "outcome_type": "ICE",
    "source_country": "BR",
    "commissioner_initials": null,
    "presented_gender": "_",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2018-05-23",
    "etiology": "STO",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 55,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 12,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 14,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 20,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 22,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 18,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 18,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 12,
    "care_error_level": 0,
    "overall_patient_rating": 20,
    "patient_id": 246,
    "event_count": 1,
    "event_title": "",
    "notes": "Likely facing worse for rape"
  },
  {
    "id": 2806,
    "pathological_event_id": 339,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": null,
    "outcome": "FAT",
    "outcome_type": "DCI",
    "source_country": "IN",
    "commissioner_initials": null,
    "presented_gender": "X",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2017-10-25",
    "etiology": "STO",
    "care_equipment_1": "SCT",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "UNK",
    "care_technique_3": "NAF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 146,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": 114,
    "fatal_symptom_2": 114,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 5,
    "care_error_level": 0,
    "overall_patient_rating": 20,
    "patient_id": 248,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2807,
    "pathological_event_id": 340,
    "care_site": "LRM",
    "pathological_source_ref_id": "30F015A",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": null,
    "outcome": "NFT",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "X",
    "patient_weight": 68,
    "outlier": "UNU",
    "pathological_event_date": "2024-01-11",
    "etiology": "SIT",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "KNE",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 156,
    "intro_symptom_start": 0,
    "intro_symptom_end": 156,
    "intro_symptom_duration": 156,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 18.5,
    "care_error_level": 0,
    "overall_patient_rating": 20,
    "patient_id": 223,
    "event_count": 19,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2808,
    "pathological_event_id": 341,
    "care_site": "LRM",
    "pathological_source_ref_id": "0E3507C",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": null,
    "outcome": "NFT",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "X",
    "patient_weight": 68,
    "outlier": "UNU",
    "pathological_event_date": "2024-01-28",
    "etiology": "STO",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 271,
    "intro_symptom_start": 0,
    "intro_symptom_end": 268,
    "intro_symptom_duration": 268,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 268,
    "recovery_duration": 3,
    "recovery_proportion": 0.0111,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": true,
    "pathological_severity": 23,
    "care_error_level": 0,
    "overall_patient_rating": 20,
    "patient_id": 223,
    "event_count": 20,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2809,
    "pathological_event_id": 342,
    "care_site": "LRM",
    "pathological_source_ref_id": "AA8295E",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": null,
    "outcome": "NFT",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "X",
    "patient_weight": 68,
    "outlier": "UNU",
    "pathological_event_date": "2024-01-28",
    "etiology": "STO",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 286,
    "intro_symptom_start": 0,
    "intro_symptom_end": 285,
    "intro_symptom_duration": 285,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 285,
    "recovery_duration": 1,
    "recovery_proportion": 0.0035,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": true,
    "pathological_severity": 19.5,
    "care_error_level": 0,
    "overall_patient_rating": 20,
    "patient_id": 223,
    "event_count": 21,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2810,
    "pathological_event_id": 343,
    "care_site": "LRM",
    "pathological_source_ref_id": "500EED8",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": null,
    "outcome": "NFT",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "X",
    "patient_weight": 68,
    "outlier": "UNU",
    "pathological_event_date": "2024-02-03",
    "etiology": "STO",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 205,
    "intro_symptom_start": 0,
    "intro_symptom_end": 205,
    "intro_symptom_duration": 205,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 20,
    "care_error_level": 0,
    "overall_patient_rating": 20,
    "patient_id": 223,
    "event_count": 22,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2588,
    "pathological_event_id": 120,
    "care_site": "LRM",
    "pathological_source_ref_id": "70E731F",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2021-09-25",
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "TIP",
    "care_technique_3": "NLT",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 207,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 5,
    "care_error_level": -2,
    "overall_patient_rating": 0.28,
    "patient_id": 117,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2586,
    "pathological_event_id": 118,
    "care_site": "LRM",
    "pathological_source_ref_id": null,
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "UNK",
    "care_equipment_1": "INH",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "TIP",
    "care_technique_3": "NLT",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 7,
    "care_error_level": 0,
    "overall_patient_rating": 1.9,
    "patient_id": 115,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2587,
    "pathological_event_id": 119,
    "care_site": "LRM",
    "pathological_source_ref_id": null,
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "TIP",
    "care_technique_3": "NLT",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 5,
    "care_error_level": -2,
    "overall_patient_rating": 0.28,
    "patient_id": 116,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2471,
    "pathological_event_id": 2,
    "care_site": "LRM",
    "pathological_source_ref_id": "C2AA098",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2022-10-02",
    "etiology": "PRD",
    "care_equipment_1": "INH",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 6,
    "care_technique_2": "CMP",
    "care_technique_3": "KNR",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 113,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 14,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 32,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 20,
    "prime_symptom_2_duration": 26,
    "first_prime_symptom": 20,
    "first_prime_symptom_type": "DCT",
    "prime_symptom_duration": 26,
    "full_prime_symptom_duration": 26,
    "prime_symptom_any": true,
    "prime_symptom_level": 2,
    "prime_symptom_3": 21,
    "prime_symptom_3_duration": 25,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 34,
    "recovery_duration": 12,
    "recovery_proportion": 0.2609,
    "prime_symptom_proportion": 0.4348,
    "pathological_event_duration": 46,
    "event_record_is_complete": true,
    "pathological_severity": 72.1,
    "care_error_level": 65,
    "overall_patient_rating": 54.79,
    "patient_id": 4,
    "event_count": 2,
    "event_title": "DIYJ06",
    "notes": ""
  },
  {
    "id": 2472,
    "pathological_event_id": 3,
    "care_site": "LRM",
    "pathological_source_ref_id": "C8269AB",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 56,
    "outlier": "CNF",
    "pathological_event_date": "2023-01-14",
    "etiology": "NWI",
    "care_equipment_1": "INO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 15,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 13,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 18,
    "prime_symptom_1_duration": 10,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 10,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 10,
    "full_prime_symptom_duration": 18,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 10,
    "prime_symptom_3_duration": 18,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 28,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.3571,
    "pathological_event_duration": 28,
    "event_record_is_complete": false,
    "pathological_severity": 38.5,
    "care_error_level": 39.5,
    "overall_patient_rating": 31.85,
    "patient_id": 3,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2476,
    "pathological_event_id": 7,
    "care_site": "LRM",
    "pathological_source_ref_id": "3CD8CD1",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 60,
    "outlier": "CNF",
    "pathological_event_date": "2021-10-05",
    "etiology": "STO",
    "care_equipment_1": "INO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 35,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 14,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 19,
    "mild_symptom_2": 10,
    "mild_symptom_2_duration": 23,
    "prime_symptom_1": 15,
    "prime_symptom_1_duration": 18,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 15,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 18,
    "full_prime_symptom_duration": 18,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 15,
    "recovery_duration": 18,
    "recovery_proportion": 0.5455,
    "prime_symptom_proportion": 0.4545,
    "pathological_event_duration": 33,
    "event_record_is_complete": true,
    "pathological_severity": 30.9,
    "care_error_level": 51.5,
    "overall_patient_rating": 36.28,
    "patient_id": 64,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2477,
    "pathological_event_id": 8,
    "care_site": "LRM",
    "pathological_source_ref_id": "BC91CC7",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2022-12-30",
    "etiology": "NWI",
    "care_equipment_1": "THO",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 149,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 14,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 26,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 18,
    "prime_symptom_1_duration": 22,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 18,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 22,
    "full_prime_symptom_duration": 22,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 20,
    "prime_symptom_3_duration": 20,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 25,
    "recovery_duration": 15,
    "recovery_proportion": 0.375,
    "prime_symptom_proportion": 0.45,
    "pathological_event_duration": 40,
    "event_record_is_complete": true,
    "pathological_severity": 49.6,
    "care_error_level": 65.5,
    "overall_patient_rating": 48.95,
    "patient_id": 4,
    "event_count": 1,
    "event_title": "NT11",
    "notes": ""
  },
  {
    "id": 2480,
    "pathological_event_id": 11,
    "care_site": "LRM",
    "pathological_source_ref_id": "D0756B7",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 56,
    "outlier": "CNF",
    "pathological_event_date": "2021-09-29",
    "etiology": "PRD",
    "care_equipment_1": "HIC",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 9,
    "care_technique_2": "CMP",
    "care_technique_3": "RPH",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 76,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 16,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 6,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 20,
    "prime_symptom_1_duration": 2,
    "prime_symptom_2": 22,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 17,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 2,
    "full_prime_symptom_duration": 5,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 17,
    "prime_symptom_3_duration": 5,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.7727,
    "pathological_event_duration": 22,
    "event_record_is_complete": false,
    "pathological_severity": 33.8,
    "care_error_level": 37,
    "overall_patient_rating": 29.22,
    "patient_id": 67,
    "event_count": 1,
    "event_title": "ABD",
    "notes": ""
  },
  {
    "id": 2481,
    "pathological_event_id": 12,
    "care_site": "LRM",
    "pathological_source_ref_id": "4590A12",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 58,
    "outlier": "CNF",
    "pathological_event_date": "2021-11-17",
    "etiology": "PRD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "007",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 6,
    "care_technique_2": "CMP",
    "care_technique_3": "007",
    "care_technique_4": false,
    "care_technique_5": true,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 769,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 9,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 8,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 15,
    "prime_symptom_1_duration": 2,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 15,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 2,
    "full_prime_symptom_duration": 2,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 16,
    "recovery_duration": 1,
    "recovery_proportion": 0.0588,
    "prime_symptom_proportion": 0.8824,
    "pathological_event_duration": 17,
    "event_record_is_complete": true,
    "pathological_severity": 25.7,
    "care_error_level": 24,
    "overall_patient_rating": 19.98,
    "patient_id": 3,
    "event_count": 1,
    "event_title": "ROM4-2",
    "notes": ""
  },
  {
    "id": 2485,
    "pathological_event_id": 16,
    "care_site": "LRM",
    "pathological_source_ref_id": "DC26B66",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 54,
    "outlier": "CNF",
    "pathological_event_date": "2022-12-16",
    "etiology": "LWD",
    "care_equipment_1": "SCT",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 96,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 15,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 45,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 24,
    "prime_symptom_1_duration": 36,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 20,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 36,
    "full_prime_symptom_duration": 40,
    "prime_symptom_any": true,
    "prime_symptom_level": 6,
    "prime_symptom_3": 20,
    "prime_symptom_3_duration": 40,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 60,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.3333,
    "pathological_event_duration": 60,
    "event_record_is_complete": false,
    "pathological_severity": 89,
    "care_error_level": 58.5,
    "overall_patient_rating": 55.86,
    "patient_id": 29,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2486,
    "pathological_event_id": 17,
    "care_site": "LRM",
    "pathological_source_ref_id": "234BBEE",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 56,
    "outlier": "CNF",
    "pathological_event_date": "2022-12-24",
    "etiology": "LWD",
    "care_equipment_1": "SCW",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CHD",
    "care_technique_3": "LWA",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 53,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 3,
    "mild_symptom_2_duration": 27,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 30,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 30,
    "event_record_is_complete": false,
    "pathological_severity": 7,
    "care_error_level": -1,
    "overall_patient_rating": 1.36,
    "patient_id": 1,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2487,
    "pathological_event_id": 18,
    "care_site": "LRM",
    "pathological_source_ref_id": "10137B2",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 56,
    "outlier": "CNF",
    "pathological_event_date": "2022-12-24",
    "etiology": "LWD",
    "care_equipment_1": "SCW",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CHD",
    "care_technique_3": "007",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 136,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 7,
    "mild_symptom_2_duration": 67,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 13,
    "prime_symptom_2_duration": 61,
    "first_prime_symptom": 13,
    "first_prime_symptom_type": "DCT",
    "prime_symptom_duration": 61,
    "full_prime_symptom_duration": 61,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 50,
    "recovery_duration": 24,
    "recovery_proportion": 0.3243,
    "prime_symptom_proportion": 0.1757,
    "pathological_event_duration": 74,
    "event_record_is_complete": true,
    "pathological_severity": 74.9,
    "care_error_level": 73,
    "overall_patient_rating": 59.88,
    "patient_id": 1,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2488,
    "pathological_event_id": 19,
    "care_site": "LRM",
    "pathological_source_ref_id": "050C595",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 56,
    "outlier": "CNF",
    "pathological_event_date": "2022-12-26",
    "etiology": "LWD",
    "care_equipment_1": "SCW",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CHD",
    "care_technique_3": "007",
    "care_technique_4": false,
    "care_technique_5": true,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 67,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 10,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 32,
    "mild_symptom_2": 10,
    "mild_symptom_2_duration": 32,
    "prime_symptom_1": 12,
    "prime_symptom_1_duration": 30,
    "prime_symptom_2": 23,
    "prime_symptom_2_duration": 19,
    "first_prime_symptom": 12,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 30,
    "full_prime_symptom_duration": 30,
    "prime_symptom_any": true,
    "prime_symptom_level": 6,
    "prime_symptom_3": 17,
    "prime_symptom_3_duration": 25,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 41,
    "recovery_duration": 1,
    "recovery_proportion": 0.0238,
    "prime_symptom_proportion": 0.2857,
    "pathological_event_duration": 42,
    "event_record_is_complete": true,
    "pathological_severity": 67.8,
    "care_error_level": 38.8,
    "overall_patient_rating": 39.43,
    "patient_id": 1,
    "event_count": 3,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2489,
    "pathological_event_id": 20,
    "care_site": "LRM",
    "pathological_source_ref_id": "84E192B",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": "PB",
    "presented_gender": "F",
    "patient_weight": 59,
    "outlier": "CNF",
    "pathological_event_date": "2022-09-04",
    "etiology": "LWD",
    "care_equipment_1": "INH",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "KNR",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 122,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 10,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 50,
    "mild_symptom_2": 10,
    "mild_symptom_2_duration": 50,
    "prime_symptom_1": 18,
    "prime_symptom_1_duration": 42,
    "prime_symptom_2": 23,
    "prime_symptom_2_duration": 37,
    "first_prime_symptom": 17,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 42,
    "full_prime_symptom_duration": 43,
    "prime_symptom_any": true,
    "prime_symptom_level": 5,
    "prime_symptom_3": 17,
    "prime_symptom_3_duration": 43,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 40,
    "recovery_duration": 20,
    "recovery_proportion": 0.3333,
    "prime_symptom_proportion": 0.2833,
    "pathological_event_duration": 60,
    "event_record_is_complete": true,
    "pathological_severity": 90.8,
    "care_error_level": 87.3,
    "overall_patient_rating": 71.95,
    "patient_id": 2,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2490,
    "pathological_event_id": 21,
    "care_site": "LRM",
    "pathological_source_ref_id": "BBC4E81",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 59,
    "outlier": "CNF",
    "pathological_event_date": "2022-09-04",
    "etiology": "PRD",
    "care_equipment_1": "INH",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 6,
    "care_technique_2": "CMP",
    "care_technique_3": "KNR",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 286,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 12,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 22,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 10,
    "prime_symptom_1_duration": 24,
    "prime_symptom_2": 24,
    "prime_symptom_2_duration": 10,
    "first_prime_symptom": 10,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 24,
    "full_prime_symptom_duration": 24,
    "prime_symptom_any": true,
    "prime_symptom_level": 5,
    "prime_symptom_3": 16,
    "prime_symptom_3_duration": 18,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 24,
    "recovery_duration": 10,
    "recovery_proportion": 0.2941,
    "prime_symptom_proportion": 0.2941,
    "pathological_event_duration": 34,
    "event_record_is_complete": true,
    "pathological_severity": 65.3,
    "care_error_level": 58.4,
    "overall_patient_rating": 49.37,
    "patient_id": 2,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2495,
    "pathological_event_id": 26,
    "care_site": "LRM",
    "pathological_source_ref_id": "9D96A26",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2021-10-28",
    "etiology": "LWD",
    "care_equipment_1": "HIH",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 108,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 12,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 19,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 31,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 14,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": 17,
    "prime_symptom_any": true,
    "prime_symptom_level": 1,
    "prime_symptom_3": 14,
    "prime_symptom_3_duration": 17,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 25,
    "recovery_duration": 6,
    "recovery_proportion": 0.1935,
    "prime_symptom_proportion": 0.4516,
    "pathological_event_duration": 31,
    "event_record_is_complete": true,
    "pathological_severity": 30.9,
    "care_error_level": 8.7,
    "overall_patient_rating": 13.11,
    "patient_id": 73,
    "event_count": 1,
    "event_title": "ACGIAJ02",
    "notes": ""
  },
  {
    "id": 2498,
    "pathological_event_id": 29,
    "care_site": "LRM",
    "pathological_source_ref_id": "876F675",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 62,
    "outlier": "CNF",
    "pathological_event_date": "2021-11-04",
    "etiology": "NWI",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 105,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 10,
    "prime_symptom_1_duration": 25,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 10,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 25,
    "full_prime_symptom_duration": 25,
    "prime_symptom_any": true,
    "prime_symptom_level": 2,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 13,
    "recovery_duration": 22,
    "recovery_proportion": 0.6286,
    "prime_symptom_proportion": 0.2857,
    "pathological_event_duration": 35,
    "event_record_is_complete": true,
    "pathological_severity": 37.3,
    "care_error_level": 77.5,
    "overall_patient_rating": 52.1,
    "patient_id": 32,
    "event_count": 1,
    "event_title": "RN44",
    "notes": ""
  },
  {
    "id": 2500,
    "pathological_event_id": 31,
    "care_site": "LRM",
    "pathological_source_ref_id": "1988311",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2023-06-20",
    "etiology": "PRD",
    "care_equipment_1": "INH",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "004",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 9,
    "care_technique_2": "CMP",
    "care_technique_3": "TID",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 157,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 17,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 17,
    "mild_symptom_2": 12,
    "mild_symptom_2_duration": 22,
    "prime_symptom_1": 34,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 18,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": 16,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 18,
    "prime_symptom_3_duration": 16,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.5294,
    "pathological_event_duration": 34,
    "event_record_is_complete": false,
    "pathological_severity": 41.2,
    "care_error_level": 29.5,
    "overall_patient_rating": 27.17,
    "patient_id": 75,
    "event_count": 1,
    "event_title": "UX2-1",
    "notes": ""
  },
  {
    "id": 2501,
    "pathological_event_id": 32,
    "care_site": "RPV",
    "pathological_source_ref_id": null,
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "NWI",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "MLT",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 25,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 7,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 7,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 9,
    "recovery_duration": 5,
    "recovery_proportion": 0.3571,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 14,
    "event_record_is_complete": true,
    "pathological_severity": 18,
    "care_error_level": 29.2,
    "overall_patient_rating": 20.7,
    "patient_id": 76,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2506,
    "pathological_event_id": 37,
    "care_site": "LRM",
    "pathological_source_ref_id": "014E9BA",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 62,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "NWI",
    "care_equipment_1": "INO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "003",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 717,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 10,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 2,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 12,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 12,
    "event_record_is_complete": false,
    "pathological_severity": 11.6,
    "care_error_level": 19,
    "overall_patient_rating": 13.44,
    "patient_id": 81,
    "event_count": 1,
    "event_title": "RN52",
    "notes": ""
  },
  {
    "id": 2507,
    "pathological_event_id": 38,
    "care_site": "LRM",
    "pathological_source_ref_id": "0E2BFBC",
    "consultant_doctor": "AGG",
    "consultant_doctor_variation": 2,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": "TW",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 54,
    "outlier": "CNF",
    "pathological_event_date": "2020-09-28",
    "etiology": "NWI",
    "care_equipment_1": "INO",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": true,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 172,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 9,
    "mild_symptom_2_duration": 95,
    "prime_symptom_1": 23,
    "prime_symptom_1_duration": 81,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 23,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 81,
    "full_prime_symptom_duration": 81,
    "prime_symptom_any": true,
    "prime_symptom_level": 6,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 87,
    "recovery_duration": 17,
    "recovery_proportion": 0.1635,
    "prime_symptom_proportion": 0.2212,
    "pathological_event_duration": 104,
    "event_record_is_complete": true,
    "pathological_severity": 121.5,
    "care_error_level": 75,
    "overall_patient_rating": 73.63,
    "patient_id": 13,
    "event_count": 6,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2508,
    "pathological_event_id": 39,
    "care_site": "LRM",
    "pathological_source_ref_id": "F4E64C2",
    "consultant_doctor": "AGG",
    "consultant_doctor_variation": 2,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": "TW",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 61,
    "outlier": "CNF",
    "pathological_event_date": "2022-10-26",
    "etiology": "NWI",
    "care_equipment_1": "INO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 56,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 11,
    "prime_symptom_1_duration": 41,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 11,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 41,
    "full_prime_symptom_duration": 41,
    "prime_symptom_any": true,
    "prime_symptom_level": 6,
    "prime_symptom_3": 21,
    "prime_symptom_3_duration": 31,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": 30,
    "death_response_1": null,
    "slight_death_response_2": 3,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 50,
    "recovery_duration": 2,
    "recovery_proportion": 0.0385,
    "prime_symptom_proportion": 0.2115,
    "pathological_event_duration": 52,
    "event_record_is_complete": true,
    "pathological_severity": 84.6,
    "care_error_level": 55.5,
    "overall_patient_rating": 53.04,
    "patient_id": 13,
    "event_count": 7,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2509,
    "pathological_event_id": 40,
    "care_site": "LRM",
    "pathological_source_ref_id": "55C4508",
    "consultant_doctor": "AGG",
    "consultant_doctor_variation": 2,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "TW",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 61,
    "outlier": "CNF",
    "pathological_event_date": "2022-05-23",
    "etiology": "NWI",
    "care_equipment_1": "INO",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": true,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 103,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 12,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 62,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 12,
    "prime_symptom_1_duration": 62,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 12,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 62,
    "full_prime_symptom_duration": 62,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 15,
    "prime_symptom_3_duration": 59,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 58,
    "recovery_duration": 16,
    "recovery_proportion": 0.2162,
    "prime_symptom_proportion": 0.1622,
    "pathological_event_duration": 74,
    "event_record_is_complete": true,
    "pathological_severity": 115.5,
    "care_error_level": 78,
    "overall_patient_rating": 73.62,
    "patient_id": 119,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2510,
    "pathological_event_id": 41,
    "care_site": "LRM",
    "pathological_source_ref_id": "269B936",
    "consultant_doctor": "AGG",
    "consultant_doctor_variation": 2,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "TW",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 57,
    "outlier": "CNF",
    "pathological_event_date": "2021-07-03",
    "etiology": "NWI",
    "care_equipment_1": "SCT",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": true,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 61,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 42,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 23,
    "prime_symptom_1_duration": 19,
    "prime_symptom_2": 17,
    "prime_symptom_2_duration": 25,
    "first_prime_symptom": 17,
    "first_prime_symptom_type": "DCT",
    "prime_symptom_duration": 25,
    "full_prime_symptom_duration": 25,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": 19,
    "prime_symptom_3_duration": 23,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 36,
    "recovery_duration": 3,
    "recovery_proportion": 0.0769,
    "prime_symptom_proportion": 0.4048,
    "pathological_event_duration": 42,
    "event_record_is_complete": true,
    "pathological_severity": 47.3,
    "care_error_level": 38,
    "overall_patient_rating": 33.43,
    "patient_id": 5,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2511,
    "pathological_event_id": 42,
    "care_site": "LRM",
    "pathological_source_ref_id": "D5CB435",
    "consultant_doctor": "AGG",
    "consultant_doctor_variation": 2,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "TW",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 57,
    "outlier": "CNF",
    "pathological_event_date": "2021-07-29",
    "etiology": "NWI",
    "care_equipment_1": "SCT",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": true,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 60,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 19,
    "mild_symptom_2_duration": 24,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 42,
    "recovery_duration": 1,
    "recovery_proportion": 0.0233,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 43,
    "event_record_is_complete": true,
    "pathological_severity": 10.3,
    "care_error_level": 14,
    "overall_patient_rating": 10.38,
    "patient_id": 5,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2512,
    "pathological_event_id": 43,
    "care_site": "LRM",
    "pathological_source_ref_id": "DFC90EC",
    "consultant_doctor": "AGG",
    "consultant_doctor_variation": 2,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "TW",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 57,
    "outlier": "CNF",
    "pathological_event_date": "2021-07-29",
    "etiology": "NWI",
    "care_equipment_1": "SCT",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": true,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 80,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 17,
    "mild_symptom_2_duration": 46,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 13,
    "prime_symptom_2_duration": 50,
    "first_prime_symptom": 13,
    "first_prime_symptom_type": "DCT",
    "prime_symptom_duration": 50,
    "full_prime_symptom_duration": 50,
    "prime_symptom_any": true,
    "prime_symptom_level": 1,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 62,
    "recovery_duration": 1,
    "recovery_proportion": 0.0159,
    "prime_symptom_proportion": 0.2063,
    "pathological_event_duration": 63,
    "event_record_is_complete": true,
    "pathological_severity": 69.1,
    "care_error_level": 62.3,
    "overall_patient_rating": 52.51,
    "patient_id": 5,
    "event_count": 3,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2513,
    "pathological_event_id": 44,
    "care_site": "LRM",
    "pathological_source_ref_id": "261343B",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2021-09-15",
    "etiology": "NWI",
    "care_equipment_1": "THH",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "KNR",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 495,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 12,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 16,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 10,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": 18,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 10,
    "prime_symptom_3_duration": 18,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 28,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.3571,
    "pathological_event_duration": 28,
    "event_record_is_complete": false,
    "pathological_severity": 35.2,
    "care_error_level": 29,
    "overall_patient_rating": 25.27,
    "patient_id": 6,
    "event_count": 1,
    "event_title": "DIYJ04",
    "notes": ""
  },
  {
    "id": 2514,
    "pathological_event_id": 45,
    "care_site": "LRM",
    "pathological_source_ref_id": "CF34036",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2022-06-22",
    "etiology": "PRD",
    "care_equipment_1": "INO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 9,
    "care_technique_2": "CMP",
    "care_technique_3": "RPH",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 827,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 9,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 28,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 12,
    "prime_symptom_1_duration": 25,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 12,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 25,
    "full_prime_symptom_duration": 25,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 21,
    "recovery_duration": 16,
    "recovery_proportion": 0.4324,
    "prime_symptom_proportion": 0.3243,
    "pathological_event_duration": 37,
    "event_record_is_complete": true,
    "pathological_severity": 57.9,
    "care_error_level": 83,
    "overall_patient_rating": 60.68,
    "patient_id": 6,
    "event_count": 3,
    "event_title": "LICH01",
    "notes": ""
  },
  {
    "id": 2515,
    "pathological_event_id": 46,
    "care_site": "LRM",
    "pathological_source_ref_id": "FC399D0",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2022-06-07",
    "etiology": "NWI",
    "care_equipment_1": "INO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 577,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 16,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 26,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 38,
    "recovery_duration": 4,
    "recovery_proportion": 0.0952,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 42,
    "event_record_is_complete": true,
    "pathological_severity": 26.4,
    "care_error_level": 17,
    "overall_patient_rating": 16.38,
    "patient_id": 6,
    "event_count": 4,
    "event_title": "ACGIAJ01",
    "notes": ""
  },
  {
    "id": 2516,
    "pathological_event_id": 47,
    "care_site": "LRM",
    "pathological_source_ref_id": "BBC4E81",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2022-09-04",
    "etiology": "PRD",
    "care_equipment_1": "INH",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "MLT",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 6,
    "care_technique_2": "CMP",
    "care_technique_3": "KNR",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 286,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 13,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 21,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 24,
    "recovery_duration": 10,
    "recovery_proportion": 0.2941,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 34,
    "event_record_is_complete": true,
    "pathological_severity": 36.3,
    "care_error_level": 18.4,
    "overall_patient_rating": 19.83,
    "patient_id": 6,
    "event_count": 5,
    "event_title": "2ST",
    "notes": ""
  },
  {
    "id": 2517,
    "pathological_event_id": 48,
    "care_site": "LRM",
    "pathological_source_ref_id": "B757D22",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 66,
    "outlier": "CNF",
    "pathological_event_date": "2022-10-03",
    "etiology": "NWI",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "TIP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 168,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 7,
    "care_error_level": 16,
    "overall_patient_rating": 10.57,
    "patient_id": 82,
    "event_count": 1,
    "event_title": "NT04",
    "notes": ""
  },
  {
    "id": 2518,
    "pathological_event_id": 49,
    "care_site": "LRM",
    "pathological_source_ref_id": "DDDBF5F",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": "AF",
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2022-12-12",
    "etiology": "PRD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 3,
    "care_technique_2": "CMP",
    "care_technique_3": "",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 43,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 12,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 17,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 18,
    "recovery_duration": 11,
    "recovery_proportion": 0.3793,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 29,
    "event_record_is_complete": true,
    "pathological_severity": 30.2,
    "care_error_level": 14.5,
    "overall_patient_rating": 16.06,
    "patient_id": 6,
    "event_count": 6,
    "event_title": "NT12",
    "notes": ""
  },
  {
    "id": 2519,
    "pathological_event_id": 50,
    "care_site": "LRM",
    "pathological_source_ref_id": "FC5FB2E",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2023-04-24",
    "etiology": "NWI",
    "care_equipment_1": "INO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "007",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 121,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 9,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 26,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 10,
    "prime_symptom_2_duration": 25,
    "first_prime_symptom": 10,
    "first_prime_symptom_type": "DCT",
    "prime_symptom_duration": 25,
    "full_prime_symptom_duration": 25,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": 30,
    "prime_symptom_3_duration": 5,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 35,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.2857,
    "pathological_event_duration": 35,
    "event_record_is_complete": false,
    "pathological_severity": 53.8,
    "care_error_level": 43.5,
    "overall_patient_rating": 38.18,
    "patient_id": 6,
    "event_count": 7,
    "event_title": "RN60_1",
    "notes": ""
  },
  {
    "id": 2520,
    "pathological_event_id": 51,
    "care_site": "LRM",
    "pathological_source_ref_id": "6B79D27",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2023-06-20",
    "etiology": "NWI",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "002",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 162,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 8,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 49,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 10,
    "prime_symptom_2_duration": 47,
    "first_prime_symptom": 10,
    "first_prime_symptom_type": "DCT",
    "prime_symptom_duration": 47,
    "full_prime_symptom_duration": 47,
    "prime_symptom_any": true,
    "prime_symptom_level": 2,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 24,
    "recovery_duration": 33,
    "recovery_proportion": 0.5789,
    "prime_symptom_proportion": 0.1754,
    "pathological_event_duration": 57,
    "event_record_is_complete": true,
    "pathological_severity": 64.3,
    "care_error_level": 64.8,
    "overall_patient_rating": 52.56,
    "patient_id": 6,
    "event_count": 8,
    "event_title": "RN61_1",
    "notes": ""
  },
  {
    "id": 2530,
    "pathological_event_id": 61,
    "care_site": "LRM",
    "pathological_source_ref_id": "1CA8667",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": "PB",
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2022-12-15",
    "etiology": "NWI",
    "care_equipment_1": "THO",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 50,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 8,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 19,
    "mild_symptom_2": 12,
    "mild_symptom_2_duration": 15,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 16,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": 11,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 16,
    "prime_symptom_3_duration": 11,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 27,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.5926,
    "pathological_event_duration": 27,
    "event_record_is_complete": false,
    "pathological_severity": 22.9,
    "care_error_level": 13,
    "overall_patient_rating": 13.26,
    "patient_id": 92,
    "event_count": 1,
    "event_title": "RN56",
    "notes": ""
  },
  {
    "id": 2531,
    "pathological_event_id": 62,
    "care_site": "LRM",
    "pathological_source_ref_id": "6A2D08C",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2022-01-10",
    "etiology": "PRD",
    "care_equipment_1": "INO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 6,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 646,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 16,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 14,
    "mild_symptom_2": 12,
    "mild_symptom_2_duration": 18,
    "prime_symptom_1": 16,
    "prime_symptom_1_duration": 14,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 16,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 14,
    "full_prime_symptom_duration": 14,
    "prime_symptom_any": true,
    "prime_symptom_level": 5,
    "prime_symptom_3": 18,
    "prime_symptom_3_duration": 12,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 26,
    "recovery_duration": 4,
    "recovery_proportion": 0.1333,
    "prime_symptom_proportion": 0.5333,
    "pathological_event_duration": 30,
    "event_record_is_complete": true,
    "pathological_severity": 43.3,
    "care_error_level": 38,
    "overall_patient_rating": 32.34,
    "patient_id": 93,
    "event_count": 1,
    "event_title": "DSYN01",
    "notes": ""
  },
  {
    "id": 2532,
    "pathological_event_id": 63,
    "care_site": "LRM",
    "pathological_source_ref_id": "654E33D",
    "consultant_doctor": "NEM",
    "consultant_doctor_variation": 5,
    "outcome": "NSV",
    "outcome_type": "FSN",
    "source_country": "US",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 60,
    "outlier": "CNF",
    "pathological_event_date": "2015-01-05",
    "etiology": "NWI",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "008",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "RPH",
    "care_technique_4": true,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 80,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 62,
    "recovery_duration": 2,
    "recovery_proportion": 0.0313,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 64,
    "event_record_is_complete": true,
    "pathological_severity": 10.4,
    "care_error_level": 19,
    "overall_patient_rating": 13.11,
    "patient_id": 22,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2533,
    "pathological_event_id": 64,
    "care_site": "LRM",
    "pathological_source_ref_id": "3F3DFFE",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 60,
    "outlier": "CNF",
    "pathological_event_date": "2021-10-16",
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 55,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 7,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 45,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 18,
    "prime_symptom_1_duration": 34,
    "prime_symptom_2": 32,
    "prime_symptom_2_duration": 20,
    "first_prime_symptom": 18,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 34,
    "full_prime_symptom_duration": 34,
    "prime_symptom_any": true,
    "prime_symptom_level": 5,
    "prime_symptom_3": 23,
    "prime_symptom_3_duration": 29,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 50,
    "recovery_duration": 2,
    "recovery_proportion": 0.0385,
    "prime_symptom_proportion": 0.3462,
    "pathological_event_duration": 52,
    "event_record_is_complete": true,
    "pathological_severity": 73.1,
    "care_error_level": 40.8,
    "overall_patient_rating": 41.96,
    "patient_id": 7,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2534,
    "pathological_event_id": 65,
    "care_site": "LRM",
    "pathological_source_ref_id": "9B25638",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 60,
    "outlier": "CNF",
    "pathological_event_date": "2021-10-16",
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 25,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 9,
    "prime_symptom_1_duration": 9,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 9,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 9,
    "full_prime_symptom_duration": 9,
    "prime_symptom_any": true,
    "prime_symptom_level": 5,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 16,
    "recovery_duration": 2,
    "recovery_proportion": 0.1111,
    "prime_symptom_proportion": 0.5,
    "pathological_event_duration": 18,
    "event_record_is_complete": true,
    "pathological_severity": 18.2,
    "care_error_level": 14.6,
    "overall_patient_rating": 12.85,
    "patient_id": 7,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2535,
    "pathological_event_id": 66,
    "care_site": "LRM",
    "pathological_source_ref_id": "C2CBAC6",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 75,
    "outlier": "CNF",
    "pathological_event_date": "2023-04-24",
    "etiology": "LWD",
    "care_equipment_1": "THO",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "004",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": true,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 28,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 6,
    "prime_symptom_1_duration": 30,
    "prime_symptom_2": 11,
    "prime_symptom_2_duration": 25,
    "first_prime_symptom": 6,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 30,
    "full_prime_symptom_duration": 30,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 20,
    "prime_symptom_3_duration": 16,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 34,
    "recovery_duration": 2,
    "recovery_proportion": 0.0556,
    "prime_symptom_proportion": 0.1667,
    "pathological_event_duration": 36,
    "event_record_is_complete": true,
    "pathological_severity": 55.7,
    "care_error_level": 45.5,
    "overall_patient_rating": 39.77,
    "patient_id": 33,
    "event_count": 1,
    "event_title": "UX01",
    "notes": ""
  },
  {
    "id": 2536,
    "pathological_event_id": 67,
    "care_site": "LRM",
    "pathological_source_ref_id": "7B1712D",
    "consultant_doctor": "HPR",
    "consultant_doctor_variation": 2,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "US",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2021-11-29",
    "etiology": "LWD",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "LWD",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 49,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 10,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 5,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 14,
    "prime_symptom_2_duration": 1,
    "first_prime_symptom": 14,
    "first_prime_symptom_type": "DCT",
    "prime_symptom_duration": 1,
    "full_prime_symptom_duration": 1,
    "prime_symptom_any": true,
    "prime_symptom_level": 2,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 15,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.9333,
    "pathological_event_duration": 15,
    "event_record_is_complete": false,
    "pathological_severity": 11.7,
    "care_error_level": 17,
    "overall_patient_rating": 12.39,
    "patient_id": 8,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2537,
    "pathological_event_id": 68,
    "care_site": "LRM",
    "pathological_source_ref_id": "C8067C2",
    "consultant_doctor": "HPR",
    "consultant_doctor_variation": 2,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "US",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2022-05-30",
    "etiology": "LWD",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "LWD",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 39,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 10,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 19,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 12,
    "prime_symptom_1_duration": 17,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 12,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 17,
    "full_prime_symptom_duration": 17,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 21,
    "recovery_duration": 8,
    "recovery_proportion": 0.2759,
    "prime_symptom_proportion": 0.4138,
    "pathological_event_duration": 29,
    "event_record_is_complete": true,
    "pathological_severity": 34.8,
    "care_error_level": 59.8,
    "overall_patient_rating": 41.84,
    "patient_id": 8,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2538,
    "pathological_event_id": 69,
    "care_site": "LRM",
    "pathological_source_ref_id": "B757D22",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 66,
    "outlier": "DUB",
    "pathological_event_date": "2022-10-03",
    "etiology": "NWI",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 55,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 7,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 17,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 15,
    "recovery_duration": 9,
    "recovery_proportion": 0.375,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 24,
    "event_record_is_complete": true,
    "pathological_severity": 22,
    "care_error_level": 29.9,
    "overall_patient_rating": 22.17,
    "patient_id": 9,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2539,
    "pathological_event_id": 70,
    "care_site": "LRM",
    "pathological_source_ref_id": "0D6C897",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "STO",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 17,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 17,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 17,
    "event_record_is_complete": false,
    "pathological_severity": 13.2,
    "care_error_level": 4,
    "overall_patient_rating": 5.75,
    "patient_id": 9,
    "event_count": 3,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2540,
    "pathological_event_id": 71,
    "care_site": "LRM",
    "pathological_source_ref_id": "7229E1D",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 61,
    "outlier": "DUB",
    "pathological_event_date": "2021-12-18",
    "etiology": "NWI",
    "care_equipment_1": "INO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "003",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 852,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 11,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 20,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 18,
    "recovery_duration": 13,
    "recovery_proportion": 0.4194,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 31,
    "event_record_is_complete": true,
    "pathological_severity": 27,
    "care_error_level": 33,
    "overall_patient_rating": 25.21,
    "patient_id": 10,
    "event_count": 2,
    "event_title": "ACGIAJ02",
    "notes": ""
  },
  {
    "id": 2541,
    "pathological_event_id": 72,
    "care_site": "LRM",
    "pathological_source_ref_id": "C962C42",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2022-12-12",
    "etiology": "PRD",
    "care_equipment_1": "THH",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "004",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 6,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": true,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 25,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 19,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 6,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 22,
    "prime_symptom_1_duration": 3,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 20,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 3,
    "full_prime_symptom_duration": 5,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": 20,
    "prime_symptom_3_duration": 5,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 25,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.8,
    "pathological_event_duration": 25,
    "event_record_is_complete": false,
    "pathological_severity": 29.5,
    "care_error_level": 24.5,
    "overall_patient_rating": 21.28,
    "patient_id": 10,
    "event_count": 3,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2542,
    "pathological_event_id": 73,
    "care_site": "BPF",
    "pathological_source_ref_id": "6/15",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2018-06-11",
    "etiology": "NWI",
    "care_equipment_1": "THH",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 36,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 14,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 14,
    "first_prime_symptom_type": "DCT",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 2,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 14,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 1,
    "pathological_event_duration": 14,
    "event_record_is_complete": false,
    "pathological_severity": 13.4,
    "care_error_level": 5,
    "overall_patient_rating": 6.35,
    "patient_id": 37,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2545,
    "pathological_event_id": 77,
    "care_site": "LRM",
    "pathological_source_ref_id": "99BDEEA",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 70,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "NWI",
    "care_equipment_1": "SCT",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "RPH",
    "care_technique_4": false,
    "care_technique_5": true,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 94,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 19,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 15,
    "mild_symptom_2": 23,
    "mild_symptom_2_duration": 11,
    "prime_symptom_1": 21,
    "prime_symptom_1_duration": 13,
    "prime_symptom_2": 23,
    "prime_symptom_2_duration": 11,
    "first_prime_symptom": 21,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 13,
    "full_prime_symptom_duration": 13,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 24,
    "recovery_duration": 10,
    "recovery_proportion": 0.2941,
    "prime_symptom_proportion": 0.6176,
    "pathological_event_duration": 34,
    "event_record_is_complete": true,
    "pathological_severity": 32.9,
    "care_error_level": 53.4,
    "overall_patient_rating": 37.86,
    "patient_id": 96,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2546,
    "pathological_event_id": 78,
    "care_site": "BPF",
    "pathological_source_ref_id": "8/116",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 60,
    "outlier": "CNF",
    "pathological_event_date": "2018-06-11",
    "etiology": "LWD",
    "care_equipment_1": "SCT",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "007",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 100,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 28,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": 56,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 28,
    "prime_symptom_3_duration": 56,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 54,
    "recovery_duration": 30,
    "recovery_proportion": 0.3571,
    "prime_symptom_proportion": 0.3333,
    "pathological_event_duration": 84,
    "event_record_is_complete": true,
    "pathological_severity": 51.2,
    "care_error_level": 33,
    "overall_patient_rating": 31.78,
    "patient_id": 97,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2547,
    "pathological_event_id": 79,
    "care_site": "MHT",
    "pathological_source_ref_id": "FD92421",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 60,
    "outlier": "CNF",
    "pathological_event_date": "2017-08-07",
    "etiology": "NWI",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 49,
    "intro_symptom_start": 0,
    "intro_symptom_end": 3,
    "intro_symptom_duration": 3,
    "mild_symptom_1": 14,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 20,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 10,
    "prime_symptom_2_duration": 24,
    "first_prime_symptom": 10,
    "first_prime_symptom_type": "DCT",
    "prime_symptom_duration": 24,
    "full_prime_symptom_duration": 24,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 10,
    "prime_symptom_3_duration": 24,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 15,
    "recovery_duration": 19,
    "recovery_proportion": 0.5588,
    "prime_symptom_proportion": 0.2941,
    "pathological_event_duration": 34,
    "event_record_is_complete": true,
    "pathological_severity": 46.2,
    "care_error_level": 66.5,
    "overall_patient_rating": 48.57,
    "patient_id": 98,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2549,
    "pathological_event_id": 81,
    "care_site": "BPF",
    "pathological_source_ref_id": "6/142",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 68,
    "outlier": "CNF",
    "pathological_event_date": "2018-06-12",
    "etiology": "PRD",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "004",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 6,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": false,
    "event_record_length": 41,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 4,
    "mild_symptom_2_duration": 10,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 10,
    "prime_symptom_2_duration": 4,
    "first_prime_symptom": 10,
    "first_prime_symptom_type": "DCT",
    "prime_symptom_duration": 4,
    "full_prime_symptom_duration": 4,
    "prime_symptom_any": true,
    "prime_symptom_level": 1,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 10,
    "recovery_duration": 4,
    "recovery_proportion": 0.2857,
    "prime_symptom_proportion": 0.7143,
    "pathological_event_duration": 14,
    "event_record_is_complete": true,
    "pathological_severity": 23.2,
    "care_error_level": 24.5,
    "overall_patient_rating": 19.57,
    "patient_id": 100,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2550,
    "pathological_event_id": 82,
    "care_site": "BPF",
    "pathological_source_ref_id": "150/215",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 58,
    "outlier": "CNF",
    "pathological_event_date": "2018-06-16",
    "etiology": "STO",
    "care_equipment_1": "THH",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 58,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 5,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 12,
    "mild_symptom_2": 10,
    "mild_symptom_2_duration": 7,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 13,
    "recovery_duration": 4,
    "recovery_proportion": 0.2353,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 17,
    "event_record_is_complete": true,
    "pathological_severity": 20.1,
    "care_error_level": 0,
    "overall_patient_rating": 5.46,
    "patient_id": 101,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2551,
    "pathological_event_id": 83,
    "care_site": "LRM",
    "pathological_source_ref_id": "6050369",
    "consultant_doctor": "BOD",
    "consultant_doctor_variation": 4,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 72,
    "outlier": "CNF",
    "pathological_event_date": "2012-05-20",
    "etiology": "UNK",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "002",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 33,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 12,
    "mild_symptom_2_duration": 8,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 14,
    "recovery_duration": 6,
    "recovery_proportion": 0.3,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 20,
    "event_record_is_complete": true,
    "pathological_severity": 22.2,
    "care_error_level": 33.2,
    "overall_patient_rating": 24.01,
    "patient_id": 20,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2552,
    "pathological_event_id": 84,
    "care_site": "LRM",
    "pathological_source_ref_id": "4A8621B",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 60,
    "outlier": "CNF",
    "pathological_event_date": "2023-02-21",
    "etiology": "LWD",
    "care_equipment_1": "HIC",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "007",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": true,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 54,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 14,
    "mild_symptom_2_duration": 10,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 23,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": 1,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 23,
    "prime_symptom_3_duration": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 24,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.9583,
    "pathological_event_duration": 24,
    "event_record_is_complete": false,
    "pathological_severity": 17.8,
    "care_error_level": 22,
    "overall_patient_rating": 16.75,
    "patient_id": 102,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2553,
    "pathological_event_id": 85,
    "care_site": "LRM",
    "pathological_source_ref_id": "607D071",
    "consultant_doctor": "BOD",
    "consultant_doctor_variation": 4,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 73,
    "outlier": "CNF",
    "pathological_event_date": "2012-05-20",
    "etiology": "UNK",
    "care_equipment_1": "THH",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "003",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": false,
    "event_record_length": 39,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 12,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 17,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 12,
    "prime_symptom_1_duration": 17,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 12,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 17,
    "full_prime_symptom_duration": 17,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 28,
    "prime_symptom_3_duration": 1,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 29,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.4138,
    "pathological_event_duration": 29,
    "event_record_is_complete": false,
    "pathological_severity": 40.9,
    "care_error_level": 55,
    "overall_patient_rating": 40.9,
    "patient_id": 21,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2554,
    "pathological_event_id": 86,
    "care_site": "BPF",
    "pathological_source_ref_id": "150/250",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 55,
    "outlier": "CNF",
    "pathological_event_date": "2018-06-17",
    "etiology": "UNK",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CTP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 65,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 21,
    "recovery_duration": 18,
    "recovery_proportion": 0.4615,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 39,
    "event_record_is_complete": true,
    "pathological_severity": 23.9,
    "care_error_level": 21,
    "overall_patient_rating": 17.87,
    "patient_id": 11,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2555,
    "pathological_event_id": 87,
    "care_site": "BPF",
    "pathological_source_ref_id": "150/251",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 55,
    "outlier": "CNF",
    "pathological_event_date": "2018-06-17",
    "etiology": "UNK",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CTP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 60,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 20,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 20,
    "event_record_is_complete": false,
    "pathological_severity": 13,
    "care_error_level": 4,
    "overall_patient_rating": 5.7,
    "patient_id": 11,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2556,
    "pathological_event_id": 88,
    "care_site": "BPF",
    "pathological_source_ref_id": "150/259",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 55,
    "outlier": "CNF",
    "pathological_event_date": "2018-06-17",
    "etiology": "UNK",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CTP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 149,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 2,
    "mild_symptom_2_duration": 35,
    "prime_symptom_1": 8,
    "prime_symptom_1_duration": 29,
    "prime_symptom_2": 8,
    "prime_symptom_2_duration": 29,
    "first_prime_symptom": 8,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 29,
    "full_prime_symptom_duration": 29,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 31,
    "recovery_duration": 6,
    "recovery_proportion": 0.1622,
    "prime_symptom_proportion": 0.2162,
    "pathological_event_duration": 37,
    "event_record_is_complete": true,
    "pathological_severity": 53,
    "care_error_level": 55.7,
    "overall_patient_rating": 44.56,
    "patient_id": 11,
    "event_count": 3,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2557,
    "pathological_event_id": 89,
    "care_site": "LRM",
    "pathological_source_ref_id": "B9A7EC3",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 60,
    "outlier": "CNF",
    "pathological_event_date": "2023-07-09",
    "etiology": "LWD",
    "care_equipment_1": "SCT",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 65,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 14,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 10,
    "mild_symptom_2": 14,
    "mild_symptom_2_duration": 10,
    "prime_symptom_1": 18,
    "prime_symptom_1_duration": 6,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 16,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 6,
    "full_prime_symptom_duration": 8,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 16,
    "prime_symptom_3_duration": 8,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 24,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.6667,
    "pathological_event_duration": 24,
    "event_record_is_complete": false,
    "pathological_severity": 23.7,
    "care_error_level": 11,
    "overall_patient_rating": 12.4,
    "patient_id": 12,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2558,
    "pathological_event_id": 90,
    "care_site": "LRM",
    "pathological_source_ref_id": "F0077E3",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 60,
    "outlier": "CNF",
    "pathological_event_date": "2023-07-17",
    "etiology": "LWD",
    "care_equipment_1": "SCT",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 92,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 8,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 45,
    "mild_symptom_2": 11,
    "mild_symptom_2_duration": 42,
    "prime_symptom_1": 18,
    "prime_symptom_1_duration": 35,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 17,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 35,
    "full_prime_symptom_duration": 36,
    "prime_symptom_any": true,
    "prime_symptom_level": 6,
    "prime_symptom_3": 17,
    "prime_symptom_3_duration": 36,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 49,
    "recovery_duration": 4,
    "recovery_proportion": 0.0755,
    "prime_symptom_proportion": 0.3208,
    "pathological_event_duration": 53,
    "event_record_is_complete": true,
    "pathological_severity": 85.8,
    "care_error_level": 46.5,
    "overall_patient_rating": 48.5,
    "patient_id": 12,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2559,
    "pathological_event_id": 91,
    "care_site": "LRM",
    "pathological_source_ref_id": "FD3D9EB",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": "TW",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 59,
    "outlier": "CNF",
    "pathological_event_date": "2015-07-07",
    "etiology": "LWD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CHD",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 39,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 9,
    "mild_symptom_2_duration": 14,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 15,
    "recovery_duration": 8,
    "recovery_proportion": 0.3478,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 23,
    "event_record_is_complete": true,
    "pathological_severity": 19.9,
    "care_error_level": 16.5,
    "overall_patient_rating": 14.34,
    "patient_id": 13,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2560,
    "pathological_event_id": 92,
    "care_site": "LRM",
    "pathological_source_ref_id": "46877DD",
    "consultant_doctor": "AGG",
    "consultant_doctor_variation": 2,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": "TW",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 59,
    "outlier": "CNF",
    "pathological_event_date": "2015-07-07",
    "etiology": "STO",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CHD",
    "care_technique_3": "LWA",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 29,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 10,
    "recovery_duration": 8,
    "recovery_proportion": 0.4444,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 18,
    "event_record_is_complete": true,
    "pathological_severity": 14.4,
    "care_error_level": 11.5,
    "overall_patient_rating": 10.14,
    "patient_id": 13,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2561,
    "pathological_event_id": 93,
    "care_site": "LRM",
    "pathological_source_ref_id": "46877DD",
    "consultant_doctor": "AGG",
    "consultant_doctor_variation": 2,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": "TW",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 59,
    "outlier": "CNF",
    "pathological_event_date": "2015-07-07",
    "etiology": "STO",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CHD",
    "care_technique_3": "LWA",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 42,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 15,
    "recovery_duration": 19,
    "recovery_proportion": 0.5588,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 34,
    "event_record_is_complete": true,
    "pathological_severity": 18.9,
    "care_error_level": 14.5,
    "overall_patient_rating": 12.99,
    "patient_id": 13,
    "event_count": 3,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2562,
    "pathological_event_id": 94,
    "care_site": "LRM",
    "pathological_source_ref_id": "B31D6AC",
    "consultant_doctor": "AGG",
    "consultant_doctor_variation": 2,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": "TW",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 59,
    "outlier": "CNF",
    "pathological_event_date": "2015-07-07",
    "etiology": "STO",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CHD",
    "care_technique_3": "LWA",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 35,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 18,
    "recovery_duration": 7,
    "recovery_proportion": 0.28,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 25,
    "event_record_is_complete": true,
    "pathological_severity": 16.1,
    "care_error_level": 11.5,
    "overall_patient_rating": 10.6,
    "patient_id": 13,
    "event_count": 4,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2563,
    "pathological_event_id": 95,
    "care_site": "LRM",
    "pathological_source_ref_id": "B31D6AC",
    "consultant_doctor": "AGG",
    "consultant_doctor_variation": 2,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": "TW",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 59,
    "outlier": "CNF",
    "pathological_event_date": "2015-07-07",
    "etiology": "STO",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CHD",
    "care_technique_3": "LWA",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 59,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 45,
    "recovery_duration": 9,
    "recovery_proportion": 0.1667,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 54,
    "event_record_is_complete": true,
    "pathological_severity": 20.1,
    "care_error_level": 17.4,
    "overall_patient_rating": 14.88,
    "patient_id": 13,
    "event_count": 5,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2564,
    "pathological_event_id": 96,
    "care_site": "LRM",
    "pathological_source_ref_id": "1D5F1D9",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 58,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "LWD",
    "care_equipment_1": "SCT",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 55,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 9,
    "mild_symptom_1_1_end": 39,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 88,
    "mild_symptom_2": 11,
    "mild_symptom_2_duration": 86,
    "prime_symptom_1": 15,
    "prime_symptom_1_duration": 82,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 15,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 82,
    "full_prime_symptom_duration": 82,
    "prime_symptom_any": true,
    "prime_symptom_level": 5,
    "prime_symptom_3": 40,
    "prime_symptom_3_duration": 57,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 42,
    "recovery_duration": 55,
    "recovery_proportion": 0.567,
    "prime_symptom_proportion": 0.1546,
    "pathological_event_duration": 97,
    "event_record_is_complete": true,
    "pathological_severity": 120.6,
    "care_error_level": 88.8,
    "overall_patient_rating": 80.86,
    "patient_id": 14,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2565,
    "pathological_event_id": 97,
    "care_site": "LRM",
    "pathological_source_ref_id": "097B7C4",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 58,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 25,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 19,
    "mild_symptom_2_duration": 1,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 20,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 20,
    "event_record_is_complete": false,
    "pathological_severity": 8,
    "care_error_level": -1,
    "overall_patient_rating": 1.63,
    "patient_id": 14,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2566,
    "pathological_event_id": 98,
    "care_site": "LRM",
    "pathological_source_ref_id": "3A6B26F",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 58,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "LWD",
    "care_equipment_1": "SCT",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 27,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 16,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 7,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 23,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 23,
    "event_record_is_complete": false,
    "pathological_severity": 14.2,
    "care_error_level": 18,
    "overall_patient_rating": 13.61,
    "patient_id": 14,
    "event_count": 3,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2567,
    "pathological_event_id": 99,
    "care_site": "LRM",
    "pathological_source_ref_id": "8046FD6",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 58,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "UNK",
    "care_equipment_1": "SCT",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 28,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 11,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 11,
    "event_record_is_complete": false,
    "pathological_severity": 12.1,
    "care_error_level": 18,
    "overall_patient_rating": 13.04,
    "patient_id": 14,
    "event_count": 4,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2568,
    "pathological_event_id": 100,
    "care_site": "LRM",
    "pathological_source_ref_id": "BCD02CA",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 58,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "LWD",
    "care_equipment_1": "SCT",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 65,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 37,
    "mild_symptom_2_duration": 4,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 35,
    "prime_symptom_2_duration": 6,
    "first_prime_symptom": 30,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 6,
    "full_prime_symptom_duration": 11,
    "prime_symptom_any": true,
    "prime_symptom_level": 1,
    "prime_symptom_3": 30,
    "prime_symptom_3_duration": 11,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 41,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.7317,
    "pathological_event_duration": 41,
    "event_record_is_complete": false,
    "pathological_severity": 29.7,
    "care_error_level": 31.8,
    "overall_patient_rating": 25.29,
    "patient_id": 14,
    "event_count": 5,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2569,
    "pathological_event_id": 101,
    "care_site": "LRM",
    "pathological_source_ref_id": "787AD0E",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 62,
    "outlier": "CNF",
    "pathological_event_date": "2016-10-28",
    "etiology": "NWI",
    "care_equipment_1": "SCW",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "LWA",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 40,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 10,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 14,
    "mild_symptom_2": 7,
    "mild_symptom_2_duration": 17,
    "prime_symptom_1": 12,
    "prime_symptom_1_duration": 12,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 12,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 12,
    "full_prime_symptom_duration": 12,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 16,
    "recovery_duration": 8,
    "recovery_proportion": 0.3333,
    "prime_symptom_proportion": 0.5,
    "pathological_event_duration": 24,
    "event_record_is_complete": true,
    "pathological_severity": 24.8,
    "care_error_level": 34.5,
    "overall_patient_rating": 25.42,
    "patient_id": 103,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2595,
    "pathological_event_id": 127,
    "care_site": "LRM",
    "pathological_source_ref_id": "C0B1C1C",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2021-11-30",
    "etiology": "NWI",
    "care_equipment_1": "IHO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "003",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": 0,
    "intro_symptom_end": 51,
    "intro_symptom_duration": 51,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 51,
    "recovery_duration": 14,
    "recovery_proportion": 0.2154,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 65,
    "event_record_is_complete": true,
    "pathological_severity": 24.4,
    "care_error_level": 39.5,
    "overall_patient_rating": 28.02,
    "patient_id": 17,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2570,
    "pathological_event_id": 102,
    "care_site": "BPF",
    "pathological_source_ref_id": "8/4639",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 62,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "LWD",
    "care_equipment_1": "SCW",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "TID",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 85,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 10,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 20,
    "mild_symptom_2": 9,
    "mild_symptom_2_duration": 21,
    "prime_symptom_1": 22,
    "prime_symptom_1_duration": 8,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 12,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 8,
    "full_prime_symptom_duration": 18,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 12,
    "prime_symptom_3_duration": 18,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 30,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.4,
    "pathological_event_duration": 30,
    "event_record_is_complete": false,
    "pathological_severity": 39.7,
    "care_error_level": 29.5,
    "overall_patient_rating": 26.76,
    "patient_id": 104,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2571,
    "pathological_event_id": 103,
    "care_site": "LRM",
    "pathological_source_ref_id": "770C436",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2021-09-19",
    "etiology": "NWI",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 110,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 11,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 15,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 16,
    "recovery_duration": 10,
    "recovery_proportion": 0.3846,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 26,
    "event_record_is_complete": true,
    "pathological_severity": 19.8,
    "care_error_level": 12.9,
    "overall_patient_rating": 12.37,
    "patient_id": 105,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2572,
    "pathological_event_id": 104,
    "care_site": "LRM",
    "pathological_source_ref_id": "8ED22B9",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 62,
    "outlier": "CNF",
    "pathological_event_date": "2021-09-21",
    "etiology": "NWI",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 240,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 16,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": 4,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 16,
    "prime_symptom_3_duration": 4,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 20,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.8,
    "pathological_event_duration": 20,
    "event_record_is_complete": false,
    "pathological_severity": 11.6,
    "care_error_level": 1,
    "overall_patient_rating": 3.69,
    "patient_id": 15,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2573,
    "pathological_event_id": 105,
    "care_site": "LRM",
    "pathological_source_ref_id": "8ED22B9",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 62,
    "outlier": "CNF",
    "pathological_event_date": "2021-09-21",
    "etiology": "NWI",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 15,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 16,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 23,
    "recovery_duration": 8,
    "recovery_proportion": 0.2581,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 31,
    "event_record_is_complete": true,
    "pathological_severity": 20.9,
    "care_error_level": 13,
    "overall_patient_rating": 12.72,
    "patient_id": 15,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2574,
    "pathological_event_id": 106,
    "care_site": "LRM",
    "pathological_source_ref_id": "8ED22B9",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 62,
    "outlier": "CNF",
    "pathological_event_date": "2021-09-21",
    "etiology": "NWI",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 12,
    "recovery_duration": 49,
    "recovery_proportion": 0.8033,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 61,
    "event_record_is_complete": true,
    "pathological_severity": 26.5,
    "care_error_level": 27.5,
    "overall_patient_rating": 22.09,
    "patient_id": 15,
    "event_count": 3,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2575,
    "pathological_event_id": 107,
    "care_site": "LRM",
    "pathological_source_ref_id": "DE7E38D",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 61,
    "outlier": "CNF",
    "pathological_event_date": "2021-10-01",
    "etiology": "PRD",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "004",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 6,
    "care_technique_2": "CMP",
    "care_technique_3": "PRD",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 556,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 7,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 23,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 17,
    "recovery_duration": 13,
    "recovery_proportion": 0.4333,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 30,
    "event_record_is_complete": true,
    "pathological_severity": 29.5,
    "care_error_level": 19.5,
    "overall_patient_rating": 18.58,
    "patient_id": 24,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2576,
    "pathological_event_id": 108,
    "care_site": "LRM",
    "pathological_source_ref_id": "99810AB",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2021-10-20",
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "004",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 465,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 13,
    "recovery_duration": 9,
    "recovery_proportion": 0.4091,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 22,
    "event_record_is_complete": true,
    "pathological_severity": 15.4,
    "care_error_level": 11.9,
    "overall_patient_rating": 10.63,
    "patient_id": 106,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2577,
    "pathological_event_id": 109,
    "care_site": "LRM",
    "pathological_source_ref_id": null,
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "DUB",
    "pathological_event_date": "2021-10-21",
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 6,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 39,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 19,
    "recovery_duration": 26,
    "recovery_proportion": 0.5778,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 45,
    "event_record_is_complete": true,
    "pathological_severity": 28.7,
    "care_error_level": 19,
    "overall_patient_rating": 18.09,
    "patient_id": 107,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2578,
    "pathological_event_id": 110,
    "care_site": "LRM",
    "pathological_source_ref_id": null,
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "DUB",
    "pathological_event_date": "2021-10-27",
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 5,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 10,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 5,
    "recovery_duration": 10,
    "recovery_proportion": 0.6667,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 15,
    "event_record_is_complete": true,
    "pathological_severity": 16.7,
    "care_error_level": 11.9,
    "overall_patient_rating": 10.98,
    "patient_id": 108,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2579,
    "pathological_event_id": 111,
    "care_site": "LRM",
    "pathological_source_ref_id": "C2A43A3",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2021-10-27",
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 108,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 14,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 17,
    "mild_symptom_2": 14,
    "mild_symptom_2_duration": 17,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 25,
    "recovery_duration": 6,
    "recovery_proportion": 0.1935,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 31,
    "event_record_is_complete": true,
    "pathological_severity": 18.7,
    "care_error_level": 11.7,
    "overall_patient_rating": 11.42,
    "patient_id": 109,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2580,
    "pathological_event_id": 112,
    "care_site": "LRM",
    "pathological_source_ref_id": null,
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2021-11-04",
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 13,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 13,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 13,
    "event_record_is_complete": false,
    "pathological_severity": 6.3,
    "care_error_level": -1,
    "overall_patient_rating": 1.17,
    "patient_id": 110,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2581,
    "pathological_event_id": 113,
    "care_site": "LRM",
    "pathological_source_ref_id": "717219F",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 59,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "STO",
    "care_equipment_1": "THH",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "LWA",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 69,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 9,
    "mild_symptom_2_duration": 38,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 19,
    "prime_symptom_2_duration": 28,
    "first_prime_symptom": 19,
    "first_prime_symptom_type": "DCT",
    "prime_symptom_duration": 28,
    "full_prime_symptom_duration": 28,
    "prime_symptom_any": true,
    "prime_symptom_level": 2,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 46,
    "recovery_duration": 1,
    "recovery_proportion": 0.0213,
    "prime_symptom_proportion": 0.4043,
    "pathological_event_duration": 47,
    "event_record_is_complete": true,
    "pathological_severity": 49,
    "care_error_level": 41,
    "overall_patient_rating": 35.52,
    "patient_id": 111,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2582,
    "pathological_event_id": 114,
    "care_site": "LRM",
    "pathological_source_ref_id": "F88DCF1",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 71,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "STO",
    "care_equipment_1": "THH",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "LWA",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": true,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 46,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 4,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 19,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 5,
    "recovery_duration": 18,
    "recovery_proportion": 0.7826,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 23,
    "event_record_is_complete": true,
    "pathological_severity": 27.1,
    "care_error_level": 26,
    "overall_patient_rating": 21.44,
    "patient_id": 112,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2583,
    "pathological_event_id": 115,
    "care_site": "LRM",
    "pathological_source_ref_id": "C8223F7",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 62,
    "outlier": "CNF",
    "pathological_event_date": "2015-11-06",
    "etiology": "LWD",
    "care_equipment_1": "INH",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 49,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 11,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 20,
    "mild_symptom_2": 13,
    "mild_symptom_2_duration": 18,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 31,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 31,
    "event_record_is_complete": false,
    "pathological_severity": 13.1,
    "care_error_level": 0,
    "overall_patient_rating": 3.56,
    "patient_id": 113,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2584,
    "pathological_event_id": 116,
    "care_site": "LRM",
    "pathological_source_ref_id": "40FD3C5",
    "consultant_doctor": "BOD",
    "consultant_doctor_variation": 4,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2012-05-16",
    "etiology": "PRD",
    "care_equipment_1": "SCT",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "007",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 6,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 21,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 9,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 9,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 12,
    "prime_symptom_2_duration": 6,
    "first_prime_symptom": 12,
    "first_prime_symptom_type": "DCT",
    "prime_symptom_duration": 6,
    "full_prime_symptom_duration": 6,
    "prime_symptom_any": true,
    "prime_symptom_level": 2,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 18,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.6667,
    "pathological_event_duration": 18,
    "event_record_is_complete": false,
    "pathological_severity": 28.7,
    "care_error_level": 35,
    "overall_patient_rating": 26.75,
    "patient_id": 20,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2585,
    "pathological_event_id": 117,
    "care_site": "LRM",
    "pathological_source_ref_id": "4AF29DF",
    "consultant_doctor": "GAL",
    "consultant_doctor_variation": 1,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 57,
    "outlier": "DUB",
    "pathological_event_date": "2013-07-05",
    "etiology": "STO",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "007",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "LPV",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 110,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 16,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 28,
    "mild_symptom_2": 41,
    "mild_symptom_2_duration": 3,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 44,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 44,
    "event_record_is_complete": false,
    "pathological_severity": 13,
    "care_error_level": 2,
    "overall_patient_rating": 4.62,
    "patient_id": 114,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2589,
    "pathological_event_id": 121,
    "care_site": "LRM",
    "pathological_source_ref_id": "1A77106",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2021-10-01",
    "etiology": "UNK",
    "care_equipment_1": "THH",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "TIP",
    "care_technique_3": "NLT",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 10,
    "care_error_level": 3,
    "overall_patient_rating": 4.34,
    "patient_id": 41,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2590,
    "pathological_event_id": 122,
    "care_site": "LRM",
    "pathological_source_ref_id": "D97B7D0",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2021-12-18",
    "etiology": "UNK",
    "care_equipment_1": "IHO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "003",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "TIP",
    "care_technique_3": "NLT",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 106,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 6,
    "care_error_level": -2,
    "overall_patient_rating": 0.55,
    "patient_id": 16,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2591,
    "pathological_event_id": 123,
    "care_site": "LRM",
    "pathological_source_ref_id": "C0B1C1C",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2021-11-30",
    "etiology": "UNK",
    "care_equipment_1": "IHO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "003",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "TIP",
    "care_technique_3": "NLT",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 186,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 21,
    "recovery_duration": 59,
    "recovery_proportion": 0.7375,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 80,
    "event_record_is_complete": true,
    "pathological_severity": 27.7,
    "care_error_level": 33,
    "overall_patient_rating": 25.4,
    "patient_id": 16,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2592,
    "pathological_event_id": 124,
    "care_site": "LRM",
    "pathological_source_ref_id": "BF21F16",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 75,
    "outlier": "CNF",
    "pathological_event_date": "2023-08-10",
    "etiology": "STO",
    "care_equipment_1": "THH",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "003",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "TIP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": true,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 32,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 17,
    "mild_symptom_2_duration": 8,
    "prime_symptom_1": 25,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 25,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 5,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 1,
    "pathological_event_duration": 25,
    "event_record_is_complete": false,
    "pathological_severity": 15.5,
    "care_error_level": 11,
    "overall_patient_rating": 10.17,
    "patient_id": 42,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2593,
    "pathological_event_id": 125,
    "care_site": "LRM",
    "pathological_source_ref_id": null,
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 11,
    "recovery_duration": 9,
    "recovery_proportion": 0.45,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 20,
    "event_record_is_complete": true,
    "pathological_severity": 15.2,
    "care_error_level": 11.9,
    "overall_patient_rating": 10.57,
    "patient_id": 43,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2594,
    "pathological_event_id": 126,
    "care_site": "LRM",
    "pathological_source_ref_id": null,
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "UNK",
    "care_equipment_1": "IHO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": 0,
    "intro_symptom_end": 23,
    "intro_symptom_duration": 23,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 5,
    "recovery_duration": 23,
    "recovery_proportion": 0.8214,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 28,
    "event_record_is_complete": true,
    "pathological_severity": 21.2,
    "care_error_level": 33,
    "overall_patient_rating": 23.63,
    "patient_id": 17,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2596,
    "pathological_event_id": 128,
    "care_site": "LRM",
    "pathological_source_ref_id": "C0B1C1C",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "DUB",
    "pathological_event_date": "2021-11-30",
    "etiology": "UNK",
    "care_equipment_1": "IHO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "003",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "TIP",
    "care_technique_3": "NLT",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": 0,
    "intro_symptom_end": 21,
    "intro_symptom_duration": 21,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 21,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 21,
    "event_record_is_complete": false,
    "pathological_severity": 8.6,
    "care_error_level": -2,
    "overall_patient_rating": 1.25,
    "patient_id": 17,
    "event_count": 3,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2597,
    "pathological_event_id": 129,
    "care_site": "LRM",
    "pathological_source_ref_id": null,
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "NWI",
    "care_equipment_1": "IHO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 6,
    "care_error_level": 0,
    "overall_patient_rating": 1.63,
    "patient_id": 44,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2598,
    "pathological_event_id": 130,
    "care_site": "LRM",
    "pathological_source_ref_id": "2E0C32A",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 61,
    "outlier": "CNF",
    "pathological_event_date": "2021-11-29",
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "003",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NLT",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 270,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 14,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 19,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 17,
    "recovery_duration": 16,
    "recovery_proportion": 0.4848,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 33,
    "event_record_is_complete": true,
    "pathological_severity": 24.1,
    "care_error_level": 31.5,
    "overall_patient_rating": 23.61,
    "patient_id": 10,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2599,
    "pathological_event_id": 131,
    "care_site": "LRM",
    "pathological_source_ref_id": null,
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "TIP",
    "care_technique_3": "NLT",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": 0,
    "intro_symptom_end": 25,
    "intro_symptom_duration": 25,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 5,
    "care_error_level": -2,
    "overall_patient_rating": 0.28,
    "patient_id": 38,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2600,
    "pathological_event_id": 132,
    "care_site": "LRM",
    "pathological_source_ref_id": null,
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NLT",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": 0,
    "intro_symptom_end": 23,
    "intro_symptom_duration": 23,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 5,
    "care_error_level": -1,
    "overall_patient_rating": 0.82,
    "patient_id": 39,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2601,
    "pathological_event_id": 133,
    "care_site": "LRM",
    "pathological_source_ref_id": "19B1FF0",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2021-12-30",
    "etiology": "NWI",
    "care_equipment_1": "IHO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "004",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 889,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 12,
    "recovery_duration": 11,
    "recovery_proportion": 0.4783,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 23,
    "event_record_is_complete": true,
    "pathological_severity": 17.2,
    "care_error_level": 13.5,
    "overall_patient_rating": 11.98,
    "patient_id": 18,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2602,
    "pathological_event_id": 134,
    "care_site": "LRM",
    "pathological_source_ref_id": "19B1FF0",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2021-12-30",
    "etiology": "NWI",
    "care_equipment_1": "IHO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 889,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 13,
    "recovery_duration": 12,
    "recovery_proportion": 0.48,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 25,
    "event_record_is_complete": true,
    "pathological_severity": 18.4,
    "care_error_level": 13.5,
    "overall_patient_rating": 12.31,
    "patient_id": 18,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2603,
    "pathological_event_id": 135,
    "care_site": "LRM",
    "pathological_source_ref_id": null,
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "NWI",
    "care_equipment_1": "IHO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 11,
    "recovery_duration": 47,
    "recovery_proportion": 0.8103,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 58,
    "event_record_is_complete": true,
    "pathological_severity": 24.7,
    "care_error_level": 26,
    "overall_patient_rating": 20.79,
    "patient_id": 19,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2604,
    "pathological_event_id": 136,
    "care_site": "LRM",
    "pathological_source_ref_id": null,
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2022-03-17",
    "etiology": "NWI",
    "care_equipment_1": "IHO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 57,
    "recovery_duration": 80,
    "recovery_proportion": 0.5839,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 137,
    "event_record_is_complete": true,
    "pathological_severity": 37.2,
    "care_error_level": 47.5,
    "overall_patient_rating": 35.83,
    "patient_id": 19,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2605,
    "pathological_event_id": 137,
    "care_site": "LRM",
    "pathological_source_ref_id": "E12A065",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": null,
    "patient_weight": 58,
    "outlier": "CNF",
    "pathological_event_date": "2022-03-17",
    "etiology": "UNK",
    "care_equipment_1": "IHO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "003",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NLT",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 96,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 10,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 2,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 12,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 12,
    "event_record_is_complete": false,
    "pathological_severity": 8.6,
    "care_error_level": 16,
    "overall_patient_rating": 11,
    "patient_id": 45,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2606,
    "pathological_event_id": 138,
    "care_site": "LRM",
    "pathological_source_ref_id": "8F12A60",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": null,
    "patient_weight": 62,
    "outlier": "CNF",
    "pathological_event_date": "2022-06-06",
    "etiology": "NWI",
    "care_equipment_1": "IHO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 218,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 38,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": 7,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 38,
    "prime_symptom_3_duration": 7,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 38,
    "recovery_duration": 7,
    "recovery_proportion": 0.1556,
    "prime_symptom_proportion": 0.8444,
    "pathological_event_duration": 45,
    "event_record_is_complete": true,
    "pathological_severity": 24.9,
    "care_error_level": 33.5,
    "overall_patient_rating": 24.91,
    "patient_id": 46,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2607,
    "pathological_event_id": 139,
    "care_site": "LRM",
    "pathological_source_ref_id": "F52F53C",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 65,
    "outlier": "DUB",
    "pathological_event_date": "2023-09-19",
    "etiology": "TIP",
    "care_equipment_1": "ELC",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "004",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "TIP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 72,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 55,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 55,
    "first_prime_symptom_type": "DCT",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": 71,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": 71,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 1,
    "pathological_event_duration": 55,
    "event_record_is_complete": false,
    "pathological_severity": 17.5,
    "care_error_level": 9.5,
    "overall_patient_rating": 9.9,
    "patient_id": 47,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2608,
    "pathological_event_id": 140,
    "care_site": "LRM",
    "pathological_source_ref_id": "6516FE3",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": null,
    "patient_weight": 60,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "NWI",
    "care_equipment_1": "IHO",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "RPH",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 88,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 38,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": 7,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 38,
    "prime_symptom_3_duration": 7,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 38,
    "recovery_duration": 7,
    "recovery_proportion": 0.1556,
    "prime_symptom_proportion": 0.8444,
    "pathological_event_duration": 45,
    "event_record_is_complete": true,
    "pathological_severity": 22.9,
    "care_error_level": 24.5,
    "overall_patient_rating": 19.49,
    "patient_id": 48,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2609,
    "pathological_event_id": 141,
    "care_site": "LRM",
    "pathological_source_ref_id": null,
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": null,
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "NWI",
    "care_equipment_1": "IHO",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 16,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 17,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 20,
    "recovery_duration": 13,
    "recovery_proportion": 0.3939,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 33,
    "event_record_is_complete": true,
    "pathological_severity": 20.6,
    "care_error_level": 21,
    "overall_patient_rating": 16.97,
    "patient_id": 49,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2610,
    "pathological_event_id": 142,
    "care_site": "LRM",
    "pathological_source_ref_id": "8540B7B",
    "consultant_doctor": "COC",
    "consultant_doctor_variation": 1,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 57,
    "outlier": "CNF",
    "pathological_event_date": "2018-06-25",
    "etiology": "NWI",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "RPH",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 134,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 17,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 13,
    "mild_symptom_2": 24,
    "mild_symptom_2_duration": 6,
    "prime_symptom_1": 30,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 24,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": 6,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": 24,
    "prime_symptom_3_duration": 6,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 13,
    "recovery_duration": 7,
    "recovery_proportion": 0.35,
    "prime_symptom_proportion": 0.8,
    "pathological_event_duration": 30,
    "event_record_is_complete": true,
    "pathological_severity": 29.6,
    "care_error_level": 19.5,
    "overall_patient_rating": 18.6,
    "patient_id": 50,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2611,
    "pathological_event_id": 143,
    "care_site": "LRM",
    "pathological_source_ref_id": "402CEF8",
    "consultant_doctor": "BOD",
    "consultant_doctor_variation": 4,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 72,
    "outlier": "CNF",
    "pathological_event_date": "2013-06-05",
    "etiology": "STO",
    "care_equipment_1": "THO",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "007",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "TID",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 24,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": 7,
    "recovery_proportion": 1,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 7,
    "event_record_is_complete": true,
    "pathological_severity": 28.3,
    "care_error_level": 33.5,
    "overall_patient_rating": 25.83,
    "patient_id": 21,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2612,
    "pathological_event_id": 144,
    "care_site": "LRM",
    "pathological_source_ref_id": "F148D4B",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "X",
    "patient_weight": 60,
    "outlier": "CNF",
    "pathological_event_date": "2013-06-05",
    "etiology": "PRD",
    "care_equipment_1": "THO",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "003",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 3,
    "care_technique_2": "CMP",
    "care_technique_3": "RPH",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": true,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 31,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 9,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 22,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 21,
    "recovery_duration": 10,
    "recovery_proportion": 0.3226,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 31,
    "event_record_is_complete": true,
    "pathological_severity": 32.7,
    "care_error_level": 31.4,
    "overall_patient_rating": 25.89,
    "patient_id": 51,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2614,
    "pathological_event_id": 146,
    "care_site": "LRM",
    "pathological_source_ref_id": "419551E",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2023-02-21",
    "etiology": "NWI",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 6,
    "care_technique_2": "CMP",
    "care_technique_3": "RPH",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 18,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 7,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 4,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 7,
    "event_record_is_complete": false,
    "pathological_severity": 21.7,
    "care_error_level": 30,
    "overall_patient_rating": 22.14,
    "patient_id": 52,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2615,
    "pathological_event_id": 147,
    "care_site": "LRM",
    "pathological_source_ref_id": "E17102D",
    "consultant_doctor": "HPR",
    "consultant_doctor_variation": 2,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "US",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2022-05-30",
    "etiology": "LWD",
    "care_equipment_1": "THO",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "RPH",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 34,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 10,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 17,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 11,
    "prime_symptom_2_duration": 16,
    "first_prime_symptom": 11,
    "first_prime_symptom_type": "DCT",
    "prime_symptom_duration": 16,
    "full_prime_symptom_duration": 16,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": 12,
    "prime_symptom_3_duration": 15,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 21,
    "recovery_duration": 6,
    "recovery_proportion": 0.2222,
    "prime_symptom_proportion": 0.4074,
    "pathological_event_duration": 27,
    "event_record_is_complete": true,
    "pathological_severity": 46,
    "care_error_level": 56.5,
    "overall_patient_rating": 43.1,
    "patient_id": 8,
    "event_count": 3,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2618,
    "pathological_event_id": 150,
    "care_site": "LRM",
    "pathological_source_ref_id": "8FB0340",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 66,
    "outlier": "CNF",
    "pathological_event_date": "2023-06-20",
    "etiology": "LWD",
    "care_equipment_1": "THO",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "004",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 89,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 15,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 9,
    "mild_symptom_2": 23,
    "mild_symptom_2_duration": 1,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 24,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 24,
    "event_record_is_complete": false,
    "pathological_severity": 14.2,
    "care_error_level": 12,
    "overall_patient_rating": 10.36,
    "patient_id": 55,
    "event_count": 1,
    "event_title": "RN62",
    "notes": ""
  },
  {
    "id": 2619,
    "pathological_event_id": 151,
    "care_site": "LRM",
    "pathological_source_ref_id": "B9CD2BA",
    "consultant_doctor": "NEM",
    "consultant_doctor_variation": 5,
    "outcome": "NSV",
    "outcome_type": "FSN",
    "source_country": "US",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 70,
    "outlier": "CNF",
    "pathological_event_date": "2017-10-02",
    "etiology": "LWD",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "RPH",
    "care_technique_4": false,
    "care_technique_5": true,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 57,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 23,
    "prime_symptom_1_duration": 15,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 23,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 15,
    "full_prime_symptom_duration": 15,
    "prime_symptom_any": true,
    "prime_symptom_level": 2,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 35,
    "recovery_duration": 3,
    "recovery_proportion": 0.0789,
    "prime_symptom_proportion": 0.6053,
    "pathological_event_duration": 38,
    "event_record_is_complete": true,
    "pathological_severity": 32.6,
    "care_error_level": 34,
    "overall_patient_rating": 27.27,
    "patient_id": 56,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2620,
    "pathological_event_id": 152,
    "care_site": "LRM",
    "pathological_source_ref_id": "0A9ECA3",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 66,
    "outlier": "CNF",
    "pathological_event_date": "2022-04-24",
    "etiology": "LWD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 1124,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 12,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": 2,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 12,
    "prime_symptom_3_duration": 2,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 14,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.8571,
    "pathological_event_duration": 14,
    "event_record_is_complete": false,
    "pathological_severity": 16.2,
    "care_error_level": 22,
    "overall_patient_rating": 16.32,
    "patient_id": 40,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2621,
    "pathological_event_id": 153,
    "care_site": "LRM",
    "pathological_source_ref_id": "6E173AD",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2022-04-24",
    "etiology": "NWI",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 705,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 23,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 23,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 2,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 5,
    "recovery_duration": 10,
    "recovery_proportion": 0.6667,
    "prime_symptom_proportion": 1,
    "pathological_event_duration": 23,
    "event_record_is_complete": true,
    "pathological_severity": 19.5,
    "care_error_level": 30.9,
    "overall_patient_rating": 22.03,
    "patient_id": 23,
    "event_count": 1,
    "event_title": "RN16",
    "notes": ""
  },
  {
    "id": 2622,
    "pathological_event_id": 154,
    "care_site": "LRM",
    "pathological_source_ref_id": "6E173AD",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2022-04-24",
    "etiology": "NWI",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 705,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 5,
    "recovery_duration": 10,
    "recovery_proportion": 0.6667,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 15,
    "event_record_is_complete": true,
    "pathological_severity": 17.7,
    "care_error_level": 29.9,
    "overall_patient_rating": 21,
    "patient_id": 23,
    "event_count": 2,
    "event_title": "RN16",
    "notes": ""
  },
  {
    "id": 2623,
    "pathological_event_id": 155,
    "care_site": "LRM",
    "pathological_source_ref_id": "6E173AD",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2022-04-24",
    "etiology": "NWI",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 705,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 23,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 23,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 2,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 9,
    "recovery_duration": 10,
    "recovery_proportion": 0.5263,
    "prime_symptom_proportion": 1,
    "pathological_event_duration": 23,
    "event_record_is_complete": true,
    "pathological_severity": 21,
    "care_error_level": 30.9,
    "overall_patient_rating": 22.44,
    "patient_id": 23,
    "event_count": 3,
    "event_title": "RN16",
    "notes": ""
  },
  {
    "id": 2624,
    "pathological_event_id": 156,
    "care_site": "LRM",
    "pathological_source_ref_id": "6E173AD",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2022-04-24",
    "etiology": "NWI",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 705,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 7,
    "recovery_duration": 10,
    "recovery_proportion": 0.5882,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 17,
    "event_record_is_complete": true,
    "pathological_severity": 18.9,
    "care_error_level": 29.9,
    "overall_patient_rating": 21.33,
    "patient_id": 23,
    "event_count": 4,
    "event_title": "RN16",
    "notes": ""
  },
  {
    "id": 2625,
    "pathological_event_id": 157,
    "care_site": "LRM",
    "pathological_source_ref_id": "96A7CB3",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2022-04-22",
    "etiology": "UNK",
    "care_equipment_1": "INH",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "TIP",
    "care_technique_3": "NLT",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 726,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 9,
    "care_error_level": 17,
    "overall_patient_rating": 11.65,
    "patient_id": 57,
    "event_count": 1,
    "event_title": "RN22-2",
    "notes": ""
  },
  {
    "id": 2626,
    "pathological_event_id": 158,
    "care_site": "LRM",
    "pathological_source_ref_id": "7D0FF34",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 62,
    "outlier": "CNF",
    "pathological_event_date": "2021-12-18",
    "etiology": "LWD",
    "care_equipment_1": "HIC",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": true,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 1305,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 14,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 11,
    "mild_symptom_2": 14,
    "mild_symptom_2_duration": 11,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 19,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": 6,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 19,
    "prime_symptom_3_duration": 6,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 25,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.76,
    "pathological_event_duration": 25,
    "event_record_is_complete": false,
    "pathological_severity": 22.1,
    "care_error_level": 22,
    "overall_patient_rating": 17.92,
    "patient_id": 58,
    "event_count": 1,
    "event_title": "RN51",
    "notes": ""
  },
  {
    "id": 2627,
    "pathological_event_id": 159,
    "care_site": "LRM",
    "pathological_source_ref_id": "F1476F2",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2022-04-27",
    "etiology": "PRD",
    "care_equipment_1": "HIC",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "008",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 9,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 797,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 12,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 59,
    "mild_symptom_2": 9,
    "mild_symptom_2_duration": 62,
    "prime_symptom_1": 37,
    "prime_symptom_1_duration": 34,
    "prime_symptom_2": 47,
    "prime_symptom_2_duration": 24,
    "first_prime_symptom": 37,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 34,
    "full_prime_symptom_duration": 34,
    "prime_symptom_any": true,
    "prime_symptom_level": 5,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 71,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.5211,
    "pathological_event_duration": 71,
    "event_record_is_complete": false,
    "pathological_severity": 87.3,
    "care_error_level": 84.3,
    "overall_patient_rating": 69.37,
    "patient_id": 6,
    "event_count": 2,
    "event_title": "ALITD01",
    "notes": ""
  },
  {
    "id": 2628,
    "pathological_event_id": 160,
    "care_site": "LRM",
    "pathological_source_ref_id": "F21B414",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 61,
    "outlier": "CNF",
    "pathological_event_date": "2022-04-22",
    "etiology": "PRD",
    "care_equipment_1": "THH",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 3,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 496,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 12,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 26,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 16,
    "recovery_duration": 22,
    "recovery_proportion": 0.5789,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 38,
    "event_record_is_complete": true,
    "pathological_severity": 38.5,
    "care_error_level": 43,
    "overall_patient_rating": 33.75,
    "patient_id": 24,
    "event_count": 3,
    "event_title": "ADS01",
    "notes": ""
  },
  {
    "id": 2629,
    "pathological_event_id": 161,
    "care_site": "LRM",
    "pathological_source_ref_id": "9EE1FA3",
    "consultant_doctor": "NEM",
    "consultant_doctor_variation": 5,
    "outcome": "NSV",
    "outcome_type": "FSN",
    "source_country": "US",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 70,
    "outlier": "CNF",
    "pathological_event_date": "2015-05-20",
    "etiology": "NWI",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CHD",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": true,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 87,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 33,
    "recovery_duration": 16,
    "recovery_proportion": 0.3265,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 49,
    "event_record_is_complete": true,
    "pathological_severity": 20.9,
    "care_error_level": 26,
    "overall_patient_rating": 19.76,
    "patient_id": 59,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2630,
    "pathological_event_id": 162,
    "care_site": "LRM",
    "pathological_source_ref_id": "BEF2C85",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 58,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "NWI",
    "care_equipment_1": "INH",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 30,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 18,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 3,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 21,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 21,
    "event_record_is_complete": false,
    "pathological_severity": 8.7,
    "care_error_level": -0.5,
    "overall_patient_rating": 2.09,
    "patient_id": 25,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2631,
    "pathological_event_id": 163,
    "care_site": "LRM",
    "pathological_source_ref_id": "BEF2C85",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 58,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "NWI",
    "care_equipment_1": "SCW",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "NLF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 58,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 18,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 29,
    "mild_symptom_2": 18,
    "mild_symptom_2_duration": 29,
    "prime_symptom_1": 30,
    "prime_symptom_1_duration": 17,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 30,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 17,
    "full_prime_symptom_duration": 17,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 45,
    "recovery_duration": 2,
    "recovery_proportion": 0.0426,
    "prime_symptom_proportion": 0.6383,
    "pathological_event_duration": 47,
    "event_record_is_complete": true,
    "pathological_severity": 34.8,
    "care_error_level": 30,
    "overall_patient_rating": 25.7,
    "patient_id": 25,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2632,
    "pathological_event_id": 164,
    "care_site": "LRM",
    "pathological_source_ref_id": "08D7681",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 66,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "NWI",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "SIT",
    "care_technique_3": "NLF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 14,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 45,
    "recovery_duration": 2,
    "recovery_proportion": 0.0426,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 47,
    "event_record_is_complete": true,
    "pathological_severity": 11.7,
    "care_error_level": 0.5,
    "overall_patient_rating": 3.45,
    "patient_id": 26,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2633,
    "pathological_event_id": 165,
    "care_site": "LRM",
    "pathological_source_ref_id": "08D7681",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 66,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "NWI",
    "care_equipment_1": "SCW",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "SIT",
    "care_technique_3": "NLF",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 93,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 51,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 42,
    "mild_symptom_2": 57,
    "mild_symptom_2_duration": 36,
    "prime_symptom_1": 70,
    "prime_symptom_1_duration": 23,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 70,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 23,
    "full_prime_symptom_duration": 23,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 86,
    "recovery_duration": 7,
    "recovery_proportion": 0.0753,
    "prime_symptom_proportion": 0.7527,
    "pathological_event_duration": 93,
    "event_record_is_complete": true,
    "pathological_severity": 48.3,
    "care_error_level": 59.5,
    "overall_patient_rating": 45.35,
    "patient_id": 26,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2634,
    "pathological_event_id": 166,
    "care_site": "LRM",
    "pathological_source_ref_id": "08D7681",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 60,
    "outlier": "CNF",
    "pathological_event_date": "2020-08-10",
    "etiology": "NWI",
    "care_equipment_1": "THH",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": null,
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": true,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": null,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 9,
    "mild_symptom_2_duration": 14,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 17,
    "recovery_duration": 6,
    "recovery_proportion": 0.2609,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 23,
    "event_record_is_complete": true,
    "pathological_severity": 20.5,
    "care_error_level": 14.2,
    "overall_patient_rating": 13.26,
    "patient_id": 60,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2635,
    "pathological_event_id": 167,
    "care_site": "LRM",
    "pathological_source_ref_id": "48C4AAA6",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2023-09-21",
    "etiology": "NWI",
    "care_equipment_1": "TIP",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": true,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 111,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 15,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 15,
    "event_record_is_complete": false,
    "pathological_severity": 6,
    "care_error_level": -13,
    "overall_patient_rating": -5.41,
    "patient_id": 4,
    "event_count": 3,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2636,
    "pathological_event_id": 168,
    "care_site": "LRM",
    "pathological_source_ref_id": "48C4AAA6",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2023-09-21",
    "etiology": "NWI",
    "care_equipment_1": "THH",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": true,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 111,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 19,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 16,
    "mild_symptom_2": 19,
    "mild_symptom_2_duration": 16,
    "prime_symptom_1": 20,
    "prime_symptom_1_duration": 15,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 20,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 15,
    "full_prime_symptom_duration": 15,
    "prime_symptom_any": true,
    "prime_symptom_level": 1,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 23,
    "recovery_duration": 12,
    "recovery_proportion": 0.3429,
    "prime_symptom_proportion": 0.5714,
    "pathological_event_duration": 35,
    "event_record_is_complete": true,
    "pathological_severity": 33.3,
    "care_error_level": 29,
    "overall_patient_rating": 24.75,
    "patient_id": 4,
    "event_count": 4,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2637,
    "pathological_event_id": 169,
    "care_site": "LRM",
    "pathological_source_ref_id": "48C4AAAE",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2023-09-21",
    "etiology": "PRD",
    "care_equipment_1": "THH",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 6,
    "care_technique_2": "CMP",
    "care_technique_3": "RPH",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 96,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 10,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 17,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 10,
    "prime_symptom_1_duration": 17,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 10,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 17,
    "full_prime_symptom_duration": 17,
    "prime_symptom_any": true,
    "prime_symptom_level": 2,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 13,
    "recovery_duration": 14,
    "recovery_proportion": 0.5185,
    "prime_symptom_proportion": 0.3704,
    "pathological_event_duration": 27,
    "event_record_is_complete": true,
    "pathological_severity": 48.5,
    "care_error_level": 74.8,
    "overall_patient_rating": 53.68,
    "patient_id": 6,
    "event_count": 9,
    "event_title": "I23",
    "notes": ""
  },
  {
    "id": 2638,
    "pathological_event_id": 170,
    "care_site": "LRM",
    "pathological_source_ref_id": "48C4ABB4",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2023-09-21",
    "etiology": "NWI",
    "care_equipment_1": "INO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": true,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 179,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 25,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 6,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 13,
    "prime_symptom_1_duration": 18,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 13,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 18,
    "full_prime_symptom_duration": 18,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": 25,
    "prime_symptom_3_duration": 6,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 31,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.4194,
    "pathological_event_duration": 31,
    "event_record_is_complete": false,
    "pathological_severity": 42.6,
    "care_error_level": 39.8,
    "overall_patient_rating": 33.13,
    "patient_id": 6,
    "event_count": 10,
    "event_title": "RN65_1",
    "notes": ""
  },
  {
    "id": 2639,
    "pathological_event_id": 171,
    "care_site": "LRM",
    "pathological_source_ref_id": "9711D4E",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2023-09-26",
    "etiology": "NWI",
    "care_equipment_1": "INO",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "003",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": true,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 30,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 7,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 19,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 10,
    "prime_symptom_1_duration": 16,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 10,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 16,
    "full_prime_symptom_duration": 16,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": 20,
    "prime_symptom_3_duration": 6,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 26,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.3846,
    "pathological_event_duration": 26,
    "event_record_is_complete": false,
    "pathological_severity": 41,
    "care_error_level": 39.3,
    "overall_patient_rating": 32.42,
    "patient_id": 6,
    "event_count": 11,
    "event_title": "RN59-1",
    "notes": ""
  },
  {
    "id": 2640,
    "pathological_event_id": 172,
    "care_site": "LRM",
    "pathological_source_ref_id": "C094E82",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2023-09-26",
    "etiology": "NWI",
    "care_equipment_1": "INO",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "003",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 40,
    "intro_symptom_start": 0,
    "intro_symptom_end": 49,
    "intro_symptom_duration": 49,
    "mild_symptom_1": 11,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 13,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 12,
    "recovery_duration": 12,
    "recovery_proportion": 0.5,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 24,
    "event_record_is_complete": true,
    "pathological_severity": 25.9,
    "care_error_level": 21.5,
    "overall_patient_rating": 18.68,
    "patient_id": 6,
    "event_count": 12,
    "event_title": "NT01",
    "notes": ""
  },
  {
    "id": 2641,
    "pathological_event_id": 173,
    "care_site": "LRM",
    "pathological_source_ref_id": "9BF07FA",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2023-10-05",
    "etiology": "NWI",
    "care_equipment_1": "INO",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "004",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": true,
    "care_technique_6": true,
    "care_technique_7": true,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 98,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 7,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 16,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 12,
    "prime_symptom_1_duration": 11,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 12,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 11,
    "full_prime_symptom_duration": 11,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 23,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.5217,
    "pathological_event_duration": 23,
    "event_record_is_complete": false,
    "pathological_severity": 33.2,
    "care_error_level": 28,
    "overall_patient_rating": 24.18,
    "patient_id": 6,
    "event_count": 13,
    "event_title": "RN63-2",
    "notes": ""
  },
  {
    "id": 2642,
    "pathological_event_id": 174,
    "care_site": "LRM",
    "pathological_source_ref_id": "9BF07FA",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": "PB",
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2023-08-05",
    "etiology": "NWI",
    "care_equipment_1": "INO",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": true,
    "care_technique_6": true,
    "care_technique_7": true,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 247,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 14,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 12,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 11,
    "prime_symptom_1_duration": 15,
    "prime_symptom_2": 10,
    "prime_symptom_2_duration": 16,
    "first_prime_symptom": 9,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": 16,
    "full_prime_symptom_duration": 17,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 9,
    "prime_symptom_3_duration": 17,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 26,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.3462,
    "pathological_event_duration": 26,
    "event_record_is_complete": false,
    "pathological_severity": 40.8,
    "care_error_level": 39,
    "overall_patient_rating": 32.21,
    "patient_id": 120,
    "event_count": 1,
    "event_title": "RN57",
    "notes": ""
  },
  {
    "id": 2802,
    "pathological_event_id": 335,
    "care_site": "SCT",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": 31,
    "outcome": "NSV",
    "outcome_type": "ATM",
    "source_country": "NP",
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2018-08-26",
    "etiology": "LWD",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "PRD",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 24,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 9,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 15,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 24,
    "recovery_duration": 0,
    "recovery_proportion": 0,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 24,
    "event_record_is_complete": true,
    "pathological_severity": 10.4,
    "care_error_level": -6,
    "overall_patient_rating": -0.42,
    "patient_id": 243,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2643,
    "pathological_event_id": 175,
    "care_site": "UNK",
    "pathological_source_ref_id": null,
    "consultant_doctor": "RPV",
    "consultant_doctor_variation": 23,
    "outcome": "NSV",
    "outcome_type": "DCI",
    "source_country": "BD",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2023-10-11",
    "etiology": "PRD",
    "care_equipment_1": "SCW",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "007",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 15,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 95,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 15,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 68,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 18,
    "prime_symptom_1_duration": 65,
    "prime_symptom_2": 25,
    "prime_symptom_2_duration": 58,
    "first_prime_symptom": 18,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 65,
    "full_prime_symptom_duration": 65,
    "prime_symptom_any": true,
    "prime_symptom_level": 6,
    "prime_symptom_3": 37,
    "prime_symptom_3_duration": 46,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": 48,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 79,
    "recovery_duration": 4,
    "recovery_proportion": 0.0482,
    "prime_symptom_proportion": 0.2169,
    "pathological_event_duration": 83,
    "event_record_is_complete": true,
    "pathological_severity": 151.2,
    "care_error_level": 77.5,
    "overall_patient_rating": 83.05,
    "patient_id": 121,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2645,
    "pathological_event_id": 177,
    "care_site": "LRM",
    "pathological_source_ref_id": null,
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2023-10-18",
    "etiology": "LWD",
    "care_equipment_1": "SCW",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "007",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 66,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 8,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 43,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 16,
    "prime_symptom_1_duration": 35,
    "prime_symptom_2": 29,
    "prime_symptom_2_duration": 22,
    "first_prime_symptom": 16,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 35,
    "full_prime_symptom_duration": 35,
    "prime_symptom_any": true,
    "prime_symptom_level": 4,
    "prime_symptom_3": 16,
    "prime_symptom_3_duration": 35,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 39,
    "recovery_duration": 12,
    "recovery_proportion": 0.2353,
    "prime_symptom_proportion": 0.3137,
    "pathological_event_duration": 51,
    "event_record_is_complete": true,
    "pathological_severity": 72.5,
    "care_error_level": 57.5,
    "overall_patient_rating": 50.84,
    "patient_id": 132,
    "event_count": 1,
    "event_title": "",
    "notes": "Possible dupe"
  },
  {
    "id": 2646,
    "pathological_event_id": 178,
    "care_site": "LRM",
    "pathological_source_ref_id": "D8D2E85",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2021-06-01",
    "etiology": "NWI",
    "care_equipment_1": "INO",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "TIP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": true,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 6,
    "intro_symptom_start": 0,
    "intro_symptom_end": 6,
    "intro_symptom_duration": 6,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 6,
    "care_error_level": -2,
    "overall_patient_rating": 0.55,
    "patient_id": 131,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2647,
    "pathological_event_id": 179,
    "care_site": "LRM",
    "pathological_source_ref_id": "D8D2E85",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2021-06-01",
    "etiology": "NWI",
    "care_equipment_1": "INO",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": true,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 8,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 5,
    "recovery_duration": 3,
    "recovery_proportion": 0.375,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 8,
    "event_record_is_complete": true,
    "pathological_severity": 12.8,
    "care_error_level": -6,
    "overall_patient_rating": 0.23,
    "patient_id": 131,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2648,
    "pathological_event_id": 180,
    "care_site": "LRM",
    "pathological_source_ref_id": "D8D2E85",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2021-06-01",
    "etiology": "NWI",
    "care_equipment_1": "INO",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "TIP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": true,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 15,
    "intro_symptom_start": 0,
    "intro_symptom_end": 15,
    "intro_symptom_duration": 15,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 6,
    "care_error_level": -2,
    "overall_patient_rating": 0.55,
    "patient_id": 130,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2649,
    "pathological_event_id": 181,
    "care_site": "LRM",
    "pathological_source_ref_id": "D8D2E85",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2021-06-01",
    "etiology": "NWI",
    "care_equipment_1": "INO",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "TIP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": true,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 29,
    "intro_symptom_start": 0,
    "intro_symptom_end": 29,
    "intro_symptom_duration": 29,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 7,
    "care_error_level": -2,
    "overall_patient_rating": 0.82,
    "patient_id": 130,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2650,
    "pathological_event_id": 182,
    "care_site": "LRM",
    "pathological_source_ref_id": "D8D2E85",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2021-06-01",
    "etiology": "NWI",
    "care_equipment_1": "INO",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "TIP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": true,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 67,
    "intro_symptom_start": 0,
    "intro_symptom_end": 27,
    "intro_symptom_duration": 27,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 7.5,
    "care_error_level": -2,
    "overall_patient_rating": 0.96,
    "patient_id": 130,
    "event_count": 3,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2651,
    "pathological_event_id": 183,
    "care_site": "LRM",
    "pathological_source_ref_id": "0ECDE02",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2013-09-14",
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": false,
    "event_record_length": 24,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 13,
    "mild_symptom_2_duration": 7,
    "prime_symptom_1": 15,
    "prime_symptom_1_duration": 5,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 15,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 5,
    "full_prime_symptom_duration": 5,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": 20,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.75,
    "pathological_event_duration": 20,
    "event_record_is_complete": false,
    "pathological_severity": 14.5,
    "care_error_level": 8.3,
    "overall_patient_rating": 8.43,
    "patient_id": 129,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2652,
    "pathological_event_id": 184,
    "care_site": "LRM",
    "pathological_source_ref_id": "D7E5D63",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2021-05-10",
    "etiology": "NWI",
    "care_equipment_1": "SCW",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "008",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 16,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 10,
    "mild_symptom_2_duration": 6,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 15,
    "recovery_duration": 1,
    "recovery_proportion": 0.0625,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 16,
    "event_record_is_complete": true,
    "pathological_severity": 6.6,
    "care_error_level": -5,
    "overall_patient_rating": -0.91,
    "patient_id": 128,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2657,
    "pathological_event_id": 189,
    "care_site": "LRM",
    "pathological_source_ref_id": "DB9D8FC",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2023-08-31",
    "etiology": "STO",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "TIP",
    "care_technique_3": "PRD",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 49,
    "intro_symptom_start": 0,
    "intro_symptom_end": 27,
    "intro_symptom_duration": 27,
    "mild_symptom_1": 35,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 55,
    "mild_symptom_2": 31,
    "mild_symptom_2_duration": 59,
    "prime_symptom_1": 35,
    "prime_symptom_1_duration": 55,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 35,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 55,
    "full_prime_symptom_duration": 55,
    "prime_symptom_any": true,
    "prime_symptom_level": 6,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 41,
    "recovery_duration": 49,
    "recovery_proportion": 0.5444,
    "prime_symptom_proportion": 0.3889,
    "pathological_event_duration": 90,
    "event_record_is_complete": true,
    "pathological_severity": 59.8,
    "care_error_level": 86,
    "overall_patient_rating": 62.82,
    "patient_id": 122,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2658,
    "pathological_event_id": 190,
    "care_site": "LRM",
    "pathological_source_ref_id": "32A9543",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2023-08-28",
    "etiology": "STO",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "TIP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 37,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 17,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 20,
    "mild_symptom_2": 15,
    "mild_symptom_2_duration": 22,
    "prime_symptom_1": 19,
    "prime_symptom_1_duration": 18,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 19,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 18,
    "full_prime_symptom_duration": 18,
    "prime_symptom_any": true,
    "prime_symptom_level": 5,
    "prime_symptom_3": 24,
    "prime_symptom_3_duration": 13,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 37,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.5135,
    "pathological_event_duration": 37,
    "event_record_is_complete": false,
    "pathological_severity": 41.9,
    "care_error_level": 35.8,
    "overall_patient_rating": 30.77,
    "patient_id": 122,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2660,
    "pathological_event_id": 192,
    "care_site": "LRM",
    "pathological_source_ref_id": "50BE29D",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2023-10-18",
    "etiology": "STO",
    "care_equipment_1": "SCT",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "007",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 94,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 11,
    "recovery_duration": 6,
    "recovery_proportion": 0.3529,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 17,
    "event_record_is_complete": true,
    "pathological_severity": 12.9,
    "care_error_level": 3.2,
    "overall_patient_rating": 5.24,
    "patient_id": 134,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2661,
    "pathological_event_id": 193,
    "care_site": "LRM",
    "pathological_source_ref_id": "50BE29D",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2023-10-18",
    "etiology": "STO",
    "care_equipment_1": "SCT",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "007",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 120,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 11,
    "recovery_duration": 6,
    "recovery_proportion": 0.3529,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 17,
    "event_record_is_complete": true,
    "pathological_severity": 12.9,
    "care_error_level": 3.2,
    "overall_patient_rating": 5.24,
    "patient_id": 135,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2662,
    "pathological_event_id": 194,
    "care_site": "LRM",
    "pathological_source_ref_id": "50BE29D",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2023-10-18",
    "etiology": "STO",
    "care_equipment_1": "SCT",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "007",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 125,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 11,
    "recovery_duration": 6,
    "recovery_proportion": 0.3529,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 17,
    "event_record_is_complete": true,
    "pathological_severity": 12.9,
    "care_error_level": 3.2,
    "overall_patient_rating": 5.24,
    "patient_id": 136,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2663,
    "pathological_event_id": 195,
    "care_site": "LRM",
    "pathological_source_ref_id": "50BE29D",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": "2023-10-18",
    "etiology": "STO",
    "care_equipment_1": "SCT",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "007",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 38,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 11,
    "recovery_duration": 6,
    "recovery_proportion": 0.3529,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 17,
    "event_record_is_complete": true,
    "pathological_severity": 12.9,
    "care_error_level": 3.2,
    "overall_patient_rating": 5.24,
    "patient_id": 137,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2664,
    "pathological_event_id": 196,
    "care_site": "LRM",
    "pathological_source_ref_id": "4965A86",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 60,
    "outlier": "CNF",
    "pathological_event_date": "2018-06-11",
    "etiology": "NWI",
    "care_equipment_1": "THH",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "SIT",
    "care_technique_3": "LWD",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": true,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 114,
    "intro_symptom_start": 0,
    "intro_symptom_end": 55,
    "intro_symptom_duration": 55,
    "mild_symptom_1": 68,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 121,
    "mild_symptom_2": 68,
    "mild_symptom_2_duration": 121,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 75,
    "recovery_duration": 114,
    "recovery_proportion": 0.6032,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 189,
    "event_record_is_complete": true,
    "pathological_severity": 69.6,
    "care_error_level": 70,
    "overall_patient_rating": 56.82,
    "patient_id": 138,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2665,
    "pathological_event_id": 197,
    "care_site": "LRM",
    "pathological_source_ref_id": "457400E",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 60,
    "outlier": "CNF",
    "pathological_event_date": "2022-03-01",
    "etiology": "SIT",
    "care_equipment_1": "SCT",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "SIT",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 27,
    "intro_symptom_start": 0,
    "intro_symptom_end": 27,
    "intro_symptom_duration": 27,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 4,
    "care_error_level": -1,
    "overall_patient_rating": 0.55,
    "patient_id": 139,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2666,
    "pathological_event_id": 198,
    "care_site": "LRM",
    "pathological_source_ref_id": "1161B9F",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 60,
    "outlier": "CNF",
    "pathological_event_date": "2022-03-01",
    "etiology": "LWD",
    "care_equipment_1": "SCW",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "SIT",
    "care_technique_3": "LWD",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 47,
    "intro_symptom_start": 0,
    "intro_symptom_end": 47,
    "intro_symptom_duration": 47,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 47,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 47,
    "event_record_is_complete": false,
    "pathological_severity": 6.7,
    "care_error_level": 0.5,
    "overall_patient_rating": 2.09,
    "patient_id": 139,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2668,
    "pathological_event_id": 200,
    "care_site": "LRM",
    "pathological_source_ref_id": "6691C36",
    "consultant_doctor": "PRV",
    "consultant_doctor_variation": 31,
    "outcome": "NSV",
    "outcome_type": "ATM",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 75,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "PRD",
    "care_equipment_1": "SCW",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "001",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 15,
    "care_technique_2": "CMP",
    "care_technique_3": "WEI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": true,
    "event_record_length": 155,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 17,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 75,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 21,
    "prime_symptom_1_duration": 71,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 21,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 71,
    "full_prime_symptom_duration": 71,
    "prime_symptom_any": true,
    "prime_symptom_level": 2,
    "prime_symptom_3": 31,
    "prime_symptom_3_duration": 61,
    "fatal_symptom_1": null,
    "fatal_symptom_2": 52,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 68,
    "recovery_duration": 24,
    "recovery_proportion": 0.2609,
    "prime_symptom_proportion": 0.2283,
    "pathological_event_duration": 92,
    "event_record_is_complete": true,
    "pathological_severity": 147.3,
    "care_error_level": 97.8,
    "overall_patient_rating": 92.99,
    "patient_id": 141,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2669,
    "pathological_event_id": 201,
    "care_site": "LRM",
    "pathological_source_ref_id": "D8D2E85",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 60,
    "outlier": "CNF",
    "pathological_event_date": "2021-06-01",
    "etiology": "NWI",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 30,
    "intro_symptom_start": 0,
    "intro_symptom_end": 10,
    "intro_symptom_duration": 10,
    "mild_symptom_1": 16,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 14,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 14,
    "recovery_duration": 16,
    "recovery_proportion": 0.5333,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 30,
    "event_record_is_complete": true,
    "pathological_severity": 28.8,
    "care_error_level": 20,
    "overall_patient_rating": 18.66,
    "patient_id": 130,
    "event_count": 4,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2670,
    "pathological_event_id": 202,
    "care_site": "LRM",
    "pathological_source_ref_id": "UNK",
    "consultant_doctor": "NEM",
    "consultant_doctor_variation": 5,
    "outcome": "NSV",
    "outcome_type": "FSN",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 64,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "NWI",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": false,
    "event_record_length": 26,
    "intro_symptom_start": 0,
    "intro_symptom_end": 2,
    "intro_symptom_duration": 2,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": 2,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 20,
    "recovery_duration": 5,
    "recovery_proportion": 0.2,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 25,
    "event_record_is_complete": true,
    "pathological_severity": 14.7,
    "care_error_level": 12.2,
    "overall_patient_rating": 10.6,
    "patient_id": 142,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2671,
    "pathological_event_id": 203,
    "care_site": "LRM",
    "pathological_source_ref_id": "UNK",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 70,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "PRD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": 18,
    "care_technique_2": "CTP",
    "care_technique_3": "RPH",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 92,
    "intro_symptom_start": 0,
    "intro_symptom_end": 66,
    "intro_symptom_duration": 66,
    "mild_symptom_1": 6,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 17,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 6,
    "recovery_duration": 17,
    "recovery_proportion": 0.7391,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 23,
    "event_record_is_complete": true,
    "pathological_severity": 38.7,
    "care_error_level": 21,
    "overall_patient_rating": 21.89,
    "patient_id": 143,
    "event_count": 1,
    "event_title": "RN34",
    "notes": ""
  },
  {
    "id": 2672,
    "pathological_event_id": 204,
    "care_site": "LRM",
    "pathological_source_ref_id": "EA801AE",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "LWD",
    "care_equipment_1": "INO",
    "care_equipment_2": false,
    "care_equipment_3": true,
    "care_equipment_4": "007",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CTP",
    "care_technique_3": "RPH",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 48,
    "intro_symptom_start": 0,
    "intro_symptom_end": 15,
    "intro_symptom_duration": 15,
    "mild_symptom_1": 6,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 16,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 6,
    "prime_symptom_1_duration": 16,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 6,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 16,
    "full_prime_symptom_duration": 16,
    "prime_symptom_any": true,
    "prime_symptom_level": 1,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 5,
    "recovery_duration": 17,
    "recovery_proportion": 0.7727,
    "prime_symptom_proportion": 0.2727,
    "pathological_event_duration": 22,
    "event_record_is_complete": true,
    "pathological_severity": 25,
    "care_error_level": 29.8,
    "overall_patient_rating": 22.93,
    "patient_id": 144,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2737,
    "pathological_event_id": 269,
    "care_site": "BPF",
    "pathological_source_ref_id": "110174740",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 70,
    "outlier": "CNF",
    "pathological_event_date": "2023-11-01",
    "etiology": "STO",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "LWD",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 18,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 15,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 15,
    "event_record_is_complete": false,
    "pathological_severity": 5.5,
    "care_error_level": -1,
    "overall_patient_rating": 0.95,
    "patient_id": 209,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2738,
    "pathological_event_id": 270,
    "care_site": "BPF",
    "pathological_source_ref_id": "110174741",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 70,
    "outlier": "CNF",
    "pathological_event_date": "2023-11-01",
    "etiology": "STO",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "LWD",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 18,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 13,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 13,
    "event_record_is_complete": false,
    "pathological_severity": 6.3,
    "care_error_level": -1,
    "overall_patient_rating": 1.17,
    "patient_id": 209,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2739,
    "pathological_event_id": 271,
    "care_site": "BPF",
    "pathological_source_ref_id": "110174742",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 70,
    "outlier": "CNF",
    "pathological_event_date": "2023-11-01",
    "etiology": "STO",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "LWD",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 22,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 17,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 17,
    "event_record_is_complete": false,
    "pathological_severity": 7.2,
    "care_error_level": -1,
    "overall_patient_rating": 1.42,
    "patient_id": 209,
    "event_count": 3,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2740,
    "pathological_event_id": 272,
    "care_site": "BPF",
    "pathological_source_ref_id": "110174753",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 70,
    "outlier": "CNF",
    "pathological_event_date": "2023-11-06",
    "etiology": "STO",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "LWD",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 30,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 14,
    "mild_symptom_2_duration": 10,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 24,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 24,
    "event_record_is_complete": false,
    "pathological_severity": 8.4,
    "care_error_level": -1,
    "overall_patient_rating": 1.74,
    "patient_id": 209,
    "event_count": 4,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2741,
    "pathological_event_id": 273,
    "care_site": "UNK",
    "pathological_source_ref_id": "",
    "consultant_doctor": "NEM",
    "consultant_doctor_variation": 5,
    "outcome": "NSV",
    "outcome_type": "FSN",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "NWI",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": null,
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 32,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 5,
    "recovery_duration": 2,
    "recovery_proportion": 0.2857,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 7,
    "event_record_is_complete": true,
    "pathological_severity": 5.7,
    "care_error_level": -5,
    "overall_patient_rating": -1.16,
    "patient_id": 210,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2742,
    "pathological_event_id": 274,
    "care_site": "UNK",
    "pathological_source_ref_id": "",
    "consultant_doctor": "NEM",
    "consultant_doctor_variation": 5,
    "outcome": "NSV",
    "outcome_type": "FSN",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "NWI",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": null,
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 24,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 4,
    "recovery_duration": 1,
    "recovery_proportion": 0.2,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 5,
    "event_record_is_complete": true,
    "pathological_severity": 6.5,
    "care_error_level": -5,
    "overall_patient_rating": -0.94,
    "patient_id": 210,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2743,
    "pathological_event_id": 275,
    "care_site": "UNK",
    "pathological_source_ref_id": "",
    "consultant_doctor": "NEM",
    "consultant_doctor_variation": 5,
    "outcome": "NSV",
    "outcome_type": "FSN",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "NWI",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": null,
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 53,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 7,
    "recovery_duration": 8,
    "recovery_proportion": 0.5333,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 15,
    "event_record_is_complete": true,
    "pathological_severity": 15.6,
    "care_error_level": 12.5,
    "overall_patient_rating": 11.01,
    "patient_id": 210,
    "event_count": 3,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2744,
    "pathological_event_id": 276,
    "care_site": "UNK",
    "pathological_source_ref_id": "",
    "consultant_doctor": "NEM",
    "consultant_doctor_variation": 5,
    "outcome": "NSV",
    "outcome_type": "FSN",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "NWI",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": null,
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 23,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 6,
    "recovery_duration": 3,
    "recovery_proportion": 0.3333,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 9,
    "event_record_is_complete": true,
    "pathological_severity": 12.9,
    "care_error_level": -5,
    "overall_patient_rating": 0.8,
    "patient_id": 210,
    "event_count": 4,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2747,
    "pathological_event_id": 279,
    "care_site": "LRM",
    "pathological_source_ref_id": "42CC0D6",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 60,
    "outlier": "CNF",
    "pathological_event_date": "2023-04-28",
    "etiology": "STO",
    "care_equipment_1": "ELC",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 162,
    "intro_symptom_start": 0,
    "intro_symptom_end": 9,
    "intro_symptom_duration": 9,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 120,
    "recovery_duration": 160,
    "recovery_proportion": 0.5714,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 280,
    "event_record_is_complete": true,
    "pathological_severity": 54.5,
    "care_error_level": 55.5,
    "overall_patient_rating": 44.86,
    "patient_id": 213,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2748,
    "pathological_event_id": 280,
    "care_site": "LRM",
    "pathological_source_ref_id": "C5348C3",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 60,
    "outlier": "CNF",
    "pathological_event_date": "2023-04-28",
    "etiology": "STO",
    "care_equipment_1": "ELC",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "SIT",
    "care_technique_3": "RPH",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 126,
    "intro_symptom_start": 0,
    "intro_symptom_end": 3,
    "intro_symptom_duration": 3,
    "mild_symptom_1": 101,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 1,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 101,
    "recovery_duration": 1,
    "recovery_proportion": 0.0098,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 102,
    "event_record_is_complete": true,
    "pathological_severity": 20.4,
    "care_error_level": 16,
    "overall_patient_rating": 14.21,
    "patient_id": 213,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2749,
    "pathological_event_id": 281,
    "care_site": "LRM",
    "pathological_source_ref_id": "14C11DB",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 60,
    "outlier": "CNF",
    "pathological_event_date": "2023-04-28",
    "etiology": "STO",
    "care_equipment_1": "ELC",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "SIT",
    "care_technique_3": "RPH",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 54,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 26,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 6,
    "mild_symptom_2": 12,
    "mild_symptom_2_duration": 20,
    "prime_symptom_1": 19,
    "prime_symptom_1_duration": 13,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 19,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 13,
    "full_prime_symptom_duration": 13,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 28,
    "recovery_duration": 4,
    "recovery_proportion": 0.125,
    "prime_symptom_proportion": 0.5938,
    "pathological_event_duration": 32,
    "event_record_is_complete": true,
    "pathological_severity": 35.3,
    "care_error_level": 26.5,
    "overall_patient_rating": 23.94,
    "patient_id": 213,
    "event_count": 3,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2750,
    "pathological_event_id": 282,
    "care_site": "LRM",
    "pathological_source_ref_id": "4F88563",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 60,
    "outlier": "CNF",
    "pathological_event_date": "2023-04-28",
    "etiology": "STO",
    "care_equipment_1": "ELC",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 23,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 12,
    "mild_symptom_2_duration": 7,
    "prime_symptom_1": 19,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 19,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": 5,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 1,
    "pathological_event_duration": 19,
    "event_record_is_complete": false,
    "pathological_severity": 14.9,
    "care_error_level": 3.5,
    "overall_patient_rating": 5.94,
    "patient_id": 213,
    "event_count": 4,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2751,
    "pathological_event_id": 283,
    "care_site": "BPF",
    "pathological_source_ref_id": "6/2759",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "UNK",
    "care_technique_3": "RPH",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 23,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 11,
    "mild_symptom_2_duration": 12,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 22,
    "recovery_duration": 1,
    "recovery_proportion": 0.0435,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 23,
    "event_record_is_complete": true,
    "pathological_severity": 7.3,
    "care_error_level": -7,
    "overall_patient_rating": -1.81,
    "patient_id": 214,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2752,
    "pathological_event_id": 284,
    "care_site": "LRM",
    "pathological_source_ref_id": "4715DEC",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2023-03-11",
    "etiology": "LWD",
    "care_equipment_1": "SCW",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "004",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "SIT",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 108,
    "intro_symptom_start": 0,
    "intro_symptom_end": 12,
    "intro_symptom_duration": 12,
    "mild_symptom_1": 51,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 12,
    "mild_symptom_2": 51,
    "mild_symptom_2_duration": 12,
    "prime_symptom_1": 63,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 60,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": 3,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": 60,
    "prime_symptom_3_duration": 3,
    "fatal_symptom_1": 107,
    "fatal_symptom_2": 107,
    "slight_death_response_1": 90,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.9524,
    "pathological_event_duration": 63,
    "event_record_is_complete": false,
    "pathological_severity": 12.9,
    "care_error_level": 7,
    "overall_patient_rating": 7.3,
    "patient_id": 215,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2753,
    "pathological_event_id": 285,
    "care_site": "LRM",
    "pathological_source_ref_id": "63F72E9",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 65,
    "outlier": "CNF",
    "pathological_event_date": "2023-07-16",
    "etiology": "LWD",
    "care_equipment_1": "SCW",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "SIT",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 87,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 31,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 21,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": 10,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 21,
    "prime_symptom_3_duration": 10,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": 70,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.6774,
    "pathological_event_duration": 31,
    "event_record_is_complete": false,
    "pathological_severity": 11.1,
    "care_error_level": -1.5,
    "overall_patient_rating": 2.2,
    "patient_id": 215,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2754,
    "pathological_event_id": 286,
    "care_site": "BPF",
    "pathological_source_ref_id": "4605/5787",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2023-11-16",
    "etiology": "STO",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "SIT",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 107,
    "intro_symptom_start": 0,
    "intro_symptom_end": 14,
    "intro_symptom_duration": 14,
    "mild_symptom_1": 102,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 102,
    "event_record_is_complete": false,
    "pathological_severity": 11.2,
    "care_error_level": 14,
    "overall_patient_rating": 10.62,
    "patient_id": 216,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2755,
    "pathological_event_id": 287,
    "care_site": "LRM",
    "pathological_source_ref_id": "D26AB78",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2023-07-13",
    "etiology": "STO",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CHD",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 27,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 10,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 10,
    "event_record_is_complete": false,
    "pathological_severity": 6,
    "care_error_level": -1,
    "overall_patient_rating": 1.09,
    "patient_id": 217,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2756,
    "pathological_event_id": 288,
    "care_site": "LRM",
    "pathological_source_ref_id": "B9D9681",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": null,
    "outlier": "CNF",
    "pathological_event_date": "2023-07-13",
    "etiology": "STO",
    "care_equipment_1": "STR",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CHD",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 23,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 16,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 12,
    "mild_symptom_2_duration": 4,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 16,
    "event_record_is_complete": false,
    "pathological_severity": 5.6,
    "care_error_level": -1,
    "overall_patient_rating": 0.98,
    "patient_id": 217,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2757,
    "pathological_event_id": 289,
    "care_site": "LRM",
    "pathological_source_ref_id": "CD0A5ED",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": "RU",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 61,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "STO",
    "care_equipment_1": "UNK",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CTP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 97,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 10,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 46,
    "mild_symptom_2": 12,
    "mild_symptom_2_duration": 44,
    "prime_symptom_1": 40,
    "prime_symptom_1_duration": 16,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 40,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 16,
    "full_prime_symptom_duration": 16,
    "prime_symptom_any": true,
    "prime_symptom_level": 3,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 56,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.7143,
    "pathological_event_duration": 56,
    "event_record_is_complete": false,
    "pathological_severity": 39.5,
    "care_error_level": 36.8,
    "overall_patient_rating": 30.66,
    "patient_id": 24,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2758,
    "pathological_event_id": 290,
    "care_site": "LRM",
    "pathological_source_ref_id": "8A23F34",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": "IN",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 55,
    "outlier": "CNF",
    "pathological_event_date": "2023-12-25",
    "etiology": "NWI",
    "care_equipment_1": "SCT",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 67,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 7,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 21,
    "mild_symptom_2": 9,
    "mild_symptom_2_duration": 19,
    "prime_symptom_1": 15,
    "prime_symptom_1_duration": 13,
    "prime_symptom_2": 15,
    "prime_symptom_2_duration": 13,
    "first_prime_symptom": 15,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 13,
    "full_prime_symptom_duration": 13,
    "prime_symptom_any": true,
    "prime_symptom_level": 2,
    "prime_symptom_3": 28,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.5357,
    "pathological_event_duration": 28,
    "event_record_is_complete": false,
    "pathological_severity": 30.4,
    "care_error_level": 22.5,
    "overall_patient_rating": 20.44,
    "patient_id": 218,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2759,
    "pathological_event_id": 291,
    "care_site": "UNK",
    "pathological_source_ref_id": "",
    "consultant_doctor": "PRO",
    "consultant_doctor_variation": 1,
    "outcome": "NSV",
    "outcome_type": "ACC",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 50,
    "outlier": "UNU",
    "pathological_event_date": null,
    "etiology": "UNK",
    "care_equipment_1": "UNK",
    "care_equipment_2": true,
    "care_equipment_3": false,
    "care_equipment_4": "001",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "LWD",
    "care_technique_4": true,
    "care_technique_5": true,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": true,
    "event_record_length": 19,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 6,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 13,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 13,
    "recovery_duration": 6,
    "recovery_proportion": 0.3158,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 19,
    "event_record_is_complete": true,
    "pathological_severity": 8.7,
    "care_error_level": -4.8,
    "overall_patient_rating": -0.24,
    "patient_id": 219,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2760,
    "pathological_event_id": 292,
    "care_site": "LRM",
    "pathological_source_ref_id": "9A04FE4",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": "IN",
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 55,
    "outlier": "UNU",
    "pathological_event_date": "2023-12-16",
    "etiology": "SIT",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "SIT",
    "care_technique_3": "RPH",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 81,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 12,
    "mild_symptom_2_duration": 65,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": 20,
    "prime_symptom_2_duration": 57,
    "first_prime_symptom": 20,
    "first_prime_symptom_type": "DCT",
    "prime_symptom_duration": 57,
    "full_prime_symptom_duration": 57,
    "prime_symptom_any": true,
    "prime_symptom_level": 2,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 56,
    "recovery_duration": 21,
    "recovery_proportion": 0.2727,
    "prime_symptom_proportion": 0.2597,
    "pathological_event_duration": 77,
    "event_record_is_complete": true,
    "pathological_severity": 72.6,
    "care_error_level": 73.8,
    "overall_patient_rating": 59.69,
    "patient_id": 218,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2762,
    "pathological_event_id": 294,
    "care_site": "LRM",
    "pathological_source_ref_id": "80C64B4",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 65,
    "outlier": "DUB",
    "pathological_event_date": "2023-12-29",
    "etiology": "STD",
    "care_equipment_1": "SCT",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "TIP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 286,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 7,
    "care_error_level": 0,
    "overall_patient_rating": 1.9,
    "patient_id": 221,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2763,
    "pathological_event_id": 295,
    "care_site": "LRM",
    "pathological_source_ref_id": "814B2C3",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "M",
    "patient_weight": 75,
    "outlier": "CNF",
    "pathological_event_date": "2024-01-09",
    "etiology": "STO",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "TIP",
    "care_technique_3": "RPH",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 24,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 10,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 7,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 10,
    "prime_symptom_1_duration": 7,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 10,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 7,
    "full_prime_symptom_duration": 7,
    "prime_symptom_any": true,
    "prime_symptom_level": 6,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 12,
    "recovery_duration": 5,
    "recovery_proportion": 0.2941,
    "prime_symptom_proportion": 0.5882,
    "pathological_event_duration": 17,
    "event_record_is_complete": true,
    "pathological_severity": 29.2,
    "care_error_level": 28.7,
    "overall_patient_rating": 23.48,
    "patient_id": 42,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2764,
    "pathological_event_id": 296,
    "care_site": "LRM",
    "pathological_source_ref_id": "946E424",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 62,
    "outlier": "CNF",
    "pathological_event_date": null,
    "etiology": "STO",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": true,
    "observed_movement_response_2": false,
    "event_record_length": 31,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 13,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 18,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": 15,
    "prime_symptom_1_duration": 16,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 15,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 16,
    "full_prime_symptom_duration": 16,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 31,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 0.4839,
    "pathological_event_duration": 31,
    "event_record_is_complete": false,
    "pathological_severity": 42.5,
    "care_error_level": 61,
    "overall_patient_rating": 44.58,
    "patient_id": 222,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2765,
    "pathological_event_id": 297,
    "care_site": "LRM",
    "pathological_source_ref_id": "BA086FD",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "X",
    "patient_weight": 68,
    "outlier": "UNU",
    "pathological_event_date": "2023-06-05",
    "etiology": "STD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "RPH",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 68,
    "intro_symptom_start": 0,
    "intro_symptom_end": 67,
    "intro_symptom_duration": 67,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 67,
    "recovery_duration": 1,
    "recovery_proportion": 0.0147,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 68,
    "event_record_is_complete": true,
    "pathological_severity": 17.8,
    "care_error_level": 9,
    "overall_patient_rating": 9.71,
    "patient_id": 223,
    "event_count": 1,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2766,
    "pathological_event_id": 298,
    "care_site": "LRM",
    "pathological_source_ref_id": "D02E478",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "X",
    "patient_weight": 68,
    "outlier": "UNU",
    "pathological_event_date": "2023-06-26",
    "etiology": "STD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "RPH",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": true,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 86,
    "intro_symptom_start": 0,
    "intro_symptom_end": 85,
    "intro_symptom_duration": 85,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 85,
    "recovery_duration": 1,
    "recovery_proportion": 0.0116,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 86,
    "event_record_is_complete": true,
    "pathological_severity": 21.6,
    "care_error_level": 21,
    "overall_patient_rating": 17.24,
    "patient_id": 223,
    "event_count": 2,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2767,
    "pathological_event_id": 299,
    "care_site": "LRM",
    "pathological_source_ref_id": "43FED3B",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "X",
    "patient_weight": 68,
    "outlier": "UNU",
    "pathological_event_date": "2023-07-03",
    "etiology": "STD",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "RPH",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": true,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 159,
    "intro_symptom_start": 0,
    "intro_symptom_end": 157,
    "intro_symptom_duration": 157,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 157,
    "recovery_duration": 2,
    "recovery_proportion": 0.0126,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 159,
    "event_record_is_complete": true,
    "pathological_severity": 29.4,
    "care_error_level": 31.5,
    "overall_patient_rating": 25.05,
    "patient_id": 223,
    "event_count": 3,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2768,
    "pathological_event_id": 300,
    "care_site": "LRM",
    "pathological_source_ref_id": "955AEE0",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "X",
    "patient_weight": 68,
    "outlier": "UNU",
    "pathological_event_date": "2023-10-06",
    "etiology": "NWI",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "004",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "TIP",
    "care_technique_3": "RPH",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 44,
    "intro_symptom_start": 0,
    "intro_symptom_end": 44,
    "intro_symptom_duration": 44,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 44,
    "recovery_duration": 1,
    "recovery_proportion": 0.0222,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 45,
    "event_record_is_complete": true,
    "pathological_severity": 18.5,
    "care_error_level": 3,
    "overall_patient_rating": 6.65,
    "patient_id": 223,
    "event_count": 4,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2769,
    "pathological_event_id": 301,
    "care_site": "LRM",
    "pathological_source_ref_id": "9131827",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "X",
    "patient_weight": 68,
    "outlier": "UNU",
    "pathological_event_date": "2023-10-13",
    "etiology": "STO",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "CMP",
    "care_technique_3": "RPH",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 68,
    "intro_symptom_start": 0,
    "intro_symptom_end": 57,
    "intro_symptom_duration": 57,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 59,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 59,
    "event_record_is_complete": false,
    "pathological_severity": 20.4,
    "care_error_level": 13.5,
    "overall_patient_rating": 12.85,
    "patient_id": 223,
    "event_count": 5,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2771,
    "pathological_event_id": 303,
    "care_site": "LRM",
    "pathological_source_ref_id": "5B19E66",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "X",
    "patient_weight": 68,
    "outlier": "UNU",
    "pathological_event_date": "2023-10-20",
    "etiology": "NWI",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "TIP",
    "care_technique_3": "RPH",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 84,
    "intro_symptom_start": 0,
    "intro_symptom_end": 80,
    "intro_symptom_duration": 80,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 80,
    "recovery_duration": 4,
    "recovery_proportion": 0.0476,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 84,
    "event_record_is_complete": true,
    "pathological_severity": 28.9,
    "care_error_level": 14,
    "overall_patient_rating": 15.43,
    "patient_id": 223,
    "event_count": 7,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2772,
    "pathological_event_id": 304,
    "care_site": "LRM",
    "pathological_source_ref_id": "BEFBC1F",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "X",
    "patient_weight": 68,
    "outlier": "UNU",
    "pathological_event_date": "2023-11-02",
    "etiology": "UNK",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "RPH",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 41,
    "intro_symptom_start": 0,
    "intro_symptom_end": 41,
    "intro_symptom_duration": 41,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 10,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 10,
    "event_record_is_complete": false,
    "pathological_severity": 16,
    "care_error_level": 4.5,
    "overall_patient_rating": 6.78,
    "patient_id": 223,
    "event_count": 8,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2773,
    "pathological_event_id": 305,
    "care_site": "LRM",
    "pathological_source_ref_id": "A854B9E",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "X",
    "patient_weight": 68,
    "outlier": "DUB",
    "pathological_event_date": "2023-11-13",
    "etiology": "UNK",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 117,
    "intro_symptom_start": 0,
    "intro_symptom_end": 117,
    "intro_symptom_duration": 117,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 58,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 58,
    "event_record_is_complete": false,
    "pathological_severity": 19.3,
    "care_error_level": 9.5,
    "overall_patient_rating": 10.39,
    "patient_id": 223,
    "event_count": 9,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2774,
    "pathological_event_id": 306,
    "care_site": "LRM",
    "pathological_source_ref_id": "CC70983",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "X",
    "patient_weight": null,
    "outlier": "EAR",
    "pathological_event_date": "2023-11-16",
    "etiology": "UNK",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "TIP",
    "care_technique_3": "RPH",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 251,
    "intro_symptom_start": 0,
    "intro_symptom_end": 251,
    "intro_symptom_duration": 251,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 17,
    "care_error_level": 5,
    "overall_patient_rating": 7.33,
    "patient_id": 223,
    "event_count": 10,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2775,
    "pathological_event_id": 307,
    "care_site": "LRM",
    "pathological_source_ref_id": "CDC2B45",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "X",
    "patient_weight": 68,
    "outlier": "EAR",
    "pathological_event_date": "2023-12-01",
    "etiology": "STO",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "TIP",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 129,
    "intro_symptom_start": 0,
    "intro_symptom_end": 68,
    "intro_symptom_duration": 68,
    "mild_symptom_1": 68,
    "mild_symptom_1_1_end": 121,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 33,
    "mild_symptom_2": 85,
    "mild_symptom_2_duration": 16,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 101,
    "first_prime_symptom_type": "ABD",
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": true,
    "prime_symptom_level": null,
    "prime_symptom_3": 101,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": 1,
    "pathological_event_duration": 101,
    "event_record_is_complete": false,
    "pathological_severity": 34.2,
    "care_error_level": 21,
    "overall_patient_rating": 20.67,
    "patient_id": 223,
    "event_count": 11,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2776,
    "pathological_event_id": 308,
    "care_site": "LRM",
    "pathological_source_ref_id": "1621F40",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "X",
    "patient_weight": 68,
    "outlier": "EAR",
    "pathological_event_date": "2023-12-07",
    "etiology": "UNK",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "UNK",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 72,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 47,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 47,
    "event_record_is_complete": false,
    "pathological_severity": 19.7,
    "care_error_level": 6,
    "overall_patient_rating": 8.6,
    "patient_id": 223,
    "event_count": 12,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2777,
    "pathological_event_id": 309,
    "care_site": "LRM",
    "pathological_source_ref_id": "2731464",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "X",
    "patient_weight": 68,
    "outlier": "EAR",
    "pathological_event_date": "2023-12-13",
    "etiology": "UNK",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "FFT",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 51,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 38,
    "mild_symptom_2_duration": 13,
    "prime_symptom_1": 41,
    "prime_symptom_1_duration": 10,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": 41,
    "first_prime_symptom_type": "DCB",
    "prime_symptom_duration": 10,
    "full_prime_symptom_duration": 10,
    "prime_symptom_any": true,
    "prime_symptom_level": 1,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 47,
    "recovery_duration": 4,
    "recovery_proportion": 0.0784,
    "prime_symptom_proportion": 0.8039,
    "pathological_event_duration": 51,
    "event_record_is_complete": true,
    "pathological_severity": 36.7,
    "care_error_level": 26.3,
    "overall_patient_rating": 24.21,
    "patient_id": 223,
    "event_count": 13,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2778,
    "pathological_event_id": 310,
    "care_site": "LRM",
    "pathological_source_ref_id": "BDA711B",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "X",
    "patient_weight": 68,
    "outlier": "EAR",
    "pathological_event_date": "2023-12-28",
    "etiology": "SIT",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "SIT",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 87,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": 42,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 14,
    "mild_symptom_2": 56,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 56,
    "event_record_is_complete": false,
    "pathological_severity": 22.4,
    "care_error_level": 8.5,
    "overall_patient_rating": 10.69,
    "patient_id": 223,
    "event_count": 14,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2779,
    "pathological_event_id": 311,
    "care_site": "LRM",
    "pathological_source_ref_id": "32E3630",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "X",
    "patient_weight": 68,
    "outlier": "EAR",
    "pathological_event_date": "2023-12-31",
    "etiology": "SIT",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "SIT",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 142,
    "intro_symptom_start": 0,
    "intro_symptom_end": 75,
    "intro_symptom_duration": 75,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 90,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 90,
    "event_record_is_complete": false,
    "pathological_severity": 23.5,
    "care_error_level": 17.5,
    "overall_patient_rating": 15.86,
    "patient_id": 223,
    "event_count": 15,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2780,
    "pathological_event_id": 312,
    "care_site": "LRM",
    "pathological_source_ref_id": "45647D8",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "X",
    "patient_weight": 68,
    "outlier": "EAR",
    "pathological_event_date": "2024-01-03",
    "etiology": "UNK",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "004",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "UNK",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 36,
    "intro_symptom_start": 0,
    "intro_symptom_end": 36,
    "intro_symptom_duration": 36,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 16,
    "care_error_level": 1,
    "overall_patient_rating": 4.89,
    "patient_id": 223,
    "event_count": 16,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2781,
    "pathological_event_id": 313,
    "care_site": "LRM",
    "pathological_source_ref_id": "91A2E97",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "X",
    "patient_weight": 68,
    "outlier": "EAR",
    "pathological_event_date": "2024-01-04",
    "etiology": "SIT",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "SIT",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 110,
    "intro_symptom_start": 0,
    "intro_symptom_end": 110,
    "intro_symptom_duration": 110,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": 22,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 22,
    "event_record_is_complete": false,
    "pathological_severity": 17.7,
    "care_error_level": 2,
    "overall_patient_rating": 5.89,
    "patient_id": 223,
    "event_count": 17,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2782,
    "pathological_event_id": 314,
    "care_site": "LRM",
    "pathological_source_ref_id": "46C28B2",
    "consultant_doctor": "AMT",
    "consultant_doctor_variation": 42,
    "outcome": "NSV",
    "outcome_type": "AMT",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "X",
    "patient_weight": 68,
    "outlier": "EAR",
    "pathological_event_date": "2024-01-07",
    "etiology": "UNK",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "006",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "UNK",
    "care_technique_3": "UNK",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": false,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 91,
    "intro_symptom_start": null,
    "intro_symptom_end": null,
    "intro_symptom_duration": null,
    "mild_symptom_1": null,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": null,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": null,
    "recovery_duration": null,
    "recovery_proportion": null,
    "prime_symptom_proportion": null,
    "pathological_event_duration": null,
    "event_record_is_complete": false,
    "pathological_severity": 17,
    "care_error_level": 1,
    "overall_patient_rating": 5.16,
    "patient_id": 223,
    "event_count": 18,
    "event_title": "",
    "notes": ""
  },
  {
    "id": 2783,
    "pathological_event_id": 315,
    "care_site": "LRM",
    "pathological_source_ref_id": "9B0941C",
    "consultant_doctor": "CRW",
    "consultant_doctor_variation": 36,
    "outcome": "NSV",
    "outcome_type": "PID",
    "source_country": null,
    "commissioner_initials": null,
    "presented_gender": "F",
    "patient_weight": 66,
    "outlier": "CNF",
    "pathological_event_date": "2023-11-12",
    "etiology": "NWI",
    "care_equipment_1": "THO",
    "care_equipment_2": false,
    "care_equipment_3": false,
    "care_equipment_4": "005",
    "care_equipment_5": null,
    "care_equipment_6": null,
    "care_equipment_7": null,
    "care_technique_1": null,
    "care_technique_2": "TIP",
    "care_technique_3": "NWI",
    "care_technique_4": false,
    "care_technique_5": false,
    "care_technique_6": true,
    "care_technique_7": false,
    "observed_movement_response_1": false,
    "observed_movement_response_2": false,
    "event_record_length": 141,
    "intro_symptom_start": 0,
    "intro_symptom_end": 129,
    "intro_symptom_duration": 129,
    "mild_symptom_1": 129,
    "mild_symptom_1_1_end": null,
    "mild_symptom_1_2": null,
    "mild_symptom_1_duration": 12,
    "mild_symptom_2": null,
    "mild_symptom_2_duration": null,
    "prime_symptom_1": null,
    "prime_symptom_1_duration": null,
    "prime_symptom_2": null,
    "prime_symptom_2_duration": null,
    "first_prime_symptom": null,
    "first_prime_symptom_type": null,
    "prime_symptom_duration": null,
    "full_prime_symptom_duration": null,
    "prime_symptom_any": false,
    "prime_symptom_level": null,
    "prime_symptom_3": null,
    "prime_symptom_3_duration": null,
    "fatal_symptom_1": null,
    "fatal_symptom_2": null,
    "slight_death_response_1": null,
    "death_response_1": null,
    "slight_death_response_2": null,
    "death_response_2": null,
    "time_of_death": null,
    "pathogenesis_duration": 129,
    "recovery_duration": 12,
    "recovery_proportion": 0.0851,
    "prime_symptom_proportion": null,
    "pathological_event_duration": 141,
    "event_record_is_complete": true,
    "pathological_severity": 39.4,
    "care_error_level": 58.5,
    "overall_patient_rating": 42.39,
    "patient_id": 82,
    "event_count": 2,
    "event_title": "NT05",
    "notes": ""
  }
]

export default RealFullData