const InternetUsageData=[
[203,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,2.7,-1,-1,4.5,15.4,17.1,18.8,20.8,23,25.4,28,30.9,52,58,62,69,74,78.9,83.7,88.6,93.5,97.1,-1,-1,-1,-1,-1],
[4,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,-1,-1,-1,-1,0,0,0,0.1,1.2,2.1,1.9,1.8,3.5,4,5,5.4,5.9,7,8.2,11,13.5,16.8,17.6,18.4,-1,-1],
[6,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0.1,0.1,0.2,0.3,0.4,1.1,1.5,1.7,1.9,2.3,2.8,4.7,7.7,13,21.4,22,23.2,26,29,32.1,32.5,32.6,-1],
[189,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0.1,0.3,0.3,0.9,2.4,6.0,9.6,15.0,23.8,41.2,45,47,49.4,51.8,54.3,56.9,59.6,62.4,65.4,68.5,72.2,79.3,82.6],
[190,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,1.5,3.0,6.8,7.6,10.5,-1,11.2,13.5,26.8,37.6,48.9,70.8,70,78.5,81,81,82.7,84.4,86.1,87.9,89.7,91.5,-1,90.7,93.2,93.8,-1],
[177,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0.1,0.3,3.2,6.9,14.9,23.6,26.2,28.3,29.4,30.1,40,52,61,63,64,68,78,84.9,88,90.4,90.5,90.6,94.8,98.4,99.1,100,100,100],
[8,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0.1,0.2,0.8,3.2,7.0,9.7,10.8,11.9,16.0,17.7,20.9,25.9,28.1,34,45,51,55.8,59.9,64.7,68.0,70.9,74.2,77.7,79.9,85.5,87.1,88.3],
[9,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0.1,0.1,0.9,1.3,1.6,1.9,4.5,4.8,5.2,5.6,6.0,6.2,15.3,25,32,37.5,41.9,54.6,59.1,64.3,64.7,68.2,66.5,76.5,78.6,-1],
[142,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1],
[7,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,2.2,2.8,3.4,4.0,5.3,6.4,8.8,12.5,17.2,24.2,27,30,34,38,42,47,52,58,63.4,67.7,70,73,-1,-1,95.7,95.6,95.6,-1],
[10,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0.5,1.0,1.7,1.9,2.2,2.7,3.2,16.3,30.8,40.7,46.7,52.6,-1,-1,-1,63,66,69.4,71.6,74.2,76,79.4,79,83.4,84,84.5,86.5,86.5,90,93.6,96.3,96.2,-1],
[11,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0.1,0.2,0.6,0.7,1.3,1.8,6.9,9.5,15.4,23.0,33.7,39.1,36.5,42.7,54.2,58,63.6,69.3,72.8,73.4,75.1,78.7,80,80.6,80.9,83.9,84.3,87.9,87.4,87.7,87.5,92.5,93.6],
[12,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0.1,0.3,4.9,-1,-1,8.0,11.9,14.5,17.0,27.4,46,50,54.2,73.0,75.0,77,78.2,79,79.7,81.0,84.5,86,-1],
[26,0,-1,-1,-1,-1,0,-1,-1,-1,-1,0,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0.1,0.1,0.2,0.3,0.5,0.6,0.7,0.8,0.9,1,1.1,1.2,1.2,1.4,2,2.2,2.6,2.7,2.7,3.7,5.8,-1],
[192,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0.1,0.1,0.6,0.9,2.9,4.9,7.8,13.7,29.4,31.2,46.3,49.9,53.8,55.8,61.9,66.7,68.8,74.5,77.6,81.6,80.7,82.1,85,85.0,86.5,87.6,88.6,90.2,91.5,92.7,94.0],
[17,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0.1,0.2,0.3,0.7,0.9,1.1,1.2,1.5,1.7,1.8,2.2,3.1,4.1,4.5,4.9,6,11.2,14.5,18.7,19,22.4,28.2,33.9,-1],
[25,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0,0.1,0.2,0.3,0.4,0.4,0.6,0.7,0.9,1.1,2.4,3,3.7,5,6,6.5,7.1,7.7,8.5,9.2,13.9,21.5,-1],
[191,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,0,0,0,0,0.1,0.1,0.1,0.1,0.2,1,1.8,2.5,3.1,3.7,4.5,5,6.6,11.9,12.9,18.1,19.8,23.4,27.7,32.8,38.9,-1],
[24,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0.1,0.7,1.2,1.8,2.9,5.3,7.6,9.0,12.0,18.1,19.9,27.0,33.6,39.6,45,46.2,47.9,51.8,53.0,55.4,56.6,59.8,63.4,64.7,67.9,70.1,75.2,79.1],
[200,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0.3,0.8,1.6,3.2,4.7,6.1,15.0,18.0,21.5,21.4,21.3,28.2,32.9,51.9,53,55,76.9,88,90,90.5,93.4,97.9,95.8,98.6,99.7,99.6,100,-1],
[13,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0.9,1.7,1.3,2.3,3.7,8,11.8,18,20,22,25,26,27,31.5,33.8,43,65,71.7,72,76.9,78,80,80.6,83,90.6,92.4,94.2,-1],
[20,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0.1,0.1,1.0,1.2,2.6,3.9,15.4,21.3,25.1,27.9,34.6,37.7,42.7,43.8,45.0,48.5,49.9,52.6,60.2,64.8,70.1,69.9,73.2,75.6,78.8],
[15,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0.4,1.8,4.3,8.9,-1,-1,-1,16.2,19.7,23,27.4,31.8,39.6,46.9,54.1,59.0,62.2,71.1,74.4,79.1,82.7,85.0,86.8,89.5],
[16,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0.8,1.2,2.0,4.0,5.9,-1,5.6,-1,9.8,17,24,24.6,26.3,27.2,28.2,30.7,31,33.6,38.7,41.5,44.5,47.0,49.8,57.1,59.5,61.9,-1],
[205,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,6.8,16.2,24.1,32.1,39.9,42.9,47.5,52.0,56.5,60.9,65.4,69.8,74.3,82.3,83.2,84.2,88.3,91.2,95.3,96.8,98.3,98,98.3,-1,-1,-1,-1,-1],
[19,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0.1,0.4,0.6,0.9,1.4,2.1,3.1,3.5,4.4,5.2,6.2,10.4,12.5,16.8,22.4,30,35.3,36.9,34.6,37.4,39.6,43.8,44.2,47.4,59.9,65.9,-1],
[22,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0.1,0.4,0.7,1.4,2.0,2.8,4.5,9.1,13.2,19.0,21.0,28.1,30.8,33.8,39.2,40.6,45.6,48.5,51.0,54.5,58.3,60.8,67.4,70.4,73.9,81.3,80.6,80.5],
[14,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0.3,0.7,1.9,2.3,3.9,11.9,27.8,39.6,49.8,52.5,55.3,58.2,61.4,64.7,65.1,66.5,71.2,71.8,75.1,76.1,79.5,81.7,82.4,83.2,82.4,85.8,-1],
[23,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,1.0,3.3,4.8,6.2,7.6,8.9,12.9,15.3,19.5,29.7,36.4,42.1,44.6,46,49,53,56,60.2,64.5,68.7,71.2,90,94.8,95,95,96.0,98.0,-1],
[18,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,-1,-1,0.1,0.4,0.8,1.6,2.4,3.1,3.8,4.5,5.9,6.5,7.1,13.6,14.4,15.6,22.4,30.3,39.8,46.5,54.3,63.5,74.1,79.8,85.6,-1],
[21,0,-1,-1,-1,-1,0,-1,-1,-1,-1,0,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0.1,0.3,0.6,1.1,2.9,3.4,3.3,3.3,3.3,3.2,4.2,5.2,6.2,6.1,6,9,16,30,36.7,37.3,39.3,41.4,58,64.8,72.7,73.5,-1],
[31,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0,0,0.1,0.1,0.2,0.2,0.3,0.3,1,1.8,2,2.2,2.4,2.5,2.7,2.9,3.1,4,5.1,7.5,8.5,10.5,-1],
[29,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0.3,0.5,0.9,1.1,2.3,4.1,6.7,15.0,24.8,36.1,51.3,60.2,61.5,64.2,65.9,71.6,72.4,73.2,76.7,80.3,80.3,83,83,85.8,87.1,90,91.1,92.7,94.6,91.9,92.3,92.8,-1],
[162,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0.5,1.1,1.7,2.1,2.7,3.5,4.5,15.1,24.8,34,47.1,55.1,61.4,65.1,67.8,70.1,75.7,77.2,79.2,81.3,83.9,85.1,85.2,86.3,87.4,87.4,89.1,89.6,91.8,93.1,94.3,95.5,-1],
[206,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1],
[33,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0.1,0.3,0.6,1.0,1.6,4.1,16.6,19.1,22.1,25.4,28.1,31.1,34.4,35.9,37.3,41.5,45,52.2,55.0,58,61.1,76.6,83.5,82.3,84.9,85.0,86.3,90.1,-1],
[34,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0.1,0.7,1.7,2.6,4.5,6.2,7.3,8.5,10.5,16,22.6,28.9,34.3,38.3,42.3,45.8,47.9,50.3,53.2,54.3,59.2,64.0,70,73.0,75.6],
[39,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0.1,0.2,0.3,0.4,0.7,0.8,1.0,1.5,1.8,1.9,2,2.7,2.9,4.4,6.6,10,16.7,41.2,43.8,37.5,36.2,41.3,45.4,35.4],
[28,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,0,0,0.1,0.2,0.2,0.3,0.5,0.9,1.4,2.0,2.9,3.4,3.8,4.3,5,7.5,10,16.2,18.3,20.6,23.2,29.7,36.5,45.5,45.6,-1],
[44,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0,0,0,0.1,0.1,0.2,0.2,0.3,0.4,0.5,0.7,1.2,1.6,2.2,3,3.7,6.2,8.6,11.7,16.4,22.8,22.9,-1],
[37,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0,0,0.1,0.4,1.0,1.4,2.0,2.7,4.2,4.5,5,5.6,6.1,6.6,7.1,7.6,8.1,8.6,-1,-1,-1,-1,-1],
[35,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0.1,0.1,0.3,0.5,1.1,1.6,2.2,2.8,4.6,7.3,9.1,11.0,15.3,21.8,25.6,30,36.5,40.3,48.9,51.7,52.5,55.9,58.1,62.2,64.1,65.0,69.7,73.0,72.7],
[36,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,0,0,0.1,0.2,0.4,0.5,0.8,1.3,2,2.2,2.5,3,3.5,5.1,5.5,5.9,6.5,6.9,7.4,7.9,8.4,-1,16.3,21.1,27.3,-1],
[30,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,0.2,0.4,1.1,1.8,2.6,3.5,4.3,5.3,6.0,6.8,8.2,14,21,30,32,34.7,37.5,40.2,42.6,50.3,57.1,59.5,61.9,63.1,69.7,-1],
[38,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0.2,0.4,0.8,1.6,2.6,3.9,5.8,9.5,19.8,20.3,20.7,22.0,25.1,28.4,32.2,34.3,36.5,39.2,47.5,45.9,53,59.7,65.8,71.5,73.4,81.2,80.5,82.7,82.5],
[41,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0.2,0.3,0.5,1.0,3.7,5.2,8.4,9.7,11.1,11.6,12.9,14.3,15.9,16.0,21.2,27.9,29.0,37.3,42.9,57.1,62.6,67.9,71.1,71.1,-1],
[207,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,61.8,68.1,-1,-1,-1,-1,-1],
[208,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,3.9,-1,-1,-1,-1,-1,-1,-1,-1,-1,38.0,44.5,52,61,64.5,66,69.4,69.7,71.4,74.1,77,79,81.0,-1,-1,-1,-1,-1],
[42,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0.1,0.4,0.6,4.3,8.8,11.3,15.2,18.8,28.3,30,33.8,32.8,35.8,40.7,42.3,49.8,52.9,56.8,60.6,65.4,69.3,71.7,75.9,80.7,84.4,86.0,90.8,90.7,89.6],
[43,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0.5,1.2,1.4,1.9,2.9,3.8,6.8,9.7,14.6,23.9,34.3,35.5,35.2,47.9,51.9,62.9,64.4,68.8,70.4,73.4,74.1,74.2,75.6,76.4,78.7,80.6,80.8,81.3,82.6,84.5],
[64,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0.1,0.2,0.4,0.4,0.9,1.8,3.0,6.7,9.8,20.8,30.2,31.6,48.8,55.9,64.7,68.7,72.1,75.1,78,79,82,81.2,82.3,84.1,86.1,87.5,84.1,84.3,87.0,88.1,89.8,91.4,91.6],
[193,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0.1,0.1,0.3,0.4,0.6,0.7,0.9,1.2,1.6,2.2,4,6.5,7,9.4,12.7,17,22.9,30.8,55.6,58,59.3,61.2,68.8,-1],
[46,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0.5,1.1,-1,2.9,2.9,8.8,13.2,18.4,23.6,30.3,38.5,39.3,40.2,41.1,42.0,47.4,48.6,49.8,51,57.5,65,67.0,69.6,-1,73.3,77.0,80.9,-1],
[45,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0.1,0.3,0.5,1.3,3.8,5.7,11.3,22.6,30.5,39.1,42.9,64.2,76.2,80.9,82.7,86.6,85.0,85.0,86.8,88.7,89.8,92.2,94.6,95.9,96.3,96.9,97.0,97.3,98.0,96.5,98.8,97.8],
[47,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0.1,0.2,1.1,3.7,4.4,6.8,7.8,8.8,11.4,14.8,17.6,20.8,27.7,31.4,38,42.3,45.9,49.5,54.2,63.8,67.5,74.8,79.7,82.1,85.2,-1],
[5,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0.1,0.4,0.6,1.5,2.1,4.6,5.8,7.3,9.4,10.1,11.2,12.5,14.9,18.2,22.5,29.5,38.2,42.9,47.6,49.0,58.9,63.9,70.7,-1],
[49,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0.1,0.1,0.8,1.4,2.6,4.2,4.4,4.8,5.9,7.2,10.8,18.8,24.6,29.0,31.3,35.1,40.2,45.5,48.9,54.0,55.8,57.5,59.2,70.7,76.2,69.7],
[50,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0.1,0.2,0.6,0.8,2.7,4.0,11.9,12.7,13.6,16.0,18.0,20,21.6,25.6,26.4,29.4,33.8,37.8,41.2,44.9,46.9,57.2,71.9,72.0,72.1],
[53,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0.1,0.1,0.2,-1,-1,-1,-1,0.4,0.4,0.5,0.6,0.7,0.8,0.9,0.9,1.0,1.1,1.3,-1,8.8,14.1,21.7,-1],
[157,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0.1,0.2,0.3,1.3,2.8,4.3,7.0,13.6,18.1,20.3,39.9,44.0,47.8,50.3,55.1,59.6,62.4,65.8,67.0,69.8,71.6,76.1,78.6,80.5,84.6,86.1,90.7,93.2,93.8,94.4],
[54,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0.3,1.1,2.7,3.5,5.7,10.7,14.5,28.5,31.5,41.5,45.3,53.2,61.4,63.5,66.1,70.5,72.5,74.1,76.5,78.3,80,84.2,88.4,87.2,88.1,89.3,90.2,89.0,90.9,91.0],
[56,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0,0,0,0.1,0.1,0.2,0.3,0.3,0.4,0.5,0.7,1.1,2.9,4.6,7.7,13.8,15.3,15.6,15.9,16.1,16.4,16.6,-1],
[58,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0.4,1.3,1.8,2.5,4.9,13.9,16.7,19.4,25.4,32.2,37.2,43.1,62.4,69.2,72.3,74.4,79.6,80.7,83.6,82.4,86.8,88.7,89.8,91.5,86.5,86.4,87.7,87.4,88.8,89.6,92.1,92.8,92.9],
[57,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0.2,0.6,0.9,1.4,1.8,6.1,6.7,7.4,8.4,9.6,10.8,13,17,20,28,33.7,35.2,37.4,42.5,49.4,65.7,68.9,77.1,84.8,87.6,-1],
[59,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0.1,0.2,0.5,0.8,1.6,2.5,4.2,6.3,9.1,14.3,26.3,30.1,36.1,39.1,42.8,46.8,66.0,70.6,71.5,77.2,77.8,81.4,81.9,83.7,78.0,79.2,80.5,82.0,83.3,84.7,86.0,85.3],
[211,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,2.3,4.6,11.3,22.2,32.9,43.2,53.2,58.9,66.5,67.9,69.3,75.9,75.5,75.1,75.2,80.7,85.3,90,93.3,94.2,95.1,97.5,-1,-1,-1,-1,-1],
[108,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0.2,0.5,1.8,2.7,3.7,4.6,5.5,9.2,11.0,11.8,12.7,13.6,14.4,15.3,20,22.8,25.9,27.8,29.6,31.5,33.3,35.3,-1,39.8,40.1,40.4,-1],
[61,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0.1,0.2,1.2,1.3,1.9,2.6,2.9,4.8,5.4,5.7,7.5,9.5,13,18,24,30.5,38.0,45.7,48.0,50.3,59.6,68.6,70.7,71.7,-1],
[178,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0.1,0.2,0.5,1.0,1.8,4.1,7.3,13.6,21.2,26.8,33.4,56.4,64.8,65.6,70,68.8,75.0,78.3,83.5,85,85.3,87.4,89.8,91.6,92.0,94.7,90.4,90.6,92.5,94.8,96.6,-1],
[63,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0.1,0.4,0.4,0.9,1.5,2.5,3.8,6.0,7.5,8.2,10,20,26.9,31.5,36.9,43.3,44,47.5,58.4,59.7,62.7,68.8,72.5,76.4,78.7],
[65,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0.1,0.1,0.2,0.8,1.1,1.7,1.8,2.7,3.8,4.2,5.4,7.8,9,10.6,15,19,23,28,37.8,43,45.0,56.6,68.2,-1],
[212,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,2.6,4.1,5.9,19.1,21.1,23.6,28.3,32.8,39.0,45.2,51.6,58,65.0,65,65,65.0,-1,-1,-1,94.4,-1,-1,-1,-1,-1,-1],
[70,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0,0.1,0.4,0.4,0.5,0.5,0.6,0.7,0.9,0.9,1,2,3.1,4.5,6.4,9.2,13.1,18.7,21.8,23.3,28.8,34.6,-1],
[62,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0.2,0.7,0.9,1.3,1.7,2.4,3.3,3.7,5.2,6.2,6.8,7.6,9.2,10.8,12.4,14,15.5,17.6,19.9,22.5,25.5,28.8,31.6,32.9,-1],
[71,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,0,0,0.1,0.2,0.2,1.0,1.3,1.8,1.9,2.0,2.2,2.3,2.3,2.4,2.6,2.8,3.1,3.3,6.1,13.8,14.8,15.7,22.9,27.9,35.1,-1],
[52,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,0,0,0,0.1,0.1,0.3,0.5,0.8,1.1,1.2,1.5,1.8,2.1,6,11.5,13.9,16.4,18.8,21.3,23.7,26.2,-1,49.1,53.8,53.9,-1],
[66,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0.1,0.3,0.7,1.3,1.8,3.2,6.8,9.1,10.9,14.6,17.8,21.4,24,32.2,35.8,38.2,42.4,44.4,51.6,55.0,59.8,63.2,66.8,69.0,69.8,72.2,75.6,78.1,78.4,83.1],
[68,0,-1,-1,-1,-1,0,-1,-1,-1,-1,0,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0.2,0.9,1.4,2.4,4.0,5.1,14.7,18.6,19.5,20.4,21.3,22.2,23.1,24.0,27,30,32,35,51.6,52.5,53.3,54.2,61.4,69.6,74.7,77.7,-1],
[67,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,1.7,7.9,14.6,21.6,31.7,35.4,44.1,54.5,56.0,57.7,59.3,61.0,62.8,62.8,63,64,64.8,65.8,66.7,67.6,68.5,69.4,-1,-1,-1,-1,-1],
[69,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0.4,0.5,0.7,1.7,3.3,4.5,5.1,5.7,6.5,7.3,8.3,9.3,10.5,12.3,16,19.7,23.4,28.8,34.5,37.9,41.5,44.4,47.5,50.8,-1],
[2,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0.3,0.6,1.3,2.3,4.5,8.4,16.1,25.3,31.1,33.7,36.1,38.5,43.8,46.1,48.4,50.6,54.0,57.7,61.5,65.4,69.2,73.1,77.0,80.5,-1,-1,-1,-1,-1],
[72,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0.1,0.2,0.4,0.6,1,1.5,2.4,3.7,5.7,8.9,13.8,18.2,23.9,29.9,30,30.5,31,32,34,35.6,37.3,-1,78.6,81.8,84.7,-1],
[213,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0.1,0.8,1.3,2.7,3.2,4.7,10.5,14.5,21.2,27.8,38.6,43.0,52.2,56.3,56.9,60.8,64.8,66.7,69.4,72,72.2,72.9,74.2,79.8,84.9,87.4,89.4,90.5,91.7,92.4,93.0,95.6],
[74,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0.1,0.3,0.5,1.2,1.4,2.5,4.8,5.6,6.5,7.8,9.4,9.6,9.8,11.0,15.9,18.1,17.8,23,27.1,29.5,31.7,36.0,39.3,43.4,48.0,-1],
[40,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0.2,0.5,0.8,1.7,3.2,4.4,6.6,11.5,17.7,22.7,30.9,33.1,37.9,41.4,44.2,50.5,56.5,57.7,61.9,66.7,68.5,69.8,72.6,67.0,75.2,79.0,78.3,81.2,82.0],
[73,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,-1,0,0,0.2,0.3,0.8,1.6,5.4,6.3,6.7,7.2,7.6,8.1,8.3,9,9.8,10.6,11.4,14.2,22.8,27.2,32.4,32.4,36.3,38.9,-1],
[75,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0.1,0.4,0.6,0.9,1.9,3.8,5.8,6.9,14.5,16.6,21.6,27.7,38.9,47.0,53.3,61,62,65,68.0,70.5,72.6,75.6,72.8,79.2,76.7,76.0,80.3,84.7,88.6,90.4],
[78,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0.1,0.2,0.4,0.9,2.0,2.1,2.3,2.6,3.6,4.7,5.7,7.9,6.9,10.9,12.2,14.5,14.9,17.1,22.0,25.4,32.3,39.9,47.6,53.7,62.1,66.4],
[214,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1],
[77,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0.1,0.2,0.5,0.6,1.5,1.6,1.9,2.3,2.8,3.9,4.3,5.1,7.5,10,11.1,12.3,13.5,14.9,16.5,18.2,20,29.5,43.4,46.3,-1],
[81,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0.1,0.2,0.5,1.1,2.1,4.0,8.0,10.9,17.8,23.1,25.8,34.3,36.9,41.6,54.8,61.1,65.3,67.3,69.8,74.8,76.9,78.2,83.4,83.4,83.5,84.1,87.0,87.0,91.9,95.1,-1],
[79,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0.3,0.9,1.4,4.6,6.9,7.4,8.1,8.7,9.4,12.0,13.8,15.9,19,22.7,29.9,39.3,45.3,53.2,64.0,70.2,72.4,75.5,78.5,-1],
[80,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,-1,-1,-1,-1,0.1,0.5,0.6,0.9,0.9,0.9,0.9,1,1.0,2.5,5,7.1,9.2,13.2,15.2,19.9,26,33.9,44.3,45.7,48.9,78.7],
[76,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0.5,1.5,2.6,6.7,11.2,14.8,27.4,36.2,41.2,44.4,49.3,79.1,83.1,83.8,87,89.5,90.6,91,93,93.3,94.8,96.2,96.5,98.1,98.2,98.2,98.2,99.0,99.5,99.5,99.6,-1],
[82,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0.1,0.2,0.3,0.3,0.5,0.9,2.1,4.3,10.3,13.4,20.8,17.3,17.7,19.5,22.7,25.1,27.8,48.1,59.3,63.1,67.5,68.8,70.8,70.2,75.0,77.3,79.6,81.5,83.7,86.7,90.1,90.2,-1],
[83,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0.1,0.1,0.5,1.0,2.2,4.5,14.3,23.1,27.2,28.0,29.0,33.2,35,37.9,40.7,44.5,48.8,53.6,54.3,55.8,58.4,55.6,58.1,61.3,63.0,74.3,67.8,70.4,74.8,85.0],
[84,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0.1,0.5,0.7,1.9,2.3,3.1,3.8,6.1,7.8,10,12.8,16.4,21.1,23.6,24.3,27.6,37.4,33.7,37.1,40.4,42.2,44.3,55.0,68.2,70.8,79.4,82.3,-1],
[86,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0.5,1.3,2.5,2.6,4.7,6.0,8.4,11.6,12.9,13.8,20,23,26,27.2,34.9,37,41.4,46.2,54.2,56.1,64.5,65.2,70,75.4,85.9,-1],
[85,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0.4,0.7,1.5,4.3,9.1,13.4,21.3,29.9,38.5,46.5,48.4,62.3,66.9,68.6,74.3,75.4,78,78.2,79.0,79.4,88.2,89.1,91.0,93.1,91.7,88.7,92.7,90.2,82.9,-1],
[87,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0.1,0.4,0.6,1.0,1.6,2.0,2.6,2.9,3.2,4.0,11,18.2,31.6,50.6,61.9,63.3,66,70.8,74.5,76.4,78.9,81.8,85.9,90.9,92.2],
[88,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0.1,0.3,0.6,1.2,2.9,3.0,3.1,3.6,4.4,5.2,6.1,7.2,8.8,10.5,13,16.5,16.5,16.6,17.8,19.5,22.6,25.5,28.7,-1],
[91,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,-1,0,0.2,1.0,3.0,2.9,3.9,5.0,10.5,12.3,14.0,15.7,16,16.3,17.5,19.8,23,28.3,30.2,40.5,50.8,57.1,64.1,72,77.9,-1],
[27,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,0,0,0,0,0,0.2,0.2,0.3,0.3,0.4,0.4,0.5,0.5,1.2,3.1,4.9,6,14,18,32.3,32.9,-1,52.3,53.6,60.1,-1],
[89,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,-1,0.6,1.2,1.7,2.3,2.5,3,3.5,4,4.5,6,7,8.9,9.0,10,10.7,11.5,12.2,14.9,19.4,25.3,32.9,42.9,45.1,53.6,-1],
[139,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,1.9,2.2,3.3,4.4,5.8,7.7,21.1,22.9,24.7,34,38.5,43.5,49.2,55.7,63,63.2,64,64.6,68,75.7,76.8,80.7,-1,79.7,80.3,79.4,-1],
[155,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0.2,0.3,0.8,1.6,3.6,6.7,23.5,44.7,56.6,59.4,65.5,72.7,73.5,78.1,78.8,81,81.6,83.7,83.7,84.0,84.7,87.5,89.8,92.8,95.0,96.0,96.1,96.5,97.5,97.1],
[201,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0.1,0.2,0.8,2.1,3.0,4.7,6.7,8.5,10.2,22.4,22.9,25.9,28.7,34.8,42,50.8,61.4,65.7,70.4,75.4,78.7,82,85.6,97.9,99.5,99.5,99.1,99.7,-1],
[92,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,-1,0,0,0.1,0.1,0.2,0.3,0.3,0.8,1.1,1.6,3.5,6,7,9,10.7,12.5,14.2,18.2,21.8,25.5,36.3,47.0,54,62,-1],
[94,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0.1,1.2,2.7,5.3,7.9,6.7,7,8,9,10.1,15,18.7,22.5,30.1,43.6,52,61.2,70.5,73,74,76.1,78.1,80.9,79.7,83.1,86.5,-1],
[95,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,0,0,0,0,0,0,0,0,-1,-1,0.5,0.5,2,2.3,2.5,2.6,3.2,5.4,10,15.7,16.3,18.9,23.4,27.9,33.6,-1],
[96,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,-1,-1,0.1,0.1,0.3,2.2,2.8,3.5,3.9,4.3,4.7,9,10.8,14,14,15.2,16.5,17.7,-1,-1,-1,-1,-1,-1,-1,-1],
[140,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0.3,0.6,0.9,1.3,1.9,5.0,8.1,14.6,20.9,21.3,21.5,24.5,27.9,30,31,32.5,34,45.8,49.4,53.2,57.3,61.7,66.4,71.5,76.9,77.5,78.0,-1],
[194,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,-1,-1,-1,36.5,45.1,59.4,58.8,64.0,63.3,64.2,65.0,70,75,80,85,89.4,93.8,95.2,96.6,98.0,99.5,-1,93.1,94.3,95.5,-1],
[158,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0.1,0.2,0.3,0.6,0.7,1.0,1.4,1.4,1.7,2.5,3.8,-1,-1,-1,-1,-1,-1,10.5,15.2,22.1,32.2,46.9,50.6,36.1,44.4,-1],
[195,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0.2,0.2,1.0,1.5,2.1,2.5,2.9,3.4,3.5,3.7,3.8,7,10,15,22,25,32.4,39,40.8,42.3,42.8,47.9,-1],
[97,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0.2,0.9,1.9,2.9,6.4,7.1,17.6,25.9,31.2,36.2,43.9,49.9,55.2,59.7,62.1,63.6,67.2,68.4,72.1,71.3,74.3,77.6,79.7,81.5,83.0,86.9,87.7],
[98,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0.1,0.3,0.4,1.5,5.5,7.1,11.7,17.3,22.8,36.1,39.8,54.5,65.8,70,72.5,78.9,82.2,87.3,90.6,90,91.9,93.7,94.6,96.3,98.1,97.3,97.0,97.1,98.4,98.6,98.2],
[93,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0.8,2.0,3.3,4.3,6.3,7.2,21.9,26.9,38.5,46,53.6,59.1,63.4,66.8,68.4,69.7,73.1,75.2,75.8,79.2,79.8,80.1,83.5,86.1,88.8,91.1,91.0],
[215,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0.2,0.7,2.3,7.0,9.2,13.6,22.5,25.1,25.7,31.4,34.8,46.4,47.3,49.2,54,55.1,60.2,61.3,65.8,69.7,77.6,81.6,83.1,83.7,86.4,88.3,88.4,-1],
[60,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1],
[112,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0.1,0.1,0.6,1.3,2.3,3.3,11.6,15.0,19.7,21.5,33.1,41.3,52,46.1,55.4,56,56.8,57.0,58.2,61.7,64.8,84.1,84.1,88.1,-1],
[196,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,-1,-1,-1,42.1,46.6,48.0,49.4,52.4,55.4,61.4,64.3,67.2,70.1,75,80.3,87,90.7,92.4,93.3,95.2,97.0,-1,83.3,84.7,86.0,-1],
[109,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0.2,0.6,1.2,1.4,3.7,7.4,10.6,14.6,19.6,20.4,23.3,27.5,29.6,32.0,34.6,37.4,40.5,43.1,46.7,49.9,50.3,57.7,59.1,61.2,-1],
[99,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0.1,0.1,0.2,0.3,0.4,0.5,0.5,0.6,0.6,1.6,1.6,1.7,1.9,2.3,3,3.7,4.1,4.7,6.4,8.7,11.8,13.7,19.7,-1],
[102,0,-1,-1,-1,-1,0,-1,-1,-1,-1,0,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0.2,0.3,0.5,1.1,2.2,3.6,5.3,5.9,6.5,6.8,11.0,16.3,23.2,24.8,26.5,34,38.9,44.1,49.2,54.4,59.5,65,70.9,77.4,77.5,85.7,-1],
[107,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0.1,0.2,0.6,1.2,1.8,5.0,7.0,11.9,12.9,14.1,17.2,19.5,20.8,21.7,26.3,31.0,37.1,39.7,43.4,44.3,57.4,59.5,53.0,56.6,69.6,71.4,75.6,-1],
[209,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,-1,-1,0.9,1.5,1.7,2.3,2.5,3.5,3.8,3.7,3.9,4.6,5.6,7,10,12.5,14,16.8,19.2,29.7,38.7,-1,-1,-1,-1,-1],
[125,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0.5,1.0,1.4,2.4,3.4,17.3,19.0,24.4,26.4,28.6,36.3,46.0,51.7,51.9,56.7,57.4,65.2,68.0,70.3,72.1,74.5,79.1,81.4,81.4,83.0,-1],
[103,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0.1,0.1,0.2,0.3,0.4,0.5,0.7,0.8,1.5,1.8,2,2.2,2.8,3.5,7,10.3,14,18.9,21.4,22.3,27.8,34.4,-1],
[104,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0.2,1.0,3.9,6.5,7.7,13.1,17.8,28.9,31.6,34.6,41.2,40.4,46.9,50,58.8,63,68.0,68.1,68.9,73.1,75.9,78.0,81.0,81.6,85.7,86.8,87.4,91.5],
[114,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,-1,-1,0,-1,0,0,0,0,0,0.1,0.2,0.2,0.2,0.2,0.9,1.4,1.8,7.4,10.9,16,23.6,28.7,30.6,41.9,44.0,-1],
[111,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,25.3,27.1,28.9,30.8,32.9,35.1,37.5,35.6,56.8,60.3,61,68.1,69.8,71.2,71.5,73.4,77.6,82.2,88.2],
[110,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0.1,0.1,0.5,1.2,1.6,2.0,-1,-1,-1,-1,9,9.8,10,10.2,12.4,16.4,17.6,19.9,22.5,22.2,23.7,47.1,51.0,65.6,81.6,-1],
[217,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1],
[113,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0.1,0.1,0.2,0.4,0.6,0.8,0.8,0.9,1.5,2.6,4.1,4.6,5,5.5,6,6.5,7,7.7,10.9,14.6,16.3,17.3,-1],
[105,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,0,0,0.1,0.1,0.2,0.3,0.4,0.4,0.6,0.9,1.4,1.8,2.2,4,4.5,5,6.2,11.8,17.6,24.1,24.2,35.1,42.3,53.5,58.7,-1],
[106,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0.1,0.4,2.5,4.6,7.2,8.7,10.2,12.1,13.6,15.1,16.7,20.2,21.8,22.5,28.3,34.9,35.4,40.1,44.8,50.1,52.1,55.4,58.5,61.7,64.8,67.5,-1],
[100,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,0,0,0,0.1,0.1,0.2,0.2,0.3,0.3,0.4,0.9,0.7,1.0,2.2,3.3,4.3,5.0,5.8,6,6.3,7,8,13.4,18.9,24.4,-1],
[101,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0.1,0.8,2.3,6.7,12.3,21.3,26.6,32.3,34.9,42.2,48.6,51.6,55.7,55.8,55.9,56.3,61,65.8,57.0,63.6,71.0,78.7,80.1,81.2,84.1,89.5,96.7,97.3],
[115,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0.2,0.3,1.6,2.4,2.6,3.3,3.8,4.0,4.3,4.8,5.3,6.5,11.6,12,12.9,13.9,14.8,25.6,31.0,36.8,40,44.8,48.7,52.9,-1],
[119,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0.2,0.9,1.9,5.6,13.9,18.2,22.3,26.4,30.2,32.3,33.5,35.0,34.5,33.9,42,50,58,66,70,74.0,-1,82.0,-1,-1,-1,-1,-1],
[122,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0,0.1,0.1,0.1,0.1,0.2,0.2,0.3,0.7,0.7,0.8,0.9,1.0,1.1,1.2,2.4,4.3,10.2,-1,16.6,21.4,22.3,-1],
[123,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0,0,0.3,0.5,1.2,3.5,5.5,6.7,8,9.3,11.5,13.8,16.1,19.1,21,24.5,28.5,33.2,38.7,45.0,51.4,55.3,-1],
[121,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0.2,0.3,0.4,0.9,1.4,1.7,1.8,2.3,2.5,2.8,3.9,5.3,7.3,10,10.6,13.5,15.5,17.6,19.7,24.5,30.4,37.6,44.9,53.4,57.1,-1],
[118,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0.3,0.5,1.3,1.9,3.2,6.4,9.6,14.0,22.2,39.1,43.9,49.3,61.2,64.3,68.5,81,83.7,85.8,87.4,89.6,90.7,91.4,92.8,93.9,91.6,91.7,90.4,93.1,91.8,93.2,91.3,92.0,92.5],
[197,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0.7,1.4,2.2,2.7,4.1,6.4,18.2,20.4,22.5,40,52,64,72.8,78.1,77.6,81.9,82.5,86.9,90.5,92.0,93.3,93.4,94.6,95.0,96.3,96.8,97.2,96.3,96.4,98.0,94.6,99.0,98.9],
[117,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0.1,0.2,0.2,0.3,0.3,0.4,0.8,1.1,1.4,1.7,1.9,7.9,9,11.1,13.3,15.4,17.5,20.7,24.3,28.6,34.8,45.8,51.6,-1],
[116,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,-1,-1,-1,-1,2.9,-1,-1,-1,-1,-1,-1,-1,-1,-1,54,-1,-1,-1,-1,-1,62.3,-1,74.7,76.6,83.9,-1],
[120,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0.2,0.6,3.1,4.8,8.0,14.6,31.6,41.4,47.3,53.2,59.0,60.9,61.8,62.7,69,69.7,72.0,79.7,80.4,81.2,81.6,82.7,84,85.2,86.5,87.7,89,89.8,92.8,95.9,-1],
[198,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0.4,0.8,2.5,3.5,5.8,6.8,7.2,6.7,6.6,8.2,16.6,20,26.8,35.8,48,60,66.4,70.2,73.5,76.8,80.1,85.5,90.2,95.2,96.3,-1],
[126,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,-1,1.3,2.5,5.0,6.1,6.3,6.5,6.8,7,7.5,8,8,8.1,9,10,11,12.3,13.7,15.3,17.0,18.9,21.0,-1],
[128,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0.2,0.5,2.7,3.8,6.5,7.2,8.5,9.9,11.1,11.4,17.3,22.2,33.8,39.0,40.1,42.7,40.3,44.0,44.9,51.2,54,59.9,61.8,63.6,65.5,67.5,-1],
[131,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0.2,0.4,1.1,1.9,3.0,7.5,8.9,11.6,14.1,17.1,20.7,25.2,30.5,31.4,34.7,36.0,38.2,39.2,40.2,40.8,45.4,50.4,55.0,59.9,65.2,71.1,74.6],
[132,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0.1,1.1,1.4,1.9,2.5,4.3,4.8,5.2,5.3,5.7,5.9,6.2,9,25,29,30.8,32.7,34.7,36.9,39.2,41.6,44.1,43.0,47.1,52.6,-1],
[3,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,-1,-1,-1,-1,-1,20.2,21.6,26.9,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1],
[129,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0.1,0.2,0.6,0.8,0.9,1.3,1.3,1.5,1.7,1.7,1.7,1.1,1.6,1.2,2,3.5,5.1,6.5,7.9,9.6,11.2,-1,19.9,27.7,32.0,-1],
[133,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0.1,0.3,0.6,1.2,2.0,4.1,5.4,7.2,9.9,21.1,24.8,32.5,38.8,44.5,48.6,53.1,58.9,62.3,61.9,62.3,62.8,66.6,67.9,73.3,75.9,77.5,80.4,83.1,85.3,86.9],
[135,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0.1,0.2,1.3,2.6,5.2,10.4,15.6,17.5,19.7,22.1,23.4,25.4,27.8,38,41.5,45.3,48,68.9,68.9,69,63.4,68.6,68.7,70.8,77.7,-1,84.7,-1],
[124,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,-1,-1,-1,-1,-1,-1,-1,-1,-1],
[134,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0.1,0.2,0.4,0.7,1.4,2.9,4.9,9.8,14.7,16.4,18.0,19.3,29.6,31.7,34.9,38.0,42.0,44.1,48.2,53.3,55.2,60.3,62.0,64.5,68.6,70.4,73.7,74.6,75.3,78.2,82.3,84.4],
[130,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0.1,0.3,0.7,1.0,1.7,2.1,3.4,7.9,7.9,11.2,14.2,18.9,19.8,24.7,29.3,36.9,43,49.7,53.4,61.0,64.9,68.5,73.9,77.0,76.2],
[127,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,-1,-1,-1,1.1,1.8,3.1,4.1,4.4,16.0,18.4,21.1,24.3,32.2,37.4,41.0,43.4,46.6,53.6,56.7,59.9,63.3,64.3,70.6,76.0,81.8,88.6],
[169,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0.2,1.3,3.4,6.3,6.2,8.2,14.1,17.8,21.5,25.1,28.5,33.8,44.6,49,49,52.8,56.8,60.6,64.5,68.4,72.7,-1,-1,-1,-1,-1],
[202,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0.1,0.9,3.0,3.5,4.0,4.8,6.1,10.2,19.2,20.7,24.7,28.9,37,44.3,53.1,69,69,69.3,85.3,91.4,92.8,95.1,97.3,99.6,99.6,99.6,100,-1],
[136,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0.2,0.4,2.2,2.6,3.6,4.5,6.5,8.9,15,21.5,24.6,28.3,32.4,36.6,39.9,40,45.8,49.7,54.0,55.7,59.5,63.7,70.6,73.6,78.4,83.5,85.5],
[137,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0.1,0.2,0.4,0.8,1.0,1.9,2.9,4.1,8.2,12.8,15.2,18.0,24.6,32,42.5,49,58,66,67.9,70.5,70,73.0,76.0,80.8,82.6,84.9,88.2,90.4],
[138,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0,0.2,0.2,0.3,0.4,0.5,-1,2.1,4.5,7.7,8,7,8.0,9,10.6,12.5,14.6,17.2,19.2,21.3,23.8,30.4,-1],
[144,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0.1,0.4,2.2,4.6,6.3,8.0,10.2,12.7,19.4,30,36,38,41,47.5,54,60.5,64.7,69.6,74.8,94.1,93.3,95.7,97.8,100,100],
[159,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0,0.1,0.4,0.5,0.7,1.2,-1,8.6,-1,-1,-1,-1,-1,-1,-1,-1,14.0,18.6,24.6,25.9,28.4,-1,-1],
[145,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0.3,0.4,0.9,1.0,2.1,4.3,4.7,5.6,6.8,7.1,7.5,8,9.8,10.8,13.1,17.7,21.7,25.6,29.6,35.3,36.2,47.1,58.0,-1],
[149,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0.1,0.4,0.7,1.1,2.8,8.3,13.4,19.5,24.1,36,41.6,47,53.8,62,61,59,69.9,69,69,71,71,72,80.9,82.1,83.2,84.4,84.4,88.1,88.9,92.0,96.9,95.9],
[152,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0.2,0.3,0.5,0.4,0.4,0.4,0.5,0.5,0.6,0.8,1.6,2,3,4,5,6,6.9,8,9,10,11.0,11.9,-1,28.1,35.8,36.1,-1],
[148,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0.1,0.1,0.1,0.1,0.2,0.2,0.2,0.2,0.2,0.2,0.5,0.9,2.5,4,6.0,6.3,11.7,13.2,15.8,16.8,18,-1,-1],
[51,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0.2,0.4,0.8,1.1,1.5,1.9,2.5,3.2,4.2,5.5,6.1,10,12.1,15.9,18.9,20.3,23.1,24.7,26.8,28.9,33.8,43.8,50.4,54.6,62.8,-1],
[199,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,1.3,1.4,1.4,1.4,42.8,48.7,50.3,50.8,50,50.5,50.2,50.2,50.3,54.5,54.2,-1,49.6,-1,-1,-1,-1,-1,60.1,-1,67.8,70.4,74.8,-1],
[153,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0,0,0.1,0.3,1.0,1.0,1.1,1.1,1.1,1.1,-1,1.2,1.3,1.5,1.6,1.7,1.8,2.0,-1,-1,-1,-1,-1],
[146,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,23.5,26.3,27.2,33.1,35.6,38.1,40.9,42.2,48.1,53.4,62.0,65.3,67.0,70.3,73.3,77.4,78.3,81.1,83.5],
[156,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,2.2,2.6,3,3.5,4.1,4.8,5.6,6.5,-1,-1],
[143,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,-1,0.2,0.3,4.6,6.3,7.5,10.1,13.3,13.7,14.1,14.5,15.4,16.4,18.7,20.1,21.5,23,24.4,25.8,28,29.9,30,30.2,40.3,51.2,-1],
[160,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0.1,0.2,1.0,1.6,1.8,2.5,3.0,4.1,4.7,6.0,6.4,9.4,14.1,21.0,31.3,31.5,32,34.6,37.4,40,42.7,45.4,48.9,53.8,56.8,62.0,65.9,-1],
[150,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0.1,0.3,0.5,0.7,1.1,2.6,5.4,9.4,12.5,40.1,43.0,52.8,55.1,56.0,61.8,66.0,70,75.7,74.4,76.7,77.8,79.9,77.6,80.4,81.6,80.4,82.8,89.9,88.9,-1],
[151,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0.4,1.0,2.8,5.0,7.5,10.1,12.6,15.1,30.1,27.8,31.8,40.8,46.8,54.0,56.7,58,64,70,67.3,68.3,72.6,71.5,73.0,75.4,78.8,79.7,83.1,86.6,89.0,88.9],
[161,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0.5,1.1,1.4,1.7,3.4,5.0,9.0,23.7,33.4,41.4,45.6,51.7,70.5,79.1,83.8,84.8,87.7,82.0,90,91,90,92.7,93.1,94.7,92.5,90.6,89.6,93.0,89.2,94.4,94.5,94.6,95.0],
[55,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0.4,0.9,1.2,1.8,2.4,3.2,3.6,3.6,4.1,6.8,8.9,11.0,18.1,20.7,24.7,25,25.6,28.5,35.3,43.7,54.0,56.4,58.9,-1],
[216,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1],
[147,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0.6,1.2,2.5,6.2,7.3,11.0,14.3,14.5,24.2,25.4,34.9,38.3,40.4,-1,41,43.1,47.0,50.4,51.2,54.2,56.5,58.7,70.1,74.7-175.4,81.5,-1],
[163,0,-1,-1,-1,-1,0,-1,-1,-1,-1,0,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0.1,0.1,0.3,2.0,3.3,4.3,5.6,7.8,11.5,14,17.3,20.7,22.5,24.3,26.2,28.0,29.9,31.8,32.7,33.7,34.6,35.7,-1,-1],
[218,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1],
[32,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,0,0,0,0,0,0.1,0.3,0.3,0.3,0.5,0.8,1.1,1.5,1.7,1.9,2.1,2.5,2.9,3.5,5.8,7.4,8,8.3,11.6,17.8,-1],
[168,0,-1,-1,-1,-1,0,-1,-1,-1,-1,0,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0.2,0.3,0.5,0.8,0.9,1,1.2,1.5,1.8,2,2.2,2.4,2.6,3,3.5,4,4.5,5.7,7.1,11.3,12.3,15.5,20.7,29.0,34.9,-1],
[167,0,-1,-1,-1,-1,0,-1,-1,-1,-1,0,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0.1,0.3,1.0,2.4,3.6,5.5,7.5,9.2,10.6,15.0,17.1,20,18.2,20.1,22.4,23.6,26.4,28.9,34.8,39.3,47.5,52.8,56.8,66.6,77.8,85.2,87.9],
[165,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,-1,-1,0,0,0,0,0,0,0.2,3.7,7.1,8.7,10,11.5,13.0,14.5,16,17.4,18.9,20.4,21.9,-1,-1,-1,-1,-1],
[173,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,-1,-1,0,0.1,0.1,0.3,0.4,0.7,0.9,1.3,1.4,1.7,1.9,3,5,7.1,9.6,12.2,14.9,17.9,21.2,-1,-1,-1,-1,-1],
[48,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,-1,-1,-1,-1,-1,0,0,0,0,0.5,1,1.5,2,3,4,7,11,17.5,18.6,21.2,24.2,27.6,28.0,34.1,39.4,-1],
[210,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0.1,0.1,0.5,0.7,1.0,2.4,2.8,2.9,2.9,3.9,4.9,5.8,7.1,8.1,10,16,25,30,32.7,35.9,38.6,39.9,46.2,53.4,61.7,66.6,71.5,-1],
[170,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0.1,0.3,1.1,2.7,5.8,7.7,15.3,21.9,25.9,27.0,28.9,30,32.3,34.8,44.3,48.5,55.2,57.5,59.9,62.4,65.1,67.8,70.6,-1,78.9,78.6,79,-1],
[171,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0.1,1.6,2.7,4.2,5.2,6.4,8.5,9.6,12.9,17.1,27.5,34.0,36.8,39.1,41.4,43.8,46.1,46.4,49.5,55.5,64.1,66.6,72.8,78.9,-1],
[172,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0.1,0.4,0.6,2.2,3.7,5.1,11.3,12.3,14.5,15.4,18.2,28.6,34.3,36.4,39.8,43.0,45.1,46.2,51.0,53.7,58.3,64.6,71.0,73.9,77.6,81.4,83.4],
[174,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,-1,-1,-1,5.2,5.7,6.3,6.9,7.6,8.3,9.1,10,11.2,12.8,14.6,16.5,18.7,21.2,24.1,27.3,31,35.2,-1,-1,-1,71.5,-1],
[166,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0.1,0.1,0.2,0.6,0.8,1.1,1.3,1.6,1.9,2.4,2.9,3.2,3.9,4.4,7,10,13.5,15.9,17.1,18.2,23.4,31.6,-1],
[175,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0.1,0.1,0.2,0.3,0.4,0.7,1.7,2.5,3.6,3.8,4.1,4.3,4.6,4.8,5.1,5.4,5.8,5.9,5.9,6,6.0,6.1,10.3,-1],
[176,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0.1,0.3,0.4,0.7,1.2,1.8,3.1,3.4,3.7,4.5,6.5,11,17.9,23.3,28.7,35.2,40.9,46.2,48.8,53.0,58.8,62.5,70.1,75.0,79.2,-1],
[180,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0.3,1.8,3.3,6.9,9.9,10.5,11.1,11.4,15.9,17.0,20,29.4,34,39.3,41.8,46.4,51.4,54.4,57.6,61.4,64.5,66.4,70.3,80.7,83.3,86.3,90,89.8],
[179,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0.7,1.1,1.7,2.2,4.8,9.2,16.4,21.6,30,35.8,43.0,49.0,58.7,61.6,64.7,67.9,68.9,75,74,71,71.6,69.7,74.7,71.4,73,74.5,85.5,87.2,88.4,89.4,90.6,91.7,-1],
[181,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0.4,0.5,1.0,1.9,2.5,3.3,6.3,7.4,9.0,11.9,15.9,18.6,23.6,26.8,35.5,42.8,46.7,48.6,55.2,70.3,71.0,76.5,-1],
[141,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0.1,0.4,0.9,1.8,2.7,3.2,5.0,5.5,6.4,7.3,9.1,12,16,21,31,33.7,36.7,40,43.5,47.4,49.4,51.5,53.7,56,73.9,80.4,84.9,-1],
[183,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0.1,0.2,0.3,1.3,2.8,3.3,4.6,4.9,7.4,8.4,12.5,15.2,20.8,25.8,32.7,37.3,40.2,49.0,54.9,57,58,60,61.6,-1,-1,-1,-1,-1],
[219,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,-1,-1,-1,-1,-1,18.8,-1,-1,-1,-1,-1,-1,36,37,37.4,37.6,-1,-1,-1,77.7,77.7,-1,-1,-1,-1,-1],
[220,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0.9,2.8,4.6,6.9,9.2,11.0,13.8,18.3,27.4,27.4,27.3,27.3,27.3,27.3,27.3,27.3,31.2,35.6,40.5,45.3,50,54.8,59.6,64.3,-1,-1,-1,-1,-1],
[184,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0.1,0.2,1.2,1.8,3.7,7.6,12.7,17.2,20.7,23.9,26.5,30.6,35.0,36.8,38.5,41,45,53,58.1,69.8,68.6,70.3,74.2,78.5],
[182,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0.1,0.2,0.5,2.1,2.8,3.5,3.9,4.7,5.0,5.8,6.8,7.2,7.5,8,9.2,10.5,11.3,18.8,22.3,24,30.8,39.5,50.7,53.2,66.3,-1],
[204,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,-1,0.1,0.2,0.2,0.5,1.6,2.2,2.7,3.0,3.3,4.4,4.7,5.0,6,7,11,12.9,15.3,21.2,25.4,31.7,39.4,49.1,61.2,69.2,78.2,-1],
[90,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,83.8,89.4,-1,-1,-1,-1],
[186,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0,0,0,0.5,0.6,0.8,1.0,1.2,5.0,6.8,9.9,12.3,14.9,17.4,20,22.5,24.0,24.5,26.7,-1,-1,-1,-1,-1],
[154,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0.1,0.2,0.6,0.8,1.6,2.9,4.1,5.3,6.3,6.7,7.0,8.4,7.4,7.6,8.0,8.4,10,24,33.9,41,46.5,49,51.9,54,56.1,62.4,69.6,70.3,72.3,-1],
[187,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0.1,0.1,0.2,0.4,0.9,1.1,1.3,1.6,1.9,2.2,2.5,3,3.5,4.1,4.8,6.5,8.8,10.3,12.2,14.2,16.8,18.0,21.2,-1],
[188,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,0,0,0,0,0,0,0,0,0.1,0.4,0.7,1.1,1.8,2.1,2.4,2.4,3,3.5,4,6.4,8.4,12,15.5,16.3,22.7,23.1,24.4,25,26.5,29.2,34.8,-1],
[164,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1],
[185,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1],
]

export default InternetUsageData
