const RealAntiBiasJudgementSimData = [
  184,
  190,
  193,
  184,
  184,
  190,
  193,
  190,
  190,
  190,
  196,
  187,
  190,
  190,
  193,
  190,
  193,
  193,
  187,
  190,
  190,
  190,
  193,
  190,
  187,
  190,
  190,
  193,
  193,
  184,
  187,
  193,
  187,
  190,
  190,
  193,
  190,
  190,
  187,
  190,
  187,
  196,
  190,
  184,
  190,
  190,
  187,
  187,
  190,
  190,
  187,
  187,
  187,
  190,
  187,
  193,
  190,
  190,
  190,
  187,
  190,
  181,
  193,
  187,
  184,
  190,
  190,
  193,
  184,
  190,
  196,
  196,
  190,
  187,
  196,
  187,
  190,
  190,
  187,
  196,
  187,
  193,
  187,
  184,
  193,
  196,
  193,
  178,
  193,
  193,
  193,
  190,
  196,
  193,
  184,
  187,
  187,
  190,
  190,
  187,
  187,
  193,
  190,
  190,
  193,
  187,
  196,
  193,
  184,
  190,
  184,
  190,
  190,
  190,
  193,
  187,
  187,
  190,
  190,
  193,
  190,
  187,
  190,
  193,
  196,
  196,
  196,
  193,
  190,
  184,
  187,
  190,
  193,
  187,
  196,
  193,
  190,
  190,
  190,
  190,
  196,
  193,
  193,
  187,
  196,
  190,
  193,
  184,
  184,
  196,
  190,
  187,
  190,
  190,
  193,
  190,
  190,
  187,
  193,
  187,
  193,
  187,
  190,
  184,
  187,
  190,
  196,
  187,
  187,
  193,
  184,
  190,
  190,
  193,
  187,
  190,
  193,
  184,
  196,
  193,
  190,
  196,
  187,
  193,
  190,
  187,
  190,
  187,
  184,
  190,
  193,
  190,
  193,
  181,
  193,
  190,
  187,
  190,
  193,
  196,
  190,
  193,
  196,
  187,
  187,
  190,
  187,
  190,
  190,
  190,
  196,
  184,
  181,
  199,
  190,
  184,
  193,
  196,
  190,
  187,
  190,
  187,
  196,
  193,
  196,
  190,
  190,
  193,
  190,
  187,
  190,
  190,
  190,
  190,
  193,
  190,
  190,
  193,
  190,
  193,
  187,
  190,
  187,
  193,
  184,
  190,
  193,
  196,
  187,
  190,
  190,
  184,
  193,
  190,
  193,
  196,
  193,
  190,
  190,
  193,
  190,
  190,
  187,
  190,
  184,
  184,
  193,
  190,
  193,
  196,
  190,
  190,
  184,
  190,
  187,
  181,
  193,
  196,
  187,
  190,
  193,
  190,
  196,
  184,
  190,
  193,
  187,
  190,
  190,
  196,
  190,
  187,
  190,
  190,
  187,
  190,
  190,
  193,
  193,
  190,
  190,
  190,
  190,
  184,
  190,
  196,
  193,
  187,
  190,
  187,
  187,
  190,
  190,
  190,
  193,
  193,
  190,
  190,
  190,
  181,
  193,
  196,
  190,
  190,
  196,
  190,
  190,
  190,
  190,
  193,
  184,
  196,
  193,
  190,
  190,
  187,
  190,
  181,
  190,
  187,
  190,
  190,
  184,
  190,
  193,
  190,
  190,
  187,
  190,
  187,
  193,
  187,
  190,
  187,
  190,
  190,
  193,
  184,
  193,
  190,
  190,
  187,
  193,
  190,
  190,
  187,
  190,
  190,
  190,
  184,
  190,
  196,
  196,
  190,
  190,
  190,
  184,
  184,
  190,
  190,
  190,
  187,
  184,
  187,
  190,
  187,
  187,
  193,
  190,
  190,
  190,
  193,
  190,
  187,
  187,
  190,
  184,
  181,
  193,
  196,
  196,
  196,
  190,
  190,
  190,
  190,
  190,
  190,
  184,
  190,
  190,
  190,
  187,
  187,
  190,
  190,
  190,
  187,
  184,
  190,
  187,
  196,
  190,
  196,
  193,
  190,
  184,
  187,
  196,
  184,
  184,
  196,
  187,
  187,
  190,
  190,
  187,
  190,
  193,
  193,
  184,
  184,
  184,
  193,
  193,
  193,
  190,
  187,
  196,
  187,
  187,
  187,
  184,
  181,
  190,
  190,
  187,
  187,
  193,
  190,
  193,
  190,
  190,
  187,
  190,
  187,
  196,
  187,
  187,
  190,
  193,
  187,
  190,
  190,
  193,
  190,
  196,
  193,
  187,
  190,
  187,
  181,
  181,
  193,
  187,
  196,
  187,
  190,
  196,
  190,
  184,
  184,
  190,
  190,
  196,
  184,
  190,
  187,
  190,
  193,
  196,
  193,
  193,
  193,
  190,
  184,
  187,
  190,
  190,
  193,
  193,
  187,
  193,
  181,
  196,
  190,
  196,
  190,
  190,
  193,
  184,
  190,
  193,
  184,
  187,
  193,
  196,
  193,
  187,
  190,
  190,
  190,
  187,
  187,
  187,
  190,
  193,
  196,
  193,
  196,
  193,
  184,
  190,
  184,
  193,
  187,
  193,
  190,
  193,
  190,
  190,
  190,
  184,
  187,
  190,
  193,
  184,
  184,
  187,
  190,
  193,
  190,
  181,
  193,
  196,
  193,
  190,
  190,
  187,
  187,
  196,
  190,
  190,
  190,
  190,
  193,
  184,
  187,
  187,
  190,
  184,
  190,
  190,
  190,
  190,
  187,
  187,
  190,
  190,
  181,
  193,
  190,
  193,
  187,
  190,
  187,
  196,
  190,
  190,
  184,
  187,
  190,
  190,
  187,
  193,
  193,
  187,
  190,
  190,
  187,
  193,
  187,
  190,
  187,
  193,
  187,
  190,
  187,
  190,
  196,
  196,
  193,
  193,
  196,
  190,
  184,
  187,
  196,
  184,
  193,
  184,
  190,
  187,
  184,
  193,
  190,
  190,
  187,
  190,
  196,
  193,
  193,
  190,
  193,
  193,
  190,
  187,
  184,
  190,
  187,
  193,
  193,
  187,
  193,
  184,
  190,
  190,
  190,
  184,
  193,
  190,
  193,
  196,
  190,
  184,
  193,
  187,
  193,
  190,
  196,
  187,
  190,
  187,
  190,
  190,
  187,
  190,
  196,
  193,
  193,
  190,
  184,
  190,
  184,
  193,
  190,
  187,
  187,
  190,
  190,
  190,
  190,
  190,
  196,
  187,
  190,
  193,
  193,
  196,
  190,
  190,
  190,
  196,
  187,
  187,
  190,
  193,
  187,
  190,
  184,
  184,
  190,
  196,
  190,
  190,
  193,
  190,
  190,
  193,
  184,
  187,
  193,
  190,
  190,
  184,
  187,
  196,
  187,
  193,
  193,
  187,
  190,
  187,
  187,
  181,
  193,
  190,
  190,
  193,
  190,
  196,
  184,
  187,
  190,
  190,
  193,
  196,
  190,
  190,
  184,
  190,
  187,
  193,
  193,
  187,
  190,
  187,
  193,
  181,
  196,
  190,
  187,
  190,
  193,
  187,
  190,
  193,
  187,
  193,
  193,
  190,
  184,
  187,
  190,
  190,
  187,
  184,
  190,
  196,
  193,
  187,
  187,
  184,
  190,
  196,
  196,
  187,
  184,
  190,
  187,
  193,
  193,
  193,
  193,
  184,
  190,
  190,
  190,
  181,
  190,
  190,
  187,
  196,
  193,
  190,
  190,
  184,
  190,
  181,
  187,
  187,
  190,
  196,
  190,
  193,
  190,
  196,
  193,
  193,
  187,
  187,
  190,
  190,
  190,
  184,
  193,
  190,
  187,
  193,
  190,
  187,
  178,
  184,
  190,
  193,
  187,
  190,
  193,
  184,
  187,
  193,
  193,
  196,
  190,
  190,
  187,
  190,
  184,
  190,
  187,
  190,
  184,
  187,
  187,
  190,
  190,
  193,
  190,
  193,
  193,
  187,
  187,
  184,
  193,
  190,
  184,
  187,
  187,
  190,
  187,
  181,
  187,
  193,
  187,
  187,
  190,
  193,
  187,
  187,
  190,
  193,
  190,
  190,
  184,
  193,
  193,
  190,
  187,
  187,
  193,
  190,
  193,
  184,
  187,
  184,
  190,
  193,
  187,
  193,
  187,
  190,
  193,
  190,
  190,
  181,
  190,
  190,
  187,
  184,
  190,
  190,
  184,
  193,
  193,
  190,
  190,
  190,
  184,
  190,
  187,
  196,
  184,
  190,
  187,
  193,
  190,
  193,
  187,
  187,
  193,
  190,
  184,
  184,
  187,
  190,
  187,
  190,
  187,
  193,
  190,
  190,
  187,
  190,
  190,
  190,
  193,
  187,
  190,
  190,
  187,
  190,
  187,
  187,
  193,
  187,
  184,
  193,
  187,
  193,
  190,
  184,
  190,
  187,
  196,
  190,
  193,
  196,
  193,
  187,
  196,
  196,
  184,
  184,
  196,
  190,
  196,
  187,
  190,
  196,
  187,
  187,
  187,
  187,
  196,
  190,
  190,
  196,
  193,
  190,
  190,
  190,
  193,
  184,
  184,
  187,
  187,
  187,
  196,
  184,
  196,
  196,
  187,
  184,
  193,
  190,
  190,
  184,
  190,
  184,
  190,
  184,
  190,
  184,
  196,
  196,
  193,
  187,
  193,
  190,
  193,
  190,
  190,
  193,
  184,
  190,
  184,
  190,
  184,
  184,
  190,
  184,
  184,
  193,
  184,
  187,
  196,
  187,
  184,
  190,
  193,
  190,
  193,
  190,
  184,
  187,
  190,
  187,
  190,
  184,
  190,
  184,
  193,
  193,
  193,
  187,
  187,
  196,
  184,
  187,
  196,
  190,
  190,
  193,
  184,
  184,
  193,
  190,
  187,
  190,
  190,
  190,
  190,
  184,
  193,
  193,
  196,
  190,
  187,
  196,
  187,
  181,
  184,
  187,
  193,
  190,
  190,
  190,
  187,
  187,
  190,
  193,
  193,
  193,
  187,
  181,
  196,
  196,
  190,
  184,
  190,
  187,
  190,
  196,
  187,
  193,
  190,
  193,
  190,
  190,
  184,
  184,
  193,
  193,
  190,
  187,
  187,
  190,
  196,
  193,
  193,
  190,
  187,
  190,
  184,
  187,
  181,
  190,
  190,
  193,
  187,
  190,
  190,
  190,
  184,
  187,
  190,
  190,
  187,
  190,
  190,
  193,
  187,
  196,
  187,
  190,
  187,
  187,
  190,
  187,
  193,
  193,
  190,
  190,
  187,
  193,
  196,
  190,
  193,
  193,
  190,
  190,
  193,
  190,
  190,
  187,
  184,
  190,
  190,
  190,
  187,
  193,
  190,
  184,
  190,
  187,
  187,
  184,
  190,
  193,
  190,
  193,
  196,
  187,
  190,
  190,
  187,
  193,
  187,
  196,
  193,
  196,
  190,
  190,
  190,
  190,
  187,
  187,
  184,
  190,
  193,
  184,
  184,
  190,
  190,
  187,
  196,
  184,
  184,
  193,
  190,
  190,
  187,
  190,
  190,
  190,
  184,
  187,
  190,
  187,
  193,
  187,
  187,
  193,
  184,
  187,
  190,
  184,
  190,
  190,
  187,
  184,
  190,
  193,
  187,
  184,
  190,
  190,
  190,
  187,
  190,
  190,
  196,
  190,
  184,
  196,
  187,
  187,
  187,
  196,
  190,
  193,
  187,
  184,
  190,
  193,
  193,
  190,
  190,
  187,
  190,
  187,
  187,
  190,
  193,
  193,
  190,
  187,
  193,
  187,
  193,
  193,
  190,
  190,
  190,
  193,
  187,
  196,
  190,
  184,
  190,
  190,
  187,
  187,
  190,
  190,
  184,
  187,
  193,
  193,
  187,
  190,
  187,
  190,
  190,
  196,
  187,
  196,
  190,
  187,
  190,
  190,
  196,
  196,
  190,
  193,
  184,
  196,
  190,
  193,
  190,
  190,
  187,
  187,
  190,
  187,
  190,
  190,
  193,
  190,
  181,
  187,
  190,
  193,
  184,
  187,
  187,
  190,
  187,
  187,
  184,
  190,
  193,
  187,
  190,
  190,
  184,
  190,
  196,
  184,
  184,
  196,
  190,
  187,
  190,
  196,
  187,
  190,
  190,
  196,
  190,
  196,
  184,
  187,
  190,
  190,
  184,
  187,
  190,
  190,
  190,
  190,
  184,
  193,
  187,
  193,
  193,
  190,
  190,
  190,
  184,
  193,
  193,
  190,
  190,
  178,
  184,
  190,
  196,
  187,
  190,
  184,
  196,
  190,
  190,
  190,
  190,
  190,
  190,
  190,
  190,
  190,
  190,
  193,
  193,
  190,
  190,
  184,
  190,
  187,
  184,
  184,
  187,
  190,
  184,
  196,
  187,
  193,
  190,
  187,
  190,
  187,
  196,
  184,
  184,
  187,
  187,
  187,
  190,
  193,
  190,
  193,
  196,
  190,
  193,
  193,
  184,
  196,
  190,
  187,
  193,
  190,
  184,
  187,
  196,
  187,
  184,
  184,
  190,
  193,
  190,
  190,
  190,
  196,
  190,
  187,
  193,
  187,
  184,
  190,
  184,
  190,
  193,
  193,
  187,
  187,
  190,
  187,
  187,
  190,
  193,
  187,
  196,
  190,
  190,
  193,
  193,
  187,
  196,
  190,
  187,
  184,
  184,
  190,
  187,
  190,
  190,
  184,
  193,
  190,
  193,
  190,
  190,
  196,
  193,
  193,
  187,
  190,
  187,
  187,
  190,
  187,
  190,
  190,
  196,
  187,
  190,
  190,
  187,
  193,
  196,
  190,
  196,
  184,
  193,
  184,
  190,
  190,
  190,
  190,
  196,
  190,
  190,
  184,
  190,
  196,
  184,
  187,
  190,
  190,
  196,
  190,
  184,
  190,
  190,
  190,
  190,
  196,
  184,
  190,
  193,
  187,
  187,
  184,
  190,
  193,
  190,
  184,
  187,
  187,
  187,
  193,
  184,
  184,
  184,
  193,
  190,
  190,
  187,
  184,
  190,
  190,
  196,
  190,
  190,
  184,
  181,
  190,
  190,
  184,
  193,
  184,
  190,
  196,
  181,
  190,
  190,
  190,
  190,
  196,
  190,
  190,
  190,
  190,
  196,
  190,
  190,
  193,
  184,
  187,
  196,
  196,
  190,
  190,
  193,
  187,
  193,
  187,
  187,
  193,
  190,
  190,
  181,
  193,
  193,
  193,
  190,
  193,
  190,
  193,
  184,
  184,
  184,
  187,
  193,
  193,
  190,
  184,
  196,
  184,
  193,
  187,
  190,
  184,
  187,
  193,
  190,
  190,
  187,
  187,
  190,
  190,
  190,
  187,
  193,
  187,
  190,
  190,
  190,
  193,
  193,
  193,
  193,
  190,
  190,
  190,
  196,
  190,
  196,
  190,
  181,
  187,
  190,
  196,
  190,
  193,
  190,
  190,
  190,
  190,
  190,
  190,
  193,
  187,
  187,
  190,
  187,
  190,
  187,
  190,
  196,
  190,
  193,
  196,
  181,
  190,
  184,
  184,
  187,
  190,
  187,
  190,
  184,
  190,
  187,
  193,
  193,
  193,
  190,
  190,
  184,
  184,
  184,
  187,
  196,
  193,
  190,
  184,
  190,
  193,
  196,
  187,
  184,
  193,
  181,
  196,
  193,
  190,
  190,
  190,
  190,
  196,
  193,
  190,
  184,
  190,
  190,
  181,
  190,
  196,
  190,
  193,
  190,
  187,
  193,
  187,
  190,
  190,
  184,
  193,
  196,
  190,
  187,
  190,
  196,
  190,
  187,
  190,
  190,
  190,
  187,
  184,
  184,
  184,
  190,
  184,
  187,
  196,
  190,
  190,
  190,
  190,
  187,
  190,
  190,
  190,
  187,
  184,
  187,
  193,
  196,
  196,
  190,
  184,
  193,
  190,
  187,
  196,
  193,
  184,
  199,
  190,
  196,
  193,
  196,
  196,
  187,
  184,
  187,
  187,
  190,
  187,
  187,
  193,
  193,
  181,
  184,
  193,
  187,
  193,
  196,
  193,
  196,
  190,
  193,
  193,
  187,
  196,
  190,
  190,
  196,
  184,
  190,
  190,
  193,
  193,
  193,
  190,
  184,
  193,
  184,
  187,
  193,
  184,
  193,
  190,
  187,
  196,
  190,
  190,
  184,
  190,
  184,
  193,
  196,
  187,
  184,
  184,
  193,
  184,
  184,
  190,
  190,
  187,
  196,
  187,
  190,
  193,
  190,
  187,
  190,
  193,
  190,
  193,
  190,
  184,
  193,
  190,
  196,
  190,
  196,
  196,
  187,
  193,
  193,
  187,
  187,
  184,
  190,
  190,
  193,
  193,
  190,
  190,
  193,
  190,
  193,
  190,
  190,
  193,
  190,
  184,
  187,
  187,
  193,
  190,
  193,
  190,
  187,
  190,
  193,
  196,
  190,
  190,
  187,
  190,
  184,
  190,
  184,
  184,
  187,
  193,
  187,
  190,
  193,
  190,
  193,
  187,
  190,
  190,
  196,
  187,
  190,
  184,
  187,
  190,
  190,
  190,
  190,
  190,
  190,
  193,
  193,
  190,
  184,
  190,
  184,
  193,
  190,
  193,
  193,
  187,
  190,
  190,
  193,
  190,
  193,
  190,
  196,
  187,
  187,
  187,
  187,
  196,
  193,
  190,
  193,
  190,
  190,
  178,
  193,
  181,
  187,
  196,
  187,
  190,
  193,
  196,
  196,
  193,
  184,
  187,
  187,
  193,
  184,
  187,
  187,
  190,
  187,
  184,
  190,
  196,
  190,
  190,
  193,
  193,
  190,
  193,
  193,
  190,
  196,
  187,
  187,
  190,
  187,
  184,
  190,
  193,
  187,
  193,
  190,
  196,
  193,
  187,
  193,
  196,
  190,
  190,
  190,
  190,
  184,
  190,
  196,
  193,
  187,
  190,
  190,
  190,
  187,
  184,
  190,
  190,
  190,
  190,
  187,
  193,
  196,
  193,
  190,
  196,
  190,
  193,
  193,
  196,
  184,
  190,
  190,
  190,
  190,
  190,
  187,
  190,
  196,
  190,
  184,
  190,
  184,
  190,
  184,
  184,
  187,
  190,
  196,
  193,
  181,
  187,
  190,
  190,
  187,
  193,
  190,
  190,
  193,
  193,
  190,
  184,
  190,
  184,
  196,
  190,
  190,
  184,
  187,
  190,
  187,
  190,
  184,
  193,
  187,
  184,
  187,
  190,
  193,
  187,
  193,
  184,
  187,
  190,
  187,
  193,
  190,
  187,
  190,
  190,
  193,
  190,
  193,
  193,
  193,
  190,
  187,
  187,
  187,
  190,
  187,
  190,
  190,
  187,
  181,
  181,
  190,
  187,
  193,
  187,
  193,
  190,
  190,
  184,
  181,
  187,
  184,
  193,
  196,
  193,
  190,
  187,
  190,
  190,
  193,
  187,
  187,
  184,
  193,
  193,
  187,
  190,
  184,
  187,
  187,
  187,
  190,
  187,
  190,
  193,
  190,
  190,
  190,
  190,
  193,
  196,
  178,
  181,
  184,
  196,
  190,
  190,
  196,
  184,
  190,
  193,
  190,
  190,
  184,
  193,
  190,
  190,
  187,
  196,
  187,
  187,
  193,
  187,
  184,
  184,
  196,
  190,
  196,
  196,
  190,
  187,
  190,
  184,
  196,
  187,
  193,
  193,
  193,
  193,
  193,
  190,
  190,
  193,
  196,
  193,
  193,
  187,
  193,
  190,
  187,
  187,
  187,
  184,
  184,
  184,
  187,
  190,
  184,
  187,
  190,
  190,
  187,
  196,
  190,
  193,
  190,
  190,
  193,
  196,
  187,
  187,
  187,
  187,
  187,
  190,
  196,
  190,
  187,
  190,
  190,
  187,
  187,
  193,
  193,
  187,
  190,
  193,
  187,
  190,
  196,
  184,
  193,
  187,
  190,
  187,
  193,
  196,
  190,
  190,
  196,
  196,
  190,
  193,
  190,
  184,
  187,
  193,
  187,
  193,
  187,
  190,
  196,
  190,
  187,
  196,
  193,
  190,
  190,
  190,
  184,
  190,
  187,
  184,
  196,
  196,
  193,
  190,
  193,
  181,
  187,
  190,
  184,
  193,
  187,
  187,
  193,
  187,
  190,
  190,
  190,
  187,
  184,
  190,
  187,
  190,
  196,
  190,
  187,
  193,
  184,
  190,
  193,
  187,
  193,
  187,
  190,
  190,
  196,
  187,
  187,
  190,
  184,
  190,
  190,
  196,
  181,
  190,
  187,
  193,
  190,
  184,
  190,
  190,
  190,
  184,
  193,
  187,
  193,
  190,
  190,
  187,
  187,
  196,
  190,
  193,
  184,
  196,
  193,
  190,
  193,
  187,
  193,
  184,
  196,
  190,
  196,
  187,
  193,
  190,
  181,
  193,
  187,
  190,
  196,
  184,
  190,
  190,
  187,
  193,
  193,
  196,
  190,
  181,
  196,
  196,
  193,
  190,
  193,
  190,
  190,
  184,
  190,
  196,
  190,
  187,
  193,
  196,
  187,
  184,
  190,
  190,
  187,
  187,
  190,
  187,
  187,
  193,
  190,
  193,
  193,
  193,
  187,
  193,
  187,
  193,
  193,
  190,
  190,
  193,
  181,
  184,
  193,
  187,
  190,
  190,
  190,
  187,
  196,
  190,
  187,
  190,
  190,
  196,
  187,
  193,
  190,
  187,
  187,
  187,
  190,
  184,
  187,
  196,
  190,
  184,
  196,
  193,
  190,
  187,
  190,
  190,
  187,
  190,
  187,
  193,
  190,
  184,
  190,
  190,
  190,
  190,
  187,
  193,
  190,
  193,
  181,
  190,
  190,
  193,
  193,
  193,
  184,
  196,
  190,
  190,
  181,
  187,
  190,
  190,
  193,
  193,
  193,
  193,
  190,
  190,
  196,
  190,
  196,
  193,
  196,
  196,
  193,
  187,
  196,
  187,
  190,
  187,
  190,
  184,
  190,
  190,
  193,
  193,
  190,
  190,
  190,
  190,
  190,
  190,
  187,
  184,
  187,
  190,
  190,
  187,
  190,
  190,
  184,
  190,
  196,
  190,
  190,
  193,
  190,
  190,
  196,
  193,
  190,
  190,
  193,
  193,
  193,
  187,
  196,
  184,
  190,
  196,
  193,
  187,
  196,
  190,
  190,
  190,
  187,
  187,
  187,
  187,
  193,
  190,
  184,
  178,
  190,
  193,
  190,
  190,
  193,
  190,
  193,
  190,
  187,
  193,
  196,
  190,
  190,
  193,
  190,
  196,
  190,
  190,
  193,
  187,
  184,
  190,
  190,
  190,
  190,
  187,
  190,
  193,
  190,
  193,
  193,
  190,
  193,
  193,
  184,
  196,
  187,
  190,
  202,
  184,
  193,
  187,
  187,
  193,
  190,
  193,
  184,
  184,
  190,
  190,
  184,
  193,
  196,
  181,
  184,
  190,
  190,
  193,
  187,
  196,
  187,
  190,
  187,
  190,
  190,
  190,
  190,
  193,
  187,
  193,
  190,
  184,
  184,
  187,
  196,
  190,
  196,
  196,
  190,
  190,
  190,
  184,
  187,
  184,
  190,
  196,
  184,
  193,
  187,
  190,
  187,
  187,
  190,
  187,
  196,
  196,
  190,
  193,
  193,
  187,
  190,
  193,
  190,
  196,
  190,
  184,
  187,
  190,
  190,
  187,
  187,
  196,
  193,
  184,
  196,
  193,
  190,
  190,
  196,
  184,
  190,
  190,
  187,
  196,
  184,
  187,
  190,
  193,
  190,
  196,
  190,
  190,
  196,
  196,
  181,
  190,
  193,
  184,
  190,
  187,
  190,
  190,
  193,
  184,
  184,
  184,
  190,
  190,
  190,
  196,
  190,
  184,
  184,
  187,
  190,
  190,
  190,
  193,
  193,
  190,
  190,
  196,
  181,
  190,
  187,
  193,
  187,
  193,
  187,
  193,
  184,
  190,
  187,
  193,
  190,
  193,
  190,
  190,
  190,
  190,
  190,
  193,
  187,
  190,
  190,
  190,
  190,
  193,
  187,
  193,
  193,
  187,
  190,
  190,
  187,
  193,
  193,
  193,
  196,
  187,
  196,
  190,
  190,
  193,
  190,
  190,
  196,
  190,
  190,
  193,
  187,
  190,
  190,
  187,
  187,
  187,
  190,
  190,
  193,
  190,
  190,
  193,
  193,
  190,
  193,
  190,
  187,
  196,
  193,
  193,
  190,
  190,
  187,
  187,
  193,
  184,
  190,
  193,
  190,
  187,
  190,
  193,
  187,
  187,
  190,
  193,
  193,
  187,
  190,
  187,
  184,
  193,
  187,
  193,
  184,
  193,
  187,
  196,
  190,
  193,
  193,
  190,
  190,
  187,
  187,
  184,
  196,
  190,
  184,
  193,
  184,
  196,
  187,
  196,
  193,
  190,
  190,
  187,
  193,
  193,
  190,
  190,
  190,
  196,
  190,
  184,
  184,
  193,
  193,
  187,
  190,
  193,
  193,
  193,
  187,
  196,
  187,
  184,
  193,
  193,
  193,
  190,
  196,
  190,
  196,
  187,
  190,
  190,
  190,
  193,
  187,
  187,
  190,
  184,
  187,
  193,
  190,
  187,
  193,
  196,
  196,
  187,
  196,
  184,
  190,
  190,
  190,
  187,
  190,
  196,
  184,
  190,
  190,
  190,
  190,
  190,
  193,
  190,
  181,
  187,
  193,
  187,
  193,
  196,
  190,
  184,
  187,
  184,
  190,
  193,
  190,
  193,
  190,
  187,
  190,
  184,
  196,
  184,
  184,
  196,
  190,
  196,
  190,
  184,
  190,
  196,
  187,
  184,
  193,
  184,
  193,
  190,
  187,
  184,
  184,
  187,
  196,
  187,
  193,
  190,
  196,
  190,
  184,
  190,
  187,
  184,
  187,
  190,
  187,
  193,
  184,
  196,
  193,
  190,
  187,
  196,
  184,
  190,
  193,
  190,
  193,
  190,
  190,
  187,
  187,
  187,
  190,
  184,
  193,
  184,
  187,
  184,
  190,
  187,
  190,
  187,
  193,
  190,
  187,
  184,
  193,
  190,
  187,
  193,
  187,
  190,
  187,
  196,
  190,
  193,
  187,
  193,
  190,
  190,
  190,
  190,
  190,
  190,
  187,
  190,
  181,
  196,
  190,
  190,
  190,
  187,
  187,
  187,
  184,
  190,
  196,
  196,
  187,
  190,
  193,
  190,
  190,
  190,
  196,
  190,
  193,
  196,
  190,
  190,
  190,
  190,
  187,
  184,
  184,
  190,
  184,
  190,
  187,
  190,
  193,
  187,
  196,
  184,
  190,
  187,
  187,
  193,
  184,
  193,
  190,
  190,
  190,
  190,
  190,
  193,
  190,
  190,
  184,
  193,
  187,
  184,
  190,
  193,
  190,
  190,
  184,
  184,
  187,
  190,
  193,
  184,
  193,
  190,
  184,
  193,
  190,
  190,
  193,
  187,
  193,
  193,
  193,
  190,
  193,
  190,
  196,
  187,
  190,
  193,
  184,
  184,
  187,
  190,
  184,
  190,
  184,
  190,
  193,
  187,
  187,
  193,
  190,
  181,
  190,
  190,
  190,
  178,
  187,
  190,
  193,
  190,
  184,
  193,
  187,
  184,
  190,
  190,
  184,
  190,
  187,
  193,
  196,
  193,
  193,
  190,
  193,
  187,
  178,
  187,
  184,
  187,
  193,
  184,
  196,
  190,
  190,
  181,
  190,
  193,
  190,
  190,
  190,
  190,
  190,
  187,
  196,
  187,
  196,
  190,
  190,
  184,
  190,
  190,
  193,
  193,
  196,
  193,
  190,
  190,
  190,
  187,
  184,
  190,
  193,
  196,
  187,
  187,
  181,
  193,
  193,
  190,
  190,
  196,
  187,
  193,
  190,
  196,
  196,
  193,
  199,
  184,
  196,
  184,
  190,
  190,
  193,
  187,
  187,
  190,
  187,
  196,
  187,
  190,
  193,
  184,
  193,
  184,
  190,
  184,
  181,
  193,
  187,
  184,
  181,
  193,
  193,
  190,
  190,
  187,
  196,
  184,
  184,
  187,
  187,
  196,
  187,
  187,
  196,
  190,
  196,
  190,
  193,
  193,
  187,
  193,
  187,
  190,
  190,
  187,
  193,
  187,
  184,
  190,
  190,
  190,
  193,
  196,
  184,
  190,
  187,
  187,
  193,
  193,
  193,
  190,
  187,
  196,
  193,
  190,
  190,
  184,
  193,
  196,
  187,
  196,
  184,
  190,
  190,
  190,
  196,
  193,
  184,
  190,
  184,
  193,
  187,
  196,
  196,
  190,
  196,
  193,
  190,
  187,
  190,
  190,
  190,
  190,
  190,
  190,
  187,
  196,
  184,
  190,
  187,
  193,
  190,
  193,
  193,
  193,
  193,
  190,
  187,
  193,
  184,
  196,
  190,
  187,
  193,
  193,
  190,
  190,
  193,
  193,
  187,
  184,
  190,
  193,
  190,
  187,
  187,
  187,
  187,
  184,
  187,
  190,
  190,
  190,
  187,
  187,
  190,
  196,
  193,
  190,
  190,
  187,
  187,
  190,
  190,
  190,
  190,
  196,
  190,
  193,
  184,
  193,
  190,
  190,
  187,
  190,
  193,
  196,
  187,
  187,
  193,
  184,
  184,
  193,
  196,
  190,
  187,
  184,
  196,
  190,
  190,
  187,
  193,
  193,
  187,
  184,
  190,
  190,
  187,
  190,
  184,
  196,
  190,
  187,
  193,
  190,
  190,
  190,
  190,
  193,
  193,
  193,
  193,
  190,
  196,
  190,
  181,
  187,
  184,
  190,
  193,
  187,
  187,
  184,
  193,
  187,
  190,
  190,
  196,
  190,
  193,
  190,
  193,
  190,
  187,
  187,
  190,
  190,
  190,
  187,
  190,
  187,
  190,
  187,
  196,
  187,
  193,
  190,
  187,
  190,
  193,
  184,
  193,
  187,
  190,
  190,
  193,
  187,
  190,
  193,
  193,
  190,
  187,
  193,
  190,
  193,
  193,
  184,
  190,
  193,
  190,
  193,
  190,
  190,
  193,
  187,
  184,
  187,
  181,
  190,
  184,
  187,
  187,
  187,
  184,
  193,
  190,
  190,
  184,
  193,
  190,
  187,
  193,
  184,
  187,
  190,
  193,
  187,
  196,
  196,
  193,
  184,
  190,
  190,
  190,
  187,
  190,
  187,
  190,
  190,
  187,
  190,
  187,
  187,
  196,
  190,
  196,
  190,
  193,
  187,
  190,
  187,
  187,
  196,
  190,
  190,
  190,
  187,
  181,
  190,
  187,
  193,
  187,
  190,
  184,
  187,
  196,
  196,
  184,
  187,
  190,
  190,
  190,
  187,
  184,
  190,
  187,
  187,
  193,
  187,
  187,
  190,
  193,
  193,
  190,
  187,
  190,
  193,
  184,
  193,
  190,
  196,
  190,
  184,
  184,
  193,
  196,
  190,
  193,
  184,
  196,
  196,
  190,
  187,
  184,
  190,
  184,
  196,
  184,
  193,
  190,
  193,
  190,
  184,
  190,
  187,
  193,
  184,
  193,
  178,
  190,
  187,
  190,
  187,
  196,
  187,
  190,
  193,
  187,
  190,
  196,
  193,
  187,
  190,
  193,
  190,
  187,
  190,
  187,
  190,
  190,
  190,
  187,
  190,
  181,
  187,
  187,
  190,
  190,
  196,
  187,
  187,
  181,
  190,
  193,
  190,
  190,
  190,
  184,
  193,
  190,
  193,
  196,
  190,
  187,
  190,
  193,
  190,
  187,
  187,
  190,
  187,
  190,
  190,
  184,
  187,
  190,
  190,
  184,
  184,
  187,
  196,
  187,
  193,
  193,
  190,
  187,
  190,
  190,
  184,
  196,
  190,
  190,
  190,
  196,
  190,
  184,
  196,
  190,
  196,
  190,
  181,
  190,
  190,
  187,
  187,
  190,
  190,
  196,
  193,
  187,
  187,
  196,
  187,
  187,
  190,
  187,
  184,
  184,
  190,
  190,
  181,
  184,
  193,
  190,
  187,
  190,
  193,
  190,
  184,
  196,
  193,
  187,
  196,
  193,
  187,
  187,
  196,
  190,
  181,
  184,
  196,
  193,
  190,
  196,
  187,
  187,
  190,
  187,
  190,
  184,
  184,
  190,
  190,
  190,
  187,
  187,
  187,
  196,
  187,
  187,
  196,
  184,
  190,
  193,
  193,
  193,
  187,
  193,
  190,
  193,
  193,
  193,
  187,
  190,
  193,
  190,
  190,
  190,
  196,
  193,
  193,
  193,
  184,
  187,
  193,
  193,
  190,
  190,
  184,
  193,
  184,
  196,
  190,
  184,
  187,
  190,
  190,
  190,
  193,
  187,
  190,
  190,
  187,
  190,
  190,
  184,
  190,
  187,
  190,
  196,
  190,
  190,
  190,
  190,
  196,
  184,
  193,
  196,
  196,
  187,
  190,
  196,
  196,
  190,
  190,
  187,
  187,
  190,
  190,
  190,
  187,
  196,
  187,
  181,
  190,
  187,
  190,
  193,
  193,
  193,
  190,
  190,
  187,
  187,
  190,
  190,
  193,
  190,
  190,
  190,
  190,
  190,
  190,
  190,
  190,
  190,
  184,
  190,
  190,
  193,
  184,
  184,
  187,
  193,
  187,
  187,
  190,
  187,
  187,
  187,
  184,
  184,
  193,
  193,
  187,
  193,
  190,
  187,
  190,
  193,
  196,
  190,
  184,
  187,
  190,
  187,
  190,
  187,
  190,
  193,
  196,
  187,
  196,
  184,
  187,
  190,
  190,
  190,
  193,
  190,
  190,
  196,
  187,
  187,
  190,
  193,
  193,
  190,
  190,
  190,
  190,
  187,
  184,
  187,
  193,
  190,
  193,
  193,
  190,
  190,
  190,
  196,
  196,
  190,
  193,
  190,
  190,
  190,
  190,
  190,
  190,
  190,
  193,
  193,
  190,
  184,
  187,
  193,
  184,
  190,
  190,
  193,
  187,
  193,
  190,
  187,
  196,
  184,
  190,
  193,
  187,
  193,
  190,
  187,
  184,
  193,
  187,
  190,
  193,
  193,
  184,
  190,
  190,
  190,
  190,
  196,
  187,
  196,
  190,
  187,
  190,
  193,
  190,
  187,
  187,
  190,
  190,
  190,
  193,
  190,
  193,
  187,
  187,
  187,
  190,
  190,
  184,
  187,
  193,
  184,
  193,
  187,
  190,
  193,
  184,
  190,
  190,
  190,
  190,
  190,
  193,
  193,
  187,
  190,
  187,
  190,
  196,
  193,
  196,
  190,
  181,
  190,
  190,
  184,
  196,
  190,
  196,
  190,
  190,
  193,
  196,
  184,
  184,
  190,
  193,
  187,
  190,
  184,
  190,
  190,
  193,
  193,
  193,
  187,
  196,
  190,
  190,
  184,
  196,
  193,
  190,
  193,
  190,
  196,
  193,
  190,
  190,
  190,
  187,
  184,
  190,
  190,
  190,
  190,
  178,
  196,
  187,
  184,
  196,
  190,
  187,
  199,
  190,
  187,
  196,
  190,
  184,
  196,
  196,
  196,
  196,
  196,
  190,
  190,
  181,
  187,
  196,
  193,
  190,
  184,
  184,
  187,
  187,
  190,
  190,
  187,
  187,
  193,
  184,
  193,
  190,
  187,
  193,
  187,
  193,
  190,
  196,
  190,
  190,
  190,
  190,
  190,
  187,
  196,
  187,
  190,
  196,
  190,
  184,
  187,
  193,
  187,
  187,
  196,
  184,
  190,
  187,
  184,
  190,
  187,
  190,
  190,
  187,
  190,
  190,
  178,
  184,
  190,
  187,
  190,
  187,
  196,
  187,
  190,
  190,
  193,
  184,
  190,
  193,
  190,
  184,
  196,
  187,
  190,
  190,
  184,
  190,
  190,
  193,
  190,
  190,
  193,
  190,
  193,
  190,
  190,
  187,
  190,
  193,
  190,
  187,
  190,
  190,
  193,
  193,
  190,
  187,
  193,
  184,
  184,
  193,
  190,
  190,
  196,
  190,
  190,
  187,
  196,
  190,
  190,
  190,
  196,
  187,
  193,
  190,
  190,
  187,
  187,
  193,
  190,
  193,
  184,
  190,
  190,
  190,
  193,
  187,
  187,
  193,
  187,
  190,
  190,
  187,
  190,
  190,
  193,
  190,
  184,
  196,
  190,
  187,
  193,
  196,
  190,
  196,
  190,
  196,
  190,
  193,
  193,
  181,
  190,
  187,
  190,
  190,
  190,
  184,
  187,
  196,
  196,
  187,
  196,
  190,
  184,
  196,
  193,
  196,
  190,
  193,
  196,
  190,
  187,
  193,
  190,
  187,
  190,
  190,
  190,
  193,
  187,
  193,
  190,
  187,
  193,
  190,
  184,
  184,
  184,
  193,
  190,
  190,
  190,
  190,
  196,
  193,
  196,
  184,
  187,
  187,
  187,
  187,
  193,
  190,
  187,
  184,
  187,
  184,
  190,
  190,
  193,
  187,
  187,
  187,
  190,
  187,
  193,
  190,
  190,
  193,
  187,
  184,
  196,
  196,
  190,
  190,
  190,
  187,
  193,
  196,
  190,
  190,
  181,
  193,
  193,
  193,
  187,
  187,
  190,
  187,
  196,
  190,
  193,
  187,
  187,
  184,
  190,
  187,
  187,
  190,
  190,
  190,
  196,
  184,
  193,
  190,
  193,
  193,
  187,
  190,
  187,
  193,
  190,
  193,
  181,
  190,
  196,
  190,
  190,
  193,
  184,
  187,
  187,
  190,
  187,
  193,
  190,
  187,
  193,
  193,
  196,
  190,
  187,
  193,
  184,
  187,
  190,
  181,
  193,
  184,
  190,
  184,
  190,
  190,
  187,
  190,
  196,
  193,
  190,
  193,
  184,
  190,
  190,
  184,
  196,
  190,
  193,
  193,
  193,
  187,
  190,
  187,
  190,
  190,
  184,
  196,
  184,
  187,
  181,
  196,
  196,
  190,
  190,
  190,
  193,
  187,
  190,
  190,
  190,
  190,
  190,
  190,
  196,
  193,
  190,
  184,
  181,
  187,
  190,
  184,
  190,
  196,
  178,
  187,
  190,
  193,
  193,
  193,
  184,
  193,
  193,
  187,
  187,
  190,
  196,
  187,
  190,
  190,
  193,
  193,
  187,
  190,
  196,
  187,
  190,
  190,
  184,
  184,
  193,
  196,
  184,
  184,
  193,
  187,
  187,
  184,
  184,
  193,
  190,
  190,
  193,
  187,
  187,
  196,
  196,
  196,
  190,
  190,
  196,
  190,
  187,
  190,
  187,
  190,
  187,
  190,
  190,
  184,
  196,
  187,
  190,
  184,
  190,
  187,
  193,
  187,
  187,
  190,
  184,
  187,
  190,
  187,
  190,
  190,
  190,
  184,
  190,
  187,
  184,
  190,
  193,
  196,
  190,
  187,
  184,
  190,
  187,
  184,
  187,
  196,
  190,
  193,
  190,
  190,
  187,
  187,
  193,
  178,
  190,
  187,
  193,
  193,
  193,
  190,
  196,
  190,
  193,
  190,
  187,
  190,
  190,
  187,
  190,
  190,
  196,
  190,
  193,
  187,
  193,
  184,
  190,
  193,
  190,
  184,
  193,
  184,
  187,
  193,
  190,
  193,
  190,
  187,
  190,
  184,
  190,
  196,
  193,
  181,
  181,
  190,
  193,
  184,
  184,
  187,
  193,
  187,
  190,
  184,
  184,
  190,
  187,
  196,
  193,
  190,
  187,
  184,
  190,
  190,
  190,
  190,
  193,
  190,
  187,
  190,
  193,
  190,
  196,
  187,
  187,
  184,
  187,
  190,
  181,
  187,
  190,
  190,
  190,
  184,
  190,
  187,
  193,
  190,
  193,
  190,
  193,
  190,
  184,
  184,
  184,
  193,
  190,
  196,
  184,
  193,
  190,
  190,
  190,
  190,
  187,
  184,
  187,
  190,
  184,
  193,
  193,
  187,
  187,
  187,
  184,
  193,
  196,
  193,
  187,
  190,
  190,
  181,
  193,
  190,
  190,
  196,
  193,
  196,
  187,
  193,
  190,
  190,
  190,
  190,
  187,
  190,
  190,
  190,
  184,
  190,
  193,
  193,
  190,
  184,
  193,
  196,
  193,
  196,
  190,
  190,
  190,
  193,
  196,
  187,
  190,
  190,
  187,
  187,
  190,
  190,
  184,
  196,
  196,
  184,
  193,
  190,
  190,
  196,
  187,
  190,
  187,
  187,
  190,
  187,
  190,
  193,
  190,
  193,
  187,
  190,
  190,
  196,
  190,
  190,
  190,
  190,
  193,
  193,
  184,
  187,
  193,
  187,
  184,
  190,
  193,
  190,
  190,
  187,
  196,
  190,
  187,
  190,
  187,
  190,
  190,
  187,
  187,
  190,
  187,
  193,
  190,
  187,
  190,
  187,
  178,
  181,
  190,
  196,
  193,
  193,
  190,
  190,
  187,
  181,
  190,
  187,
  190,
  187,
  190,
  193,
  193,
  190,
  190,
  190,
  190,
  187,
  184,
  196,
  193,
  190,
  187,
  187,
  193,
  187,
  187,
  190,
  190,
  184,
  190,
  193,
  193,
  187,
  196,
  193,
  187,
  190,
  193,
  190,
  184,
  187,
  193,
  190,
  184,
  187,
  181,
  193,
  187,
  196,
  187,
  187,
  184,
  193,
  190,
  187,
  193,
  190,
  196,
  190,
  184,
  193,
  190,
  193,
  190,
  190,
  187,
  187,
  190,
  184,
  187,
  190,
  190,
  184,
  193,
  190,
  190,
  193,
  187,
  193,
  190,
  193,
  181,
  190,
  193,
  193,
  193,
  193,
  187,
  190,
  187,
  190,
  193,
  187,
  190,
  187,
  187,
  187,
  196,
  190,
  193,
  187,
  187,
  187,
  187,
  190,
  196,
  193,
  187,
  181,
  184,
  190,
  184,
  196,
  187,
  190,
  196,
  190,
  193,
  184,
  190,
  193,
  184,
  184,
  187,
  190,
  190,
  187,
  184,
  196,
  187,
  184,
  190,
  190,
  184,
  190,
  190,
  193,
  190,
  193,
  190,
  184,
  187,
  193,
  196,
  187,
  190,
  184,
  184,
  190,
  190,
  184,
  190,
  187,
  196,
  190,
  184,
  193,
  190,
  196,
  187,
  187,
  187,
  193,
  187,
  190,
  187,
  196,
  190,
  184,
  193,
  184,
  187,
  184,
  190,
  187,
  184,
  190,
  184,
  190,
  184,
  190,
  196,
  184,
  196,
  187,
  190,
  190,
  190,
  187,
  184,
  193,
  187,
  190,
  190,
  196,
  178,
  187,
  193,
  187,
  196,
  190,
  193,
  190,
  193,
  190,
  193,
  184,
  196,
  193,
  190,
  190,
  190,
  190,
  196,
  184,
  184,
  193,
  184,
  190,
  187,
  196,
  193,
  181,
  184,
  190,
  187,
  184,
  187,
  187,
  196,
  184,
  184,
  190,
  193,
  190,
  190,
  190,
  193,
  190,
  193,
  193,
  193,
  184,
  196,
  190,
  184,
  187,
  196,
  190,
  190,
  190,
  187,
  196,
  190,
  190,
  190,
  190,
  187,
  190,
  187,
  190,
  187,
  190,
  190,
  196,
  190,
  184,
  196,
  187,
  190,
  190,
  184,
  184,
  196,
  187,
  178,
  184,
  187,
  181,
  187,
  193,
  193,
  193,
  187,
  190,
  187,
  187,
  190,
  190,
  190,
  184,
  193,
  193,
  193,
  190,
  193,
  190,
  187,
  193,
  193,
  190,
  190,
  187,
  190,
  187,
  187,
  190,
  193,
  187,
  193,
  190,
  190,
  196,
  187,
  193,
  187,
  187,
  196,
  190,
  193,
  193,
  196,
  190,
  187,
  190,
  184,
  184,
  193,
  196,
  190,
  190,
  184,
  187,
  187,
  190,
  187,
  187,
  193,
  193,
  190,
  190,
  190,
  187,
  193,
  193,
  184,
  187,
  187,
  187,
  184,
  193,
  187,
  184,
  187,
  190,
  187,
  193,
  190,
  187,
  187,
  184,
  187,
  184,
  187,
  184,
  187,
  184,
  190,
  190,
  190,
  190,
  187,
  196,
  190,
  190,
  190,
  184,
  196,
  187,
  190,
  190,
  184,
  187,
  190,
  184,
  193,
  193,
  190,
  193,
  190,
  184,
  190,
  196,
  187,
  187,
  187,
  184,
  184,
  190,
  187,
  187,
  190,
  190,
  187,
  190,
  196,
  193,
  190,
  187,
  193,
  184,
  190,
  196,
  190,
  193,
  184,
  187,
  190,
  190,
  193,
  193,
  184,
  196,
  190,
  184,
  196,
  184,
  193,
  184,
  190,
  187,
  187,
  187,
  187,
  193,
  187,
  190,
  187,
  190,
  193,
  196,
  190,
  187,
  190,
  184,
  196,
  196,
  184,
  193,
  190,
  190,
  190,
  193,
  187,
  196,
  187,
  190,
  190,
  187,
  190,
  190,
  193,
  190,
  190,
  190,
  190,
  187,
  190,
  184,
  190,
  187,
  187,
  184,
  193,
  190,
  187,
  187,
  193,
  190,
  193,
  187,
  190,
  190,
  190,
  193,
  196,
  190,
  187,
  190,
  184,
  184,
  190,
  187,
  190,
  196,
  193,
  190,
  190,
  187,
  184,
  187,
  193,
  190,
  193,
  193,
  190,
  196,
  187,
  190,
  190,
  190,
  196,
  193,
  193,
  184,
  196,
  190,
  190,
  187,
  187,
  181,
  193,
  190,
  193,
  190,
  196,
  190,
  187,
  190,
  190,
  184,
  187,
  187,
  190,
  193,
  190,
  196,
  184,
  187,
  190,
  193,
  193,
  193,
  193,
  190,
  190,
  187,
  196,
  190,
  190,
  190,
  190,
  193,
  196,
  190,
  190,
  196,
  193,
  190,
  187,
  184,
  193,
  190,
  193,
  190,
  190,
  196,
  187,
  190,
  193,
  196,
  187,
  190,
  187,
  190,
  187,
  196,
  193,
  184,
  184,
  187,
  193,
  190,
  193,
  181,
  187,
  193,
  190,
  196,
  196,
  193,
  187,
  187,
  184,
  190,
  190,
  184,
  187,
  187,
  187,
  190,
  190,
  190,
  196,
  190,
  190,
  193,
  190,
  184,
  187,
  196,
  193,
  196,
  190,
  190,
  187,
  190,
  187,
  190,
  187,
  190,
  190,
  190,
  181,
  196,
  187,
  193,
  190,
  181,
  187,
  190,
  190,
  190,
  187,
  190,
  184,
  184,
  187,
  190,
  190,
  190,
  187,
  184,
  187,
  193,
  187,
  193,
  193,
  190,
  187,
  193,
  187,
  190,
  187,
  187,
  190,
  187,
  196,
  193,
  187,
  190,
  193,
  193,
  187,
  190,
  193,
  190,
  193,
  184,
  187,
  184,
  190,
  196,
  190,
  187,
  196,
  187,
  187,
  193,
  181,
  181,
  196,
  190,
  193,
  193,
  190,
  196,
  190,
  184,
  181,
  190,
  190,
  196,
  190,
  196,
  196,
  190,
  190,
  193,
  187,
  190,
  187,
  184,
  190,
  184,
  190,
  190,
  190,
  184,
  190,
  184,
  184,
  190,
  190,
  190,
  184,
  187,
  187,
  190,
  184,
  196,
  196,
  190,
  190,
  196,
  187,
  190,
  190,
  184,
  190,
  187,
  184,
  190,
  184,
  184,
  187,
  184,
  196,
  193,
  187,
  190,
  187,
  184,
  193,
  184,
  187,
  187,
  187,
  190,
  187,
  190,
  184,
  196,
  193,
  190,
  190,
  190,
  187,
  193,
  190,
  193,
  190,
  190,
  193,
  184,
  190,
  190,
  187,
  193,
  190,
  193,
  184,
  193,
  196,
  193,
  190,
  190,
  193,
  187,
  190,
  187,
  190,
  187,
  193,
  184,
  184,
  190,
  190,
  187,
  196,
  187,
  193,
  184,
  190,
  196,
  190,
  196,
  193,
  184,
  190,
  190,
  190,
  190,
  190,
  196,
  193,
  193,
  184,
  190,
  190,
  193,
  190,
  190,
  187,
  184,
  187,
  184,
  190,
  190,
  190,
  187,
  184,
  181,
  190,
  181,
  190,
  193,
  190,
  190,
  193,
  187,
  190,
  184,
  190,
  193,
  193,
  190,
  196,
  190,
  190,
  190,
  190,
  190,
  190,
  190,
  184,
  193,
  184,
  196,
  187,
  190,
  193,
  187,
  184,
  190,
  196,
  190,
  190,
  187,
  184,
  190,
  187,
  190,
  184,
  187,
  190,
  187,
  187,
  190,
  193,
  184,
  190,
  190,
  196,
  196,
  190,
  190,
  187,
  193,
  196,
  181,
  190,
  187,
  190,
  184,
  184,
  184,
  193,
  193,
  193,
  190,
  193,
  187,
  190,
  181,
  190,
  190,
  187,
  181,
  178,
  190,
  196,
  187,
  190,
  196,
  190,
  196,
  187,
  190,
  187,
  187,
  196,
  193,
  187,
  184,
  187,
  184,
  190,
  190,
  184,
  193,
  196,
  190,
  190,
  190,
  184,
  190,
  190,
  190,
  190,
  190,
  184,
  184,
  184,
  190,
  190,
  196,
  190,
  187,
  187,
  193,
  190,
  193,
  190,
  190,
  193,
  187,
  187,
  196,
  193,
  187,
  196,
  187,
  196,
  190,
  187,
  190,
  190,
  187,
  190,
  193,
  193,
  196,
  187,
  187,
  184,
  190,
  193,
  190,
  184,
  196,
  193,
  190,
  193,
  190,
  184,
  187,
  187,
  190,
  190,
  190,
  190,
  181,
  190,
  196,
  190,
  190,
  187,
  190,
  187,
  187,
  184,
  193,
  190,
  190,
  193,
  190,
  184,
  196,
  190,
  196,
  184,
  190,
  190,
  193,
  187,
  190,
  187,
  190,
  190,
  187,
  190,
  187,
  190,
  187,
  190,
  187,
  193,
  187,
  187,
  196,
  187,
  196,
  193,
  193,
  193,
  193,
  196,
  187,
  196,
  190,
  193,
  184,
  187,
  190,
  193,
  190,
  196,
  190,
  193,
  193,
  187,
  193,
  193,
  187,
  190,
  187,
  187,
  187,
  190,
  190,
  187,
  190,
  190,
  196,
  190,
  187,
  190,
  187,
  184,
  193,
  187,
  184,
  184,
  187,
  196,
  190,
  193,
  190,
  184,
  187,
  187,
  184,
  187,
  190,
  190,
  187,
  190,
  193,
  187,
  190,
  187,
  190,
  190,
  190,
  193,
  196,
  190,
  190,
  193,
  190,
  184,
  190,
  193,
  184,
  187,
  193,
  193,
  184,
  196,
  193,
  187,
  187,
  196,
  193,
  196,
  190,
  193,
  190,
  190,
  190,
  187,
  184,
  190,
  190,
  187,
  190,
  193,
  193,
  190,
  190,
  187,
  196,
  184,
  190,
  184,
  190,
  193,
  184,
  190,
  187,
  187,
  187,
  190,
  190,
  187,
  193,
  193,
  190,
  190,
  190,
  187,
  190,
  190,
  187,
  190,
  184,
  187,
  190,
  190,
  190,
  184,
  193,
  187,
  181,
  190,
  193,
  196,
  190,
  187,
  187,
  190,
  193,
  193,
  184,
  196,
  187,
  193,
  193,
  190,
  190,
  190,
  193,
  178,
  193,
  190,
  190,
  193,
  190,
  196,
  190,
  190,
  187,
  184,
  190,
  187,
  196,
  193,
  190,
  187,
  190,
  193,
  193,
  187,
  187,
  184,
  193,
  190,
  196,
  190,
  193,
  190,
  184,
  184,
  196,
  193,
  190,
  184,
  187,
  193,
  193,
  193,
  190,
  190,
  187,
  193,
  187,
  196,
  190,
  187,
  190,
  190,
  190,
  190,
  193,
  193,
  193,
  196,
  187,
  190,
  187,
  187,
  187,
  190,
  187,
  187,
  187,
  196,
  190,
  187,
  193,
  190,
  187,
  190,
  190,
  190,
  190,
  190,
  190,
  193,
  184,
  193,
  187,
  190,
  184,
  187,
  190,
  190,
  190,
  187,
  187,
  193,
  187,
  196,
  187,
  184,
  187,
  193,
  187,
  193,
  190,
  187,
  190,
  190,
  187,
  196,
  184,
  187,
  193,
  193,
  196,
  184,
  184,
  187,
  193,
  190,
  196,
  187,
  184,
  187,
  190,
  196,
  190,
  190,
  187,
  184,
  193,
  193,
  187,
  190,
  184,
  190,
  187,
  187,
  190,
  193,
  184,
  187,
  187,
  190,
  193,
  187,
  184,
  193,
  193,
  196,
  190,
  187,
  190,
  190,
  187,
  184,
  190,
  187,
  184,
  187,
  193,
  184,
  193,
  193,
  184,
  196,
  193,
  190,
  190,
  187,
  196,
  187,
  181,
  187,
  190,
  190,
  193,
  187,
  187,
  190,
  187,
  190,
  178,
  190,
  196,
  187,
  196,
  184,
  190,
  193,
  193,
  187,
  187,
  190,
  184,
  190,
  184,
  196,
  187,
  190,
  187,
  196,
  190,
  187,
  187,
  190,
  190,
  190,
  190,
  190,
  190,
  190,
  187,
  196,
  193,
  184,
  184,
  190,
  193,
  190,
  190,
  193,
  193,
  193,
  193,
  184,
  190,
  184,
  193,
  187,
  193,
  190,
  190,
  190,
  184,
  187,
  190,
  184,
  196,
  187,
  190,
  187,
  187,
  187,
  190,
  190,
  187,
  190,
  184,
  190,
  184,
  178,
  187,
  184,
  193,
  190,
  193,
  187,
  193,
  184,
  190,
  187,
  190,
  184,
  196,
  190,
  190,
  196,
  187,
  190,
  190,
  190,
  184,
  187,
  190,
  184,
  184,
  190,
  193,
  184,
  193,
  184,
  193,
  193,
  190,
  196,
  187,
  187,
  196,
  193,
  190,
  187,
  190,
  184,
  187,
  193,
  190,
  187,
  184,
  184,
  193,
  193,
  187,
  187,
  193,
  187,
  187,
  187,
  190,
  190,
  193,
  190,
  190,
  196,
  190,
  187,
  184,
  190,
  193,
  193,
  190,
  193,
  193,
  190,
  196,
  187,
  190,
  184,
  190,
  184,
  196,
  190,
  199,
  190,
  187,
  184,
  196,
  187,
  190,
  190,
  190,
  190,
  187,
  190,
  190,
  187,
  190,
  193,
  190,
  196,
  190,
  184,
  187,
  190,
  187,
  187,
  190,
  190,
  190,
  190,
  187,
  190,
  187,
  190,
  184,
  190,
  190,
  190,
  196,
  187,
  187,
  193,
  190,
  184,
  181,
  193,
  187,
  193,
  187,
  196,
  193,
  190,
  190,
  187,
  196,
  187,
  187,
  184,
  190,
  181,
  190,
  190,
  184,
  190,
  190,
  190,
  190,
  196,
  184,
  187,
  190,
  184,
  190,
  190,
  190,
  184,
  187,
  193,
  193,
  190,
  196,
  193,
  187,
  187,
  196,
  193,
  187,
  187,
  187,
  187,
  193,
  196,
  193,
  190,
  196,
  196,
  190,
  190,
  190,
  190,
  184,
  190,
  193,
  190,
  187,
  190,
  190,
  190,
  187,
  190,
  190,
  184,
  184,
  193,
  193,
  184,
  193,
  184,
  193,
  187,
  193,
  196,
  184,
  184,
  190,
  196,
  187,
  187,
  190,
  178,
  190,
  190,
  181,
  190,
  184,
  187,
  184,
  193,
  190,
  196,
  193,
  190,
  193,
  193,
  181,
  187,
  187,
  193,
  184,
  178,
  184,
  190,
  187,
  193,
  190,
  196,
  187,
  193,
  187,
  196,
  190,
  196,
  190,
  187,
  190,
  190,
  190,
  187,
  190,
  190,
  193,
  187,
  190,
  196,
  193,
  196,
  196,
  193,
  190,
  196,
  190,
  190,
  187,
  190,
  196,
  190,
  187,
  190,
  187,
  190,
  193,
  193,
  193,
  190,
  193,
  187,
  190,
  193,
  190,
  190,
  193,
  193,
  190,
  184,
  196,
  190,
  190,
  184,
  190,
  190,
  190,
  184,
  190,
  187,
  190,
  187,
  196,
  190,
  190,
  190,
  190,
  190,
  187,
  193,
  196,
  190,
  187,
  196,
  184,
  193,
  193,
  190,
  187,
  196,
  187,
  190,
  190,
  196,
  190,
  190,
  193,
  190,
  196,
  190,
  184,
  193,
  193,
  193,
  187,
  187,
  190,
  193,
  187,
  190,
  187,
  181,
  187,
  184,
  193,
  187,
  187,
  193,
  196,
  187,
  190,
  193,
  196,
  193,
  190,
  190,
  187,
  190,
  193,
  193,
  190,
  190,
  190,
  190,
  190,
  190,
  190,
  193,
  193,
  190,
  193,
  181,
  187,
  187,
  193,
  184,
  190,
  190,
  190,
  193,
  196,
  193,
  184,
  190,
  187,
  190,
  190,
  193,
  190,
  190,
  190,
  178,
  193,
  187,
  190,
  187,
  196,
  190,
  190,
  190,
  196,
  196,
  184,
  187,
  190,
  190,
  196,
  187,
  193,
  187,
  190,
  184,
  193,
  190,
  193,
  193,
  190,
  190,
  193,
  184,
  190,
  184,
  193,
  190,
  196,
  187,
  187,
  190,
  184,
  187,
  190,
  196,
  190,
  193,
  190,
  190,
  190,
  193,
  190,
  184,
  193,
  187,
  190,
  193,
  196,
  190,
  187,
  184,
  193,
  184,
  196,
  190,
  190,
  190,
  187,
  196,
  184,
  187,
  184,
  190,
  184,
  184,
  184,
  190,
  190,
  190,
  190,
  196,
  184,
  190,
  184,
  196,
  190,
  190,
  193,
  193,
  190,
  190,
  190,
  184,
  184,
  187,
  190,
  190,
  184,
  187,
  187,
  184,
  196,
  187,
  190,
  190,
  193,
  187,
  193,
  184,
  187,
  193,
  193,
  193,
  184,
  184,
  190,
  187,
  184,
  187,
  184,
  193,
  193,
  190,
  190,
  190,
  187,
  190,
  184,
  190,
  190,
  193,
  187,
  184,
  184,
  196,
  193,
  190,
  184,
  181,
  196,
  190,
  187,
  187,
  193,
  190,
  193,
  190,
  190,
  193,
  193,
  187,
  190,
  196,
  184,
  190,
  187,
  184,
  187,
  193,
  190,
  196,
  196,
  184,
  193,
  187,
  190,
  190,
  190,
  190,
  184,
  190,
  184,
  190,
  190,
  193,
  190,
  190,
  196,
  187,
  196,
  190,
  190,
  190,
  196,
  193,
  193,
  184,
  190,
  187,
  184,
  187,
  187,
  187,
  184,
  193,
  190,
  193,
  190,
  184,
  184,
  190,
  196,
  184,
  190,
  190,
  196,
  184,
  190,
  190,
  190,
  190,
  187,
  190,
  184,
  193,
  184,
  187,
  190,
  193,
  193,
  190,
  190,
  193,
  196,
  190,
  190,
  190,
  190,
  190,
  193,
  190,
  190,
  190,
  193,
  184,
  184,
  187,
  190,
  193,
  190,
  187,
  190,
  190,
  187,
  193,
  190,
  193,
  193,
  187,
  190,
  187,
  193,
  178,
  190,
  190,
  190,
  190,
  190,
  190,
  190,
  190,
  184,
  184,
  196,
  193,
  187,
  187,
  187,
  190,
  187,
  196,
  184,
  196,
  190,
  190,
  184,
  190,
  190,
  190,
  187,
  184,
  187,
  190,
  187,
  193,
  196,
  190,
  187,
  187,
  193,
  193,
  190,
  190,
  196,
  184,
  187,
  187,
  196,
  196,
  190,
  196,
  193,
  187,
  190,
  190,
  193,
  190,
  190,
  193,
  187,
  190,
  190,
  184,
  190,
  190,
  187,
  190,
  193,
  187,
  190,
  187,
  190,
  190,
  193,
  184,
  196,
  187,
  190,
  190,
  187,
  193,
  196,
  187,
  187,
  196,
  187,
  190,
  187,
  190,
  190,
  190,
  190,
  190,
  187,
  187,
  190,
  193,
  190,
  193,
  190,
  187,
  190,
  193,
  184,
  190,
  193,
  184,
  187,
  190,
  190,
  184,
  190,
  190,
  190,
  187,
  196,
  190,
  181,
  190,
  193,
  187,
  184,
  184,
  190,
  187,
  190,
  187,
  190,
  190,
  190,
  190,
  190,
  190,
  184,
  187,
  187,
  190,
  190,
  184,
  190,
  190,
  184,
  193,
  190,
  190,
  190,
  190,
  193,
  196,
  190,
  187,
  187,
  196,
  190,
  190,
  184,
  193,
  193,
  190,
  190,
  184,
  196,
  193,
  187,
  196,
  193,
  190,
  184,
  193,
  196,
  184,
  187,
  187,
  187,
  196,
  190,
  187,
  193,
  190,
  193,
  193,
  187,
  196,
  190,
  184,
  187,
  190,
  184,
  190,
  190,
  190,
  190,
  190,
  193,
  190,
  190,
  193,
  184,
  196,
  193,
  196,
  196,
  184,
  190,
  184,
  187,
  193,
  187,
  190,
  190,
  190,
  190,
  196,
  187,
  187,
  196,
  193,
  187,
  184,
  193,
  187,
  190,
  190,
  190,
  187,
  193,
  190,
  190,
  196,
  187,
  193,
  190,
  187,
  187,
  196,
  190,
  187,
  190,
  193,
  184,
  190,
  190,
  190,
  187,
  187,
  193,
  187,
  187,
  190,
  193,
  187,
  190,
  190,
  187,
  184,
  184,
  184,
  190,
  193,
  187,
  193,
  187,
  190,
  193,
  193,
  187,
  184,
  187,
  187,
  190,
  196,
  193,
  184,
  187,
  193,
  187,
  190,
  190,
  190,
  190,
  187,
  190,
  193,
  184,
  196,
  184,
  187,
  193,
  187,
  190,
  187,
  187,
  190,
  190,
  193,
  196,
  187,
  190,
  184,
  196,
  190,
  190,
  190,
  193,
  187,
  190,
  193,
  190,
  187,
  184,
  190,
  190,
  193,
  190,
  190,
  193,
  184,
  193,
  190,
  190,
  184,
  187,
  190,
  187,
  190,
  190,
  187,
  190,
  187,
  187,
  193,
  187,
  190,
  193,
  187,
  193,
  187,
  190,
  190,
  190,
  190,
  187,
  187,
  184,
  187,
  190,
  190,
  190,
  193,
  196,
  187,
  190,
  187,
  187,
  190,
  190,
  193,
  196,
  190,
  193,
  193,
  193,
  193,
  190,
  187,
  190,
  190,
  193,
  193,
  184,
  193,
  190,
  190,
  190,
  193,
  187,
  190,
  196,
  196,
  187,
  190,
  193,
  190,
  187,
  184,
  190,
  196,
  190,
  190,
  184,
  193,
  193,
  190,
  193,
  190,
  193,
  184,
  190,
  193,
  184,
  193,
  193,
  196,
  190,
  187,
  193,
  193,
  184,
  187,
  184,
  193,
  193,
  196,
  196,
  187,
  190,
  187,
  190,
  196,
  193,
  190,
  190,
  184,
  184,
  187,
  193,
  193,
  187,
  193,
  190,
  196,
  190,
  184,
  196,
  190,
  190,
  187,
  190,
  190,
  187,
  184,
  193,
  190,
  190,
  187,
  190,
  187,
  190,
  190,
  184,
  190,
  190,
  190,
  193,
  190,
  193,
  193,
  193,
  190,
  193,
  187,
  193,
  187,
  190,
  187,
  187,
  187,
  184,
  187,
  196,
  193,
  190,
  193,
  190,
  184,
  184,
  193,
  190,
  187,
  190,
  190,
  193,
  178,
  187,
  193,
  187,
  190,
  190,
  193,
  193,
  184,
  187,
  190,
  190,
  184,
  193,
  196,
  193,
  193,
  193,
  190,
  193,
  193,
  181,
  187,
  193,
  193,
  190,
  190,
  190,
  190,
  193,
  190,
  187,
  187,
  196,
  190,
  193,
  190,
  184,
  196,
  193,
  196,
  190,
  190,
  187,
  190,
  187,
  190,
  193,
  190,
  193,
  190,
  196,
  190,
  187,
  193,
  193,
  196,
  193,
  190,
  190,
  190,
  184,
  193,
  187,
  190,
  196,
  193,
  190,
  190,
  196,
  187,
  187,
  190,
  193,
  193,
  184,
  190,
  187,
  193,
  196,
  190,
  193,
  193,
  190,
  190,
  193,
  196,
  190,
  196,
  196,
  190,
  187,
  196,
  187,
  190,
  190,
  187,
  184,
  190,
  187,
  187,
  190,
  193,
  190,
  193,
  190,
  187,
  184,
  184,
  184,
  193,
  187,
  193,
  187,
  193,
  187,
  187,
  190,
  187,
  190,
  184,
  190,
  187,
  181,
  193,
  187,
  190,
  193,
  190,
  190,
  190,
  196,
  187,
  190,
  178,
  184,
  190,
  187,
  190,
  187,
  187,
  187,
  193,
  187,
  193,
  187,
  178,
  190,
  190,
  187,
  184,
  187,
  187,
  190,
  190,
  184,
  190,
  190,
  187,
  193,
  193,
  184,
  190,
  181,
  193,
  193,
  187,
  190,
  190,
  196,
  193,
  184,
  193,
  193,
  187,
  193,
  196,
  190,
  190,
  184,
  190,
  190,
  187,
  187,
  190,
  190,
  196,
  190,
  187,
  187,
  184,
  193,
  190,
  190,
  193,
  187,
  190,
  184,
  187,
  196,
  187,
  187,
  187,
  184,
  190,
  193,
  193,
  184,
  190,
  187,
  190,
  196,
  190,
  187,
  184,
  190,
  190,
  190,
  196,
  190,
  184,
  184,
  184,
  193,
  196,
  190,
  187,
  196,
  184,
  187,
  187,
  190,
  190,
  196,
  187,
  190,
  190,
  187,
  190,
  190,
  184,
  196,
  193,
  193,
  187,
  187,
  190,
  190,
  193,
  187,
  190,
  190,
  196,
  190,
  193,
  184,
  190,
  187,
  184,
  190,
  187,
  190,
  190,
  196,
  190,
  196,
  193,
  190,
  190,
  187,
  190,
  190,
  193,
  196,
  190,
  190,
  196,
  190,
  190,
  187,
  193,
  190,
  193,
  190,
  190,
  193,
  193,
  190,
  193,
  190,
  187,
  193,
  190,
  193,
  193,
  193,
  196,
  193,
  181,
  190,
  190,
  190,
  181,
  190,
  193,
  193,
  190,
  196,
  184,
  187,
  190,
  190,
  193,
  190,
  184,
  193,
  184,
  193,
  190,
  187,
  184,
  196,
  184,
  187,
  190,
  184,
  190,
  196,
  190,
  190,
  190,
  193,
  193,
  190,
  196,
  190,
  193,
  190,
  187,
  193,
  181,
  187,
  190,
  184,
  190,
  187,
  196,
  190,
  184,
  190,
  193,
  190,
  184,
  187,
  187,
  187,
  190,
  190,
  187,
  190,
  193,
  184,
  187,
  190,
  190,
  190,
  196,
  190,
  184,
  190,
  184,
  184,
  193,
  190,
  190,
  196,
  193,
  184,
  190,
  190,
  190,
  193,
  193,
  193,
  193,
  193,
  190,
  196,
  187,
  193,
  184,
  190,
  193,
  187,
  190,
  190,
  187,
  190,
  190,
  193,
  193,
  190,
  190,
  193,
  193,
  190,
  190,
  184,
  181,
  190,
  190,
  187,
  190,
  190,
  193,
  193,
  193,
  193,
  193,
  190,
  190,
  187,
  184,
  190,
  190,
  193,
  193,
  187,
  190,
  190,
  190,
  184,
  190,
  187,
  190,
  187,
  187,
  187,
  190,
  184,
  190,
  190,
  187,
  193,
  193,
  196,
  187,
  196,
  196,
  187,
  184,
  190,
  184,
  190,
  190,
  187,
  184,
  184,
  190,
  187,
  187,
  190,
  184,
  187,
  196,
  190,
  190,
  190,
  190,
  193,
  190,
  193,
  190,
  190,
  196,
  187,
  196,
  196,
  187,
  196,
  190,
  187,
  193,
  193,
  190,
  193,
  187,
  196,
  190,
  187,
  187,
  190,
  184,
  187,
  190,
  193,
  196,
  196,
  187,
  193,
  193,
  190,
  193,
  193,
  190,
  190,
  187,
  196,
  190,
  193,
  196,
  187,
  184,
  190,
  196,
  184,
  196,
  184,
  190,
  187,
  193,
  190,
  187,
  187,
  193,
  193,
  184,
  193,
  184,
  193,
  196,
  187,
  190,
  193,
  187,
  190,
  193,
  193,
  187,
  196,
  190,
  193,
  187,
  190,
  190,
  196,
  190,
  196,
  187,
  190,
  187,
  190,
  187,
  190,
  190,
  196,
  187,
  187,
  193,
  190,
  190,
  184,
  196,
  190,
  193,
  193,
  184,
  196,
  193,
  196,
  190,
  193,
  193,
  190,
  190,
  196,
  193,
  190,
  190,
  193,
  196,
  190,
  187,
  187,
  196,
  190,
  190,
  190,
  190,
  190,
  190,
  187,
  193,
  187,
  184,
  190,
  196,
  196,
  193,
  187,
  190,
  193,
  187,
  184,
  196,
  196,
  193,
  190,
  184,
  190,
  193,
  187,
  190,
  190,
  193,
  181,
  193,
  190,
  190,
  190,
  187,
  193,
  187,
  190,
  190,
  193,
  187,
  196,
  190,
  190,
  184,
  181,
  190,
  193,
  184,
  190,
  193,
  187,
  190,
  187,
  196,
  184,
  187,
  190,
  187,
  187,
  190,
  190,
  184,
  196,
  190,
  193,
  193,
  193,
  193,
  184,
  187,
  190,
  193,
  187,
  190,
  193,
  187,
  187,
  196,
  190,
  187,
  187,
  187,
  190,
  190,
  196,
  187,
  190,
  190,
  193,
  196,
  190,
  187,
  184,
  196,
  193,
  193,
  193,
  193,
  190,
  190,
  193,
  196,
  190,
  193,
  190,
  193,
  184,
  190,
  187,
  193,
  187,
  190,
  196,
  190,
  190,
  187,
  196,
  190,
  190,
  190,
  184,
  190,
  196,
  196,
  196,
  190,
  190,
  196,
  184,
  190,
  187,
  193,
  190,
  184,
  190,
  193,
  190,
  193,
  190,
  187,
  187,
  187,
  190,
  190,
  190,
  193,
  187,
  184,
  184,
  193,
  184,
  187,
  184,
  190,
  196,
  190,
  184,
  190,
  187,
  187,
  187,
  190,
  190,
  187,
  190,
  187,
  187,
  190,
  187,
  193,
  190,
  190,
  187,
  187,
  190,
  187,
  190,
  184,
  196,
  193,
  190,
  190,
  187,
  187,
  184,
  190,
  184,
  190,
  193,
  187,
  187,
  190,
  193,
  190,
  193,
  190,
  196,
  193,
  190,
  190,
  187,
  187,
  190,
  190,
  187,
  190,
  196,
  193,
  187,
  190,
  187,
  190,
  181,
  196,
  190,
  190,
  187,
  181,
  190,
  193,
  190,
  193,
  184,
  193,
  190,
  190,
  190,
  187,
  190,
  193,
  190,
  190,
  184,
  190,
  193,
  187,
  193,
  190,
  187,
  190,
  190,
  190,
  190,
  193,
  187,
  190,
  187,
  190,
  193,
  190,
  184,
  184,
  196,
  196,
  196,
  190,
  196,
  196,
  187,
  184,
  193,
  187,
  187,
  196,
  190,
  190,
  187,
  187,
  196,
  190,
  187,
  184,
  193,
  190,
  190,
  184,
  190,
  190,
  190,
  187,
  187,
  184,
  190,
  187,
  193,
  196,
  184,
  190,
  190,
  193,
  187,
  190,
  190,
  190,
  187,
  196,
  187,
  190,
  196,
  190,
  190,
  190,
  196,
  193,
  190,
  190,
  190,
  184,
  193,
  193,
  178,
  190,
  190,
  190,
  190,
  193,
  190,
  184,
  196,
  184,
  193,
  187,
  190,
  190,
  193,
  184,
  187,
  190,
  190,
  190,
  196,
  193,
  193,
  190,
  184,
  181,
  193,
  190,
  190,
  190,
  190,
  193,
  187,
  190,
  184,
  190,
  196,
  190,
  193,
  196,
  190,
  190,
  190,
  190,
  187,
  190,
  187,
  190,
  190,
  196,
  187,
  193,
  190,
  190,
  193,
  190,
  190,
  193,
  190,
  187,
  190,
  196,
  190,
  187,
  184,
  190,
  196,
  190,
  190,
  196,
  184,
  193,
  190,
  184,
  196,
  184,
  184,
  193,
  184,
  187,
  193,
  187,
  196,
  190,
  187,
  196,
  193,
  190,
  187,
  184,
  196,
  196,
  193,
  193,
  190,
  190,
  187,
  190,
  193,
  193,
  193,
  190,
  187,
  190,
  196,
  193,
  190,
  184,
  190,
  193,
  190,
  193,
  184,
  193,
  190,
  190,
  193,
  187,
  190,
  196,
  196,
  190,
  193,
  193,
  193,
  190,
  190,
  190,
  190,
  187,
  193,
  187,
  193,
  190,
  190,
  190,
  190,
  190,
  190,
  184,
  190,
  193,
  190,
  184,
  190,
  190,
  184,
  187,
  193,
  184,
  187,
  187,
  190,
  196,
  190,
  187,
  193,
  190,
  190,
  190,
  190,
  184,
  190,
  187,
  196,
  184,
  187,
  193,
  187,
  190,
  187,
  184,
  190,
  187,
  193,
  184,
  193,
  190,
  184,
  193,
  187,
  184,
  190,
  190,
  193,
  190,
  184,
  187,
  193,
  196,
  190,
  193,
  193,
  193,
  193,
  190,
  193,
  187,
  187,
  190,
  190,
  187,
  184,
  190,
  187,
  190,
  193,
  190,
  187,
  187,
  190,
  187,
  193,
  187,
  184,
  190,
  196,
  196,
  196,
  193,
  190,
  190,
  190,
  190,
  193,
  190,
  190,
  184,
  190,
  196,
  190,
  184,
  193,
  193,
  190,
  190,
  187,
  193,
  190,
  190,
  190,
  190,
  193,
  190,
  193,
  190,
  187,
  190,
  187,
  190,
  190,
  187,
  196,
  190,
  190,
  187,
  196,
  196,
  187,
  190,
  190,
  193,
  184,
  190,
  193,
  196,
  193,
  190,
  190,
  190,
  196,
  187,
  190,
  187,
  196,
  187,
  190,
  196,
  184,
  193,
  196,
  184,
  196,
  190,
  193,
  193,
  184,
  193,
  184,
  190,
  187,
  190,
  190,
  190,
  193,
  187,
  190,
  190,
  187,
  196,
  190,
  190,
  187,
  190,
  184,
  190,
  190,
  193,
  196,
  193,
  184,
  190,
  184,
  187,
  184,
  190,
  193,
  190,
  187,
  193,
  187,
  193,
  190,
  184,
  193,
  190,
  190,
  190,
  193,
  193,
  187,
  184,
  193,
  187,
  187,
  190,
  193,
  184,
  184,
  190,
  193,
  190,
  187,
  190,
  184,
  184,
  187,
  190,
  190,
  187,
  190,
  190,
  193,
  196,
  196,
  187,
  196,
  184,
  187,
  187,
  190,
  196,
  193,
  184,
  193,
  190,
  196,
  190,
  190,
  190,
  184,
  190,
  181,
  193,
  190,
  190,
  193,
  184,
  190,
  184,
  190,
  184,
  184,
  190,
  193,
  196,
  190,
  187,
  190,
  190,
  190,
  190,
  193,
  187,
  184,
  187,
  196,
  187,
  196,
  190,
  190,
  193,
  187,
  187,
  190,
  196,
  196,
  193,
  190,
  193,
  190,
  190,
  196,
  190,
  190,
  190,
  193,
  187,
  190,
  193,
  190,
  184,
  184,
  196,
  187,
  190,
  181,
  193,
  193,
  190,
  193,
  190,
  187,
  187,
  196,
  190,
  190,
  190,
  196,
  193,
  196,
  196,
  184,
  196,
  187,
  190,
  190,
  193,
  190,
  187,
  190,
  187,
  196,
  190,
  184,
  193,
  196,
  190,
  193,
  187,
  190,
  187,
  184,
  190,
  190,
  190,
  184,
  190,
  181,
  190,
  196,
  190,
  190,
  193,
  187,
  190,
  190,
  187,
  184,
  193,
  187,
  196,
  184,
  190,
  187,
  196,
  190,
  193,
  190,
  196,
  190,
  190,
  190,
  196,
  190,
  187,
  190,
  193,
  184,
  190,
  196,
  190,
  193,
  190,
  187,
  190,
  187,
  190,
  187,
  190,
  184,
  187,
  190,
  193,
  193,
  193,
  190,
  187,
  196,
  184,
  190,
  190,
  187,
  187,
  196,
  187,
  196,
  196,
  187,
  190,
  190,
  187,
  190,
  190,
  190,
  190,
  190,
  190,
  193,
  190,
  184,
  190,
  190,
  190,
  187,
  196,
  190,
  190,
  190,
  193,
  190,
  196,
  184,
  190,
  187,
  184,
  190,
  184,
  190,
  187,
  190,
  187,
  190,
  190,
  190,
  190,
  190,
  190,
  193,
  190,
  193,
  184,
  193,
  187,
  187,
  193,
  196,
  190,
  190,
  196,
  187,
  190,
  190,
  184,
  196,
  190,
  190,
  187,
  187,
  190,
  193,
  190,
  187,
  193,
  193,
  190,
  193,
  196,
  184,
  190,
  190,
  187,
  193,
  190,
  184,
  187,
  190,
  193,
  187,
  184,
  187,
  187,
  193,
  184,
  193,
  190,
  190,
  193,
  190,
  187,
  184,
  184,
  190,
  190,
  193,
  193,
  193,
  190,
  190,
  193,
  190,
  193,
  190,
  190,
  187,
  196,
  190,
  193,
  187,
  199,
  190,
  190,
  190,
  190,
  193,
  190,
  190,
  190,
  184,
  190,
  196,
  193,
  187,
  196,
  190,
  196,
  187,
  190,
  190,
  190,
  187,
  187,
  190,
  193,
  193,
  187,
  187,
  190,
  190,
  187,
  187,
  190,
  190,
  193,
  196,
  190,
  190,
  190,
  190,
  178,
  190,
  196,
  193,
  196,
  190,
  187,
  184,
  184,
  193,
  193,
  187,
  187,
  193,
  184,
  187,
  190,
  184,
  190,
  187,
  193,
  196,
  193,
  190,
  190,
  190,
  196,
  193,
  190,
  190,
  196,
  184,
  187,
  187,
  187,
  190,
  184,
  184,
  190,
  196,
  190,
  196,
  190,
  193,
  184,
  187,
  193,
  193,
  184,
  187,
  187,
  187,
  187,
  193,
  187,
  196,
  187,
  193,
  193,
  190,
  187,
  187,
  187,
  184,
  190,
  196,
  193,
  196,
  187,
  190,
  187,
  196,
  196,
  193,
  190,
  190,
  178,
  190,
  196,
  193,
  190,
  193,
  187,
  196,
  187,
  196,
  193,
  184,
  187,
  184,
  193,
  193,
  187,
  184,
  181,
  193,
  193,
  190,
  184,
  184,
  196,
  190,
  190,
  196,
  190,
  193,
  190,
  193,
  193,
  190,
  193,
  187,
  187,
  193,
  187,
  184,
  187,
  181,
  196,
  190,
  187,
  187,
  190,
  193,
  187,
  187,
  193,
  184,
  193,
  190,
  196,
  190,
  193,
  190,
  187,
  187,
  190,
  187,
  184,
  190,
  190,
  181,
  190,
  187,
  184,
  190,
  196,
  184,
  196,
  187,
  187,
  190,
  187,
  187,
  193,
  190,
  187,
  187,
  190,
  190,
  190,
  190,
  190,
  190,
  187,
  187,
  190,
  190,
  184,
  184,
  193,
  193,
  187,
  187,
  193,
  193,
  187,
  184,
  190,
  187,
  193,
  193,
  190,
  184,
  187,
  187,
  190,
  193,
  190,
  190,
  190,
  178,
  184,
  187,
  196,
  190,
  190,
  187,
  184,
  196,
  184,
  184,
  184,
  187,
  190,
  187,
  190,
  190,
  196,
  190,
  193,
  190,
  187,
  196,
  190,
  187,
  193,
  193,
  190,
  196,
  190,
  187,
  190,
  190,
  190,
  190,
  187,
  190,
  184,
  193,
  196,
  187,
  187,
  187,
  193,
  190,
  190,
  190,
  196,
  193,
  190,
  187,
  187,
  190,
  187,
  193,
  193,
  196,
  187,
  190,
  193,
  190,
  187,
  184,
  190,
  190,
  193,
  193,
  184,
  190,
  196,
  193,
  190,
  193,
  187,
  187,
  190,
  190,
  193,
  187,
  190,
  181,
  193,
  190,
  190,
  190,
  190,
  190,
  184,
  193,
  190,
  190,
  190,
  187,
  193,
  184,
  190,
  193,
  184,
  190,
  187,
  193,
  184,
  193,
  193,
  184,
  184,
  193,
  193,
  187,
  190,
  187,
  187,
  190,
  190,
  184,
  193,
  190,
  184,
  190,
  193,
  190,
  184,
  187,
  190,
  190,
  196,
  184,
  193,
  196,
  187,
  184,
  184,
  196,
  187,
  187,
  184,
  187,
  190,
  190,
  190,
  193,
  193,
  187,
  190,
  187,
  193,
  190,
  196,
  184,
  187,
  190,
  190,
  190,
  187,
  184,
  187,
  190,
  190,
  193,
  193,
  196,
  181,
  193,
  187,
  190,
  193,
  190,
  190,
  193,
  187,
  187,
  193,
  187,
  190,
  193,
  187,
  187,
  190,
  187,
  190,
  190,
  193,
  184,
  187,
  187,
  190,
  193,
  190,
  184,
  193,
  187,
  196,
  184,
  190,
  187,
  193,
  184,
  196,
  190,
  187,
  193,
  190,
  196,
  193,
  184,
  190,
  193,
  190,
  190,
  187,
  181,
  190,
  193,
  187,
  187,
  187,
  187,
  187,
  193,
  187,
  193,
  181,
  190,
  193,
  187,
  190,
  184,
  187,
  190,
  190,
  196,
  190,
  190,
  187,
  187,
  193,
  190,
  190,
  190,
  193,
  193,
  187,
  190,
  187,
  187,
  193,
  190,
  187,
  184,
  196,
  187,
  193,
  187,
  187,
  190,
  196,
  190,
  184,
  193,
  187,
  190,
  196,
  193,
  184,
  190,
  184,
  187,
  187,
  193,
  184,
  187,
  193,
  190,
  193,
  184,
  196,
  187,
  190,
  190,
  187,
  190,
  196,
  190,
  187,
  190,
  184,
  190,
  190,
  190,
  187,
  193,
  190,
  190,
  193,
  190,
  193,
  193,
  181,
  193,
  196,
  190,
  184,
  190,
  196,
  187,
  187,
  187,
  181,
  190,
  190,
  190,
  190,
  187,
  184,
  193,
  190,
  187,
  190,
  187,
  190,
  193,
  190,
  187,
  190,
  196,
  193,
  190,
  193,
  187,
  193,
  184,
  184,
  193,
  190,
  196,
  184,
  184,
  184,
  190,
  187,
  190,
  190,
  196,
  190,
  193,
  184,
  196,
  187,
  187,
  187,
  190,
  196,
  190,
  190,
  190,
  190,
  193,
  196,
  190,
  187,
  193,
  187,
  184,
  190,
  187,
  187,
  190,
  196,
  187,
  190,
  193,
  190,
  193,
  184,
  190,
  187,
  190,
  190,
  190,
  187,
  190,
  196,
  193,
  190,
  181,
  190,
  190,
  190,
  190,
  193,
  196,
  196,
  193,
  193,
  190,
  190,
  184,
  190,
  190,
  190,
  190,
  190,
  184,
  190,
  187,
  187,
  190,
  196,
  193,
  193,
  181,
  190,
  196,
  190,
  184,
  190,
  184,
  187,
  190,
  190,
  181,
  196,
  187,
  196,
  184,
  190,
  187,
  190,
  193,
  187,
  196,
  196,
  184,
  193,
  184,
  190,
  190,
  193,
  187,
  190,
  187,
  190,
  193,
  193,
  190,
  187,
  193,
  190,
  193,
  193,
  190,
  184,
  184,
  193,
  190,
  193,
  190,
  184,
  190,
  193,
  187,
  187,
  187,
  187,
  196,
  193,
  187,
  190,
  193,
  193,
  190,
  193,
  193,
  187,
  187,
  190,
  184,
  190,
  193,
  193,
  187,
  190,
  190,
  196,
  193,
  193,
  184,
  187,
  190,
  190,
  187,
  190,
  196,
  190,
  187,
  190,
  184,
  190,
  181,
  190,
  187,
  187,
  193,
  193,
  190,
  190,
  193,
  190,
  184,
  190,
  196,
  190,
  190,
  187,
  187,
  184,
  190,
  190,
  190,
  193,
  184,
  190,
  187,
  193,
  184,
  184,
  187,
  190,
  190,
  190,
  187,
  190,
  196,
  196,
  187,
  190,
  196,
  196,
  187,
  190,
  193,
  193,
  187,
  193,
  196,
  184,
  190,
  193,
  190,
  190,
  190,
  193,
  193,
  190,
  190,
  193,
  184,
  196,
  190,
  187,
  190,
  187,
  196,
  190,
  184,
  181,
  187,
  193,
  196,
  190,
  190,
  196,
  190,
  196,
  190,
  190,
  190,
  190,
  190,
  187,
  184,
  187,
  190,
  187,
  196,
  196,
  184,
  190,
  196,
  190,
  193,
  190,
  193,
  184,
  193,
  190,
  190,
  187,
  184,
  193,
  187,
  193,
  187,
  196,
  190,
  187,
  184,
  184,
  184,
  193,
  187,
  184,
  193,
  184,
  190,
  190,
  184,
  193,
  193,
  190,
  193,
  196,
  190,
  193,
  190,
  190,
  190,
  190,
  193,
  187,
  187,
  184,
  196,
  190,
  196,
  196,
  190,
  187,
  193,
  184,
  193,
  184,
  193,
  190,
  193,
  190,
  181,
  190,
  187,
  190,
  190,
  190,
  190,
  193,
  187,
  190,
  190,
  190,
  190,
  184,
  193,
  190,
  196,
  196,
  187,
  187,
  190,
  181,
  190,
  184,
  190,
  187,
  187,
  181,
  193,
  187,
  193,
  190,
  196,
  193,
  184,
  190,
  184,
  196,
  190,
  190,
  187,
  193,
  193,
  184,
  196,
  190,
  187,
  190,
  190,
  193,
  193,
  187,
  190,
  193,
  193,
  184,
  181,
  187,
  190,
  193,
  184,
  187,
  190,
  190,
  184,
  190,
  190,
  181,
  190,
  184,
  187,
  190,
  184,
  196,
  190,
  196,
  190,
  181,
  190,
  190,
  184,
  196,
  190,
  187,
  193,
  190,
  187,
  190,
  190,
  187,
  190,
  190,
  190,
  190,
  193,
  187,
  193,
  181,
  196,
  193,
  184,
  181,
  190,
  187,
  193,
  187,
  190,
  193,
  196,
  193,
  193,
  190,
  181,
  187,
  190,
  190,
  193,
  193,
  193,
  187,
  193,
  187,
  184,
  190,
  193,
  190,
  196,
  196,
  193,
  184,
  187,
  196,
  190,
  190,
  196,
  187,
  184,
  190,
  187,
  190,
  190,
  190,
  196,
  187,
  187,
  187,
  190,
  187,
  190,
  178,
  187,
  184,
  190,
  196,
  196,
  190,
  190,
  190,
  196,
  190,
  193,
  190,
  190,
  190,
  187,
  190,
  190,
  187,
  187,
  190,
  187,
  187,
  196,
  187,
  190,
  190,
  190,
  184,
  190,
  190,
  190,
  196,
  196,
  187,
  196,
  190,
  187,
  187,
  184,
  190,
  193,
  190,
  193,
  193,
  193,
  196,
  193,
  196,
  190,
  190,
  196,
  196,
  190,
  190,
  181,
  190,
  193,
  184,
  190,
  196,
  190,
  187,
  184,
  193,
  193,
  181,
  187,
  190,
  196,
  181,
  181,
  187,
  190,
  193,
  193,
  184,
  187,
  193,
  190,
  193,
  187,
  184,
  184,
  193,
  193,
  190,
  190,
  184,
  187,
  190,
  190,
  187,
  190,
  190,
  184,
  190,
  193,
  193,
  196,
  187,
  193,
  187,
  193,
  187,
  190,
  190,
  190,
  190,
  190,
  190,
  193,
  190,
  196,
  190,
  193,
  187,
  196,
  190,
  190,
  187,
  190,
  193,
  193,
  187,
  187,
  193,
  196,
  190,
  190,
  193,
  190,
  193,
  184,
  190,
  193,
  190,
  187,
  193,
  190,
  184,
  184,
  193,
  184,
  193,
  190,
  193,
  190,
  187,
  196,
  196,
  190,
  190,
  190,
  196,
  190,
  187,
  187,
  184,
  196,
  193,
  187,
  190,
  190,
  190,
  196,
  187,
  184,
  196,
  190,
  184,
  187,
  190,
  184,
  193,
  187,
  190,
  187,
  184,
  196,
  193,
  190,
  187,
  196,
  187,
  187,
  187,
  190,
  193,
  184,
  187,
  193,
  190,
  193,
  190,
  193,
  187,
  196,
  187,
  190,
  190,
  190,
  187,
  190,
  196,
  190,
  190,
  190,
  187,
  190,
  190,
  187,
  196,
  190,
  193,
  193,
  190,
  193,
  193,
  190,
  184,
  193,
  184,
  190,
  190,
  196,
  190,
  193,
  184,
  193,
  196,
  193,
  193,
  184,
  190,
  190,
  190,
  190,
  184,
  193,
  193,
  193,
  190,
  190,
  190,
  196,
  190,
  190,
  190,
  187,
  190,
  178,
  190,
  187,
  184,
  190,
  190,
  193,
  193,
  187,
  190,
  193,
  187,
  187,
  196,
  190,
  184,
  193,
  196,
  190,
  190,
  193,
  184,
  190,
  184,
  187,
  193,
  190,
  193,
  196,
  190,
  184,
  190,
  184,
  187,
  196,
  190,
  190,
  190,
  190,
  190,
  190,
  190,
  190,
  184,
  187,
  193,
  196,
  190,
  193,
  181,
  190,
  190,
  187,
  181,
  193,
  184,
  190,
  187,
  193,
  193,
  187,
  196,
  190,
  196,
  187,
  190,
  187,
  196,
]
export default RealAntiBiasJudgementSimData
